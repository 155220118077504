import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter
} from '@reduxjs/toolkit';

import { createImage } from "./imagesSlice";
import client from '../../api/client';
import client_ai from '../../api/client_ai';

const identificationsAdapter = createEntityAdapter();
const initialState = identificationsAdapter.getInitialState({
  status: 'idle',
  error: null,
  currentImage: null,
  currentSearchImage: null,
  currentIdentification: null,
  detectTextLanguage: "eng"
});

// fetch all identifications
export const fetchIdentifications = createAsyncThunk('identifications/fetchIdentifications', async (parameters, thunkAPI) => {
  const response = await client.get('/identification/getAll');
  return response.identifications;
});


// adding image
export const createIdentification = createAsyncThunk(
  'images/createIdentification',
  async (parameters, thunkAPI) => {
    let response = await thunkAPI.dispatch(createImage(parameters))
      .then(async function (image) {
        const body = {
          image: image.payload.id
        };
        const response = await client.post('/identification/create', body);
        return response;
      })
    return response.identification;
  }
);

export const deleteIdentification = createAsyncThunk(
  'images/deleteIdentification',
  async (parameters) => {
    const response = await client.get('/deleteIdentification/' + parameters.objectId);
    if (response) return { id: parameters.objectId };
  });


export const launchDetectText = createAsyncThunk(
  'image/detectText',
  async (_parameters, { getState }) => {
    const state = getState();
    const body = {
      image_url: state.identifications.currentIdentification.images[0].urls.default,
      lang: state.identifications.detectTextLanguage
    };
    const response = await client_ai.post('/detect_text', body);
    return response.text;
  });

// THE REDUCERS
const identificationsSlice = createSlice({
  name: 'identifications',
  initialState,
  reducers: {
    setCurrentImage(state, action) {
      state.currentImage = action.payload
    },
    setCurrentSearchImage(state, action) {
      state.currentSearchImage = action.payload
    },
    setCurrentIdentification(state, action) {
      state.currentIdentification = action.payload
    },
    setCurrentDetectedText(state, action) {
      state.currentDetectedText = action.payload
    },
    setDetectTextLanguage(state, action) {
      console.log(action.payload)
      state.detectTextLanguage = action.payload
    }
  },
  extraReducers: {
    [fetchIdentifications.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchIdentifications.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      // Add any fetched investigations to the array
      identificationsAdapter.upsertMany(state, action.payload);
    },
    [fetchIdentifications.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [launchDetectText.fulfilled]: (state, action) => {
      state.currentDetectedText = action.payload;
    },
    [createIdentification.fulfilled]: (state, action) => {
      identificationsAdapter.upsertOne(state, action.payload);
      state.currentIdentification = action.payload;
    },
  },
});

const selectSelf = identificationsAdapter.getSelectors((state) => state.identifications).selectAll

export const useSortedIdentifications = createSelector(selectSelf, (state) => {
  var new_state = state.sort((a, b) => {
    console.log(a["created"])
    return a["created"] < b["created"];
  })
  console.log(new_state)
  return new_state
})


export default identificationsSlice.reducer;

export const { setCurrentImage, setCurrentSearchImage, setCurrentIdentification, setCurrentDetectedText, setDetectTextLanguage } = identificationsSlice.actions;

export const {
  selectAll: selectAllIdentifications,
  selectById: selectIdentificationById,
  selectIds: selectIdentificationIds,
} = identificationsAdapter.getSelectors((state) => state.identifications);
