import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectTreeCategoryById, updateCategory} from "../../../store/entities/treeCategoriesSlice";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel, Grid, TextField
} from "@material-ui/core";
import {selectFieldEdited, selectFieldSelected, setCategoryEdited} from "./TreeEditorSlice";
import tdb from "../../../services/TranslateDB";
import Translatable from "../../Collection/Fields/Translatable2";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import FieldSet from "../../Misc/FieldSet";
import {toLowerKeys} from "../../Misc/languages";
import axios from "axios";

export default function EditCategory({categoryId}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const category = useSelector(state => selectTreeCategoryById(state, categoryId));

    const [form, setForm] = useState({
        name: category.name || "",
        title: toLowerKeys(category.title) || {},
        synonym: toLowerKeys(category.synonym) || {},
        false_cognate: toLowerKeys(category.false_cognate) || {},
        thesaurus: category.thesaurus || "",
        branch: category.branch || "",
        wiki_data_ref: category.wiki_data_ref || "",
        wiki_data_url: category.wiki_data_url || "",
        aat_ref: category.aat_ref || "",
        aat_url: category.aat_url || "",
        parent_id: category.parent_id || ""
    });
    
    const reduceLabels = (labels) => {
        const _labels = {}
        Object.keys(labels).forEach(key=>{
            _labels[key] = labels[key].value
        })
        return _labels
    }
    
    const handleImportWikiData = () => {
        const URL = `https://www.wikidata.org/wiki/Special:EntityData/${form.wiki_data_ref}.json`;
        console.log("LOAD", URL);
        axios.get(URL)
            .then(res => {
                if (res.data && res.data.entities && res.data.entities[form.wiki_data_ref]) {
                    const _entity = res.data.entities[form.wiki_data_ref];
                    if (_entity.labels) {
                        setForm(state=>({...state, title: reduceLabels(_entity.labels)}))
                    }
                }
                console.log(res.data);
            })
            .catch(e=>{
                // console.log(e);
            })
    }
    
    const handleSave = () => {
        dispatch(updateCategory({
            categoryId: categoryId,
            body: form
        }));
        handleClose();
    }

    const handleClose = () => {
        dispatch(setCategoryEdited(null))
    }

    return <Dialog open={true} onClose={handleClose} maxWidth={"md"} fullWidth>
        <DialogTitle className={classes.dialogTitle}>
            {tdb(category.title)} !
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid xs={12} item>
                    <TextField
                        fullWidth
                        label={"Ref"}
                        variant={"outlined"}
                        value={form.name}
                        onChange={(event)=>setForm(state=>({...state, name: event.target.value}))}
                    />
                </Grid>
                <Grid xs={12} item>
                    <Translatable
                        inputProps={{variant: "outlined"}}
                        initValue={form.title}
                        onChange={(newValue)=>setForm(state=>({...state, title:newValue}))}
                        label={t("Title")}
                        fieldsetMode={t("Title")}
                    />
                </Grid>
                <Grid sm={6} xs={12} item>
                    <FieldSet title={"WikiData"}>
                        <TextField
                            label={"Ref"}
                            variant={"outlined"}
                            value={form.wiki_data_ref}
                            onChange={(event)=>setForm(state=>({...state, wiki_data_ref: event.target.value}))}
                        />
                        <TextField
                            label={"URL"}
                            variant={"outlined"}
                            value={form.wiki_data_url}
                            style={{marginTop: '1rem'}}
                            //InputProps={{disableUnderline:true}}
                            onChange={(event)=>setForm(state=>({...state, wiki_data_url: event.target.value}))}
                        />
                    </FieldSet>
                </Grid>
                <Grid sm={6} xs={12} item>
                    <FieldSet title={"AAT"}>
                        <TextField
                            label={"Ref"}
                            variant={"outlined"}
                            value={form.aat_ref}
                            onChange={(event)=>setForm(state=>({...state, aat_ref: event.target.value}))}
                        />
                        <TextField
                            label={"URL"}
                            variant={"outlined"}
                            value={form.aat_url}
                            style={{marginTop: '1rem'}}
                            onChange={(event)=>setForm(state=>({...state, aat_url: event.target.value}))}
                        />
                    </FieldSet>
                </Grid>
                <Grid xs={12} item>
                    <Translatable
                        inputProps={{variant: "outlined"}}
                        initValue={form.false_cognate}
                        onChange={(newValue)=>setForm(state=>({...state, false_cognate:newValue}))}
                        label={t("FalseCognate")}
                        fieldsetMode={t("FalseCognate")}
                    />
                </Grid>
                <Grid xs={12} item>
                    <Translatable
                        inputProps={{variant: "outlined"}}
                        initValue={form.synonym}
                        onChange={(newValue)=>setForm(state=>({...state, synonym:newValue}))}
                        label={t("Synonyms")}
                        fieldsetMode={t("Synonyms")}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color={"default"} variant={"contained"} onClick={handleImportWikiData} disabled={!form.wiki_data_ref}>
                {t('TreeEditorCategoryEdit.ImportFromWikidata')}
            </Button>
            <Button color={"secondary"} variant={"contained"} onClick={handleSave}>
                {t('TreeEditorCategoryEdit.Save')}
            </Button>
        </DialogActions>
    </Dialog>
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
    },
    group: {
        marginTop: '1rem'
    },
    fieldset: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.grey[500],
        padding: theme.spacing(1)
    },
    legend: {
        backgroundColor: "white",
        paddingLeft: 5,
        paddingRight: 5,
    }
}))