import React from "react";
import { useDispatch } from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import {getCriminalCasesFields} from '../../api/criminalCasesFields';
import {useTranslation} from "react-i18next";
import {CardActions, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import {Save} from "@material-ui/icons";

// import {selectCriminalCaseById, fetchCriminalCaseById} from "../../store/reducers/criminalCasesSlice";

const CriminalCaseCreate = ({onClose}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const fields = getCriminalCasesFields();
    const handleSubmit = (event, data) => {
        console.log(data);
    };
    return <Dialog open={true} onClose={onClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>{t("CreateNewCase")}</DialogTitle>
        <DialogContent>
            {/* TODO: form here */}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputGroup group="legal" label={"Legal"} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup group="action" label={"Action"} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup group="victim" label={"Victim"} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup group="info" label={"Info"} />
                    </Grid>
                    <Grid item xs={12}>
                        <InputGroup group="admin" label={"Admin"} />
                    </Grid>
                </Grid>
                
        </DialogContent>
        <DialogActions>
            <Button type="submit" variant="contained" color="primary" onClick={onClose}>
                {t('Cancel')}
            </Button>
            <Button startIcon={<Save />} type="submit" variant="contained" color="secondary">
                {t('Create')}
            </Button>
        </DialogActions>
   </Dialog>;
};

function InputGroup({group, label}) {
    const fields = getCriminalCasesFields();
    const {t} = useTranslation()
    const groupedField = fields.filter(field => field.group === group);
    return <Card elevation={2}>
        <CardHeader title={t("CriminalCase-"+label)} />
        <CardContent>
            {
                groupedField.map(field => {
                    return <InputField field={field}/>
                })
            }
        </CardContent>
    </Card>;
}

function InputField({field}) {
    const classes = useStyles();
    const {t} = useTranslation()
    return <TextField
        id={field.field}
        label={t("CriminalCase--"+field.field)}
        type="text"
        variant="outlined"
        className={classes.input}
    />
}

function DataDisplay({label, value}) {
    return <Grid container>
        <Grid xs={2}>{label}</Grid>
        <Grid xs={4}>{value}</Grid>
    </Grid>;
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    input: {
        marginTop: ".5rem",
        width: '100%'
    }
}));

export default CriminalCaseCreate;