import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit';
import client from '../../api/client';

const initialState = {
    status: 'idle',
    error: null,
    currentUser: null
};

export const fetchCurrentUser = createAsyncThunk(
    'currentUser/fetchCurrentUser',
    async () => {
        const response = await client.get('/getCurrentUser');
        return response.user;
});

export const logoutUser = createAsyncThunk(
    'currentUser/logoutUser',
    async () => {
        const response = await client.get('/logout');
        return null;
    });

const usersSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        userUpdated(state, action) {
            const { id, title, content } = action.payload;
            const existingUser = state.entities[id];
            if (existingUser) {
                existingUser.title = title;
                existingUser.content = content;
            }
        }

    },
    extraReducers: {
        // fetchUsers
        [fetchCurrentUser.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCurrentUser.fulfilled]: (state, action) => {
            state.status = 'success';
            state.currentUser = action.payload;
        },
        [fetchCurrentUser.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [logoutUser.fulfilled]: (state, action) => {
            state.status = 'logout';
            state.currentUser = null;
        }
    },
});

export default usersSlice.reducer

export const { userUpdated } = usersSlice.actions;

export const getCurrentUser = (state) => state.currentUser.currentUser;

export const isGranted = (currentUser, type) => {
    const userType = currentUser.type;
    return userType === 'admin' || userType === type;
}

const ALLOW = true;
const DENY = false;

const accessMatrix = {
    home: {             all:ALLOW, lea:ALLOW, prevision: ALLOW, inventory: ALLOW},
    cases: {            all:DENY,  lea:ALLOW, prevision: DENY,  inventory: DENY},
    investigations: {   all:DENY,  lea:ALLOW, prevision: DENY,  inventory: DENY},
    identifications: {  all:ALLOW, lea:ALLOW, prevision: ALLOW, inventory: ALLOW},
    queries: {          all:DENY,  lea:ALLOW, prevision: ALLOW, inventory: DENY},
    surveys: {          all:DENY,  lea:ALLOW, prevision: ALLOW, inventory: DENY},
    collections: {      all:DENY,  lea:ALLOW, prevision: DENY,  inventory: ALLOW},
    datasources: {      all:ALLOW, lea:ALLOW, prevision: ALLOW, inventory: ALLOW},
    places: {           all:DENY,  lea:ALLOW, prevision: DENY,  inventory: DENY},
    thesaurus: {        all:DENY,  lea:DENY,  prevision: ALLOW, inventory: DENY},
    users: {            all:DENY,  lea:ALLOW, prevision: DENY,  inventory: DENY},
    organizations: {    all:DENY,  lea:ALLOW, prevision: DENY,  inventory: DENY},
}

// to match the UI with API
export function isAllowed(type, topic) {
    if (type==="admin") {
        return true
    }
    else if (!(topic in accessMatrix)){
        return false;
    }
    else if (accessMatrix[topic].all) {
        return true;
    }
    else if (!(type in accessMatrix[topic])) {
        return false;
    }
    return accessMatrix[topic][type];
}