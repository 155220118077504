import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/client';
import {selectAllInvestigations} from "./investigationsSlice";

const treeCategoriesAdapter = createEntityAdapter();
const initialState = treeCategoriesAdapter.getInitialState({
    status: 'idle',
    updateStatus: false,
    createStatus: false,
    error: null,
});

// fetch all treeCategories
export const fetchTreeCategories = createAsyncThunk(
    'treeCategories/fetchTreeCategories',
    async (parameters, thunkAPI) => {
        const response = await client.get('/tree/category/all');
        return response.categories;
    }
);

// update Category
export const updateCategory = createAsyncThunk(
    'treeCategories/updateCategory',
    async (parameters, thunkAPI) => {
        const response = await client.post(`/tree/category/${parameters.categoryId}`, parameters.body);
        return response.category;
    }
);


// create Category
export const createCategory = createAsyncThunk(
    'treeCategories/createCategory',
    async (parameters, thunkAPI) => {
        const response = await client.post(`/tree/category/create`, parameters.body);
        return response.category;
    }
);

// THE REDUCERS
const treeCategoriesSlice = createSlice({
    name: 'treeCategories',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchTreeCategories.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchTreeCategories.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            treeCategoriesAdapter.upsertMany(state, action.payload);
        },
        [fetchTreeCategories.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [updateCategory.pending]: (state, action) => {
            state.updateStatus = 'loading';
        },
        [updateCategory.fulfilled]: (state, action) => {
            state.updateStatus = 'succeeded';
            treeCategoriesAdapter.upsertOne(state, action.payload);
        },
        [updateCategory.rejected]: (state, action) => {
            state.updateStatus = 'failed';
            state.error = action.payload;
        },
        [createCategory.pending]: (state, action) => {
            state.createStatus = 'loading';
        },
        [createCategory.fulfilled]: (state, action) => {
            state.createStatus = action.payload.id;
            treeCategoriesAdapter.upsertOne(state, action.payload);
        },
        [createCategory.rejected]: (state, action) => {
            state.createStatus = 'failed';
            state.error = action.payload;
        },
    },
});

// export const {  } = treeCategoriesSlice.actions;

export default treeCategoriesSlice.reducer

export const {
    selectAll: selectAllTreeCategories,
    selectById: selectTreeCategoryById,
    selectIds: selectTreeCategoryIds,
} = treeCategoriesAdapter.getSelectors((state) => state.treeCategories);

export const selectRootCategories = createSelector(
    [selectAllTreeCategories],
    (treeCategories) => treeCategories.filter((treeCategory) => (
        treeCategory.parent_id === null
    ))
);

export const selectSubCategories = createSelector(
    [selectAllTreeCategories, (state, parentId) => parentId],
    (treeCategories, parentId) => treeCategories.filter((treeCategory) => (
        treeCategory.parent_id === parentId
    ))
);

export const selectPathOfCategory = (state, categoryId) => {
    if (!categoryId)return [];
    let category = selectTreeCategoryById(state, categoryId);
    let ids = [];
    let parentId = category.parent_id;
    while (parentId) {
        category = selectTreeCategoryById(state, parentId);
        ids.push(category.id);
        parentId = category.parent_id;
    }
    return ids;
};

export const selectCategoryFlatTree = (state, categoryId) => {
    const tree = [];
    addTreeBranch(state, tree, 0, categoryId);
    return tree;
};

const addTreeBranch = (state, tree, level, categoryId) => {
    const children = selectSubCategories(state, categoryId);
    children.map(child => {
        tree.push({
            level: level,
            id: child.id,
            title: child.title
        });
        addTreeBranch(state, tree, level+1, child.id);
    })
}