import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useTranslation}     from 'react-i18next';

import {makeStyles, createTheme} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";

import { NavLink, useParams} from "react-router-dom";

import Typography from "@material-ui/core/Typography";

import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";

import {selectInvestigationById, fetchInvestigationById, fetchInvestigationUpdate, createInvestigation} from "../../store/entities/investigationsSlice";
import {selectTasksByInvestigation} from "../../store/entities/tasksSlice";
import {selectCriminalCasesByInvestigation} from "../../store/entities/criminalCasesSlice";
import {selectCollectionsByInvestigation} from "../../store/entities/collectionsSlice";

import {formatDataAsCols} from "../../services/dataGridTools";
import {SimpleGrid} from "../Misc/Table";
import Surveys from "../Survey/Surveys";
import EditableText from "../Collection/Fields/EditableText";
import {Avatar, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Link, TextField} from "@material-ui/core";
import Collection from "../Collection/Collection.jsx";
import NiceTooltip from "../Misc/CustomToolTip";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {Assignment, Edit, Save} from "@material-ui/icons";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import PolicyIcon from "@material-ui/icons/Policy";
import {HomeList, HomeListItem} from "../Home/Home";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import Add from "@material-ui/icons/Add";
import CollectionCreate from "../Collection/CollectionCreate";

const Investigation = () => {
    const { investigationId } = useParams();
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const investigation = useSelector(state => selectInvestigationById(state, investigationId));
    const tasks = useSelector(state => selectTasksByInvestigation(state, investigationId));
    const criminalCases = useSelector(state => selectCriminalCasesByInvestigation(state, investigationId));
    const collections = useSelector(state => selectCollectionsByInvestigation(state, investigationId));

    const [editing, setEditing] = useState(false);

    const handleChange = (value, field) =>  {
        let body = {};
        body[field] = value;
        dispatch(fetchInvestigationUpdate({
            investigationId: investigationId,
            body: body
        }))
    }
    
    const [openCreateTask, setOpenCreateTask] = useState(false);
    const handleOpenCreateTask = () => setOpenCreateTask(true);
    const handleCloseCreateTask = () => setOpenCreateTask(false);

    const handleLinkToCases = () => {

    }

    const [openCreateCollection, setOpenCreateCollection] = useState(false);
    const handleOpenCreateCollection = () => setOpenCreateCollection(true);
    const handleCloseCreateCollection = () => setOpenCreateCollection(false);

    useEffect(()=>{
        dispatch(fetchInvestigationById(investigationId));
    }, [investigationId, dispatch]);

    if (!investigation)return <Container disableGutters={true}>Loading</Container>;
    
    return <>
        <Card elevation={5} className={classes.investigation}>
            <CardHeader
                avatar={<Avatar><PolicyIcon  color={"primary"} /></Avatar>}
                title={
                    editing
                        ? <EditableText
                            initValue={investigation.name}
                            onChange={handleChange}
                            field='name'
                            variant='h5'
                            inputClasses={classes.h5input}
                        />
                        : <Typography variant={"h5"}>{investigation.name}</Typography>
                }
                subheader={
                        editing
                            ? <EditableText
                                initValue={investigation.description}
                                onChange={handleChange}
                                field='description'
                                inputClasses={classes.descriptionInput}
                            />
                            : investigation.description
                }
                action={
                    <ToggleButtonGroup>
                        <NiceTooltip title={t("toggleEditMode")} placement={"bottom-end"}>
                            <ToggleButton onClick={()=>setEditing(e=>!e)} selected={editing} size={"small"}>
                                <Edit />
                                <Typography variant={"subtitle2"} style={{marginLeft:5}}>{t('EditMode')}</Typography>
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>
                }
            />
            <CardContent>
                {criminalCases.length
                    ? <>
                        <Typography>{t('CriminalCaseInvestigation')}</Typography>
                        <HomeList>
                            {criminalCases.map((investigationCase, i) => <CriminalCaseSummary criminalCase={investigationCase} />)}
                        </HomeList>
                    </>
                    : <Typography>{t('NoInvestigationForThisCase')}</Typography>}
                    <Link href="#" color={"secondary"} onClick={handleLinkToCases}>{t("AttachCaseToInvestigation")}</Link>
            </CardContent>
            <CardContent>
                <>
                    <Card elevation={3}>
                        <CardHeader
                            title={<Typography variant={"h5"}> {t("Tasks")}</Typography>}
                            avatar={<Avatar><Assignment color={"primary"} /></Avatar>}
                        />
                        <CardContent>
                            {
                                ! tasks.length
                                    ?   <Typography>{t('NoTasksUpcoming')}</Typography>
                                    :   <SimpleGrid
                                        columns={[
                                            {field: "due_date",     label: t("Date"),          attr: {xs:2}},
                                            {field: "name",         label: t('Task'),          attr: {xs:4}},
                                            {field: "description",  label: t("Description"),   attr: {xs:6}}
                                        ]}
                                        rows={tasks}
                                        spacing={1}
                                        headerClass={classes.headerCell}
                                    />
                            }
                        </CardContent>
                        <CardActions style={{justifyContent: "flex-end"}}>
                            <Button startIcon={<Add />} color="secondary" variant="contained" onClick={handleOpenCreateTask}>
                                {t('CreateTask')}
                            </Button>
                            <InvestigationTaskCreate open={openCreateTask} onClose={handleCloseCreateTask} />
                        </CardActions>
                    </Card>
                </>
            </CardContent>
            <CardContent>
                <Surveys investigationId={investigationId} simpleTable={true} createButton={true} />
            </CardContent>
                {collections.map((collection, i) => (<CardContent>
                    {i>=0 && <Collection collectionId={collection.id} fullPage={false} givenPath={`/investigations/${investigationId}/`}/>}
                </CardContent>))}
            <CardActions style={{justifyContent: "flex-end"}}>
                <Button startIcon={<Add />} variant={"contained"} color={"secondary"} onClick={handleOpenCreateCollection}>
                    {t('AddCollectionInInvestigation')}
                </Button>
            </CardActions>
            <CollectionCreate open={openCreateCollection} onClose={handleCloseCreateCollection} investigationId={investigationId} />
        </Card>
    </>;
};


function CriminalCaseSummary({criminalCase}) {
    return <HomeListItem
        icon={<BusinessCenterIcon />}
        link={`/cases/${criminalCase.id}`}
    >
        {criminalCase.summary}
    </HomeListItem>;
}


function InvestigationTaskCreate({open, onClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch()

    const [date, setDate] = useState(null);
    const handleDate = (event) => setDate(event.target.value);

    const [name, setName] = useState(null);
    const handleName = (event) => setName(event.target.value);

    const [description, setDescription] = useState(null);
    const handleDescription = (event) => setDescription(event.target.value);

    const handleCreate = () => {
        if (name && description) {
            dispatch(createInvestigation({
                body: {
                    name: name,
                    description: description,
                }
            }));
            onClose();
        }
    }

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>
            {t('CreateTask')}
        </DialogTitle>
        <DialogContent>
            <Grid spacing={2} container>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth value={date} onChange={handleDate} placeholder="Date" type={t("date")} />
                </Grid>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth value={name} onChange={handleName} placeholder={t("Name")} />
                </Grid>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth value={description} onChange={handleDescription} placeholder={t("Description")} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" size="medium" color="primary" onClick={onClose}>
                {t('Cancel')}
            </Button>
            <Button startIcon={<Save />} variant="contained" size="medium" color="secondary" onClick={handleCreate}>
                {t('Create')}
            </Button>
        </DialogActions>
    </Dialog>;
}


function DataDisplay({label, value}) {
    const classes = useStyles();
    return <Grid item container className={classes.dataRow}>
        <Grid xs={4} className={classes.label}>{label}</Grid>
        <Grid xs={8} className={classes.value}>{value}</Grid>
    </Grid>;
}

// Theme
const lightTheme = (prevision = false) => createTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette)
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    investigation: {
        backgroundColor: 'rgba(255,255,255,0.8)'
    },
    dataRow: {
        borderTop: 'Apx solid #e0e0e0'
    },
    headerCell: {
        fontWeight: "bold"
    },
    h5input: {
        fontSize: theme.typography.h5.fontSize,
    },
    descriptionInput: {
        color: theme.palette.secondary
    },
    label: {
        color: '#666'
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));

export default Investigation;