import {
    createSlice
} from '@reduxjs/toolkit';

const initialState = {
    collectionDisplay: 'grid',
    collectionsDisplay: 'grid',
    collectionDirectUpload: false,
};

const preferencesSlice = createSlice({
    name: 'preferencesSlice',
    initialState,
    reducers: {
        setCollectionDisplay(state, action) {
            state.collectionDisplay = action.payload;
        },
        setCollectionsDisplay(state, action) {
            state.collectionsDisplay = action.payload;
        },
        setCollectionDirectUpload(state, action) {
            state.collectionDirectUpload = action.payload;
        },
    }
});

export default preferencesSlice.reducer

export const {
    setCollectionDisplay, setCollectionsDisplay
} = preferencesSlice.actions;

export const selectCollectionDisplay =          state => state.preferences.collectionDisplay;
export const selectCollectionsDisplay =         state => state.preferences.collectionsDisplay;
export const selectCollectionsDirectUpload =    state => state.preferences.collectionDirectUpload;
