import React, {useState, useEffect} from "react";
import {Chip, ClickAwayListener, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import {selectThesaurusFlatTree} from "../../../store/entities/treeThesaurusSlice";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import tdb from "../../../services/TranslateDB";
import {BookmarkBorder, TextFields} from "@material-ui/icons";

const KEY_ENTER = 13;
const KEY_ESC = 27;

export default function EditableThesaurus({multiple, initValue, rootId, field, label, textClasses, inputClasses, onChange, variant, inputVariant, initEditing = false}) {
    const [editing, setEditing] = useState(initEditing);
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const thesaurusTree = useSelector(state => selectThesaurusFlatTree(state, rootId));
    const [optionTree, setOptionTree] = useState([]);

    // can be optimized by saving into entity
    useEffect(()=>{
        if (0 && thesaurusTree) {
            const _options = thesaurusTree.map(t=>({...t,
                //stringPath: removeAccents(t.path.join(' / '))
                stringPath: t.path.map(p=>tdb(p)).join("/")
            }))
            setOptionTree(_options);
        }
    }, [thesaurusTree])

    useEffect(()=>{if (optionTree)console.log(optionTree)}, [optionTree]);

    const [value, setValue] = useState(initValue);

    const handleValue = (event, newValue) => {
        console.debug({newValue:newValue})
        onChange(newValue, field);
        setValue(newValue)
        if (!multiple) {
            setEditing(false);
        }

        // setValue(newValue);
        //if (!multiple) {
        //}
    }



    const handleEdit = () => setEditing(true);
    const handleUnEdit = () => {
        setEditing(false);
        onChange(value, field);
    }

    const clickAway = () => handleUnEdit();

    const handleKey = (event) => {
        if (event.keyCode === KEY_ENTER) {
            console.log(event.target)
            handleUnEdit();
        }
        else if (event.keyCode === KEY_ESC) {
            setValue(initValue);
            setEditing(false);
        }
    };

    const filterOptions = createFilterOptions({
        matchFrom: "any",
        ignoreAccents: true,
        ignoreCase: true,
        stringify: (option) => option.path.map(p=>tdb(p)).join("/")
    })

    return <>
        {!editing
            ? <div>
                <Typography
                    onClick={handleEdit}
                    classes={textClasses}
                    variant={variant}
                >
                    {label}
                </Typography>
            </div>
            : <ClickAwayListener onClickAway={clickAway}>
                <div style={{position:"relative"}}>
                    <Autocomplete
                        size="small"
                        multiple={multiple}
                        onKeyUp={handleKey}
                        filterOptions={filterOptions}
                        value={value}
                        onChange={handleValue}
                        disablePortal={false}
                        getOptionLabel={option => tdb(option.title)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                //classes={clsx(textClasses, classes.autocomplete)}
                                variant={inputVariant}
                                InputProps={{...params.InputProps, disableUnderline: true, autoFocus: true, classes: textClasses}}
                            />}
                        renderOption={option => (
                            <div style={{paddingLeft: 6 + option.level * 16}}>
                                {tdb(option.title)}
                            </div>
                        )}
                        renderTags={(value, getTagProps)=>
                            value.map((option, index) => (
                                <Chip variant="outlined" icon={option.id ? <BookmarkBorder fontSize={"small"} /> : <TextFields fontSize={"small"} />} label={(tdb(option.title))} {...getTagProps({ index })} />
                            ))
                        }
                        classes={{
                            paper: classes.acPaper,
                            option: classes.acOption
                        }}
                        options={thesaurusTree}
                    />
                </div>
            </ClickAwayListener>}</>
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    base: {
        borderWidth: 0,
        padding:0
    },
    input: {
        padding: 0
    },
    acPaper: {
        backgroundColor: '#eee',
    },
    acOption: {
        fontSize: '0.8rem',
        padding:1
    },
    autocomplete: {
        options: {
            padding: 2
        }
    }
}));