import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createTheme, makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {selectTreeCategoryById} from "../../../store/entities/treeCategoriesSlice";
import {selectTreeFieldById} from "../../../store/entities/treeFieldsSlice";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField
} from "@material-ui/core";
import Translatable from "../../Collection/Fields/Translatable2";
import {setFieldEdited} from "./TreeEditorSlice";
import {toLowerKeys} from "../../Misc/languages";
import {selectAllFieldTypes} from "../../../store/entities/fieldTypesSlice";
import tdb from "../../../services/TranslateDB";
import FieldSet from "../../Misc/FieldSet";
import EditableThesaurus from "../../Collection/Fields/EditableThesaurus";
import {
    selectPathOfThesaurus,
    selectRootThesauruses,
    selectTreeThesaurusById
} from "../../../store/entities/treeThesaurusSlice";



export default function FieldEdit({fieldId}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const field = useSelector(state => selectTreeFieldById(state, fieldId));
    const category = useSelector(state => selectTreeCategoryById(state, field.tree_category_id));
    const fieldTypes = useSelector(selectAllFieldTypes)
    
    const [fieldType, setFieldType] = useState();
    useEffect(()=>{
        if (fieldTypes && field) {
            setFieldType(fieldTypes.find(type=>type.id===field.tree_field_type_id));
        }
    }, [fieldTypes, field])
    
    const [form, setForm] = useState({
        name: field.name || "",
        grouped: field.grouped || "",
        ord: field.ord || "",
        title: toLowerKeys(field.title) || {},
        tree_thesaurus_id: field.tree_thesaurus_id || null,
        tree_field_type_id: field.tree_field_type_id || null
    });
    
    const handleClose = () => {
        dispatch(setFieldEdited(null))
    }
    
    const handleType = (event) => {
        // if ()
    }
    
    return <Dialog open={true} onClose={handleClose} maxWidth={"md"} fullWidth>
        <DialogTitle>
            {t('tree.tree-editor.field.operation.edit.dialog-title', {})}
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid xs={12} item>
                    <TextField
                        fullWidth
                        label={t("Name")}
                        variant={"outlined"}
                        value={form.name}
                        helper={t('tree.tree-editor.field.operation.edit.name-helper')}
                        onChange={(event)=>setForm(state=>({...state, name: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldSet title={"Type"}>
                        <TextField
                            select
                            variant={"standard"}
                            fullWidth
                            //label={t("Type")}
                            value={form.tree_field_type_id}
                            InputProps={{disableUnderline:true}}
                        >
                            {fieldTypes.map(_fieldType=>(<MenuItem key={_fieldType.id} value={_fieldType.id}>{tdb(_fieldType.title, _fieldType.name)}</MenuItem>))}
                        </TextField>
                        {fieldType && fieldType.name === "Thesaurus" && <FieldEditThesaurus
                            onChange={()=>{}}
                            fieldId={fieldId}
                        />}
                    </FieldSet>
                </Grid>
                <Grid item xs={12}>
                    <Translatable
                        inputProps={{variant: "outlined"}}
                        initValue={form.title}
                        onChange={(newValue)=>setForm(state=>({...state, title:newValue}))}
                        label={t("Title")}
                        fieldsetMode={t("Title")}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
        
        </DialogActions>
    </Dialog>
}

function FieldEditThesaurus({fieldId, onChange}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const field = useSelector(state => selectTreeFieldById(state, fieldId));
    const rootThesaurus = useSelector(selectRootThesauruses)
    const fieldThesaurus = useSelector(state => selectTreeThesaurusById(state, field.tree_thesaurus_id))
    const fieldThesaurusPath = useSelector(state => selectPathOfThesaurus(state, field.tree_thesaurus_id))
    
    const [rootId, setRootId] = useState("");
    const [subTreeMode, setSubTreeMode] = useState();
    
    useEffect(()=>{
        if (fieldThesaurusPath.length > 0) {
            setRootId(fieldThesaurusPath[0])
            setSubTreeMode(true);
        }
        else {
            setRootId(fieldThesaurus.id)
            setSubTreeMode(false);
        }
    }, []);
    
    const handleChange = (value) => {
        console.log(value)
    }
    
    return <Box className={classes.thesaurusDetails}>
        <Grid container spacing={2}>
            <Grid md={6} xs={12}>
                <TextField
                    select
                    variant={"outlined"}
                    fullWidth
                    label={t("Root")}
                    value={rootId}
                >
                    {rootThesaurus && rootThesaurus.map(_root=>(<MenuItem key={_root.id} value={_root.id}>{tdb(_root.title, _root.name)} </MenuItem>))}
                </TextField>
            </Grid>
            <Grid md={6} xs={12}>
                {subTreeMode
                    ? <EditableThesaurus
                        label={rootId === fieldThesaurus.id ? "Root" : tdb(fieldThesaurus.title)}
                        rootId={rootId}
                        field={fieldThesaurus}
                        inputVariant={"outlined"}
                        multiple={false}
                        initEditing={true}
                        onChange={handleChange}
                        initValue={fieldThesaurus.id}
                    />
                    : <Button onClick={()=>setSubTreeMode(true)}>{t('tree.tree-editor.field.operation.edit.selectSubItem')}</Button>
                }
            </Grid>
        </Grid>
    </Box>
}

const useStyles = makeStyles((theme) => ({
    thesaurusDetails: {
        //marginTop: theme.spacing(2),
        padding: theme.spacing(1),
    }
}))