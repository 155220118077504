import React, {useEffect, useState} from 'react';
import {
    Button,
    Grid,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    CardHeader,
    CardActions,
    TextField,
    FormGroup,
    Radio,
    RadioGroup,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import tdb from "../../services/TranslateDB";

import {useDispatch, useSelector} from "react-redux";

import {NavLink, Route, Switch, useParams} from "react-router-dom";
import {fetchCollectionTypes, selectAllCollectionTypes, selectCollectionTypeById, createCollectionType} from '../../store/entities/collectionTypesSlice'
import {createCollectionTypeField, fetchCollectionTypeFields, selectCollectionTypeFieldById, selectCollectionFieldsOfType} from "../../store/entities/collectionTypesFieldsSlice";
import {fetchFieldTypes, selectAllFieldTypes, selectFieldTypeById} from "../../store/entities/fieldTypesSlice";
import List from "@material-ui/core/List";
import Translatable from "./Fields/Translatable";
import {Edit} from "@material-ui/icons";
import {DataGrid} from "@material-ui/data-grid";

const CollectionTypes = () => {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const [openCreate, setOpenCreate] = useState(false);
    const handleCreateOpen = () => setOpenCreate(true);
    const handleCreateClose = () => setOpenCreate(false);

    const collectionTypes = useSelector(selectAllCollectionTypes)
    const statusType = useSelector(state => state.collectionTypes.status);
    useEffect(()=>{
        if (statusType === 'idle') {
            dispatch(fetchCollectionTypes(null));
            dispatch(fetchFieldTypes(null));
        }
    }, [statusType, dispatch]);

    return <Card>
            <CardHeader title={t('CollectionTypes')} />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <List>
                            {
                                collectionTypes.map((collectionType, index) => {
                                    return <ListItem
                                        button
                                        component={NavLink}
                                        to={`/collections/types/${collectionType.id}`}
                                        activeClassName={classes.activeLink}
                                        color={"secondary"}
                                    >

                                        <>
                                            {tdb(collectionType.title)}
                                        </>
                                    </ListItem>
                                })
                            }
                        </List>
                    </Grid>
                    <Grid item xs={9}>
                        <Switch>
                            <Route exact path="/collections/types" >
                                Choose a type
                            </Route>
                            <Route exact path="/collections/types/:collectionTypeId" >
                                <CurrentCollectionType />
                            </Route>
                        </Switch>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button title={t('CreateNewCollectionType')} variant="contained" color="secondary" onClick={handleCreateOpen}>
                    {t('CreateNewCollectionType')}
                </Button>
                <CollectionTypeCreate open={openCreate} onClose={handleCreateClose} />
            </CardActions>
        </Card>
};

function CurrentCollectionType() {
    const { collectionTypeId } = useParams();
    return <CollectionType collectionTypeId={parseInt(collectionTypeId)} />
}

function CollectionType({collectionTypeId}) {
    const collectionType = useSelector(state => selectCollectionTypeById(state, collectionTypeId))
    const collectionTypeFields = useSelector((state) => selectCollectionFieldsOfType(state, collectionTypeId));
    const status = useSelector(state => state.collectionTypeFields.status);

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [openCreate, setOpenCreate] = useState(false);
    const handleCreateOpen = () => setOpenCreate(true);
    const handleCreateClose = () => setOpenCreate(false);

    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchCollectionTypeFields(null));
        }
    }, [status, dispatch]);

    const [editedField, setEditedField] = useState(null);

    const cols = [
        {field: "id",                   flex: 1, headerName: "ID"},
        {field: "title",                flex: 2, headerName: "Title", renderCell: (params)=>(tdb(params.row.title))},
        {field: "desc",                 flex: 4, headerName: "Description", renderCell: (params)=>(tdb(params.row.description))},
        {field: "tree_field_type_id",   flex: 1, headerName: "Description"},
        {field: "target",               flex: 1, headerName: "Target"},
        {field: "actions",              width: 100, headerName: " ", renderCell: params=>(<IconButton onClick={()=>setEditedField(params.row.id)}><Edit /></IconButton>)},
    ]

    if (!collectionType) return <div>Loader</div>;

    return <Card elevation={3}>
        <CardHeader
            title={tdb(collectionType.title)}
            subheader={collectionType.name}
            action={<IconButton><Edit /></IconButton>}
        />
        <CardContent>
            <DataGrid rows={collectionTypeFields} columns={cols} hideFooter autoHeight density={"compact"} />
        </CardContent>
        <CardActions>
            <Button title={t('CollectionTypeFieldCreate')} variant={"contained"} color="secondary" onClick={handleCreateOpen}>
                {t('CollectionTypeFieldCreate')}
            </Button>
            <CollectionTypeFieldCreate collectionTypeId={collectionTypeId} open={openCreate} onClose={handleCreateClose} />
        </CardActions>
        {editedField && <CollectionTypeFieldEdit
            open={!!editedField}
            collectionTypeFieldId={editedField}
            onClose={() => setEditedField(null)}
            collectionTypeId={collectionTypeId}
        />}
    </Card>
}


function CollectionTypeField({collectionTypeFieldId}) {
    const {t} = useTranslation();
    const collectionTypeField = useSelector(state => selectCollectionTypeFieldById(state, collectionTypeFieldId));
    const fieldType = useSelector(state => selectFieldTypeById(state, collectionTypeField.tree_field_type_id));
    return <Grid container spacing={3}>
        <Grid item xs={2}>{collectionTypeField.name}</Grid>
        <Grid item xs={4}>
            {tdb(collectionTypeField.title)}
            {
                collectionTypeField.description ? <div>{tdb(collectionTypeField.description)}</div> : null
            }
        </Grid>
        <Grid item xs={2}>{fieldType ? fieldType.name: null}</Grid>
        <Grid item xs={2}>{collectionTypeField.target}</Grid>
        <Grid item xs={2}>
            <IconButton>
                <Edit fontSize={"small"} />
            </IconButton>
        </Grid>
    </Grid>;
}

function CollectionTypeCreate({open, onClose}) {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [name, setName] = useState(null);
    const handleName = (event) => setName(event.target.value);

    const [title, setTitle] = useState(null);
    const handleTitle = (event) => setTitle(event.target.value);

    const [description, setDescription] = useState(null);
    const handleDescription = (event) => setDescription(event.target.value);

    const handleCreate = () => {
        if (name && title) {
            const body = {
                name: name,
                title: title
            }
            if (description) {
                body.description = {
                    default: description,
                    [i18n.language]: description
                }
            }
            dispatch(createCollectionType(body));
            onClose();
        }
        else {
            console.log("name", name);
            console.log("description", description);
        }
    }
    return <Dialog open={open} fullWidth maxWidth={"sm"}>
        <DialogTitle
            title={t('CreateCollectionType')}
        >
            {t('CreateCollectionType')}
        </DialogTitle>
        <DialogContent>
            <FormGroup className={classes.formGroup}>
                <TextField variant="standard" value={name} label="Name" onChange={handleName} placeholder="Name" />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
                <Translatable inputProps={{variant: "standard"}} label={"Title"} onChange={(value)=>setTitle(value)} />
            </FormGroup>
            <FormGroup className={classes.formGroup}>
                <TextField variant="standard" value={description} onChange={handleDescription} placeholder="Description" />
            </FormGroup>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="default">
                {t('Cancel')}
            </Button>
            <Button onClick={handleCreate} color="secondary" variant="contained">
                {t('Create')}
            </Button>
        </DialogActions>
    </Dialog>;
}

function CollectionTypeFieldCreate({collectionTypeId, open, onClose}) {
    const collectionType = useSelector(state => selectCollectionTypeById(state, collectionTypeId));
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [name, setName] = useState(null);
    const fieldTypes = useSelector(selectAllFieldTypes);
    const handleName = (event) => setName(event.target.value);

    const [title, setTitle] = useState(null);
    const handleTitle = (event) => setTitle(event.target.value);

    const [type, setType] = useState('1');
    const handleType = (event) => setType(event.target.value);

    const [target, setTarget] = useState('collection');
    const handleTarget = (event) => setTarget(event.target.value);
    const targets = [
        {name: 'collection', description: 'CollectionTypeFieldTargetCollectionDescription'},
        {name: 'object', description: 'CollectionTypeFieldTargetObjectDescription'},
        {name: 'both', description: 'CollectionTypeFieldTargetBothDescription'}
    ];

    const [description, setDescription] = useState(null);
    const handleDescription = (event) => setDescription(event.target.value);

    const handleCreate = () => {
        if (name && title) {
            const body = {
                name: name,
                target: target,
                collection_type: parseInt(collectionTypeId),
                tree_field_type: parseInt(type),
                title: {
                    default: title,
                    [i18n.language]: title
                }
            }
            if (description) {
                body.description = {
                    default: description,
                    [i18n.language]: description
                }
            }
            dispatch(createCollectionTypeField(body));
            onClose();
        }
        else {
            console.log("name", name);
            console.log("description", description);
        }
    }
    return <Dialog open={open} fullWidth maxWidth={"sm"}>
        <DialogTitle
            title={t('CreateCollectionTypeField')}
        >
            {t('CreateCollectionTypeField')}
        </DialogTitle>
        <DialogContent>
            <FormGroup>
                <TextField variant="standard" value={name} onChange={handleName} placeholder="Name" />
            </FormGroup>
            <RadioGroup aria-label="type" value={target} onChange={handleTarget}>
                <Grid container>
                    {targets.map((target, i) => {
                        return <FormControlLabel value={`${target.name}`} control={<Radio />} label={t(target.description)} />;
                    })}
                </Grid>
            </RadioGroup>
            <FormGroup>
                <TextField variant="standard" value={title} onChange={handleTitle} placeholder="Title" />
            </FormGroup>
            <RadioGroup aria-label="type" value={type} onChange={handleType}>
                <Grid container>
                    {fieldTypes.map((fieldType, i) => {
                        return <Grid item xs={4}>
                            <FormControlLabel value={`${fieldType.id}`} control={<Radio />} label={fieldType.name} />
                        </Grid>;
                    })}

                </Grid>
            </RadioGroup>

            <FormGroup>
                <TextField multiline variant="standard" value={description} onChange={handleDescription} placeholder="Description" />
            </FormGroup>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="default">
                {t('Cancel')}
            </Button>
            <Button onClick={handleCreate} color="secondary" variant="contained">
                {t('Create')}
            </Button>
        </DialogActions>
    </Dialog>;
}

function CollectionTypeFieldEdit({collectionTypeId, collectionTypeFieldId, open, onClose}) {
    const collectionType = useSelector(state => selectCollectionTypeById(state, collectionTypeId));
    const collectionTypeField = useSelector(state => selectCollectionTypeFieldById(state, collectionTypeFieldId));
    const fieldType = useSelector(state => selectFieldTypeById(state, collectionTypeField.tree_field_type_id));

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const [name, setName] = useState(collectionTypeField.name);
    const fieldTypes = useSelector(selectAllFieldTypes);
    const handleName = (event) => setName(event.target.value);

    const [title, setTitle] = useState(collectionTypeField.title);
    const handleTitle = (value) => setTitle(value);

    const [type, setType] = useState(collectionTypeField.tree_field_type_id);
    const handleType = (event) => setType(event.target.value);

    const [target, setTarget] = useState(collectionTypeField.target);
    const handleTarget = (event) => setTarget(event.target.value);
    const targets = [
        {name: 'collection', description: 'CollectionTypeFieldTargetCollectionDescription'},
        {name: 'object', description: 'CollectionTypeFieldTargetObjectDescription'},
        {name: 'both', description: 'CollectionTypeFieldTargetBothDescription'}
    ];

    const [description, setDescription] = useState(null);
    const handleDescription = (event) => setDescription(event.target.value);

    const handleUpdate = () => {
        if (name && title) {
            const body = {
                name: name,
                target: target,
                collection_type: parseInt(collectionTypeId),
                tree_field_type: parseInt(type),
                title: {
                    default: title,
                    [i18n.language]: title
                }
            }
            if (description) {
                body.description = {
                    default: description,
                    [i18n.language]: description
                }
            }
            dispatch(createCollectionTypeField(body));
            onClose();
        }
        else {
            console.log("name", name);
            console.log("description", description);
        }
    }
    return <Dialog open={open} fullWidth maxWidth={"sm"}>
        <DialogTitle
            title={t('CreateCollectionTypeField')}
        >
            {t('CreateCollectionTypeField')}
        </DialogTitle>
        <DialogContent>
            <FormGroup>
                <TextField variant="standard" value={name} onChange={handleName} placeholder="Name" />
            </FormGroup>
            <RadioGroup aria-label="type" value={target} onChange={handleTarget}>
                <Grid container>
                    {targets.map((target, i) => {
                        return <FormControlLabel value={`${target.name}`} control={<Radio />} label={t(target.description)} />;
                    })}
                </Grid>
            </RadioGroup>
            <FormGroup>
                <Translatable inputProps={{variant: "standard"}} initValue={title} onChange={()=>{}} label={"Title"} />
            </FormGroup>
            <RadioGroup aria-label="type" value={type} onChange={handleType}>
                <Grid container>
                    {fieldTypes.map((fieldType, i) => {
                        return <Grid item xs={4}>
                            <FormControlLabel value={`${fieldType.id}`} control={<Radio />} label={fieldType.name} />
                        </Grid>;
                    })}

                </Grid>
            </RadioGroup>

            <FormGroup>
                <TextField multiline variant="standard" value={description} onChange={handleDescription} placeholder="Description" />
            </FormGroup>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="default">
                {t('Cancel')}
            </Button>
            <Button onClick={handleUpdate} color="secondary" variant="contained">
                {t('Create')}
            </Button>
        </DialogActions>
    </Dialog>;
}

export default CollectionTypes;

// style of the layout
const useStyles = makeStyles((theme) => ({
    activeLink: {
        backgroundColor: '#ccc'
    },
    formGroup: {
        marginBottom: theme.spacing(2)
    }
}));