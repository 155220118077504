import React from "react";
import {ThemeProvider, createTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";
import {Switch, Route, NavLink} from "react-router-dom";
import {useTranslation}     from 'react-i18next';
import {Container, Link, Breadcrumbs, Typography} from "@material-ui/core";

// import Query from "./Query.jsx";
import Queries from "./Queries.jsx";
import Query from "./Query.jsx";
import QueryCreate from "./QueryCreate.jsx";
import {useSelector} from "react-redux";
import {getCurrentUser} from "../../store/entities/currentUserSlice";

const QueriesRouter = () => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const currentUser = useSelector(getCurrentUser);

    // TODO improve breadbrumb
    return <Container disableGutters={true} maxWidth={"xl"}>
        <ThemeProvider theme={lightTheme(currentUser && currentUser.type === "prevision")}>
            <Switch>
                <Route exact path="/queries" >
                    <Breadcrumbs separator="/">
                        <Link to="/" component={NavLink} className={classes.Link}>
                            {t('HomePageLink')}
                        </Link>
                        <Typography>
                            {t('QueriesLink')}
                        </Typography>
                    </Breadcrumbs>
                    <Queries/>
                </Route>
                <Route path="/queries/example">
                    <InvestigationsBreadCrumbs current={<Typography>Current</Typography>} />
                    <Query />
                </Route>
                <Route path="/queries/new">
                    <InvestigationsBreadCrumbs current={<Typography>new</Typography>} />
                    <QueryCreate />
                </Route>
            </Switch>
        </ThemeProvider>
    </Container>;
};

function InvestigationsBreadCrumbs({current}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    return <Breadcrumbs separator="/">
        <Link to="/" component={NavLink} className={classes.Link}>
            {t('HomePageLink')}
        </Link>
        <Link to="/queries/" component={NavLink} className={classes.Link}>
            {t('InvestigationsLink')}
        </Link>
        {current && current}
    </Breadcrumbs>
}

// Theme
const lightTheme = (prevision = false) => createTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette)
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    }
}));

export default QueriesRouter;