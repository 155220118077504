import React from 'react';
import {
    Button, Card, CardContent, CardHeader, CardActions, Grid, Radio, RadioGroup, Stepper, Step, StepLabel, FormControlLabel, FormControl, Dialog, DialogTitle, DialogContent, DialogActions
} from "@material-ui/core";
import {createTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

function getSteps() {
    return ['Databases', 'MoreOptions'];
}

const QueryCreate = ({title, content, onClose}) => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const [source, setSource] = React.useState('text');
    const handleSource = (event) => {
        setSource(event.target.value);
    };

    return  <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle>
            {t('CreateNewQuery')}
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label} color={"secondary"}>
                                <StepLabel>{t(label)}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid item xs={12}>
                    <RadioGroup value={source} onChange={handleSource}>
                        <FormControlLabel value="text" control={<Radio />} label={t("TextSearch")} />
                        <FormControlLabel value="collection" control={<Radio />} label={t("Collections")} />
                        <FormControlLabel value="images" control={<Radio />} label={t("Images")} />
                        <FormControlLabel value="category" control={<Radio />} label={t("Typologies")} />
                    </RadioGroup>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button
                variant="contained"
                color="primary"
                onClick={onClose}
            >
                {t('Cancel')}
            </Button>
            <Button
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                color="secondary"
            >
                {t('Back')}
            </Button>
            <Button
                disabled={true}
                variant="contained"
                color="secondary"
                onClick={handleNext}
            >
                {activeStep === steps.length - 1 ? t('Create') : t('Next')}
            </Button>
        </DialogActions>
    </Dialog>
};



function Wizard() {

}

// Theme
const lightTheme = (prevision = false) => createTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette)
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    }
}));

export default QueryCreate;