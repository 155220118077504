import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/client';
import {createCategory, selectPathOfCategory, updateCategory} from "./treeCategoriesSlice";

const treeFieldsAdapter = createEntityAdapter();
const initialState = treeFieldsAdapter.getInitialState({
    status: 'idle',
    updateStatus: false,
    createStatus: false,
    error: null,
});

// fetch all treeFields
export const fetchTreeFields = createAsyncThunk(
    'treeFields/fetchTreeFields',
    async (parameters, thunkAPI) => {
        const response = await client.get('/tree/field/all');
        return response.fields;
    }
);

// update Field
export const updateField = createAsyncThunk(
    'treeCategories/updateField',
    async (parameters, thunkAPI) => {
        const response = await client.post(`/tree/field/${parameters.fieldId}`, parameters.body);
        return response.field;
    }
);


// create Field
export const createField = createAsyncThunk(
    'treeCategories/createField',
    async (parameters, thunkAPI) => {
        const response = await client.post(`/tree/field/create`, parameters.body);
        return response.field;
    }
);

// THE REDUCERS
const treeFieldsSlice = createSlice({
    name: 'treeFields',
    initialState,
    extraReducers: {
        [fetchTreeFields.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchTreeFields.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched treeFields to the array
            treeFieldsAdapter.upsertMany(state, action.payload);
        },
        [fetchTreeFields.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [updateField.pending]: (state, action) => {
            state.updateStatus = 'loading';
        },
        [updateField.fulfilled]: (state, action) => {
            state.updateStatus = 'succeeded';
            treeFieldsAdapter.upsertOne(state, action.payload);
        },
        [updateField.rejected]: (state, action) => {
            state.updateStatus = 'failed';
            state.error = action.payload;
        },
        [createField.pending]: (state, action) => {
            state.createStatus = 'loading';
        },
        [createField.fulfilled]: (state, action) => {
            state.createStatus = action.payload.id;
            treeFieldsAdapter.upsertOne(state, action.payload);
        },
        [createField.rejected]: (state, action) => {
            state.createStatus = 'failed';
            state.error = action.payload;
        },
    },
});

// export const {  } = treeFieldsSlice.actions;

export default treeFieldsSlice.reducer

export const {
    selectAll: selectAllTreeFields,
    selectById: selectTreeFieldById,
    selectIds: selectTreeFieldIds,
} = treeFieldsAdapter.getSelectors((state) => state.treeFields);

export const selectFieldsByCategory = createSelector(
    [selectAllTreeFields, (state, categoryId) => categoryId],
    (treeFields, categoryId) => treeFields
        .filter((treeField) => (treeField.tree_category_id === categoryId))
        .sort((a,b)=>a.ord===b.ord?0:(a.ord>b.ord?1:-1))
);

export const selectInheritedFieldsByCategory = (state, categoryId) => {
    const fields = [];
    let currentCategory = categoryId;
    let currentFields;
    while (currentCategory) {
        const category = state.treeCategories.entities[currentCategory];
        fields.push(...selectFieldsByCategory(state, currentCategory));
        currentCategory = category ? category.parent_id : null;
    }
    // const fields =
    return fields.sort((a,b)=>(a>b?1:-1));
};

export const getFieldById = (state, categoryId) => {
    return state.treeCategories.entities[categoryId];
}