import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {selectTreeThesaurusEntities} from "../../../store/entities/treeThesaurusSlice";
import tdb from "../../../services/TranslateDB";
import EditableThesaurus from "./EditableThesaurus";
import EditableText from "./EditableText";
import EditableBoolean from "./EditableBoolean";
import {makeStyles} from "@material-ui/core/styles";
import {BookmarkBorder, BookmarksOutlined} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {IconButton} from "@material-ui/core";
import {
    addFieldsOpened,
    selectDrawerVisible,
    selectInputThesaurus,
    setDrawer,
    setInputThesaurus
} from "../../Selectors/Drawer/SelectorSlice";

// just display
export function ObjectData({name, value, subValue, cols=[4,8], margin=4}) {
    return <Grid container spacing={1} style={{marginBottom: margin}}>
        <Grid item xs={cols[0]}>
            <strong>{name}</strong>
        </Grid>
        <Grid item xs={cols[1]}>
            {value}
            {!!subValue && <Typography>{subValue}</Typography>}
        </Grid>
    </Grid>
}

// all fields exept thesaurus
export function ObjectField({field, values, editing, type, textClasses, update, cols=[4,7,1], margin=4, onlyValue=false}) {
    // console.log("field", field)
    const classes = useStyles()
    const {t} = useTranslation();
    const [editable, setEditable] = useState(null);
    const [label, setLabel] = useState(null);
    const [attributes, setAttributes] = useState({});
    const [fieldValue, setFieldValue] = useState(null);

    useEffect(()=>{
        setFieldValue(values && values[field.name] ? values[field.name] : null)
    }, [values, field])


    useEffect(()=>{
        if (type && type.name === "Boolean") {
            setEditable(<EditableBoolean field={field.name} initValue={fieldValue} textClasses={textClasses} onChange={update} type={type} />);
            switch (fieldValue) {
                case "0": case 0: case false: setLabel(t('No'));break;
                case "1": case 1: case true: setLabel(t('Yes'));break;
                default: setLabel(t('unknown'));break;
            }
        }
        else {
            setEditable(<EditableText field={field.name} initValue={fieldValue} textClasses={textClasses} onChange={update} type={type} toolTip={false} />);
            setLabel(`${fieldValue}`)
        }
    }, [type, fieldValue, field])


    useEffect(()=>{
        setAttributes((Array.isArray(cols))
            ? [{sm: cols[0]}, {sm: cols[1]}]
            : [{style: {width:cols}}, {style: {flexGrow:1}}])
    }, [cols])

    if (!editing && (fieldValue === false || fieldValue === null))return null;
    if (onlyValue) {
        return <Typography classes={textClasses}>
            { editing ? editable : label}
        </Typography>
    }


    return <Grid container spacing={1} className={classes.fieldRow}>
        <Grid item {...attributes[0]}>
            <strong>{tdb(field.title, field.name)}</strong>
        </Grid>
        <Grid item {...attributes[1]}>
            <Typography classes={textClasses} variant={"body2"}>
                { editing ? editable : label}
            </Typography>
        </Grid>
    </Grid>;
}

// thesaurus field
export function ObjectThesaurus({field, values, editing, update, textClasses, cols = [4,7,1], onlyValue=false, margin=4, showBookMark=true}) {
    const classes = useStyles()
    const thesaurusEntities = useSelector(selectTreeThesaurusEntities);

    const [label, setLabel] = useState();
    const [fieldValue, setFieldValue] = useState();
    const [initValue, setInitValue] = useState();
    const [error, setError] = useState();

    useEffect(()=>{
        if (values) {
            setFieldValue(values[field.name] ? values[field.name] : null)
        }
    }, [values])

    const getId = (value) => {
        if (value && typeof value === "object" && 'id' in value && value.id in thesaurusEntities)return value.id;
        if (value && value in thesaurusEntities)return value;
        else return null;
    }

    const getTitle = (value) => {
        if (!value)return {};
        if (typeof value === "object") {
            if ("title" in value) {
                if (typeof value.title === "object") {
                    return value.title;
                }
                else return {
                    default: value.title
                }
            }
            if ("id" in value && value.id in thesaurusEntities) {
                return thesaurusEntities[value.id].title
            }
            if ("value" in value && value.value in thesaurusEntities) {
                return thesaurusEntities[value.value].title
            }
            if ("value" in value)return {default: JSON.stringify(value.value)}
        }
        if (value in thesaurusEntities)return thesaurusEntities[value].title;
        if (typeof value === "string")return {default: value};
        return {"default": "none"}
    }

    const getValue = (value) => {
        return {
            id: getId(value),
            title: getTitle(value)
        }
    }

    useEffect(()=>{
        let _initValue;
        //console.debug("----------------",field.name)
        if (fieldValue) {
            //console.debug("value", fieldValue)
            if (typeof fieldValue === "object") {
                console.debug("is object")
                if (Array.isArray(fieldValue)) {
                    console.debug("is array")
                    _initValue = fieldValue.map(v=>getValue(v));
                    if (!field.multiple) {
                        console.error("VALUE SHOULD NOT BE AN ARRAY", field, fieldValue)
                    }
                }
                else {
                    _initValue = getValue(fieldValue);
                }
            }
            else {
                _initValue = getValue(fieldValue);
            }
            if (field.multiple && !Array.isArray(_initValue))_initValue = [_initValue];
            setLabel(Array.isArray(_initValue)
                ? _initValue.map(i=>tdb(i.title)).join(", ")
                : tdb(_initValue.title)
            )
            setInitValue(_initValue);
        }
        else {
            setLabel("none")
        }
    }, [fieldValue])

    const handleChange = (value) => {
        console.log("change",{value:value});
        // console.log(field, value);
        if ('name' in field) {
            if (!value)update(null, field.name);
            else update(value, field.name);
        }
    }

    const dispatch = useDispatch();
    const selectorView = useSelector(selectDrawerVisible)
    const inputThesaurus = useSelector(selectInputThesaurus)
    const handleOpenSelector = () => {
        dispatch(setDrawer(true))
        dispatch(addFieldsOpened(field.id))
        dispatch(setInputThesaurus(field.tree_thesaurus_id));
    }

    const attributes = (Array.isArray(cols))
        ? [{sm: cols[0]}, {sm: cols[1]}, {sm: cols[2], style: {textAlign: 'right'}}]
        : [{style: {width:cols}}, {flexGrow:1}, {style: {width: 20}}]

    if (!editing && (!fieldValue || !label || !initValue))return null;

    if (onlyValue) {
        return editing
            ? <EditableThesaurus
                multiple={field.multiple}
                initValue={initValue}
                label={label}
                onChange={handleChange}
                field={"category"}
                rootId={field.tree_thesaurus_id}
                textClasses={textClasses}
                variant={"body2"}
            />
            : <Typography classes={textClasses} variant={"body2"}>{label}</Typography>
    }

    return <Grid container spacing={1} className={classes.fieldRow}>
        <Grid item {...attributes[0]}>
            <strong>{tdb(field.title, field.name)}</strong>
        </Grid>
        <Grid item {...attributes[1]}>
            {
                editing
                    ? <EditableThesaurus
                            multiple={field.multiple}
                            initValue={initValue}
                            label={label}
                            onChange={handleChange}
                            field={"category"}
                            rootId={field.tree_thesaurus_id}
                            textClasses={textClasses}
                            variant={"body2"}
                        />
                    : <Typography classes={textClasses} variant={"body2"}>{label}</Typography>
            }
        </Grid>
        {
            showBookMark &&
            <Grid item {...attributes[2]}>
                <IconButton onClick={handleOpenSelector} size={"small"} color={field && inputThesaurus === field.tree_thesaurus_id ? "secondary": "default"}>
                    {field.multiple ? <BookmarksOutlined /> : <BookmarkBorder />}
                </IconButton>
            </Grid>
        }

    </Grid>;
}

const useStyles = makeStyles((theme) => ({
    fieldRow: {
        minHeight: 34
    }
}));