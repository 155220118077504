import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {Badge, Card, CardContent, CardHeader, Container, Grid} from "@material-ui/core";
import {makeStyles, createTheme, ThemeProvider, withStyles} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentUser} from "../../store/entities/currentUserSlice";
import {useTranslation} from "react-i18next";
import {fetchCollections, selectAllCollections} from "../../store/entities/collectionsSlice";
import {fetchCollectionTypes} from "../../store/entities/collectionTypesSlice";
import {fetchFieldTypes} from "../../store/entities/fieldTypesSlice";
import {DataGrid} from "@material-ui/data-grid";
import moment from "moment";
import Add from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import CollectionCreate from "../Collection/CollectionCreate";
import {useHistory} from "react-router-dom";



export default function HomeInventory({}) {
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation()
    
    const currentUser = useSelector(getCurrentUser);
    
    const status = useSelector(state => state.collections.status);
    const statusType = useSelector(state => state.collectionTypes.status);
    const statusFieldType = useSelector(state => state.fieldTypes.status);
    
    useEffect(()=>{
        if (status === 'idle')dispatch(fetchCollections(null));
        if (statusType === 'idle')dispatch(fetchCollectionTypes(null));
        if (statusFieldType === 'idle')dispatch(fetchFieldTypes(null));
    }, [status, statusType, dispatch]);
    
    const [createCollection, setCreateCollection] = useState(false);
    
    return <Container disableGutters={true} maxWidth={"xl"}>
        <Typography variant="h1">
            {t("Dashboard")}
        </Typography>
        <ThemeProvider theme={lightTheme()}>
            <Grid spacing={2} container>
                <Grid item xs={12} lg={6}>
                    <Card>
                        <CardHeader
                            title={t('HomeInventory_RecentInventories')}
                            action={<Button
                                size={"small"}
                                startIcon={<Add />}
                                color={"secondary"}
                                variant={"contained"}
                                onClick={()=>setCreateCollection(true)}
                            >{t('CreateCollection')}</Button>}
                        />
                        {createCollection && <CollectionCreate open={true} onClose={()=>setCreateCollection(false)} />}
                        <CardContent>
                            <HomeInventoryRecent />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Card>
                        <CardHeader
                            title={t('HomeInventory_ImagesToProcess')}
                        />
                        <CardContent>
                            <HomeInventoryImages />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader
                            title={t('HomeInventory_MainActions')}
                        />
                        <CardContent>
                        
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </ThemeProvider>
    </Container>
}

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -10,
        top: 13,
        //border: `2px solid ${theme.palette.background.paper}`,
        //padding: '0 4px',
    },
}))(Badge);

export function HomeInventoryRecent() {
    const collections = useSelector(selectAllCollections);
    const {t, i18n} = useTranslation()
    const history = useHistory()
    const columns = [
        {field: "name", flex:2, headerName: t('Name'), renderCell: params => <StyledBadge color={"secondary"} badgeContent={params.row.unclassified}>{params.value}</StyledBadge>},
        {field: "collection_type_name", flex:1, headerName: t('Type')},
        {field: "modified", flex:1, headerName: t('Modified'), sort: 'DESC', renderCell: params => (moment(params.value).fromNow())},
        {field: "images_count", width:100, headerName: t('Images'), disableColumnMenu: true},
        {field: "objects_count", width:100, headerName: t('Objects'), disableColumnMenu: true},
    ]
    console.log(collections)
    
    const [filtered, setFiltered] = useState([]);
    
    useEffect(()=>{
        if (collections) {
            setFiltered([...collections]
                .sort((a,b) => a.modified===b.modified ? 0 : (a.modified < b.modified ? 1 : -1))
                //.slice(0, 5)
            )
        }
    }, [collections])
    
    const handleClick = (params) => history.push(`/collections/${params.id}`)
    
    return <div style={{height: 220}}>
        <DataGrid
            columns={columns}
            rows={filtered}
            density={"compact"}
            pageSize={4}
            //hideFooter
            autoHeight
            onRowDoubleClick={handleClick}
        />
    </div>
}



export function HomeInventoryImages() {
    const collections = useSelector(selectAllCollections);
    const {t, i18n} = useTranslation();
    const history = useHistory()
    const columns = [
        {field: "name", flex:2, headerName: t('Name'), renderCell: params => <StyledBadge color={"secondary"} badgeContent={params.row.unclassified}>{params.value}</StyledBadge>},
        {field: "collection_type_name", flex:1, headerName: t('Type')},
        {field: "created", flex:1, headerName: t('Created'), sort: 'DESC'},
        {field: "images_count", width:100, headerName: t('Images'), disableColumnMenu: true},
        {field: "objects_count", width:100, headerName: t('Objects'), disableColumnMenu: true},
    ]
    console.log(collections)
    
    const [filtered, setFiltered] = useState([]);
    
    useEffect(()=>{
        if (collections) {
            setFiltered(collections
                .filter(f=>f.unclassified > 0)
                .sort((a,b) => a.unclassified===b.unclassified ? 0 : (a.unclassified < b.unclassified ? 1 : -1))
                //.slice(0, 5)
            )
        }
    }, [collections])
    
    const handleClick = (params) => history.push(`/collections/${params.id}`)
    
    return <div style={{height: 220}}>
        <DataGrid
            columns={columns}
            rows={filtered}
            density={"compact"}
            pageSize={4}
            hideFooter={filtered.length<5}
            autoHeight
            onRowDoubleClick={handleClick}
        />
    </div>
}

export function HomeCollectionList({collections, columns}) {
    return <div style={{height: 220}}>
        <DataGrid columns={columns} rows={collections} />
    </div>
}

// Theme
const lightTheme = (prevision = false) => createTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette)
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        /*flexGrow: 1*/
    },
    cardHeader: {
        padding: theme.spacing(1),
        height: 56,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    cardContent: {
        overflow: "auto",
        // paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    ul: {
        paddingLeft: '1rem'
    },
    create: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        height: 100
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,
        
    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));