import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    FormGroup, Grid, ListItem, MenuItem, Radio, RadioGroup,
    TextField,
    Typography
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {addToCollection, deleteObject, selectObjectsByIds} from "../../../store/entities/objectsSlice";
import {
    createCollection,
    fetchCollections,
    resetCreateStatus,
    selectAllCollections
} from "../../../store/entities/collectionsSlice";
import {Autocomplete} from "@material-ui/lab";
import {getCurrentUser} from "../../../store/entities/currentUserSlice";
import {fetchCollectionTypes, selectAllCollectionTypes} from "../../../store/entities/collectionTypesSlice";
import {fetchFieldTypes} from "../../../store/entities/fieldTypesSlice";
import tdb from "../../../services/TranslateDB";
import Translatable from "../Fields/Translatable";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";

export function CollectionObjectsDeleteObjects({selection, collectionId, onClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const handleDelete = () => {
        selection.map(objectId=>{
            dispatch(deleteObject({objectId: objectId}))
        });
        onClose();
    }
    
    return <Dialog open={true} onClose={onClose}>
        <DialogTitle>{t('deleteObjectsWithCount', {count: selection.length})}</DialogTitle>
        <DialogContent>
        
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="default">
                {t('Cancel')}
            </Button>
            <Button onClick={handleDelete} color="secondary" variant="contained">
                {t('Confirm')}
            </Button>
        </DialogActions>
    </Dialog>
}

export function CollectionObjectsCopyObjectsInNewCollection({selection, collectionId, onClose}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const currentUser = useSelector(getCurrentUser);

    const [name, setName] = useState(null);
    const handleName = (event) => setName(event.target.value);

    const [description, setDescription] = useState("");
    const handleDescription = (value) => setDescription(value);

    const [redirect, setRedirect] = useState(false);
    const [type, setType] = useState(false);
    const statusType = useSelector(state => state.collectionTypes.status);
    const collectionTypes = useSelector(selectAllCollectionTypes)
    useEffect(()=>{
        if (statusType === 'idle') {
            dispatch(fetchCollectionTypes(null));
            dispatch(fetchFieldTypes(null));
        }
        else if (statusType === 'succeeded' && collectionTypes) {
            const defaultType = collectionTypes.find(c=>c.ord===1).id
            setType(defaultType.toString())
        }
    }, [statusType, dispatch]);
    const handleChooseType = (event) => setType(event.target.value);

    const createStatus = useSelector(state => state.collections.createStatus)
    const [launched, setLaunched] = useState(false);
    const handleCreate = () => {
        if (type && name) {
            const body = {
                collection_type: parseInt(type),
                name: name,
                description: {
                    'default': description,
                    [i18n.language]: description
                }
            };
            dispatch(resetCreateStatus())
            dispatch(createCollection({body: body}));
            setLaunched(true)
        }
    }
    
    useEffect(()=>{
        if (launched && createStatus && createStatus.id) {
            console.log(createStatus.id, selection)
            selection.map(objectId => {
                dispatch(addToCollection({
                    body: {
                        collection_hash: createStatus.id,
                        object_hash: objectId
                    }
                }));
            });
            if (redirect) {
                history.push(`/collections/${createStatus.id}`)
                onClose();
            }
            else {
                onClose();
            }
        }
    }, [launched, createStatus])

    return <Dialog open={true} fullWidth maxWidth={"sm"} onClose={onClose}>
        <DialogTitle>{t('copyObjectsInNewCollection')}</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField variant="outlined" value={name} onChange={handleName} label={t("CollectionName")} placeholder={t("CollectionName")} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField variant="outlined" size="medium" value={description} onChange={event=>setDescription(event.target.value)} placeholder={t("Description")} multiline fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField label={t("CollectionType")} variant={"outlined"} value={type} onChange={handleChooseType} select fullWidth>
                        {collectionTypes
                            .sort((a,b)=>a.ord===b.ord?0:(a.ord>b.ord?1:-1))
                            .map((collectionType, index) => {
                                if (currentUser.type === "admin" || (collectionType.parameters && collectionType.parameters.usage && collectionType.parameters.usage.includes(currentUser.type))) {
                                    return <MenuItem value={`${collectionType.id}`}>
                                        {tdb(collectionType.title)}
                                    </MenuItem>
                                }
                            })}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={redirect} />} label={t('CreationCreateRedirect')} onChange={event=>setRedirect(event.target.checked)} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="default">
                {t('Cancel')}
            </Button>
            <Button onClick={handleCreate} color="secondary" variant="contained">
                {t('CreateCollectionAndCopyWithCount', {count: selection.length})}
            </Button>
        </DialogActions>
    </Dialog>;
}

export function CollectionObjectsCopyObjectsInExistingCollection({selection, collectionId, onClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.collections.status);
    const collections = useSelector(selectAllCollections);
    const objects = useSelector(state => selectObjectsByIds(state, selection));

    // const collectionTypes = useSelector(selectAllCollectionTypes);
    // const collectionTypeIds = useSelector(selectCollectionTypeIds);

    // load collections if not done
    useEffect(()=>{
        if (status === 'idle')dispatch(fetchCollections(null));
    }, [dispatch, status])

    // remove current collection from list
    const [filteredCollections, setFilteredCollections] = useState([]);
    useEffect(()=>{
        setFilteredCollections(collections.filter(c=>c.id!==collectionId))
    }, [collections])

    // choose destination collection
    const [destinationId, setDestinationId] = useState();
    const handleDestinationId = (event, value) => setDestinationId(value ? value.id : null);

    // get object out of destination
    const [objectsOutOfDestination, setObjectsOutOfDestination] = useState([]);
    useEffect(()=>{
        if (objects && destinationId) {
            setObjectsOutOfDestination(objects.filter(object=>!object.collections.includes(destinationId)))
        }
    }, [objects, destinationId])

    // copy action
    const handleCopy = () => {
        if (destinationId) {
            for (let i=0;i<objectsOutOfDestination.length;i++) {
                dispatch(addToCollection({
                    body: {
                        collection_hash: destinationId,
                        object_hash: objectsOutOfDestination[i].id
                    }
                }));
            }
        }
    }

    return <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>{t('CopyToCollection')}</DialogTitle>
        <DialogContent>
            <Box p={2}>
                <Typography>{t(`ObjectSelectedWithCount`, {count: selection.length})}</Typography>
            </Box>
            <Autocomplete
                id={"destinationCollection"}
                label={t("ChooseDestinationCollection")}
                renderInput={(params) => <TextField {...params} title={t("ChooseDestinationCollection")} variant={"outlined"} label={t("ChooseDestinationCollection")} />}
                getOptionLabel={(option) => option.name}
                value={destinationId}
                onChange={handleDestinationId}
                options={filteredCollections} />
                {
                    destinationId && <Box p={2}>
                        <Typography>
                            {objectsOutOfDestination.length === 0 &&
                                <Typography>{t(`AllObjectAlreadyInCollection`)}</Typography>
                            }
                            {objectsOutOfDestination.length > 0 && selection.length > objectsOutOfDestination.length &&
                                <Typography>{t(`ObjectAlreadyInCollectionWithCount`, {count: selection.length - objectsOutOfDestination.length})}</Typography>
                            }
                        </Typography>
                    </Box>
                }
        </DialogContent>
        <DialogActions>
            <Typography>{`${objectsOutOfDestination.length}/${selection.length} ${t('remainingObjectsToCopy')}`}</Typography>
            <Button disabled={objectsOutOfDestination.length === 0} variant="contained" color="secondary" onClick={handleCopy}>Copy {objectsOutOfDestination.length} Objects</Button>
        </DialogActions>
    </Dialog>
}

export function CollectionObjectsAssignValueToObjects({selection, collectionId, onClose}) {
    const {t} = useTranslation();
    return <Dialog open={true} onClose={onClose}>
        <DialogTitle>{t('assignValueToObjects')}</DialogTitle>
        <DialogContent>

        </DialogContent>
        <DialogActions>

        </DialogActions>
    </Dialog>
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    formGroup: {
        marginBottom: theme.spacing(2)
    }
}));