import React, {useState, useEffect, createRef, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useTranslation} from "react-i18next";
import {BrowserView, MobileView, isBrowser, isMobile} from "react-device-detect";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Accordion from "@material-ui/core/Accordion";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import CardActions from "@material-ui/core/CardActions";
import Badge from "@material-ui/core/Badge";
import CardHeader from "@material-ui/core/CardHeader";
import TextField from "@material-ui/core/TextField";

import SelectedResult from "./SelectedResult";
import IdentificationResults from "./IdentificationResults"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ViewComfy from "@material-ui/icons/ViewComfy";
import ViewList from "@material-ui/icons/ViewList";
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";


import {launchSearch, searchInit, tabNames, imageSave} from "../../store/entities/searchSlice";
import {makeStyles} from "@material-ui/core/styles";

import client_ai from '../../api/client_ai';
import {CardMedia} from "@material-ui/core";


const SearchResult = (data) => {
    const search = useSelector(state => state.search.search.search)
    const bestMatch = useSelector(state => state.search.search.bestMatch);
    const identification = useSelector(state => state.identifications.currentIdentification);

    console.log("SEARCH", search)

    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const [value, setValue] = useState(Object.entries(search).findIndex(([key, content]) => key == bestMatch));
    const [col, setCol] = useState(3);
    const [display, setDisplay] = useState('list');

    const handleChange = (event, newValue) => setValue(newValue);
    const handleDisplay = (event, newValue) => setDisplay(newValue);
    const selectedResults = useSelector(state => state.search.selectedResults);
    const hasExpert = useSelector(state => {
      if (state.search.selectedResults){
          return state.search.selectedResults.findIndex(x => (typeof x.data["expert"]!="undefined" && x.data["expert"]))
      }
      else{
        return false;
      }
    });

    return <>
        {/* BROWSER VIEW : TABS */}
        <BrowserView>
            <CardContent>
                <Card elevation={4}>
                    <CardHeader
                        title={
                            <Tabs orientation="horizontal" value={value} onChange={handleChange} aria-label="Vertical tabs" className={classes.tabs}>
                                {Object.entries(search).map(([key, content], index)=> {
                                    return tabNames[key].hidden===true || typeof content == "undefined"
                                        ? null
                                        : <Tab key={index}
                                            disabled={content ? content.length===0 : true}
                                            className={classes.tab}
                                            label={
                                              key=='police_test' ? <Badge badgeContent={content.length} color={"secondary"} showZero={false}>
                                                      <Typography>
                                                          {tabNames[key] ? t(tabNames[key].label) : key}
                                                      </Typography>
                                                  </Badge>:
                                                  <Typography>
                                                      {tabNames[key] ? t(tabNames[key].label) : key} ({content.length})
                                                  </Typography>

                                            }
                                            id={`vertical-tabs-${key}`}
                                            aria-controls={`vertical-tabpanel-${index}`} />
                                })
                                }
                            </Tabs>
                        }
                        action={
                            <ToggleButtonGroup size="medium" exclusive value={display} onChange={handleDisplay}>
                                <ToggleButton value="grid" style={{height: 40}}>
                                    <ViewComfy fontSize="default"/>
                                </ToggleButton>
                                <ToggleButton value="list" style={{height: 40}}>
                                    <ViewList fontSize="default"/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        }
                    />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12} md={selectedResults.length>0 ? 9 : 12} className={classes.tabpanelLeft}>
                                {
                                    Object.entries(search).map(([key, content], index)=> (
                                        <>
                                            { typeof content != "undefined" ?
                                                <IdentificationResults  key={`irg-${index}`} value={value} index={index} type={key} content={content} display={display} col={col} />
                                                : null }
                                        </>
                                    ))}
                            </Grid>
                            <Grid item xs={12} md={selectedResults.length>0 ? 3 : false} className={classes.tabpanelRight}>
                                { hasExpert!=-1
                                    ? <>
                                        <div style={{padding: 10, marginBottom:20}}>
                                            <i>Your entries are in the field of one of our experts:</i>
                                            <div>
                                                <div>{selectedResults[hasExpert].data["expert"]} <ResultExpert image={ (identification && identification.images) ? identification.images[0].urls.default : null} selected={selectedResults} expert={selectedResults[hasExpert].data["expert"]}></ResultExpert></div>
                                            </div>
                                        </div>
                                    </>
                                    : null
                                }
                                { Object.entries(selectedResults).map(([key, content], index)=> (
                                    <SelectedResult key={`sr-${index}`} content={content}/>
                                ))}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </CardContent>
        </BrowserView>
        {/* MOBILE VIEW : ACCORDION */}
        <MobileView>
            <CardMedia className={classes.resultContainer}>
                <>
                    {Object.entries(search)
                        // sort result by count Ne fonctionne pas
                        // .sort((a,b)=>(a.length >0 && b.length > 0 && a[1] && b[1] && a[1].length === b[1].length ? 0 : (a[1].length<b[1].length ? 1 : 0)))
                        .map(([key, content], index)=> {
                            return tabNames[key].hidden===true || typeof content == "undefined"
                                ? null
                                : <Accordion key={`tn-${index}`} disabled={content.length === 0}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}a-content`}
                                        id={`panel${index}a-header`}
                                    >
                                        <Badge badgeContent={content.length} color={"secondary"} showZero={false}>
                                            <Typography>
                                                {tabNames[key] ? t(tabNames[key].label) : key}
                                            </Typography>
                                        </Badge>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                { typeof content != "undefined"
                                                    ? <IdentificationResults value={index} index={index} type={key} content={content} display={display} col={col} />
                                                    : null
                                                }
                                            </Grid>
                                            <Grid item xs={12}>
                                                { hasExpert != -1
                                                    ? <>
                                                        <div style={{padding: 10, marginBottom:20, marginTop: 20}}>
                                                            <h3>Your selection</h3>
                                                            <i>Your entries are in the field of one of our experts:</i>
                                                            <div>
                                                                <div>{selectedResults[hasExpert].data["expert"]} <ResultExpert image={(identification && identification.images) ? identification.images[0].urls.default : null} selected={selectedResults} expert={selectedResults[hasExpert].data["expert"]}></ResultExpert></div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : null
                                                }
                                                { Object.entries(selectedResults).map(([key, content], index)=> (
                                                    <SelectedResult key={`sr-${index}`} content={content}/>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            }
                        )
                    }
                </>
            </CardMedia>
        </MobileView>
    </>;
}

function ResultExpert(data) {
    console.log(data)
    const classes = useStyles();
    const [expertModal, setExpertModal] = useState(false);
    const [currentExpert, setCurrentExpert] = useState({});
    const {t} = useTranslation();

    const handleModalOpen = (data) => {
      setCurrentExpert(experts[data["expert"]]);
      setExpertModal(true);
    };

    const requestSend = (data) => {
        console.log(data)
        var body = {
            "email": inputList,
            "image": {"url": data.image},
            "selected": data.selected,
            "expert": data.expert
        }
        client_ai.post("/contactExpert", body).then(
            (response)=>{
                setExpertModal(false);
            }
        )
    };

    const [inputList, setInputList] = useState([""]);

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, ""]);
    };


    const handleModalClose = () => setExpertModal(false);
    const experts = {
        "Rollande SIMON-MILLOT": {
            "name": "Rollande SIMON-MILLOT",
            "specialty_areas": ["Europe", "France", "Italy", "Spain", "Germany", "UK", "Belgium", "Austria", "Netherlands"],
            "specialty_eras": ["10000 BC", "1200 BC"],
            "organization": "MAN",
            "reactivity": "usually less than 2 days",
        },
        "Laurent OLIVIER":{
            "name": "Laurent OLIVIER",
            "specialty_areas": ["France", "UK", "Germany", "Belgium", "Austria", "Netherlands"],
            "specialty_eras": ["750 BC", "50 BC"],
            "organization": "MAN",
            "reactivity": "usually less than 2 days",
        },
        "Fanny HAMONIC":{
            "name": "Fanny HAMONIC",
            "specialty_areas": ["Egypt"],
            "specialty_eras": ["3200 BC", "50 BC"],
            "organization": "MAN",
            "reactivity": "usually less than 2 days"
        },
        "Christine Lorre":{
            "name": "Christine Lorre",
            "specialty_areas": ["Egypt", "Libya", "Africa", "Australia", "Democratic Republic of Congo", "New Guinea"],
            "specialty_eras": ["10000 BC", "1900 AD"],
            "organization": "MAN",
            "reactivity": "usually less than 2 days",
        },
        "Corinne JOUYS-BARBELIN":{
            "name": "Corinne JOUYS-BARBELIN",
            "specialty_areas": ["Italy", "Spain", "Greece", "Libya", "Cyprus", "Crete", "Turkey"],
            "specialty_eras": ["500 BC", " 30 BC"],
            "organization": "MAN",
            "reactivity": "usually less than 2 days",
        },
        "Daniel ROGER":{
            "name": "Daniel ROGER",
            "specialty_areas": ["France", "Italy", "Germany", "Swiss", "Belgium"],
            "specialty_eras": ["753 BC", "476 AD"],
            "organization": "MAN"
        }
    }

    return <>
        <Button onClick={() => handleModalOpen(data)} color="default" size="small" variant="contained">Contact</Button>
        <Dialog
            onClose={handleModalClose}
            open={expertModal}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle color={"default"}>Specialiste</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <h3>{currentExpert.name}</h3>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div>
                            { typeof currentExpert != "undefined" && typeof currentExpert["specialty_areas"] != "undefined"
                                ? <>
                                    <span><b>{t('specialty_areas')}</b></span>
                                    {currentExpert["specialty_areas"].map((item) => (
                                        <div className={classes.expertValue}>{item}</div>
                                    ))}
                                </>
                                : null
                            }
                        </div>
                        <div>
                            { typeof currentExpert != "undefined" && typeof currentExpert["specialty_areas"] != "undefined"
                                ? <>
                                    <span><b>{t('specialty_eras')}</b></span>
                                    {currentExpert["specialty_eras"].map((item) => (
                                        <div className={classes.expertValue}>{item}</div>
                                    ))}
                                </>
                               : null
                            }
                        </div>
                        <div>
                            <span><b>{t('organization')}</b></span>
                            <span className={classes.expertValue}>{currentExpert["organization"]}</span>
                        </div>
                        <div>
                            <span><b>{t('reactivity')}</b></span>
                            <span className={classes.expertValue}><i>{currentExpert["reactivity"]}</i></span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <h3>{t('request summary')}</h3>
                        <div className={classes.expertImageRow}>
                            <div className={classes.expertImageLabel}><b>{t('main object')}</b></div>
                            <span className={classes.expertValue}><img style={{width: '120px'}} src={data.image} /></span>
                        </div>
                        <div className={classes.expertImageRow}>
                            <div className={classes.expertImageLabel}><b>{t('similar objects')}</b></div>
                            {data.selected.map((item) => (
                                <span className={classes.expertValue}><img style={{width: '100px'}} src={item.img} /></span>
                            ))}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} spacing={3}>
                        <h3>{t('Send to')}</h3>
                        <i>{t('The message will be sent to the expert. You can add other recipient if you want')}</i>
                        <div>
                            { inputList.map((x, i) => (
                                <div className="box">
                                    <TextField
                                        placeholder={t('EnterEmail')}
                                        value={x}
                                        onChange={e => handleInputChange(e, i)}
                                        style={{width:'80%'}}
                                    />
                                    <span className="btn-box">
                                        { inputList.length !== 1 &&
                                            <span style={{cursor: 'pointer'}} onClick={() => handleRemoveClick(i)}><HighlightOff /></span>
                                        }
                                        { inputList.length - 1 === i &&
                                            <span style={{cursor: 'pointer', 'margin-left': '15px'}} onClick={handleAddClick}><Add /></span>
                                        }
                                </span>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} color={"secondary"} onClick={() => requestSend(data)}>
                    {t("ContactThisExpert")}
                </Button>
            </DialogActions>
        </Dialog>
    </>
}


// style of the layout
const useStyles = makeStyles((theme) => ({
    resultContainer: {
        padding: 0,
        paddingTop: 9,
        borderTop: '1px solid #ccc',
        //backgroundColor: '#e0e0e0'
    },
    tab: {
        borderRight: '1px solid #ccc',
        color: '#000',
        fontSize: "1rem",
        wordWrap: true,
        wrap: true,
    },
    tabpanelRight: {
        padding: '5px'
    },
    tabpanelLeft: {
        paddingRight: '5px',
        borderRight: '1px solid ccc'
    }
}));

export default SearchResult;
