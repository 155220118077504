
import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField} from "@material-ui/core";
import FieldSet from "../../Misc/FieldSet";
import tdb from "../../../services/TranslateDB";
import Translatable from "../../Collection/Fields/Translatable2";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";

export function ThesaurusCreate({success, parentId, onClose}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        name: "",
        title: {[i18n.language]:""}
    });
    const handleChange = (key) => (event) => {
        const value = event.target.value
        setForm(_form => ({..._form, [key]: value}))
    }
    
    const [fields, setFields] = useState([]);
    const handleChangeField = (value, index) => {
        console.log("handleChangeField", value);
        return;
        setFields(state => {
            const _fields = {...state};
            _fields[index] = value;
            return _fields;
        })
    }
    const handleAddField = () => {
        const _value = {
            name: "new",
            type: "String",
            title: {
                [i18n.language]: ""
            }
        };
        setFields(state=>{
            const _fields = [...state];
            _fields.push(_value)
            return _fields;
        })
    }
    
    useEffect(()=>{
        console.log("fields", fields);
    }, [fields])
    
    const handleSave = () => {
    
    }
    
    return <Dialog open={true} onClose={onClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>
            {t('Create Thesaurus')}
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid xs={12} item>
                    <TextField
                        fullWidth
                        label={t("Name")}
                        variant={"outlined"}
                        value={form.name}
                        helper={t('tree.tree-editor.field.operation.edit.name-helper')}
                        onChange={handleChange('name')}
                        //onChange={(event)=>setForm(state=>({...state, name: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Translatable
                        inputProps={{variant: "outlined"}}
                        initValue={form.title}
                        onChange={(newValue)=>setForm(state=>({...state, title:newValue}))}
                        label={t("Title")}
                        fieldsetMode={t("Title")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldSet title={"Attributes"}>
                        <Grid container spacing={1}>
                            {fields.map((field, i)=>
                                <Grid item xs={12}>
                                    <ThesaurusField key={i} index={i} field={field} setter={handleChangeField} />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button onClick={handleAddField}>
                                    Add Field
                                </Button>
                            </Grid>
                        </Grid>
                    </FieldSet>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSave}>
                {t('create')}
            </Button>
        </DialogActions>
    </Dialog>
}

export function ThesaurusField({initField, setter, index}) {
    const [field, setField] = useState(initField);
    const {t} = useTranslation()
    
    useEffect(()=>{
        setter(field, index)
    }, [field])
    
    return <FieldSet title={!!field && field.name}>
        <Grid container spacing={1}>
            <Grid item xs={8}>
                <TextField fullWidth label={t("Attribute Name")} variant={"outlined"} onChange={event=>setField(state=>({...state, name: event.target.value}))} />
            </Grid>
            <Grid item xs={4}>
                <TextField select fullWidth label={t("Attribute Name")} variant={"outlined"} onChange={event=>setField(state=>({...state, type: event.target.value}))}>
                    <MenuItem value={"String"}>{t("String")}</MenuItem>
                    <MenuItem value={"String"}>{t("Number")}</MenuItem>
                    <MenuItem value={"String"}>{t("Boolean")}</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12}>
                <Translatable fullWidth label={t("Attribute Name")} variant={"outlined"} onChange={value=>setField(state=>({...state, title: value}))} />
            </Grid>
        </Grid>
    </FieldSet>
}

const useStyles = makeStyles((theme) => ({

}))
