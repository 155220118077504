import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import defaultLightPalette from "../../services/defaultLightPalette";
import {
    Container, Typography, createTheme, ThemeProvider, TextField, LinearProgress, Dialog, DialogContent,
    DialogActions, DialogTitle, FormGroup, MenuItem, FormControl, InputLabel, FormHelperText, Select, IconButton,
    Button, Card, CardHeader, CardContent, Link, Grid, useTheme, CardActions, Avatar
} from "@material-ui/core";
import {ViewCompact, ViewComfy, Edit as EditIcon, Group} from "@material-ui/icons";

import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

import {DataGrid} from "@material-ui/data-grid";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

import {fetchUsers, selectAllUsers, selectUserById, updateUser} from "../../store/entities/usersSlice";
import {stackCardHeight} from "../Dimensions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {isMobile} from "react-device-detect";
import SearchTextArea from "../Misc/SearchTextField";
import CollectionsIcon from "@material-ui/icons/Collections";

// stackCardHeight.search = 180;

export default function Users({organizationId}) {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.users.status);
    const users = useSelector(state => selectAllUsers(state));
    const [filter, setFilter] = useState();
    const [editingUser, setEditingUser] = useState(null);

    const [displayMode, setDisplayMode] = useState('table');
    const handleDisplayMode = (mode) => setDisplayMode(mode);

    // alert(status);
    useEffect(()=>{
        if (status === 'idle') {
            console.log("FETCHING USERS");
            dispatch(fetchUsers());
        }
    }, [status, dispatch]);

    useEffect(()=>{
        console.log("USERS", users);
    }, [users]);

    const getViewCell = (params) => {
        return <Button variant="contained" color="default" size="small">{t('Search')}</Button>
    };

    const getUrlCell = (params) => {
        return <Button variant="text" color="default" size="small" href={params.value} target={"_blank"} component={Link}>{t('Open')}</Button>
    };

    const handleEdit = (id) => setEditingUser(id)
    const handleUnEdit = () => setEditingUser(null)

    const handleDelete = (id) => {

    }

    const _renderName = (params) => {
        return params.row.firstName+' '+params.row.lastName
    }

    const _renderCounter = (params) => {
        if (params.field in params.row) {
            return params.row[params.field];
        }
        else if (params.field+'_count' in params.row) {
            const value = params.row[params.field+'_count'];
            return Array.isArray(value) ? value[0] : value;
        }
        return null;
    }

    const _hasKC = (params) => {
        if (params.row.id_kc && String(params.row.id_kc).length) {
            return params.row.id_kc;
        }
        return null;
    }

    const _actions = (params) => {
        return <>
            <IconButton>
                <EditIcon onClick={() => handleEdit(params.row.id)} />
            </IconButton>
            {/*<DeleteIcon onClick={() => handleDelete(params.row.id)} />*/}
        </>
    }

    const columns = [
        {field:"email",         headerName:t("Email"),             flex:2},
        {field:"username",      headerName:t("Login"),             flex:2},
        {field:"name",          headerName:t("Name"),              flex:2, renderCell: _renderName},
        {field:"type",          headerName:t("Type"),              flex:1},
        {field:"ui_title",      headerName:t("Title"),             flex:2},
        {field:"kc",            headerName:t("KC"),                flex:1, renderCell: _hasKC},
        {field:"collections",   headerName:t("Collections"),       width:80, resizable: false, renderCell: _renderCounter, disableColumnMenu: true},
        {field:"images",        headerName:t("Images"),            width:80, resizable: false, renderCell: _renderCounter, disableColumnMenu: true},
        {field:"objects",       headerName:t("Objects"),           width:80, resizable: false, renderCell: _renderCounter, disableColumnMenu: true},
        {field:"id",            headerName:t(" "),                 width:80, resizable: false, renderCell: _actions, disableColumnMenu: true},
    ];


    const handleSetFilter = (value) => setFilter(value);
    const filtered = filter
        ? users.filter(row=>(
            row.username.toLowerCase().includes(filter.toLowerCase())
            || row.email.toLowerCase().includes(filter.toLowerCase())
        ))
        : users
    ;

    const rows = filtered.map(row=>({
        ...row
    }));
    
    // stack display
    const theme = useTheme();
    const [stacked, setStacked] = useState(!isMobile);
    useEffect(()=>{setStacked(!isMobile)}, [isMobile])
    const { height: screenHeight, width: screenWidth } = useWindowDimensions();
    const [contentHeight, setContentHeight] = useState(100);
    useEffect(()=>{
        setContentHeight(screenHeight
            - stackCardHeight.header
            - stackCardHeight.help
            - stackCardHeight.navBar
            - stackCardHeight.actions
            - stackCardHeight.bread
            - 2 * theme.spacing(2)
        )
    }, [screenHeight, screenWidth, stacked])
    
    if (status !== "succeeded") {
        return <Card>
            <CardHeader title={t('Users')}/>
            <CardContent>
            
            </CardContent>
        </Card>
    }
    
    return <Card>
        <CardHeader
            title={<Typography variant={"h5"}>{t('Users')} </Typography>}
            avatar={<Avatar><Group  color={"primary"} /></Avatar>}
            className={classes.mainCardHeader}
        />
        <CardContent className={classes.mainCardHelp}>
            <Grid container>
                <Grid item>
                    <SearchTextArea callBack={handleSetFilter} initValue={filter} />
                </Grid>
                <Grid item style={{flexGrow: 1}}>
    
                </Grid>
                <Grid item>
                    <ToggleButtonGroup size="medium" exclusive value={displayMode} onChange={handleDisplayMode}>
                        <ToggleButton value="table" className={classes.toggleButtons}>
                            <ViewCompact fontSize="default"/>
                        </ToggleButton>
                        <ToggleButton value="grid" className={classes.toggleButtons}>
                            <ViewComfy fontSize="default"/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Typography>
                {t('UserHelper1')}
            </Typography>
            <ul>
                <li>{t('UserHelper2')}</li>
                <li>{t('UserHelper3')}</li>
                <li>{t('UserHelper4')}</li>
            </ul>
        </CardContent>
        <CardContent className={classes.mainCardContent}>
            <div style={{
                height:stacked ? contentHeight : 'auto',
                overflowY: stacked ? 'auto' : 'visible'
            }}>
                {status === "failed" && <LinearProgress color="secondary" />}
                {status === "succeeded" && <DataGrid columns={columns} rows={rows} pageSize={20} autoHeight={!isMobile} density={"compact"} hideFooter={rows.length < 100}/>}
            </div>
        </CardContent>
        <CardActions className={classes.mainCardActions}>
        
        </CardActions>
        {!!editingUser && <EditUser userId={editingUser} onClose={handleUnEdit}/>}
    </Card>
};

function EditUser({userId, onClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector(state => selectUserById(state, userId));

    const [type, setType] = useState();
    const [title, setTitle] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [idKC, setIdKC] = useState();

    useEffect(() => {
        if (user) {
            setType(user.type);
            setTitle(user.ui_title);
            setIdKC(user.id_kc[0]);
            setFirstName(user.firstName)
            setLastName(user.lastName)
        }
    }, [user])

    const handleType  = (event) => setType(event.target.value);
    const handleTitle  = (event) => setTitle(event.target.value);
    const handleFirstName  = (event) => setFirstName(event.target.value);
    const handleLastName  = (event) => setLastName(event.target.value);
    const handleKC  = (event) => setIdKC(event.target.value);

    const handleSave = () => {
        dispatch(updateUser({
            userId: userId,
            body : {
                user_type: type,
                ui_title: title,
                id_kc: idKC,
                firstName: firstName,
                lastName: lastName,
            }
        }))
    }

    const types = ['admin', 'inventory', 'lea', 'prevision', 'private'];

    return <Dialog open={!!userId} title={t("EditUser")} maxWidth={"md"} fullWidth onClose={onClose}>
        <DialogTitle>
            Edit User
        </DialogTitle>
        <DialogContent>
            Changer les valeurs pour <strong>{user && user.username}</strong>
        </DialogContent>
        <DialogContent>
            {
                user && <FormGroup>
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel className={classes.label} shrink={true}>Type</InputLabel>
                        <Select
                            onChange={handleType}
                            value={type}
                        >
                            {types.map((option, index)=>{
                                return <MenuItem key={option} value={option}>{option}</MenuItem>
                            })}
                        </Select>
                        <FormHelperText>Le type d'utilisateur</FormHelperText>
                    </FormControl>
                    <TextField variant={"outlined"}
                               className={classes.formControl}
                               value={title}
                               onChange={handleTitle}
                               label={"Title"}
                               helperText={"Titre de l'interface"}
                    />
                    <TextField variant={"outlined"}
                               className={classes.formControl}
                               value={firstName}
                               onChange={handleFirstName}
                               label={"Prénom"}
                               helperText={"Apparait en haut à droite"}
                    />
                    <TextField variant={"outlined"}
                               className={classes.formControl}
                               value={lastName}
                               onChange={handleLastName}
                               label={"Nom de famille"}
                               helperText={"Apparait en haut à droite"}
                    />
                    <TextField variant={"outlined"}
                               className={classes.formControl}
                               value={idKC}
                               onChange={handleKC}
                               label={"ID KyeCloak"}
                               helperText={"Ne changez ceci que si vous savez ce que vous faites"}
                    />
                </FormGroup>
            }
        </DialogContent>
        <DialogActions>
            <Button variant={"contained"} color={"secondary"} onClick={handleSave}>{t('Save')}</Button>
        </DialogActions>
    </Dialog>
}

// Theme
const lightTheme = createTheme({
    ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        '& label': {
            backgroundColor: 'white !important',
            color: '#666 !important'
        }
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    searchField: {
        marginRight: theme.spacing(2)
    },
    toggleButtons: {
        height: 50,
    },
    mainCardHeader: {height: stackCardHeight.header},
    mainCardHelp: {height: stackCardHeight.help},
    mainCardSearch: {height: stackCardHeight.search},
    mainCardActions: {height: stackCardHeight.actions},
    mainCardContent: {
        paddingTop: 0,
        paddingBottom: 0
    },

}));
