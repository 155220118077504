import React, {useEffect, useRef, useState} from "react";
import {useDrag, useDrop} from 'react-dnd';
import {useDispatch} from "react-redux";
import {addImage, updateObject} from "../../../store/entities/objectsSlice";
import * as colors from "./Borders";
import store from '../../../store/store'

export default  ({collectionId, objectId, children, selection, categoryId, selectionInGroup, hovered, setHovered}) => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    //let isActive = false;

    const [isActive, setIsActive] = useState(false);
    const [isAlsoActive, setIsAlsoActive] = useState(false);

    useEffect(()=>{

    }, [])

    const [opacity, setOpacity] = useState(1);
    if (categoryId) {
        const [{ isDragging }, connectDrag] = useDrag({
            item: {
                name: objectId,
                type: "object",
                categoryId: categoryId
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        useEffect(()=>{
            connectDrag(ref);
        }, [connectDrag])

        useEffect(()=>{
            if (isDragging) {
                setOpacity(0.5)
            }
            else {
                setOpacity(1)
            }
        }, [isDragging])
    }



    // drop Image
    const [{canDrop: canDropImage, isOver: isOverImage}, connectDropImage] = useDrop({
        accept: 'image',
        drop: (item, monitor) => {
            if (!monitor.didDrop()) {
                dispatch(addImage({
                    imageId: item.name,
                    objectId: objectId
                }));
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    useEffect(()=>{
        connectDropImage(ref);
    }, [connectDropImage])

    // drop Category
    const [{canDrop: canDropCategory, isOver: isOverCategory}, connectDropCategory] = useDrop({
        accept: 'category',
        drop: (item, monitor) => {
            if (!monitor.didDrop()) {
                console.log(item.category)

                if (selectionInGroup.length && selectionInGroup.includes(objectId)) {
                    selectionInGroup.map(_objectId=>{
                        dispatch(updateObject({
                            objectId: _objectId,
                            body: {
                                categoryId: item.category.id,
                                categoryData: {
                                    id: item.category.id,
                                    title: item.category.title
                                }
                            }
                        }))
                    })
                }
                else {
                    dispatch(updateObject({
                        objectId: objectId,
                        body: {
                            categoryId: item.category.id,
                            categoryData: {
                                id: item.category.id,
                                title: item.category.title
                            }
                        }
                    }))
                }
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    useEffect(()=>{
        connectDropCategory(ref);
    }, [connectDropCategory])


    // drop Thesaurus
    const [{canDrop: canDropThesaurus, isOver: isOverThesaurus}, connectDropThesaurus] = useDrop({
        accept: 'thesaurus',
        drop: (item, monitor) => {
            console.log("Dropping thesaurus");
            console.log(item);
            console.log(monitor);
            if (!monitor.didDrop()) {
                console.log("A");
                const field = store.getState().treeFields.entities[item.fieldId]
                console.log("B");
                const droppedValue = {
                    id: item.thesaurus.id,
                    title: item.thesaurus.title
                };
                console.log("C");
                let value = droppedValue
                if (field.multiple) {
                    const object = store.getState().objects.entities[objectId];
                    if (object.fields[field.name]) {
                        if (Array.isArray(object.fields[field.name])) {
                            value = [...object.fields[field.name], droppedValue]
                        } else if (object.fields[field.name].id) {
                            value = [object.fields[field.name], droppedValue]
                        } else {
                            value = [droppedValue]
                        }
                    } else {
                        value = [droppedValue]
                    }
                }
                console.log("field", field);
                console.log("item.thesaurus", item.thesaurus);
                dispatch(updateObject({
                    objectId: objectId,
                    body: {
                        fields: {
                            [field.name]: value
                        }
                    }
                }))
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    useEffect(()=>{
        connectDropThesaurus(ref);
    }, [connectDropThesaurus])




    useEffect(()=>{
        if (isOverImage && canDropImage) {
            setIsActive(true);
            setHovered(null)
        }
        else if (isOverCategory && canDropCategory){
            setIsActive(true);
            setHovered(objectId)
        }
        else if (isOverThesaurus && canDropThesaurus) {
            setIsActive(true);
            setHovered(objectId)
        }
        else {
            setHovered(null)
            setIsActive(false)
        }
    }, [
        isActive,
        isOverThesaurus,
        canDropThesaurus,
        isOverImage,
        canDropImage,
        isOverCategory,
        canDropCategory,
    ])

    useEffect(()=>{
        if (
            !isActive
            && !isAlsoActive
            && !isOverImage
            && selectionInGroup
            && hovered
            && hovered !== objectId
            && selectionInGroup.length
            && selectionInGroup.includes(objectId)
            && selectionInGroup.includes(hovered)
        ) {
            setIsAlsoActive(true)
        }
        else {
            setIsAlsoActive(false)
        }
    }, [isActive, selectionInGroup, hovered, objectId])


    const [border, setBorder] = useState(colors.inactiveColor);

    useEffect(()=>{
        if (isActive) {
            setBorder(colors.activeColor)
        }
        else if (isAlsoActive) {
            setBorder(colors.activeColor)
        }
        else if (canDropImage || canDropCategory || canDropThesaurus) {
            setBorder(colors.canDropColor);
        }
        else {
            setBorder(colors.inactiveColor)
        }
    }, [isActive, isAlsoActive, canDropThesaurus, canDropCategory, canDropImage])

    return (
        <div
            ref={ref}
            key={collectionId}
            style={{...style, opacity, outline: border, position: 'relative'}}
        >
            {children}
        </div>
    );
}

const style = {
    outlineRadius: 4,
    borderRadius: 4
};