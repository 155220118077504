import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createTheme, makeStyles, TypographyVariant} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {selectTreeCategoryById} from "../../../store/entities/treeCategoriesSlice";
import {createField, selectTreeFieldById} from "../../../store/entities/treeFieldsSlice";
import {
    Box,
    Button, Card, CardActions, CardContent, CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField, Typography
} from "@material-ui/core";
import Translatable from "../../Collection/Fields/Translatable2";
import {setFieldEdited} from "./TreeEditorSlice";
import {toLowerKeys} from "../../Misc/languages";
import {selectAllFieldTypes} from "../../../store/entities/fieldTypesSlice";
import tdb from "../../../services/TranslateDB";
import FieldSet from "../../Misc/FieldSet";
import EditableThesaurus from "../../Collection/Fields/EditableThesaurus";
import {
    selectAllTreeThesauruses,
    selectPathOfThesaurus,
    selectRootThesauruses,
    selectTreeThesaurusById
} from "../../../store/entities/treeThesaurusSlice";
import store from '../../../store/store'
import {Add, AddShoppingCart, Save} from "@material-ui/icons";
import {ThesaurusCreate} from "./TreeEditorCatThesaurusCreate";
import {CreateIcon} from "../../Misc/IconSet";


export default function FieldCreate({categoryId, onClose}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const category = useSelector(state => selectTreeCategoryById(state, categoryId));
    const fieldTypes = useSelector(selectAllFieldTypes)
    
    const [fieldType, setFieldType] = useState();
    
    const [form, setForm] = useState({
        name: "",
        grouped: "",
        ord: 0,
        title: {[i18n.language]:"", en: ""},
        tree_thesaurus_id: null,
        tree_field_type_id: null,
        tree_category_id: categoryId,
        parameters: {}
    });
    
    useEffect(()=>{
        console.log(form)
        if (form.tree_field_type_id) {
            setFieldType(fieldTypes.find(type=>type.id===form.tree_field_type_id));
        }
    }, [form])
    
    useEffect(()=>{
        if (form.title.en) {
            setForm(f=>({...f, name:form.title.en}))
        }
    }, [form.title.en])
    
    const handleChange = (key) => (event) => {
        //console.log(event.target)
        const value = event.target.value
        setForm(_form => ({..._form, [key]: value}))
    }
    
    const [error, setError] = useState();
    
    const handleType = (event) => {
        // if ()
    }
    
    const handleSave = () => {
        dispatch(createField({
            body: form
        }))
    }
    
    return <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle>
            {t('tree.tree-editor.field.operation.edit.dialog-title', {})}
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid xs={12} item>
                    <TextField
                        fullWidth
                        disabled
                        label={t("Name")}
                        variant={"outlined"}
                        value={form.name}
                        helper={t('tree.tree-editor.field.operation.edit.name-helper')}
                        onChange={handleChange('name')}
                        //onChange={(event)=>setForm(state=>({...state, name: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Translatable
                        inputProps={{variant: "outlined"}}
                        initValue={form.title}
                        onChange={(newValue)=>setForm(state=>({...state, title:newValue}))}
                        label={t("Title")}
                        fieldsetMode={t("Title")}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldSet title={"Type"}>
                        <TextField
                            select
                            variant={"standard"}
                            fullWidth
                            //label={t("Type")}
                            value={form.tree_field_type_id}
                            InputProps={{disableUnderline:true}}
                            onChange={handleChange('tree_field_type_id')}
                            //onChange={(event)=>setForm(state=>({...state, tree_thesaurus_id: event.target.value}))}
                        >
                            {fieldTypes.map(_fieldType=>(<MenuItem key={_fieldType.id} value={_fieldType.id}>{tdb(_fieldType.title, _fieldType.name)}</MenuItem>))}
                        </TextField>
                    </FieldSet>
                </Grid>
                {
                    fieldType && fieldType.name === "Thesaurus" && <Grid item xs={12}>
                        <FieldSet title={"Thesaurus"}>
                            <FieldCreateThesaurus onChange={()=>{}}/>
                        </FieldSet>
                    </Grid>
                }
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button
                startIcon={<CreateIcon />}
                disabled={!form.name || !form.tree_field_type_id}
                onClick={handleSave}
                variant={"contained"}
                color={"secondary"}
            >
                {t('tree.tree-editor.field.operation.create.dialog-button', {})}
            </Button>
        </DialogActions>
    </Dialog>
}

function FieldCreateThesaurus({onChange}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const rootThesauruses = useSelector(selectRootThesauruses)
    const allThesauruses = useSelector(selectAllTreeThesauruses)
    
    const [fieldThesaurus, setFieldThesaurus] = useState(null);
    const [rootId, setRootId] = useState(null)
    const [thesaurusId, setThesaurusId] = useState(null);
    
    const [createMode, setCreateMode] = useState(false);
    
    useEffect(()=>{
        if (rootId) {
            setThesaurusId(rootId)
        }
    }, [rootId])
    
    useEffect(()=>{
        if (thesaurusId) {
            setFieldThesaurus(allThesauruses.find(t=>t.id===thesaurusId))
        }
    }, [thesaurusId])
    
    const handleChange = (value) => {
        console.log(value)
    }
    
    const handleSelectCreated = () => {
    
    }
    
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Box flexGrow={1}>
                    <Typography>
                        Choisissez une liste
                    </Typography>
                </Box>
                <Box>
                    <Button onClick={()=>setCreateMode(true)} startIcon={<Add />}>Add List</Button>
                    {createMode && <ThesaurusCreate onClose={()=>setCreateMode(false)} success={handleSelectCreated} />}
                </Box>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <TextField
                select
                variant={"outlined"}
                fullWidth
                label={t("List")}
                value={rootId}
                onChange={event=>setRootId(event.target.value)}
            >
                {rootThesauruses && rootThesauruses.map(_root=>(<MenuItem key={_root.id} value={_root.id}>{tdb(_root.title, _root.name)} </MenuItem>))}
            </TextField>
        </Grid>
        {!!rootId && <Grid item xs={12}>
            <EditableThesaurus
                label={`${fieldThesaurus && tdb(fieldThesaurus.title)} ${rootId === thesaurusId && 'Root'}`}
                // label={rootId === thesaurusId ? "Root" : (fieldThesaurus && tdb(fieldThesaurus.title))}
                rootId={rootId}
                field={fieldThesaurus}
                inputVariant={"outlined"}
                multiple={false}
                initEditing={true}
                onChange={handleChange}
                initValue={thesaurusId}
            />
        </Grid>}
    </Grid>
}

const useStyles = makeStyles((theme) => ({
    thesaurusDetails: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        border: "1px solid #ccc",
        borderRadius: theme.shape.radius
    }
}))