import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Container,
    createTheme,
    ThemeProvider,
    TextField,
    LinearProgress,
    useTheme,
    IconButton, Avatar, Box
} from "@material-ui/core";

import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";

import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import {NavLink} from "react-router-dom";

import {DataGrid} from "@material-ui/data-grid";
import {fetchDatasources, selectAgreedDatasources} from "../../store/entities/datasourcesSlice";
import Link from "@material-ui/core/Link";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import ViewComfy from "@material-ui/icons/ViewComfy";

import Grid from "@material-ui/core/Grid";
import tdb from "../../services/TranslateDB";
import {getCurrentUser} from "../../store/entities/currentUserSlice";
import {stackCardHeight} from "../Dimensions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {isMobile} from "react-device-detect";
import SearchTextArea from "../Misc/SearchTextField";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {OpenIcon, OpenURL} from "../Misc/IconSet";
import NiceTooltip from "../Misc/CustomToolTip";
import CollectionsIcon from "@material-ui/icons/Collections";
import {ExitToApp} from "@material-ui/icons";



const Datasources = () => {
    const currentUser = useSelector(getCurrentUser);
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.datasources.status);
    const datasources = useSelector(selectAgreedDatasources);

    const [displayMode, setDisplayMode] = useState('table');
    const handleDisplayMode = (mode) => setDisplayMode(mode);

    const [filter, setFilter] = useState();
    const handleSetFilter = (value) => setFilter(value);


    // alert(status);
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchDatasources(null));
        }
    }, [status, dispatch]);
    
    const handleOpenURL = (url) => {
        window.open(url, '_blank');
    }

    const getUrlCell = (params) => {
        return <NiceTooltip title={params.row.url}>
            <IconButton variant="contained" color="secondary" onClick={()=>handleOpenURL(params.row.url)}>
                <OpenURL />
            </IconButton>
        </NiceTooltip>
    };

    const columns = [
        {field:"database",  headerName:"Nom",       flex:2},
        {field:"type",      headerName:"Type",      flex:1},
        {field:"category",  headerName:"Cat",       flex:1},
        {field:"access",    headerName:"Access",    flex:1},
        {field:"owner",     headerName:"Owner",     flex:1},
        {field:"typology",  headerName:"Typo",      flex:1},
        {field:"url",       headerName: "URL",      width: 120, renderCell: getUrlCell, align: "right"},

    ];

    const translated = datasources.map(source=>{
        return {
            ...source,
            category: tdb(source.category),
            access: tdb(source.access),
            typology: tdb(source.typology),
        }
    })

    const filtered = filter
        ? translated.filter(row=>(
            row.database.toLowerCase().includes(filter.toLowerCase())
            || row.type.toLowerCase().includes(filter.toLowerCase())
            || row.category.toLowerCase().includes(filter.toLowerCase())
            || row.owner.toLowerCase().includes(filter.toLowerCase())
            || row.typology.toLowerCase().includes(filter.toLowerCase())
        ))
        : translated
    ;
    
    
    
    // stack display
    const theme = useTheme();
    const [stacked, setStacked] = useState(!isMobile);
    useEffect(()=>{setStacked(!isMobile)}, [isMobile])
    const { height: screenHeight, width: screenWidth } = useWindowDimensions();
    const [contentHeight, setContentHeight] = useState(100);
    useEffect(()=>{
        setContentHeight(screenHeight
            - stackCardHeight.header
            - stackCardHeight.search
            - stackCardHeight.navBar
            - stackCardHeight.actions
            - stackCardHeight.bread
            - 2 * theme.spacing(2)
        )
    }, [screenHeight, screenWidth, stacked]);

    return <Container disableGutters={true} maxWidth={"xl"}>
        <ThemeProvider theme={lightTheme(currentUser && currentUser.type === "prevision")}>
            <Breadcrumbs separator="/">
                <Link to="/" component={NavLink} className={classes.Link}>
                    {t('HomePageLink')}
                </Link>
                <Typography>
                    {t('Datasources')}
                </Typography>
            </Breadcrumbs>
            <Card elevation={3}>
                <CardHeader
                    title={<Typography variant={"h5"}>{t('DataBases')} </Typography>}
                    avatar={<Avatar><CollectionsIcon  color={"primary"} /></Avatar>}
                    className={classes.mainCardHeader}
                />
                <CardContent className={classes.mainCardSearch}>
                    <SearchTextArea callBack={handleSetFilter} />
                </CardContent>
                <CardContent className={classes.mainCardContent}>
                    <div style={{
                        height:stacked ? contentHeight : 'auto',
                        overflowY: stacked ? 'auto' : 'visible'
                    }}>
                        {
                            status === "loading"
                                ? <LinearProgress variant={"indeterminate"} color={"secondary"} />
                                : (
                                    screenWidth < 800
                                        ? filtered.map(base => <DataSource key={base.id} base={base} />)
                                        : <DataGrid
                                        columns={columns}
                                        rows={filtered}
                                        pageSize={100}
                                        hideFooter={filtered.length<100}
                                        density={"compact"}
                                        autoHeight={isMobile}
                                        onRowDoubleClick={getUrlCell}
                                    />
                                )
                        }
                    </div>
                </CardContent>
                <CardActions>
                    <Grid container justifyContent="flex-end">
                        <Button title={t('CreateNewQuery')} variant="contained" color="secondary" disabled>
                            {t('AddDataBase')}
                        </Button>
                    </Grid>
                </CardActions>
            </Card>
        </ThemeProvider>
    </Container>
};

/*
{field:"database",  headerName:"Nom",       flex:2},
{field:"type",      headerName:"Type",      flex:1},
{field:"category",  headerName:"Cat",       flex:1},
{field:"access",    headerName:"Access",    flex:1},
{field:"owner",     headerName:"Owner",     flex:1},
{field:"typology",  headerName:"Typo",      flex:1},
{field:"url",       headerName: "URL",      width: 120, renderCell: getUrlCell, align: "right"},
* */

function DataSource({base}) {
    return <Card style={{marginTop: 10}} raised={false} elevation={1}>
        <CardHeader
            title={base.database}
            action={<Link href={base.url} target={'_blank'}><ExitToApp /></Link>}
        >
        </CardHeader>
        <CardContent>
            <DataView label={"type"} data={base.type} />
            <DataView label={"category"} data={base.category} />
            <DataView label={"access"} data={base.access} />
            <DataView label={"type"} data={base.type} />
            <DataView label={"owner"} data={base.owner} />
        </CardContent>
    </Card>
}

function DataView({label, data}) {
    return <Box display={"flex"} flexDirection={"row"}>
        <Box flexGrow={1}>{label}</Box>
        <Box>{data}</Box>
    </Box>
}

export default Datasources;

// Theme
const lightTheme = (prevision = false) => createTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette)
})



// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    },
    searchField: {
        marginRight: theme.spacing(2)
    },
    mainCardHeader: {height: stackCardHeight.header},
    mainCardSearch: {height: stackCardHeight.search},
    mainCardActions: {height: stackCardHeight.actions},
    mainCardContent: {
        paddingTop: 0,
        paddingBottom: 0
    },
    toggleButtons: {
        height: 50,
    }
}));
