
// Draggable Layer (shown instead of real element)
import {useDragLayer} from "react-dnd";
import {Box, Typography} from "@material-ui/core";
import LabelIcon from "@material-ui/icons/Label";
import React from "react";
import {Bookmark} from "@material-ui/icons";

export function SelectorDragLayer({}) {
    const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));
    if (!isDragging) {
        return null;
    }
    let label = null;
    if (itemType === 'category') {
        label = <>
            <LabelIcon fontSize={"small"} />
            <Typography variant={"caption"}>{item.title}</Typography>
        </>
    }
    else if (itemType === 'thesaurus') {
        label = <>
            <Bookmark Size={"small"} />
            <Typography variant={"caption"}>{item.title}</Typography>
        </>
    }
    else {
        return null;
    }
    return <div style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    }}>
        <div style={{
            ...getItemStyles(initialOffset, currentOffset),
            backgroundColor: 'rgba(0,0,0,.5)',
            minWidth: 120,
            display: 'inline-block',
            padding: 1
        }}>
            <Box display={"flex"} justifyItems={"center"} flexDirection={"row"}>
                {label}
            </Box>
        </div>
    </div>
}

// function to position and style the draggable layer
function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
    if (!initialOffset || !currentOffset) {
        return {display: 'none',};
    }
    let { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}