import React from "react";
import {ThemeProvider, createTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";
import { Switch, Route, NavLink} from "react-router-dom";
import {useTranslation}     from 'react-i18next';
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";

import Surveys from "./Surveys.jsx";
import SurveyCreate from "./SurveyCreate.jsx";
import Survey from "./Survey.jsx";

const SurveysRouter = () => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    // TODO improve breadbrumb
    return <Container disableGutters={true} maxWidth={"xl"}>
        <ThemeProvider theme={lightTheme}>
            <Switch>
                <Route exact path="/surveys" >
                    <Breadcrumbs separator="/">
                        <Link to="/" component={NavLink} className={classes.Link}>
                            {t('HomePageLink')}
                        </Link>
                        <Typography>
                            {t('SurveysLink')}
                        </Typography>
                    </Breadcrumbs>
                    <Surveys createButton={true} inheritStacked />
                </Route>
                <Route path="/surveys/example">
                    <SurveysBreadCrumbs current={<Typography>Current</Typography>} />
                    <Survey />
                </Route>
                <Route path="/surveys/new">
                    <SurveysBreadCrumbs current={<Typography>New</Typography>} />
                    <SurveyCreate />
                </Route>
                <Route path="/surveys/:surveyId">
                    <SurveysBreadCrumbs current={<Typography>Current</Typography>} />
                    <Survey />
                </Route>
            </Switch>
        </ThemeProvider>
    </Container>;
};


function SurveysBreadCrumbs({current}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    return <Breadcrumbs separator="/">
        <Link to="/" component={NavLink} className={classes.Link}>
            {t('HomePageLink')}
        </Link>
        <Link to="/surveys/" component={NavLink} className={classes.Link}>
            {t('SurveyLink')}
        </Link>
        {current && current}
    </Breadcrumbs>
}

// Theme
const lightTheme = createTheme({
    ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    }
}));

export default SurveysRouter;
