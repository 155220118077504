import React, {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
// MATERIAL
import {Box, Card, CardActionArea, CardContent, Paper, Typography} from "@material-ui/core";
// SLICES
import Image from "material-ui-image";
import {selectObjectById} from "../../../store/entities/objectsSlice";
import {selectImagesByObject} from "../../../store/entities/imagesSlice";
import {selectTreeCategoryById} from "../../../store/entities/treeCategoriesSlice";
// CUSTOM
import tdb from "../../../services/TranslateDB";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
let objectTimeout;

// ---------------------------------------------------------------------------------------------------------------------
// OBJECT THUMBNAIL
//const CollectionObjectThumb = memo(({objectId, imageSize, selected, onAction, onSelect})=>{
export default function CollectionObjectThumb({objectId, imageSize, selected, onSelect, actionPath, version}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const object = useSelector(state => selectObjectById(state, objectId));
    const images = useSelector(state => selectImagesByObject(state, objectId));
    const category = useSelector(state => selectTreeCategoryById(state, object ? object.category : null));
    const history = useHistory();

    const [currentImage, setCurrentImage] = useState(0);
    const handleCurrentImage = () => {
        setCurrentImage((currentImage + 1) % images.length);
        return false;
    }

    const getMappedSize = () => {
        return imageSize * 25 + 50;
    };

    const openObject = () => {
        history.push(actionPath + 'object/' + objectId)
    }

    const handleMouseDown = (event) => {
        // clearTimeout(objectTimeout)
        // objectTimeout = setTimeout(()=>openObject(), 1000)
    }

    const handleMouseUp = (event) => {
        clearTimeout(objectTimeout)
        onSelect(objectId);
    }

    // console.log(`thumb redraw ${object.name} ${selected ? "Y" : "N"}`);

    if (!object)return null;
    
    return (
        <>
            <Card className={clsx({[classes.selected]:selected})} elevation={selected ? 0 : 3}>
                <CardActionArea
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    // onClick={()=>onSelect(objectId)}
                    onDoubleClick={()=>openObject()}
                >
                    <CardContent style={{padding: 4}}>
                        <Box style={{height:44, overflow: 'hidden'}}>
                            <Typography style={{width: getMappedSize(), fontSize:  ["", ".82rem", ".85rem", ".88rem", ".92rem", ".95rem"][imageSize], height:22, overflow: 'hidden', whiteSpace: 'nowrap'}}>
                                {object.name}
                                {/*{tdb(object.title, t('Untitled'))}*/}
                            </Typography>
                            <Typography style={{width: getMappedSize(), fontSize: ["", ".65rem", ".7rem", ".76rem", ".8rem", ".9rem"][imageSize], height:22, overflow: 'hidden', whiteSpace: 'nowrap'}}>
                                {category ? tdb(category.title, category.name) : t('Unclassified')}
                            </Typography>
                        </Box>
                        <span>
                        {images.length
                            ? <img
                                alt=""
                                src={images[currentImage].urls.medium ? images[currentImage].urls.medium : "error"}
                                draggable={false}
                                className={classes.media}
                                style={{width: getMappedSize(), height: getMappedSize()}}
                                onClick={handleCurrentImage}
                            />
                            : <div
                                className={classes.media}
                                style={{width: getMappedSize(), height: getMappedSize()}}
                            >
                            </div>
                        }
                    </span>
                    </CardContent>
                </CardActionArea>
            </Card>
        </>
    );
};

function compareProps(prev, next) {
    return prev.objectId === next.objectId
        && prev.imageSize === next.imageSize
        && prev.selected === next.selected
        && prev.actionPath === next.actionPath
        && prev.version === next.version
}

export const CollectionObjectThumbMemo = memo(CollectionObjectThumb, compareProps);

// style of the layout
const useStyles = makeStyles((theme) => ({
    image: {
        borderStyle: 'hash',
        borderColor: theme.palette.primary.contrastText,
        borderWidth: 1,
        //padding: theme.spacing(1),
    },
    selected: {
        backgroundColor: "rgba(0,0,0,.2)",
        outline: "1px solid #666"
    },
    media: {
        height: '100%',
        width: '100%',
        backgroundColor: 'black'
    }
}));

