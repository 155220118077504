import React, {useEffect, useState} from "react";
import {
    TextField,
    Box,
    Button,
    MenuItem,
    Menu,
    Badge,
    Typography,
    Avatar,
    InputAdornment,
    IconButton
} from "@material-ui/core";
import FieldSet from "../../Misc/FieldSet";
import {Add, ArrowDropDown, ArrowDropUp, Close, Remove,} from "@material-ui/icons";
import {useTranslation, getI18n} from "react-i18next";
import {useLanguages} from "../../Misc/languages";

export default function Translatable({label, initValue, onChange, inputProps, fieldsetMode=false, limit=3, updatableFromOutside= true}) {
    const {t} = useTranslation();
    const {priority, languages, languagesKeys} = useLanguages();
    
    useEffect(()=>{
        if (initValue) {
            console.log("initValue", initValue);
            // updatableFromOutside is set to false, performance will improve (a bit)
            if(updatableFromOutside)setValue(initValue);
        }
    }, [initValue])
    
    
    const [value, setValue] = useState(initValue || {
        "default": ""
    });

    const handleChange = (event, iso) =>{
        setValue(state=>({...state, [iso]: event.target.value}));
    }
    
    const handleRemove = (event, iso) =>{
        const _value = {...value}
        console.log("_value", _value);
        delete _value[iso];
        console.log("_value", _value);
        setValue(_value);
    }

    const [toFocus, setToFocus] = useState(null);
    const handleAdd = (iso) => {
        setAnchorEl(null)
        setValue(state=>({...state, [iso]: ""}))
        setToFocus(iso)
    }

    const [showAll, setShowAll] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    useEffect(()=>{
        console.log("value", value)
        if (value) {
            if (value !== initValue) {
                onChange(value);
            }
        }
    }, [value]);

    const getLimit = () => {
        return limit + (!!toFocus && languages.indexOf(toFocus) >= limit ? 1 : 0);
    }

    const content = <>
        {languages
            .filter(item => item.iso in value)
            .map((item, i) => (
            (showAll || i < limit || item.iso === toFocus)
                ? <TextField
                    key={item.iso}
                    {...inputProps}
                    style={{marginTop: i > 0 ? 20 : 0}}
                    label={`${item.value.nativeName} (${item.value.name})`}
                    value={value[item.iso]}
                    onChange={(event) => handleChange(event, item.iso)}
                    focused={toFocus===item.iso}
                    InputProps={{
                        startAdornment: <InputAdornment position={"start"}>{item.iso}</InputAdornment>,
                        endAdornment: <IconButton size={"small"} onClick={(event)=>handleRemove(event, item.iso)} ><Close fontSize={"small"} /></IconButton>
                    }}
                />
                : null))}
        <Box display={"flex"} justifyContent={"flex-end"} style={{marginTop: 10}}>
            <Button variant={"contained"} size={"small"} color={"default"} startIcon={<Add />} onClick={(event)=>setAnchorEl(event.currentTarget)}>
                {t('AddTranslation')}
            </Button>
            <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                keepMounted
                onClose={()=>setAnchorEl(null)}
            >
                {languages
                    .filter(l=>!(l.iso in value))
                    .map(lang=>(
                        <MenuItem
                            key={lang.iso}
                            onClick={()=>handleAdd(lang.iso)}
                            value={lang.iso}
                        >
                            {lang.value.name}
                        </MenuItem>))}
            </Menu>
            {
                !showAll && Object.keys(value).length > getLimit() &&
                <Button startIcon={<ArrowDropDown />} onClick={()=>setShowAll(s=>!s)}>
                    {`${Object.keys(value).length - getLimit()} ${t('more')}`}
                </Button>
            }
            {
                showAll && Object.keys(value).length > getLimit() &&
                <Button startIcon={<ArrowDropUp />} onClick={()=>setShowAll(s=>!s)}>
                    {`${Object.keys(value).length - getLimit()} ${t('less')}`}
                </Button>
            }
        </Box>
    </>;

    return !!fieldsetMode
        ? <FieldSet title={fieldsetMode}>{content}</FieldSet>
        : content;
}
