import React, {useState} from "react";
import clsx from "clsx";
import {ClickAwayListener, InputBase, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import NiceTooltip from "../../Misc/CustomToolTip";
import tdb from "../../../services/TranslateDB";
import {useTranslation} from "react-i18next";

const KEY_ENTER = 13;
const KEY_ESC = 27;

export default function EditableText({initValue, field, type, textClasses, inputClasses, onChange, variant, toolTip = true}) {
    const [editing, setEditing] = useState(false);
    const {t} = useTranslation();
    const classes = useStyles();
    const [value, setValue] = useState(initValue);
    const handleEdit = () => setEditing(true);
    const handleUnEdit = () => {
        setEditing(false);
        onChange(value, field);
    }
    const handleChange = (event) => setValue(event.target.value);
    const handleKey = (event) => {
        if (event.keyCode === KEY_ENTER) {
            handleUnEdit();
        }
        else if (event.keyCode === KEY_ESC) {
            setValue(initValue);
            setEditing(false);
        }
    };
    // classes={{root: {...textClasses, ...classes.typo}}}
    return !editing
        ?   <div>
                {toolTip
                    ? <NiceTooltip title={`${t('EditField')} ${tdb(field.title)}`} placement={"top-start"}>
                        <Typography onClick={handleEdit} className={clsx(classes.hover, textClasses ? textClasses.root : null)} variant={variant}>{initValue ? initValue : '(vide)'}</Typography>
                    </NiceTooltip>
                    :   <Typography onClick={handleEdit} className={clsx(classes.hover, textClasses ? textClasses.root : null)} variant={variant}>{initValue ? initValue : '(vide)'}</Typography>}
            </div>
        :   <ClickAwayListener onClickAway={handleUnEdit}>
                <div style={{position:"relative"}}>
                    {<InputBase
                        onKeyUp={handleKey}
                        onChange={handleChange}
                        autoFocus
                        value={value}
                        type={type && type.name === "Date" ? "date" : null}
                        //classes={{root: {...classes.input, ...(textClasses && textClasses.input ? textClasses.input : [])}}}
                        //className={clsx(classes.input, textClasses ? textClasses.input : null)}
                        classes={{
                            root: clsx(classes.root, textClasses ? textClasses.root : null),
                            input: clsx(classes.input, textClasses ? textClasses.input : null)
                        }}
                    />}
                </div>
            </ClickAwayListener>
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        //padding: 2,
        //height: '2rem',
        //border: "1px solid #ccc"
    },
    input: {
        width: '100%',
        padding: 0,
    },
    hover: {
        //padding: 4,
        // height: '2rem',
        '-webkit-transition': 'background-color .5s ease-out',
        '-moz-transition': 'background-color .5s ease-out',
        '-o-transition': 'background-color .5s ease-out',
        'transition': 'background-color .5s ease-out',
        '&:hover': {
            backgroundColor: '#fdcaca',

        }
    }
}));