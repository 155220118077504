import React, {useRef} from "react";
import { useDrop } from 'react-dnd';
import {useDispatch, useSelector} from "react-redux";
import {createObject} from "../../../store/entities/objectsSlice";
import {selectRootCategories} from "../../../store/entities/treeCategoriesSlice";
import * as colors from "./Borders";
import {NativeTypes} from "react-dnd-html5-backend";
import {createImage} from "../../../store/entities/imagesSlice";

export default  ({collectionId, children, categoryId, className, onSend}) => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const rootCategories = useSelector(selectRootCategories);

    FileReader.prototype.setFile = function(file) {
        this.file = file;
        return this;
    };

    // drop Image
    const [{canDrop: canDropImage, isOver: isOverImage, item}, connectDropImage] = useDrop({
        accept: NativeTypes.FILE,
        drop: (item, monitor) => {
            if  (!monitor.didDrop()) {
                // TODO : this works, ensure it is the best way
                for (let i=0; i<item.files.length; i++) {
                    let file = item.files[i];
                    console.log(file);
                    if (file.type.startsWith("image/")) {
                        let reader = new FileReader().setFile(file);
                        reader.onload = function(e) {
                            let parameters = {
                                data: 'data:'+this.file.type+';base64,'+window.btoa(this.result),
                                name: this.file.name,
                                size: this.file.name,
                                collectionId: collectionId
                            };
                            dispatch(createImage(parameters)); // let the middleware make the proper nesting
                        };
                        reader.readAsBinaryString(file);
                    }
                }
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({shallow: true}),
            canDrop: monitor.canDrop(),
            item: monitor.getItem(),
        }),
    });
    connectDropImage(ref);

    let isActive = (isOverImage && canDropImage);

    let border= '2px solid transparent';
    if (isActive) {
        border= colors.activeColor;
    }
    else if (canDropImage) {
        border= colors.canDropColor;
    }
    return (
        <div
            ref={ref}
            key={collectionId}
            style={{...style, border}}
            className={className}
        >
            {children}
        </div>
    );
}

const style = {

};