const defaultLightPalette = {
    palette: {
        type: 'light',
        primary: {
            main: '#000',
        },
        action: {
            hoverOpacity: 0.08,
            selectedOpacity: 0.16
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        },
        h1: {
            fontSize: "2.8rem"
        },
        h2: {
            fontSize: "2.4rem"
        },
        h3: {
            fontSize: "2.1rem"
        },
        h4: {
            fontSize: "1.8rem"
        },
        h5: {
            fontSize: "1.5rem"
        },
        h6: {
            fontSize: "1.2rem"
        }
    },
};

export const previsionPalette = {
    ...defaultLightPalette,
    palette: {
        type: 'light',
        primary: {
            main: '#ffffff',
        },
        secondary: {
            light: "#a873c8",
            main: "#8a3fac",
            dark: "#5c1c7a",
            contrastText: "#fff"
        },
    },
}

export default defaultLightPalette;