import React, {useEffect, useState} from "react";
import {Avatar, Card, CardContent, CardHeader} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {fetchOrganizations, selectOrganizationById} from "../../store/entities/organizationsSlice";
import tdb from "../../services/TranslateDB";
import {Apartment} from "@material-ui/icons";
import Users from "../User/Users";

export default function Organization({organizationId}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    
    console.log(organizationId);
    
    
    
    const organization = useSelector(state => selectOrganizationById(state, organizationId));
    const status = useSelector(state => state.organizations.status);
    
    useEffect(()=>{
        if (status === "idle") {
            dispatch(fetchOrganizations(null))
        }
    }, [dispatch, status])
    
    return <Card>
        <CardHeader
            avatar={<Avatar><Apartment /></Avatar>}
            title={!!organization && organization.name}
            subheader={!!organization && tdb(organization.title)}
        />
        <CardContent>
            <Users organizationId={organizationId} />
        </CardContent>
    </Card>
}

const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    }
}));