import React, {useEffect, createRef, useState} from "react";
import {makeStyles, TypographyVariant} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    CardContent,
    Grid,
    Typography,
    Slider,
    DialogTitle,
    DialogContent,
    Box,
    Badge,
    Menu,
    MenuList,
    MenuItem,
    ListItemIcon,
    ListItemText,
    ImageList,
    ImageListItem
} from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {
    AddAPhoto, Check, Close, Create, Delete,
    DeleteForever, DoneAll,
    FileCopy, MoreVert, 
    PlayForWork,  SelectAll,
    ViewComfy,
    ViewModule,
    ZoomIn,
    ZoomOut
} from "@material-ui/icons";
import {
    createImage, deleteImage,
    fetchCollectionImages, fetchUpdateImage,
    selectCollectionRootImages,
    selectImageById, selectImagesByIds,
    selectImagesVersions
} from "../../../store/entities/imagesSlice";
import CollectionImage from "./CollectionImage.jsx";
import CollectionImagesDnD from "../DnD/CollectionImageDnD.jsx";
import CollectionImagesDnd from "../DnD/CollectionImagesDnd";
import NiceTooltip from "../../Misc/CustomToolTip";
import Fade from "@material-ui/core/Fade";
import WithRightButtons from "../../Misc/WithRightButtons";
import {createObject} from "../../../store/entities/objectsSlice";
import {selectRootCategories} from "../../../store/entities/treeCategoriesSlice";
import {collectionUpdateUnclassified} from "../../../store/entities/collectionsSlice";
import {useLocation} from "react-router-dom";



export default function CollectionImages({collectionId, editing, fullPage}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const {t, i18n} = useTranslation();
    const [displayMode, setDisplayMode] = useState('grid');
    
    
    const [cellSize, setCellSize] = useState(2);
    const rootImages = useSelector((state) => selectCollectionRootImages(state, collectionId));
    const fileInput = createRef();
    const handleDisplayMode = (event, mode) => {
        setDisplayMode(mode);
    };
    const handleCellSize = (event, size) => {
        setCellSize(size);
    };
    const getZoneHeight = () => {
        const imageSize = (cellSize * 25 + 50);
        return 60 + imageSize * 2;
    };

    const getZoneWidth = () => {
        const imageSize = (cellSize * 25 + 10);
        return Math.ceil(rootImages.length / 2) * imageSize;
    };

    useEffect(()=>{
        dispatch(fetchCollectionImages(collectionId));
    }, [collectionId, dispatch]);

    // nicer way to store file in filereader
    // TODO : add in index
    FileReader.prototype.setFile = function(file) {
        this.file = file;
        return this;
    };

    // TODO : this works, ensure it is the best way
    // sending uploaded photos
    const handleUploadPhotos = () => {
        for (let i=0; i<fileInput.current.files.length; i++) {
            let file = fileInput.current.files.item(i);
            let reader = new FileReader().setFile(file);
            reader.onload = function(e) {
                let parameters = {
                    data: 'data:'+this.file.type+';base64,'+window.btoa(this.result),
                    name: this.file.name,
                    size: this.file.name,
                    collectionId: collectionId
                };
                dispatch(createImage(parameters)); // let the middleware make the proper nesting
            };
            reader.readAsBinaryString(file);
        }
    };
    const handleAddPhoto = () => {

    };

    const handleCreateObject = () => {

    }
    
    // URL /upload opens files
    useEffect(()=>{
        if (location.pathname.endsWith('/upload')) {
            if (fileInput.current) {
                fileInput.current.click()
            }
        }
    }, [location])

    // SELECTION MANAGEMENT
    const [selection, setSelection] = useState([]);
    const handleSelect = (objectId) => {
        let newSelection;
        if (selection.indexOf(objectId) === -1) {
            newSelection = multipleSelect ? [objectId, ...selection] : [objectId]
        } else {
            newSelection = [...selection];
            newSelection.splice(selection.indexOf(objectId), 1)
        }
        setSelection(newSelection);
    }
    const [multipleSelect, setMultipleSelect] = useState(true);
    const handleMultipleSelect = () => {
        setMultipleSelect(m => !m)
    };

    useEffect(() => {
        if (!multipleSelect && selection.length > 0) {
            setSelection([]);
        }
    }, [multipleSelect])

    // SELECTION ACTIONS
    const [selectionMenuAnchor, setSelectionMenuAnchor] = useState();
    const [showSelectionMenu, setShowSelectionMenu] = useState();
    const [currentSelectionAction, setCurrentSelectionAction] = useState("");
    const handleWithSelection = (event) => {
        setSelectionMenuAnchor(event.currentTarget)
        setShowSelectionMenu(true)
    }

    const handleCurrentSelectionAction = (action) => {
        setCurrentSelectionAction(action);
        setShowSelectionMenu(false);
    }

    const handleInvertSelect = () => {
        setSelection(p => rootImages.filter(o => !p.includes(o.id)).map(f => f.id))
    }

    const showLabel = false;

    const [openModalImage, setOpenModalImage] = useState();
    const handleOpenImage = (imageId) => {
        setOpenModalImage(imageId);
    }

    const rootCategories = useSelector(selectRootCategories);
    
    //
    const handeCreateObjects = () => {
        selection.forEach(imageId=>{
            const image = rootImages.find(i=>i.id===imageId);
            if (image) {
                dispatch(createObject({
                    collectionId: collectionId,
                    imageId: imageId,
                    title: image.originalFilename
                        .replace(/\.([a-z]|[A-Z])+$/, '')
                        .replace(/_/g, ' '),
                    categoryId: rootCategories[0].id
                }));
            }
        })
        setSelection([]);
    }
    
    const moveImagesInExistingCollection = () => {
    
    }

    useEffect(()=>{
        if (rootImages) {
            dispatch(collectionUpdateUnclassified({id: collectionId, unclassified: rootImages.length}))
            setSelection([])
        }
    }, [rootImages, dispatch])

    const content = <CardContent className={classes.images}>
        <Grid unselectable direction="row" justify="space-between" alignItems="flex-start" container className={classes.zoneBar}>
            <Grid item>
                <Grid item>
                    <Typography variant="h5">{t('UnclassifiedImageWithCount', {count: rootImages.length})}</Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Box flexWrap={"nowrap"}>
                    {editing
                        ? <>
                            <ToggleButtonGroup  value={null} className={classes.buttonGroup}>
                                <NiceTooltip title={t("UploadPhoto")} placement={"top-center"}>
                                    <ToggleButton value="camera2" size="small"  style={{height: 40}} component={"label"} htmlFor={collectionId+"fileInput"}>
                                        <AddAPhoto />
                                        {showLabel && <Typography style={{marginLeft: 5}}>{t('UploadPhoto')}</Typography>}
                                        <input ref={fileInput} id={collectionId+"fileInput"} type="file" style={{display: 'none'}} multiple={true} onChange={handleUploadPhotos} />
                                    </ToggleButton>
                                </NiceTooltip>
                            </ToggleButtonGroup>
                            {rootImages.length > 0 &&
                            <ToggleButtonGroup  value={null} className={classes.buttonGroup}>
                                <NiceTooltip title={t("CreateObjectsFromImages")} placement={"top-center"}>
                                    <ToggleButton value="camera" size="small"  style={{height: 40}} onClick={handleCreateObject} disabled={rootImages.length === 0}>
                                        <PlayForWork />
                                        {showLabel && <Typography style={{marginLeft: 5}}>{t('CreateObjectsFromImages')}</Typography>}
                                    </ToggleButton>
                                </NiceTooltip>
                            </ToggleButtonGroup>}
                        </>
                        :   null}
                    <ToggleButtonGroup className={classes.buttonGroup} aria-label={"display"}>
                        <NiceTooltip title={t("ObjectsMultipleSelection")} placement={"top-end"}>
                            <ToggleButton value={t("multipleSelect")} selected={multipleSelect} size="small" style={{height: 40}} onClick={handleMultipleSelect}>
                                <DoneAll/>
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("ObjectsInvertSelection")} placement={"top-end"}>
                            <ToggleButton value={t("invertSelect")} selected={false} disabled={!multipleSelect}
                                          size="small" style={{height: 40}} onClick={handleInvertSelect}>
                                <SelectAll/>
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("OperationsOnSelection")} placement={"top-end"}>
                            <ToggleButton value={t("invertSelect")} selected={false} disabled={selection.length === 0}
                                          size="small" style={{height: 40}} onClick={handleWithSelection}>
                                <Badge badgeContent={selection.length} color={"secondary"}>
                                    <MoreVert/>
                                </Badge>
                            </ToggleButton>
                        </NiceTooltip>
                        {/* SELECTION MENU */}
                        <Menu
                            open={showSelectionMenu}
                            anchorEl={selectionMenuAnchor}
                            onClose={() => setShowSelectionMenu(false)}
                            id={"selection-menu"}
                            anchorOrigin={{vertical: "top", horizontal: "right"}}
                            TransitionComponent={Fade}
                        >
                            <MenuList>
                                {/*<MenuItem button onClick={()=>handleCurrentSelectionAction("moveImagesInExistingCollection")}>
                                    <ListItemIcon><FileCopy/></ListItemIcon>
                                    <ListItemText>{t('CopyToCollection')}</ListItemText>
                                </MenuItem>*/}
                                <MenuItem button onClick={handeCreateObjects}>
                                    <ListItemIcon><FileCopy/></ListItemIcon>
                                    <ListItemText>{t('CreateObjectFromSelection')}</ListItemText>
                                </MenuItem>
                                <MenuItem button onClick={()=>handleCurrentSelectionAction("deleteImages")}>
                                    <ListItemIcon><Delete/></ListItemIcon>
                                    <ListItemText>{t('Delete')}</ListItemText>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                        {/* SELECTION ACTIONS */}
                        {currentSelectionAction === "deleteImages" &&
                            <CollectionImageDeleteImages
                                selection={selection} collectionId={collectionId} setSelection={setSelection} onClose={()=>setCurrentSelectionAction("")} />}
                        {currentSelectionAction === "moveImagesInExistingCollection" &&
                            <CollectionImageDeleteImages
                                selection={selection} collectionId={collectionId} setSelection={setSelection} onClose={()=>setCurrentSelectionAction("")} />}
                    </ToggleButtonGroup>
                    <ToggleButtonGroup exclusive value={displayMode} onChange={handleDisplayMode} className={classes.buttonGroup}>
                        <NiceTooltip title={t("ImageAllGrid")} placement={"top-center"}>
                            <ToggleButton value="grid" style={{height: 40}} size="small" >
                                <ViewComfy/>
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("ImageShrinkGrid")} placement={"top-center"}>
                            <ToggleButton value="scrollable" style={{height: 40}} size="small">
                                <ViewModule/>
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>
                    <ToggleButtonGroup>
                        <ToggleButton size="small" style={{height: 40}}>
                            <ZoomOut/>
                            <Slider
                                style={{width: 80}}
                                className={classes.slider}
                                defaultValue={cellSize}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                onChange={handleCellSize}
                                step={1} marks min={1} max={5}
                            />
                            <ZoomIn/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Grid>
        </Grid>
        <Box className={classes.helpers}>
            {
                rootImages.length === 0 && !editing && <Typography variant={"body2"}>{t('ImageZoneHelper_noImages')}</Typography>
            }
            {
                rootImages.length === 0 && editing && <Typography variant={"body2"}>{t('ImageZoneHelper_sendImages')}</Typography>
            }
            {
                rootImages.length > 1 && editing && <Typography variant={"body2"}>{t('ImageZoneHelper_groupImages')}</Typography>
            }
            {
                rootImages.length > 0 && editing && <Typography variant={"body2"}>{t('ImageZoneHelper_dragImages')}</Typography>
            }
            {
                rootImages.length > 0 && !editing && <Typography variant={"body2"}>{t('ObjectZoneHelper_ActivateEditToCreateObjectsFromImage')}</Typography>
            }
        </Box>
        <Box>
            <Grid
                container
                spacing={2}
                wrap="wrap"
                justify="flex-start"
                className={displayMode === "scrollable" ?  classes.scrollable : null}
                style={displayMode === "scrollable" ?  {
                    height: getZoneHeight(),
                } : null}
            >
                {
                    rootImages.map((image, index) => (
                        !editing ?
                            <Grid item key={image.id} >
                                <CollectionImage
                                    imageId={image.id}
                                    imageSize={cellSize}
                                    isEditing={editing}
                                    openModal={handleOpenImage}
                                    selected={selection.includes(image.id)}
                                    onSelect={handleSelect}
                                />
                            </Grid>
                            :
                            <Grid item key={image.id} >
                                <CollectionImagesDnD
                                    imageId={image.id}
                                    name={image.id}
                                    selection={selection}
                                    selected={selection.includes(image.id)}
                                    setSelection={setSelection}
                                >
                                    <CollectionImage
                                        imageId={image.id}
                                        imageSize={cellSize}
                                        isEditing={editing}
                                        openModal={handleOpenImage}
                                        selected={selection.includes(image.id)}
                                        onSelect={handleSelect}
                                    />
                                </CollectionImagesDnD>
                            </Grid>
                    ))
                }
            </Grid>
        </Box>

        {
            !! openModalImage && <ImageModal
                imageId={openModalImage}
                isEditing={editing}
                onClose={()=>setOpenModalImage(null)}
                setOpenModalImage={setOpenModalImage}
                selection={selection}
                handleSelect={handleSelect}
            />
        }
    </CardContent>

    return (editing
        ? <CollectionImagesDnd collectionId={collectionId}>{content}</CollectionImagesDnd>
        : content);
}

export function ImageModal ({imageId, imageSize, onClose, setOpenModalImage}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const image = useSelector(state => selectImageById(state, imageId));
    const versionImages = useSelector(state => selectImagesVersions(state, imageId));
    const getMappedSize = () => {
        return imageSize * 25 + 50;
    };

    const handleDetach = (versionId) => {
        const version = versionImages.find(v=>v.id===versionId);
        const meta = {...version.meta};
        delete meta.versionOf;
        dispatch(fetchUpdateImage({
            imageId: versionId,
            body: {
                meta: meta
            }
        }))
    }

    const handleSetAsMain = (versionId) => {
        const siblings = versionImages.filter(v => v.id!==versionId)
        const newVersions = [...siblings, image]
        handleDetach(versionId)
        newVersions.forEach(_image=>{
            dispatch(fetchUpdateImage({
                imageId: _image.id,
                body: {
                    meta: {..._image.meta, versionOf: versionId}
                }
            }))
        })
        setOpenModalImage(versionId);
    }

    const handleDelete = (versionId) => {
        dispatch(deleteImage({imageId: versionId}))
    }

    const handleDeleteAll = () => {
        versionImages.forEach(_image=>{
            dispatch(deleteImage({imageId: _image.id}))
        })
        dispatch(deleteImage({imageId: imageId}))
        onClose();
    }

    const handleCreateObject = () => {

    }

    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                {image.originalFilename}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid sm={4} xs={12} item>
                        <img alt="" src={image.urls.medium} draggable={false} className={classes.image} />
                    </Grid>
                    <Grid sm={8} xs={12} item>
                        <Typography variant={"h5"}>
                            {image.originalFilename}
                        </Typography>
                        <Typography variant={"body2"}>
                            {t('ImageMetaData')}
                        </Typography>
                    </Grid>
                    {versionImages.length > 0 && <>
                        <Grid xs={12} item>
                            <Typography variant={"h5"}>
                                {t("imagesOfSameObjectWithCount", {count: versionImages.length})}
                            </Typography>
                        </Grid>
                        {versionImages.map((version, i)=>(
                            <>
                                <Grid sm={4} xs={12} item>
                                    <img alt="" src={version.urls.medium} draggable={false} className={classes.image} />
                                </Grid>
                                <Grid sm={8} xs={12} item>
                                    <WithRightButtons
                                        content={<Typography variant={"h6"}>
                                            {version.originalFilename}
                                        </Typography>}
                                        actions={
                                            <ToggleButtonGroup>
                                                <NiceTooltip title={t('ImageSetAsMain')}>
                                                    <ToggleButton  onClick={()=>handleSetAsMain(version.id)} size={"small"}>
                                                        <Check fontSize={"small"} />
                                                    </ToggleButton>
                                                </NiceTooltip>
                                                <NiceTooltip title={t('ImageDelete')}>
                                                    <ToggleButton  onClick={()=>handleDelete(version.id)} size={"small"}>
                                                        <Delete fontSize={"small"} />
                                                    </ToggleButton>
                                                </NiceTooltip>
                                                <NiceTooltip title={t('ImageDetach')}>
                                                    <ToggleButton  onClick={()=>handleDetach(version.id)} size={"small"}>
                                                        <Close fontSize={"small"} />
                                                    </ToggleButton>
                                                </NiceTooltip>
                                            </ToggleButtonGroup>
                                        }
                                    />
                                    <Typography variant={"body2"}>
                                        {t('ImageMetaData')}
                                    </Typography>
                                </Grid>
                            </>
                        ))}
                    </>}

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button startIcon={<Create />} color={"secondary"} variant={"contained"} onClick={handleCreateObject}>
                    {t('CreateObject')}</Button>
                <Button startIcon={<DeleteForever />} color={"secondary"} variant={"contained"} onClick={handleDeleteAll}>
                    {t('Delete')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
function CollectionImageDeleteImages({collectionId, onClose, selection, setSelection}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const images = useSelector(state => selectImagesByIds(state, selection));
    const handleDeleteImages = () => {
        selection.forEach(imageId=>{
            dispatch(deleteImage({imageId: imageId}))
        })
        setSelection(state=>[]);
        onClose();
    }
    return <Dialog open={true} onClose={onClose} maxWidth={"sm"} fullWidth>
        <DialogTitle>{t('DeleteImage')}</DialogTitle>
        <DialogContent>
            <ImageList rowHeight={120} cols={4} gap={0}>
                {images.map(image=>(
                    <ImageListItem key={image.id} cols={1}>
                        <img src={image.urls.medium} />
                    </ImageListItem>
                ))}
            </ImageList>
        </DialogContent>
        <DialogActions>
            <Button variant={"contained"} color={"primary"} onClick={onClose}>{t('Cancel')}</Button>
            <Button variant={"contained"} color={"secondary"} onClick={handleDeleteImages}>{t('Delete')}</Button>
        </DialogActions>
    </Dialog>
}
const useStyles = makeStyles((theme) => ({
    image: {
        width:'100%',
        height: 'auto'
    },
    root: {
        flexGrow: 1
    },
    buttonGroup: {
        marginRight: theme.spacing(2)
    },
    images: {
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: "#ffcccc"

    },
    helpers: {
        marginBottom: theme.spacing(1),
    },
    zoneBar: {
        marginBottom: theme.spacing(2)
    },
    bordered: {
        width: '50%'
    },
    slider: {
        color: 'rgba(0,0,0,1)',
    },
    scrollable: {
        overflow: 'auto'
    }
}));