
import { configureStore } from '@reduxjs/toolkit';
import collectionsReducer from './entities/collectionsSlice';
import collectionTypesReducer from './entities/collectionTypesSlice';
import collectionTypesFieldsReducer from './entities/collectionTypesFieldsSlice';
import imagesReducer from './entities/imagesSlice';
import objectReducer from './entities/objectsSlice';
import userReducer from './entities/usersSlice';
import organizationReducer from './entities/organizationsSlice';
import currentUserReducer from './entities/currentUserSlice';
import investigationsReducer from "./entities/investigationsSlice";
import criminalCasesReducer from "./entities/criminalCasesSlice";
import tasksReducer from "./entities/tasksSlice";
import surveyReducer from "./entities/surveysSlice";
import searchReducer from "./entities/searchSlice";
import queriesReducer from "./entities/queriesSlice";
import treeCategoriesReducer from "./entities/treeCategoriesSlice";
import treeThesaurusReducer from "./entities/treeThesaurusSlice";
import treeFieldsReducer from "./entities/treeFieldsSlice";
import datasourcesReducer from "./entities/datasourcesSlice";
import fieldTypesReducer from "./entities/fieldTypesSlice";
import identificationsReducer from "./entities/identificationSlice";
// HERE ADD THE SLICE OF REDUCERS
import preferencesSlice from "./reducers/preferencesSlice";
import drawerSelectorReducer from "../components/Selectors/Drawer/SelectorSlice";
import treeEditorReducer from '../components/Selectors/TreeEditor/TreeEditorSlice'

const reducers = {
    collections: collectionsReducer,
    collectionTypes: collectionTypesReducer,
    collectionTypeFields: collectionTypesFieldsReducer,
    images: imagesReducer,
    objects: objectReducer,
    users: userReducer,
    organizations: organizationReducer,
    currentUser: currentUserReducer,
    tasks: tasksReducer,
    investigations: investigationsReducer,
    identifications: identificationsReducer,
    criminalCases: criminalCasesReducer,
    surveys: surveyReducer,
    search: searchReducer,
    queries: queriesReducer,
    treeCategories: treeCategoriesReducer,
    treeThesauruses: treeThesaurusReducer,
    treeFields: treeFieldsReducer,
    datasources: datasourcesReducer,
    fieldTypes: fieldTypesReducer,
    preferences: preferencesSlice,
    drawerSelector: drawerSelectorReducer,
    treeEditor: treeEditorReducer,
}

// TODO : some stores should be added dynamically depending on user
// TODO use https://redux.js.org/usage/code-splitting to achieve this

export default configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
});
