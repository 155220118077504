import React from "react";
import { Switch, Route, NavLink, useParams} from "react-router-dom";
import {ThemeProvider, createTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import CriminalCase from "./CriminalCase";
import CriminalCases from "./CriminalCases";
import CriminalCaseCreate from "./CriminalCaseCreate";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getCurrentUser} from "../../store/entities/currentUserSlice";
import {selectCollectionById} from "../../store/entities/collectionsSlice";
import {selectCriminalCaseById} from "../../store/entities/criminalCasesSlice";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

const CriminalCasesRouter = () => {
    const currentUser = useSelector(getCurrentUser);
    const classes = useStyles();
    const {t} = useTranslation();
    // TODO improve breadbrumb
    return <Container maxWidth={"xl"} disableGutters={true} className={classes.container}>
        <ThemeProvider theme={lightTheme(currentUser && currentUser.type === "prevision")}>
            <Switch>
                <Route exact path="/cases" >
                    <Breadcrumbs separator="/">
                        <Link to="/" component={NavLink} className={classes.Link}>
                            {t('HomePageLink')}
                        </Link>
                        <Typography>
                            {t('CriminalCasesLink')}
                        </Typography>
                    </Breadcrumbs>
                    <CriminalCases/>
                </Route>
                <Route path="/cases/:criminalCaseId">
                    <CriminalCasesBreadCrumbs current={<CurrentCaseTitle />} />
                    <Current/>
                </Route>
            </Switch>
        </ThemeProvider>
    </Container>;
};

function Current() {
    const { criminalCaseId } = useParams();
    return <CriminalCase criminalCaseId={criminalCaseId} />
}

function CurrentCaseTitle() {
    const { criminalCaseId } = useParams();
    const criminalCase = useSelector(state => selectCriminalCaseById(state, criminalCaseId));
    return <Typography>{criminalCase && criminalCase.origin}</Typography>
}

function CriminalCasesBreadCrumbs({current}) {
    const classes = useStyles();
    const {t} = useTranslation();

    return <Breadcrumbs separator="/">
        <Link to="/" component={NavLink} className={classes.Link}>
            {t('HomePageLink')}
        </Link>
        <Link to="/cases/" component={NavLink} className={classes.Link}>
            {t('CriminalCasesLink')}
        </Link>
        {current && current}
    </Breadcrumbs>
}

// Theme
const lightTheme = (prevision = false) => createTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette)
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    },
    container: {
    
    }
}));

export default CriminalCasesRouter;