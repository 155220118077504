import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Card, CardContent, CardHeader, Divider, Grid, Typography} from "@material-ui/core";
import {ToggleButton, ToggleButtonGroup, TreeItem, TreeView} from "@material-ui/lab";
import {ChevronRight, ExpandMore, ImportExport, Label} from "@material-ui/icons";
import NiceTooltip from "../../Misc/CustomToolTip";

import {
    selectCategoryEdited,
    selectCategoryExpanded,
    selectCategorySelected, selectFieldEdited,
    selectFieldSelected, selectThesaurusSelected,
    setCategoryExpanded,
    setCategorySelected
} from "./TreeEditorSlice";
import {selectFieldsByCategory} from "../../../store/entities/treeFieldsSlice";
import {selectRootCategories, selectSubCategories, selectTreeCategoryById} from "../../../store/entities/treeCategoriesSlice";

import tdb from "../../../services/TranslateDB";
import {InheritedFields} from "./TreeEditorCatFields";
import {TreeCategory} from "./TreeEditorCategory";
import Field from "./TreeEditorCatField";
import EditCategory from "./TreeEditorCategoryEdit";
import {Thesaurus} from "./TreeEditorCatThesaurus";
import FieldEdit from "./TreeEditorCatFieldEdit";

export const Categories = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();

    const rootCategories = useSelector(selectRootCategories);
    const expanded = useSelector(selectCategoryExpanded);
    const selectedCategory = useSelector(selectCategorySelected);
    const editedCategory = useSelector(selectCategoryEdited);
    const selectedField = useSelector(selectFieldSelected);
    const editedField = useSelector(selectFieldEdited);
    const selectedThesaurus = useSelector(selectThesaurusSelected);

    useEffect(()=>{
        if (!selectedCategory && rootCategories.length) {
            dispatch(setCategorySelected(rootCategories[0].id));
            dispatch(setCategoryExpanded([rootCategories[0].id]));
        }
    }, [selectedCategory, rootCategories])

    const handleToggle = (event, nodeIds) => {
        dispatch(setCategoryExpanded(nodeIds))
    };
    const handleSelectCategory = (event, nodeIds) => {
        dispatch(setCategorySelected(nodeIds))
    };

    const [sortMode, setSortMode] = useState(false);
    
    const [cols, setCols] = useState({lg:4, md:6, sm: 12});

    return <Grid item container spacing={2}>
        <Grid item {...cols}>
            <Card className={classes.categories}>
                <CardHeader
                    title={t("Categories")}
                    action={<ToggleButtonGroup>
                        <NiceTooltip title={t("ReorderMode")} placement={"bottom-end"}>
                            <ToggleButton size={"small"} selected={sortMode} onClick={()=>setSortMode(s=>!s)}>
                                <ImportExport />
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>}
                />
                <CardContent>
                    <TreeView
                        className={classes.tree}
                        defaultCollapseIcon={<ExpandMore />}
                        defaultExpandIcon={<ChevronRight />}
                        expanded={expanded}
                        selected={selectedCategory}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelectCategory}
                    >
                        {rootCategories.map((rootCategory, index) => {
                            return <CategoryLeaf index={index} categoryId={rootCategory.id} />
                        })}
                    </TreeView>
                </CardContent>
            </Card>
            <Box className={classes.separator} />
            {!!editedCategory && <EditCategory categoryId={editedCategory} />}
        </Grid>
        <Grid item {...cols}>
            <Grid container spacing={2} alignItems={"flex-start"}>
                {!!selectedCategory && <TreeCategory categoryId={selectedCategory} />}
                {!!selectedCategory && <InheritedFields categoryId={selectedCategory} />}
            </Grid>
        </Grid>
        <Grid item {...cols}>
            <Grid container spacing={2} alignItems={"flex-start"}>
                {!!selectedField && <Field fieldId={selectedField} />}
                {!!editedField && <FieldEdit fieldId={editedField} />}
                {!!selectedThesaurus && <Thesaurus />}
            </Grid>
        </Grid>
    </Grid>
};

function CategoryLeaf({categoryId, index}) {
    // TODO : use categoryId here
    const classes = useStyles();
    const category = useSelector(state => selectTreeCategoryById(state, categoryId));
    const subCategories = useSelector(state => selectSubCategories(state, categoryId));
    const fields = useSelector(state => selectFieldsByCategory(state, categoryId));
    return <TreeItem
        nodeId={category.id}
        label={
            <div className={classes.labelRoot}>
                <Label className={classes.labelIcon} />
                <Typography className={classes.labelText}>{tdb(category.title, category.name)}</Typography>
                {
                    fields.length ? <Typography variant="caption">{fields.length}</Typography> : null
                }
            </div>
        }
    >
        {subCategories.map((subCategory, index) => {
            return <CategoryLeaf categoryId={subCategory.id} index={index} />
        })}
    </TreeItem>
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    categories: {
        overflow: 'auto'
    },
    separator: {
        height: theme.spacing(2)
    },
    tree: {
        fontSize: 1
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'row',
    },
    labelIcon: {
        marginRight: theme.spacing(1),
        color: "#999"
    },
    labelText: {
        flexGrow: 1
    },
}));