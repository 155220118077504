import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import {
    Container,
    Typography,
    createMuiTheme,
    ThemeProvider,
    useTheme,
    IconButton, Avatar
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { DataGrid } from "@material-ui/data-grid";
import Grid from "@material-ui/core/Grid";

import defaultLightPalette from "../../services/defaultLightPalette";
import { useHistory, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    useSortedIdentifications,
    fetchIdentifications,
    setCurrentImage,
    setCurrentIdentification
} from "../../store/entities/identificationSlice";

import { clearSearch } from "../../store/entities/searchSlice";
import Link from "@material-ui/core/Link";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import { isMobile } from "react-device-detect";
import { stackCardHeight } from "../Dimensions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import NiceTooltip from "../Misc/CustomToolTip";
import { OpenIcon } from "../Misc/IconSet";
import moment from "moment";
import {Add, RemoveRedEye} from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import CollectionsIcon from "@material-ui/icons/Collections";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

const Identifications = ({ title }) => {
    let history = useHistory();

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.identifications.status);
    const identifications = useSelector(useSortedIdentifications);

    useEffect(() => {

    }, [])

    const moveTo = (row) => {
        console.log(row)
        let src = row.images[0].urls.big
        dispatch(clearSearch())
        dispatch(setCurrentIdentification(row))
        dispatch(setCurrentImage(src))
        history.push('/identification')
    }

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchIdentifications(null));
        }
    }, [status, dispatch]);

    const getViewCell = (params) => {
        return <NiceTooltip title={t("See")}>
            <IconButton variant="contained" color="secondary" onClick={() => moveTo(params.row)}>
                <OpenIcon />
            </IconButton>
        </NiceTooltip>
    };
    const getImageCell = (params) => {
        return <>
            {params.row.images[0] ?
                <img src={params.row.images[0].urls.medium} style={{ height: '100px' }}
                    onClick={() => moveTo(params.row)} />
                : null}
        </>
    };

    const handleRowClick = (params) => {
        moveTo(params.row);
    }

    const getTargetCell = (params) => {
        return <>
            {params.row.target ?
                params.row.target.map((value) => {
                    return <span key={value.title} style={{ marginRight: '10px' }}>{value['title']} </span>
                }) : null}
        </>
    };

    const getDateCell = (params) => {
        return moment(params.row.created).format("lll");
    };


    const columns = [
        { field: "image", headerName: "Image", flex: 7, renderCell: getImageCell },
        { field: "created", headerName: t('Created'), flex: 1, renderCell: getDateCell },
        { field: "id", headerName: " ", width: 80, align: 'right', renderCell: getViewCell }
    ];

    // stack display
    const theme = useTheme();
    const [stacked, setStacked] = useState(!isMobile);
    useEffect(() => { setStacked(!isMobile) }, [isMobile])
    const { height: screenHeight, width: screenWidth } = useWindowDimensions();
    const [contentHeight, setContentHeight] = useState(100);
    useEffect(() => {
        setContentHeight(screenHeight
            - stackCardHeight.header
            // - stackCardHeight.search
            - stackCardHeight.navBar
            - stackCardHeight.actions
            - stackCardHeight.bread
            - 2 * theme.spacing(2)
            - 60
        )
    }, [screenHeight, screenWidth, stacked])
    
    const [showAll, setShowAll] = useState(false);
    const [filtered, setFiltered] = useState([]);
    
    useEffect(()=>{
        if (showAll)
            setFiltered(identifications)
        else {
            setFiltered(identifications.filter(identification=>moment(identification.created).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')))
        }
    }, [showAll, identifications])

    return <Container disableGutters={true} className={classes.root} maxWidth={"xl"}>
        <ThemeProvider theme={lightTheme}>
            <Breadcrumbs separator="/">
                <Link to="/" component={NavLink} className={classes.BreadCrumbLink}>
                    {t('HomePageLink')}
                </Link>
                <Typography>
                    {t('IdentificationLink')}
                </Typography>
            </Breadcrumbs>
            <Card>
                <CardHeader
                    title={<Typography variant={"h5"}>{title ? title : t('Identifications')}</Typography>}
                    avatar={<Avatar><VisibilityIcon color={"primary"} /></Avatar>}
                    className={classes.mainCardHeader}
                    action={<IconButton to={"/identification"} component={NavLink}><Add /></IconButton>}
                />
                <CardContent className={classes.mainCardContent}>
                    <div style={{height: 60, display: "flex", flexDirection: "row", alignItems: "baseline"}}>
                        <Typography variant={"h6"}>
                            {showAll ? t('Historique des identifications') : t('Identifications récentes')}
                        </Typography>
                        {(
                            (!showAll && identifications.length > filtered.length)
                            || (showAll)
                        ) && identifications.length && <Link onClick={() => setShowAll(s => !s)} style={{marginLeft: 20}}>
                            {showAll ? t('generic.seeLess') : t('generic.seeMore')}
                        </Link>}
                    </div>
                    <div style={{
                        height: stacked ? contentHeight : 'auto',
                        overflowY: stacked ? 'auto' : 'visible'
                    }}>
                        {filtered.length > 0 && <DataGrid
                            rowHeight="100"
                            columns={columns}
                            rows={filtered}
                            pageSize={100}
                            autoHeight={isMobile}
                            density={"compact"}
                            hideFooter={identifications.length < 100}
                            onRowDoubleClick={handleRowClick}
                        />}
                    </div>
                </CardContent>
                <CardActions className={classes.mainCardActions}>
                    <Grid container justifyContent="flex-end">
                        <Button
                            startIcon={<Add />}
                            title={t('CreateNewIdentification')}
                            variant="contained" color="secondary"
                            to={"/identification"} component={NavLink}>
                            {t('CreateNewIdentification')}
                        </Button>
                    </Grid>
                </CardActions>
                {isMobile && <SpeedDialer classes={classes} />}
            </Card>
        </ThemeProvider>
    </Container>
};

function SpeedDialer({classes}) {
    
    const [dialerOpened, setDialerOpened] = useState(false);
    const history = useHistory();
    
    const handleOpen = () => {
        setDialerOpened(true);
    };
    const handleClick = (link) => {
        history.push(link)
        setDialerOpened(false);
    };
    const actions = [
        { icon: <ImageSearchIcon />, name: 'search', title: 'Search', to:'/identification' },
    ];
    return <SpeedDial
        ariaLabel="SpeedDial tooltip"
        open={dialerOpened}
        hidden={false}
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={()=>setDialerOpened(false)}
        onOpen={handleOpen}
    >
        {actions.map((action) => (
            <SpeedDialAction
                title={action.title}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={()=>handleClick(action.to)}
            />
        ))}
    </SpeedDial>
}

// Theme
const lightTheme = createMuiTheme({
    ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    },
    root: {
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        }
    },
    BreadCrumbLink: {
        color: 'white'
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    mainCardHeader: { height: stackCardHeight.header },
    mainCardSearch: { height: stackCardHeight.search },
    mainCardActions: { height: stackCardHeight.actions },
    mainCardContent: {
        paddingTop: 0,
        paddingBottom: 0
    },
}))

export default Identifications;
;
