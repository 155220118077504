import React, {useEffect, useState} from 'react';
import {Container, useTheme} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {isMobile} from "react-device-detect";
import {stackCardHeight} from "./Dimensions";
import Link from "@material-ui/core/Link";
import {NavLink} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const ContentPage = ({title, content}) => {
    // stack display
    const theme = useTheme();
    const classes = useStyles();
    const [stacked, setStacked] = useState(!isMobile);
    useEffect(()=>{setStacked(!isMobile)}, [isMobile])
    const { height: screenHeight, width: screenWidth } = useWindowDimensions();
    const {t} = useTranslation()
    const [contentHeight, setContentHeight] = useState(100);
    useEffect(()=>{
        setContentHeight(screenHeight
            - stackCardHeight.navBar
            - stackCardHeight.bread
            - 2 * theme.spacing(2)
        )
    }, [screenHeight, screenWidth, stacked]);
    return <Container disableGutters={true} maxWidth={"xl"}>
        <Breadcrumbs separator="/">
            <Link to="/" component={NavLink} className={classes.Link}>
                {t('HomePageLink')}
            </Link>
            <Typography>
                {title}
            </Typography>
        </Breadcrumbs>
        <Card style={{height: contentHeight}}>
            <CardHeader title={title} />
            <CardContent style={{minHeight: 400}}>
                {content}
            </CardContent>
        </Card>
    </Container>
};

export default ContentPage;

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    },
    container: {
    
    }
}));