import React, { useState, useEffect, createRef, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import defaultLightPalette from "../../services/defaultLightPalette";

import SearchResult from "./SearchResult";
import ImageEditor from "../ImageEditor/ImageEditor";
import { launchSearch } from "../../store/entities/searchSlice";

import { fetchTreeThesauruses } from "../../store/entities/treeThesaurusSlice";
import { fetchTreeCategories, selectAllTreeCategories } from "../../store/entities/treeCategoriesSlice";
import { fetchTreeFields } from "../../store/entities/treeFieldsSlice";
import { getCurrentUser } from "../../store/entities/currentUserSlice";
import { isMobile } from "react-device-detect";


// Material core
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';


import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import ImageIcon from "@material-ui/icons/Image";
import TextFormat from "@material-ui/icons/TextFormat";

import { makeStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";



// Icons
import ViewComfy from "@material-ui/icons/ViewComfy";
import ViewList from "@material-ui/icons/ViewList";


import client_ai from '../../api/client_ai';


const Identification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileInput = createRef();
  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [searchDataTranslations, setSearchDataTranslations] = useState({});
  const [searchDataFalseCognate, setSearchDataFalseCognate] = useState({});
  const [searchDataSynonyms, setSearchDataSynonyms] = useState({});
  const [searchMode, setSearchMode] = useState('image');
  const [timeoutId, setTimeoutId] = useState(0);
  const [searchExtraData, setSearchExtraData] = useState({});
  const status = useSelector(state => state.search.status);
  const categories = useSelector(selectAllTreeCategories);
  const form = useRef(null)
  const currentUser = useSelector(getCurrentUser);

  let indexes = []

  if (currentUser.type == 'weapons-user') {
    indexes = ["weapons"]
  }
  else {
    indexes = ["police", "ecommerce", "smartBrowser", "positive_database", "redLists", "redListInt", "man", "mnm", "joconde", "man_rmn", "louvre"]
  }

  useEffect(() => {
    if (!categories.length) {
      dispatch(fetchTreeCategories(null));
      dispatch(fetchTreeThesauruses(null));
      dispatch(fetchTreeFields(null));
    }
  }, [categories, dispatch]);

  const handleModeChange = () => {
    if (searchMode === "text") {
      setSearchMode('image');
    } else {
      setSearchMode('text');
    }
  }
  const handleChangeLang = (event) => {
    const value = event.target;
    setSearchDataTranslations({ ...searchDataTranslations, [event.target.name]: value.checked });
  }

  const handleChangeFC = (event) => {
    const value = event.target;
    setSearchDataFalseCognate({ ...searchDataFalseCognate, [event.target.name]: value.checked });
  }

  const handleChangeSynonym = (event) => {
    const value = event.target;
    setSearchDataSynonyms({ ...searchDataSynonyms, [event.target.name]: value.checked });
  }



  const searchRequest = () => {
    let requestSearchData = {}
    for (var i in searchDataTranslations) {
      if (searchDataTranslations[i]) {
        requestSearchData[i] = searchExtraData['translations'][i]
      }
    }
    for (var i in searchDataSynonyms) {
      if (searchDataSynonyms[i]) {
        requestSearchData[i] = i
      }
    }
    for (var i in searchDataFalseCognate) {
      if (searchDataFalseCognate[i]) {
        requestSearchData[i] = searchExtraData['false_cognate'][i]
      }
    }
    var data = {
      searchText: searchText,
      searchData: requestSearchData
    }
    dispatch(launchSearch(data));
  }

  const handleImageChange = (image) => {
    var data = {
      fullImage: image,
      url: null,
      indexes: indexes
    }
    dispatch(launchSearch(data));
  }
  const handleSearchTermsChange = (event) => {
    setSearchEnabled(false)
    let value = event.target.value
    setSearchText(value);
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(function () {
        var data = {
          search: value
        }
        client_ai.post("/get_request_extra_data", data).then(
          (response) => {
            console.log(response)
            setSearchExtraData(response)
            setSearchEnabled(true)
          }
        )
      },
        800
      )
    )
  }

  return <Container disableGutters={true} maxWidth={"xl"}>
    <ThemeProvider theme={lightTheme}>
      <Card className={classes.searchCard}>
        <form ref={form}>
          <Typography component="div" className={classes.switchHolder}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item><TextFormat /></Grid>
              <Grid item>
                <Switch checked={searchMode == 'image'} onChange={handleModeChange} name="switchMode" />
              </Grid>
              <Grid item><ImageIcon /></Grid>
            </Grid>
          </Typography>
          {isMobile ? null : <CardHeader title={t('Identification')} />}
          <CardContent style={{ padding: isMobile ? "45px 0px 0px 0px" : "inherit" }}>
            {searchMode === 'image' ?
              <>
                <ImageEditor onImageChange={handleImageChange} />
              </>
              :
              <Grid container spacing={2} style={{ justify: "center" }}>
                <Grid item lg={3} md={3} sm={false} />
                <Grid md={6} sm={6} xs={12} item className={classes.searchHolder}>
                  <div>
                    <Grid container>
                      <Grid xs={11} item>
                        <TextField fullWidth variant="outlined" label={t('KeywordsSearch')} name="searchTerms" value={searchText} onChange={handleSearchTermsChange} />
                      </Grid>

                      <Grid item alignItems="stretch" style={{ display: "flex" }}>
                        <Button disabled={!searchEnabled} variant="contained" color="primary" onClick={searchRequest}>OK</Button>
                      </Grid>
                    </Grid>
                  </div>
                  {searchExtraData && searchExtraData.translations ?
                    <Grid md={12} sm={12} xs={12} item className={classes.searchExtraHolder}>
                      <Typography variant="subtitle1">{t('Différentes traductions ont été trouvées')}</Typography>
                      <div>
                        {Object.entries(searchExtraData.translations).map(([lang, translation]) => {
                          return <span className={classes.translations}>
                            <Checkbox onChange={handleChangeLang} name={lang} key={lang} />
                            {t(lang)} ({lang}): {translation}
                          </span>
                        })}
                      </div>
                    </Grid>
                    : null
                  }
                  {searchExtraData && searchExtraData.false_cognate && Object.entries(searchExtraData.false_cognate).length > 0 ?
                    <Grid md={12} sm={12} xs={12} item className={classes.searchExtraHolder}>
                      <Typography variant="subtitle1">{t('Faux ami, un autre terme semble plus approprié:')}</Typography>
                      <div>
                        {Object.entries(searchExtraData.false_cognate).map(([fc, corrected]) => {
                          return <span className={classes.translations}>
                            <Checkbox onChange={handleChangeFC} name={fc} key={fc} />
                            {fc}: {corrected}
                          </span>
                        })}
                      </div>
                    </Grid>
                    : null
                  }
                  {searchExtraData && searchExtraData.synonyms && searchExtraData.synonyms.length > 0 ?
                    <Grid md={12} sm={12} xs={12} item className={classes.searchExtraHolder}>
                      <Typography variant="subtitle1">{t('D\'autres termes sont synonymes')}</Typography>
                      <div>
                        {searchExtraData.synonyms.map((term) => {
                          return <span className={classes.translations}>
                            <Checkbox onChange={handleChangeSynonym} name={term} key={term} />
                            {term}
                          </span>
                        })}
                      </div>
                    </Grid>
                    : null
                  }
                </Grid>
              </Grid>
            }
          </CardContent>
          {status === "loading" ? <CardContent>
            <Typography>{t('Loading')}</Typography>
          </CardContent> : null}
          {status === "failed" ? <CardContent>
            <Typography>{t('An Error Occured')}</Typography>
          </CardContent> : null}
          {status === "success" ? <SearchResult image={image} /> : null}
        </form>
      </Card>
    </ThemeProvider>
  </Container>;
};

// Theme
const lightTheme = createMuiTheme({
  ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    // marginBottom: theme.spacing(5),
    height: 100,
  },
  navLink: {
    color: 'black',
    textDecoration: 'inherit'
  },
  autocomplete: {
    options: {
      padding: 2
    }
  },
  expertValue: {
    padding: '5px',
    marginLeft: "20px",
    display: 'inline-block'
  },
  expertImageLabel: {
    position: "relative"
  },
  expertImageRow: {
    position: "relative"
  },
  switchHolder: {
    position: "absolute",
    right: '10px',
    top: '5px'
  },
  searchCard: {
    position: "relative"
  },
  searchHolder: {
    paddingTop: '80px !important',
    paddingBottom: '80px !important'
  },
  translations: {
    padding: '0px 15px'
  }
}));

export default Identification;
