import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

import {makeStyles} from "@material-ui/core/styles";
import {NavLink, useHistory} from "react-router-dom";

import { DataGrid } from '@material-ui/data-grid';

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import {isMobile} from "react-device-detect";
import {stackCardHeight} from "../Dimensions";

import {selectAllInvestigations, fetchInvestigations, createInvestigation} from "../../store/entities/investigationsSlice";
import {useTranslation} from "react-i18next";
import {
    Avatar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormGroup, Grid,
    IconButton,
    Link,
    TextField, useTheme
} from "@material-ui/core";
import SearchTextArea from "../Misc/SearchTextField";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import NiceTooltip from "../Misc/CustomToolTip";
import {OpenIcon} from "../Misc/IconSet";
import PolicyIcon from "@material-ui/icons/Policy";
import Typography from "@material-ui/core/Typography";
import {Add, Save} from "@material-ui/icons";

export default function InvestigationList() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const status = useSelector(state => state.investigations.status);
    const investigations = useSelector(selectAllInvestigations);
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchInvestigations(null));
        }
    }, [status, dispatch]);

    const [openCreate, setOpenCreate] = useState(false);
    const handleOpenCreate = () => setOpenCreate(true);
    const handleCloseCreate = () => setOpenCreate(false);

    const [search, setSearch] = useState("");
    const [filtered, setFiltered] = useState(investigations);

    useEffect(()=>{
        setFiltered(investigations)
    }, [investigations])

    useEffect(()=>{
        if (search) {
            setFiltered(investigations.filter(i=>(i.name.toLowerCase().includes(search) || i.description.toLowerCase().includes(search))))
        }
        else {
            setFiltered(investigations)
        }
    }, [search])
    
    // stack display
    const theme = useTheme();
    const [stacked, setStacked] = useState(!isMobile);
    useEffect(()=>{setStacked(!isMobile)}, [isMobile])
    const { height: screenHeight, width: screenWidth } = useWindowDimensions();
    const [contentHeight, setContentHeight] = useState(100);
    useEffect(()=>{
        setContentHeight(screenHeight
            - stackCardHeight.header
            - stackCardHeight.search
            - stackCardHeight.navBar
            - stackCardHeight.actions
            - stackCardHeight.bread
            - 2 * theme.spacing(2)
        )
    }, [screenHeight, screenWidth, stacked])

    return <Card elevation={3}>
        <CardHeader
            title={<Typography variant={"h5"}>{t("InvestigationsTitle")}</Typography>}
            avatar={<Avatar><PolicyIcon color={"primary"} /></Avatar>}
            className={classes.mainCardHeader}
        />
        <CardContent className={classes.mainCardSearch}>
            <SearchTextArea callBack={setSearch} />
        </CardContent>
        <CardContent className={classes.mainCardContent}>
            <div style={{
                height:stacked ? contentHeight : 'auto',
                overflowY: stacked ? 'auto' : 'visible'
            }}>
                <InvestigationsTable investigations={filtered} gridProps={{autoHeight: isMobile, density: 'compact', hideFooter: filtered.length<100}} />
            </div>
        </CardContent>
        <CardActions className={classes.mainCardActions}>
            <Grid container justifyContent="flex-end">
                <Button startIcon={<Add />} variant="contained" size="medium" color="secondary" onClick={handleOpenCreate}>
                    {t('CreateNewInvestigation')}
                </Button>
            </Grid>
            <InvestigationCreate open={openCreate} onClose={handleCloseCreate}/>
        </CardActions>
    </Card>
}

export function InvestigationsTable({investigations, gridProps, extraCols=[]}) {
    const {t, i18n} = useTranslation();
    const history = useHistory();
    
    const renderCount = (params) => (params.value && params.value.length > 0 ? params.value.length : '')
    const handleRowClick = (params) => {
        console.log(params.row)
        history.push('/investigations/'+params.row.id);
    }
    
    // TODO: choose columns
    const columns = [
        {field: "name",         headerName: t("Name"),          flex: 1, renderCell: params => <Link component={NavLink} to={`/investigations/${params.row.id}`}>{params.value}</Link> },
        {field: "description",  headerName: t("Description"),   flex: 3},
        {field: "tasks",        headerName: t('Tasks'),         width: 80, renderCell: renderCount, disableColumnMenu: true},
        {field: "collections",  headerName: t('Collections'),   width: 80, renderCell: renderCount, disableColumnMenu: true},
        {field: "id",           headerName: " ", width: 80, disableColumnMenu: true, align: 'right',
            renderCell: (params) => (
                <NiceTooltip title={t('Open')}>
                    <IconButton variant="text" color="secondary" size="small" to={'/investigations/'+params.value} component={NavLink}>
                        <OpenIcon />
                    </IconButton>
                </NiceTooltip>
            )
        },
        ...extraCols
    ];
    return <DataGrid
        columns={columns}
        rows={investigations}
        pageSize={100}
        onRowDoubleClick={handleRowClick}
        autoHeight {...gridProps}
    />;
}

function InvestigationCreate({open, onClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch()

    const [name, setName] = useState(null);
    const handleName = (event) => setName(event.target.value);

    const [description, setDescription] = useState(null);
    const handleDescription = (event) => setDescription(event.target.value);

    const handleCreate = () => {
        if (name && description) {
            dispatch(createInvestigation({
                body: {
                    name: name,
                    description: description,
                }
            }));
            onClose();
        }
    }

    return <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>
            {t('CreateNewInvestigation')}
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField fullWidth variant="outlined" value={name} onChange={handleName} placeholder={t("Name")} />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth variant="outlined" value={description} onChange={handleDescription} placeholder={t("Description")} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button
                color={'primary'}
                variant={'contained'}
                onClick={onClose}
            >
                {t('Cancel')}
            </Button>
            <Button
                startIcon={<Save />}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={handleCreate}
                disabled={!name || !description}
            >
                {t('CreateNewInvestigation')}
            </Button>
        </DialogActions>
    </Dialog>;
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    mainCardHeader: {height: stackCardHeight.header},
    mainCardSearch: {height: stackCardHeight.search},
    mainCardActions: {height: stackCardHeight.actions},
    mainCardContent: {
        paddingTop: 0,
        paddingBottom: 0
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));