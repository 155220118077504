import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {NavLink, useHistory} from "react-router-dom";
import { DataGrid } from '@material-ui/data-grid';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import {selectAllCriminalCases, fetchCriminalCases} from "../../store/entities/criminalCasesSlice";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {
    Avatar,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Typography,
    useTheme
} from "@material-ui/core";
import {
    BusinessCenter, Cloud, CloudDone, CloudDownload
} from "@material-ui/icons";
import SearchTextArea from "../Misc/SearchTextField";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {isMobile}           from "react-device-detect";
import NiceTooltip from "../Misc/CustomToolTip";

import {stackCardHeight} from "../Dimensions";
import {OpenIcon} from "../Misc/IconSet";
import CriminalCaseCreate from "./CriminalCaseCreate";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {Alert} from "@material-ui/lab";
import Add from "@material-ui/icons/Add";

export default function CriminalCaseList() {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const status = useSelector(state => state.criminalCases.status);
    const criminalCases = useSelector(selectAllCriminalCases);

    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchCriminalCases(null));
        }
    }, [status, dispatch]);

    const [search, setSearch] = useState("");
    const [filtered, setFiltered] = useState(criminalCases);

    useEffect(()=>{
        setFiltered(criminalCases)
    }, [criminalCases])

    useEffect(()=>{
        if (search) {
            setFiltered(criminalCases.filter(cc=>(cc.summary.toLowerCase().includes(search) || cc.origin.toLowerCase().includes(search))))
        }
        else {
            setFiltered(criminalCases)
        }
    }, [search])

    // TODO: choose columns
    const columns = [
        {field: "references",   headerName: t("CriminalCaseReference"),    flex: 1, disableColumnMenu: false},
        {field: "opening_date", headerName: t("CriminalCaseDate"),         flex: 1, disableColumnMenu: false,
            renderCell: (params) => (
                moment(params.value).format('LLL')
            )},
        {field: "summary",      headerName: t("CriminalCaseSummary"),      flex: 2, disableColumnMenu: false},
        {field: "action_city",  headerName: t('CriminalCaseCity'),         flex: 2, disableColumnMenu: false},
        {field: "action_type",  headerName: t('CriminalCaseType'),         flex: 1, disableColumnMenu: false, renderCell: params => t('CriminalCaseType--'+params.value)},
        {field: "id",           headerName: " ",                           width: 80, disableColumnMenu: true, align: 'right',
            renderCell: (params) => (
                <NiceTooltip title={t('See')} placement={"top-center"}>
                    <IconButton variant="contained" color="secondary" size="small" to={'/cases/'+params.value} component={NavLink}>
                        <OpenIcon />
                    </IconButton>
                </NiceTooltip>
            )
        }
    ];
    
    const history = useHistory();
    const handleRowClick = (params) => {
        console.log(params.row)
        history.push('/cases/'+params.row.id);
    }
    
    // actions
    const [importMode, setImportMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    
    // stack display
    const theme = useTheme();
    const [stacked, setStacked] = useState(!isMobile);
    useEffect(()=>{setStacked(!isMobile)}, [isMobile])
    const { height: screenHeight, width: screenWidth } = useWindowDimensions();
    const [contentHeight, setContentHeight] = useState(100);
    useEffect(()=>{
        setContentHeight(screenHeight
            - stackCardHeight.header
            - stackCardHeight.search
            - stackCardHeight.navBar
            - stackCardHeight.actions
            - stackCardHeight.bread
            - 2 * theme.spacing(2)
        )
    }, [screenHeight, screenWidth, stacked]);

    return <Card>
        <CardHeader
            avatar={<Avatar>
                <BusinessCenter color={"primary"} />
            </Avatar>}
            title={<Typography variant={"h5"}>{t("CriminalCasesTitle")}</Typography>}
            className={classes.mainCardHeader}
        />
        <CardContent className={classes.mainCardSearch}>
            <SearchTextArea callBack={setSearch} />
        </CardContent>
        <CardContent className={classes.mainCardContent}>
            <div style={{
                height:stacked ? contentHeight : 'auto',
                minHeight:contentHeight,
                overflowY: stacked ? 'auto' : 'visible'
            }}>
                <DataGrid
                    columns={columns}
                    rows={filtered}
                    pageSize={100}
                    autoHeight={isMobile}
                    density={"compact"}
                    onRowDoubleClick={handleRowClick}
                    hideFooter={filtered.length<100}
                />
            </div>
        </CardContent>
        <CardActions className={classes.mainCardActions}>
            <Grid container justifyContent="flex-end">
                <Button startIcon={<CloudDownload />} variant="contained" size="medium" color="default" onClick={()=>setImportMode(true)}>
                    {t('ImportCriminalCases')}
                </Button>
                <div style={{width: 10}}></div>
                <Button startIcon={<Add />} variant="contained" size="medium" color="secondary" onClick={()=>setCreateMode(true)}>
                    {t('CreateNewCase')}
                </Button>
                {createMode && <CriminalCaseCreate  onClose={()=>setCreateMode(false)} />}
                {importMode && <CriminalCasesImport onClose={()=>setImportMode(false)} />}
            </Grid>
        </CardActions>
    </Card>
}

function getSteps() {
    return ['requestRemoteBase', 'ValidateData', 'ImportData'];
}

function CriminalCasesImport({onClose}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();
    
    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
    
    return  <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle>
            {t('ImportCriminalCases')}
        </DialogTitle>
        <DialogContent>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{t(label)}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {activeStep === 1 && <Alert severity={"error"}>{t('IncorrectConfigurationError')}</Alert> }
        </DialogContent>
        <DialogActions>
            <Button
                variant="contained"
                onClick={onClose}
                color="primary"
            >
                {t('Cancel')}
            </Button>
            <Button
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                color="secondary"
            >
                {t('Back')}
            </Button>
            <Button
                variant="contained"
                color="secondary"
                disabled={activeStep === 1}
                onClick={handleNext}
            >
                {activeStep === steps.length - 1 ? t('Import') : t('Next')}
            </Button>
        </DialogActions>
    </Dialog>
}

// Just if DataGrid dont fit
function CriminalCaseTable({criminalCases}) {
    return <table>
        <thead>
        <tr>
            <th>Num</th>
            <th>Date</th>
            <th>Summary</th>
            <th>City</th>
            <th>Type</th>
        </tr>
        </thead>
        <tbody>
        {criminalCases.map((criminalCase, index) => {
            return <CriminalCaseRow case={criminalCase} />
        })}
        </tbody>
    </table>;
}

function CriminalCaseRow({criminalCase}) {
    return <tr>
        <td>{criminalCase.num_ref}</td>
        <td>{criminalCase.action_place_type}</td>
        <td>{criminalCase.summary}</td>
        <td>{criminalCase.action_city}</td>
        <td>{criminalCase.action_type}</td>
    </tr>;
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    mainCardHeader: {height: stackCardHeight.header},
    mainCardSearch: {height: stackCardHeight.search},
    mainCardActions: {height: stackCardHeight.actions},
    mainCardContent: {
        paddingTop: 0,
        paddingBottom: 0
    },
    
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));