import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import defaultLightPalette, {previsionPalette} from "../../../services/defaultLightPalette";

import {useTranslation} from "react-i18next";
import {makeStyles, ThemeProvider, createTheme} from "@material-ui/core/styles";

import {Card, CardHeader, CardContent, Container, LinearProgress, Tabs, Tab, Grid, CardMedia} from '@material-ui/core';

import { fetchTreeCategories, selectRootCategories,} from "../../../store/entities/treeCategoriesSlice";
import {fetchTreeFields} from "../../../store/entities/treeFieldsSlice";
import {fetchFieldTypes} from "../../../store/entities/fieldTypesSlice";
import {fetchTreeThesauruses} from "../../../store/entities/treeThesaurusSlice";

import {getCurrentUser} from "../../../store/entities/currentUserSlice";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {Categories} from "./TreeEditorCategories";


export default function TreeEditor({}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();

    const statusCategories = useSelector(state => state.treeCategories.status);
    const statusThesauruses = useSelector(state => state.treeThesauruses.status);
    const statusFields = useSelector(state => state.treeFields.status);
    const statusTypes = useSelector(state => state.fieldTypes.status);

    const currentUser = useSelector(getCurrentUser);

    useEffect(() => {
        if (statusCategories === 'idle') dispatch(fetchTreeCategories(null));
        if (statusThesauruses === 'idle') dispatch(fetchTreeThesauruses(null));
        if (statusFields === 'idle') dispatch(fetchTreeFields(null));
        if (statusTypes === 'idle') dispatch(fetchFieldTypes(null));
    }, [statusCategories, statusThesauruses, statusFields, dispatch]);

    const [currentTab, setCurrentTab] = useState(0);

    if (statusCategories !== "succeeded") {
        return <LinearProgress/>;
    }

    return <Container disableGutters={true} maxWidth={"xl"}>
        <ThemeProvider theme={lightTheme(currentUser && currentUser.type === "prevision")}>
    
            <Switch>
                <Route path="/thesaurus/categories">
                    <Categories />
                </Route>
                <Redirect to='/thesaurus/categories' />
            </Switch>
            
                {/*
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card elevation={2}>
                            <CardHeader
                                title={"Thesaurus Explorer"}
                                action={<Tabs
                                    variant={"fullWidth"}
                                    value={currentTab}
                                    onChange={(event, newValue)=>setCurrentTab(newValue)}
                                >
                                    <Tab component={NavLink} to={"/thesaurus/categories"} label={t('ExploreFromCategories')} />
                                    <Tab component={NavLink} to={"/thesaurus/thesaurus"} label={t('ExplorerThesaurusTree')} />
                                </Tabs>}
                            />
                        </Card>
                    </Grid>
                </Grid>
                */}
            
        </ThemeProvider>
    </Container>;
}

// Theme
const lightTheme = (prevision = false) => createTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette),
    typography: {
        fontSize: 12
    }
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    tree: {
        fontSize: 1
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'row',
    },
    labelIcon: {
        marginRight: theme.spacing(1),
        color: "#999"
    },
    labelText: {
        flexGrow: 1
    },
    field: {
        //color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    fieldSelected: {
        backgroundColor: '#ccc',
    },
    categories: {
        height: '100%',
        overflow: 'auto'
    },
    paper: {
        height: 100,
    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));