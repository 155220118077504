import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";


import {Typography} from "@material-ui/core";
import tdb from "../../../services/TranslateDB";
import NiceTooltip from "../../Misc/CustomToolTip";
import ToggleButton from "@material-ui/lab/ToggleButton";

import {BookmarkBorder, Check, ChevronRight, Close, ExpandMore, Search, UnfoldLess, UnfoldMore} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";

import {ToggleButtonGroup, TreeItem, TreeView} from "@material-ui/lab";

import {selectSubThesaurus, selectTreeThesaurusById} from "../../../store/entities/treeThesaurusSlice";
import { removeFieldsOpened, selectFieldsOpened, selectFieldThesaurusExpanded, selectInputThesaurus, setFieldThesaurusExpanded, setInputThesaurusValue} from "./SelectorSlice";
import {selectTreeFieldById} from "../../../store/entities/treeFieldsSlice";

import ThesaurusDnD from "./SelectorFieldsThesaurusDnD";

// this
export default function SelectorFieldThesaurusPanels({fieldId}) {
    const fieldsOpened = useSelector(selectFieldsOpened);
    if (!fieldsOpened)return null;
    return fieldsOpened.map(fieldId=>(
        <SelectorFieldThesaurusPanel fieldId={fieldId} />
    ))
}

// panel of category fields
function SelectorFieldThesaurusPanel({fieldId}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showSearch, setShowSearch] = useState();
    const field = useSelector(state => selectTreeFieldById(state, fieldId));

    const expanded = useSelector(selectFieldThesaurusExpanded);
    const setExpanded =(values) => {
        dispatch(setFieldThesaurusExpanded({field:fieldId, values: values}))
    }

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds)
    }

    const handleSelect = (event, nodeId) => {

    }

    const handleClose = () => {
        dispatch(removeFieldsOpened(fieldId))
    }

    const handleExpandAll = () => {}
    const handleShrinkAll = () => {}

    //if (!thesaurus)ruturn null;
    return !field ? null : <div className={classes.panel}>
        <div className={classes.panelBar}>
            <Typography>{tdb(field.title)} {field.name}</Typography>
            <ToggleButtonGroup>
                <NiceTooltip title={t("ReduceTree")} placement={"top-end"} arrow>
                    <ToggleButton value="Shrink" selected={false} style={{width: 30, height:30}} size="small" onClick={handleShrinkAll}>
                        <UnfoldLess fontSize={"small"} />
                    </ToggleButton>
                </NiceTooltip>
                <NiceTooltip title={t("ExpandTree")} placement={"top-end"} arrow>
                    <ToggleButton value="Expand" selected={false} style={{width: 30, height:30}} size="small" onClick={handleExpandAll}>
                        <UnfoldMore fontSize={"small"} />
                    </ToggleButton>
                </NiceTooltip>
                <NiceTooltip title={t("Search")} placement={"top-end"} arrow>
                    <ToggleButton value="search" selected={showSearch} style={{width: 30, height:30}} size="small" onClick={()=>setShowSearch(s=>!s)}>
                        <Search fontSize={"small"} />
                    </ToggleButton>
                </NiceTooltip>
                <NiceTooltip title={t("close")} placement={"top-end"} arrow>
                    <ToggleButton value="close" selected={false} style={{width: 30, height:30}} size="small" onClick={handleClose}>
                        <Close fontSize={"small"} />
                    </ToggleButton>
                </NiceTooltip>
            </ToggleButtonGroup>
        </div>
        <TreeView
            className={classes.tree}
            expanded={expanded ? expanded[fieldId] : []}
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
        >
            <SelectorThesaurus fieldId={fieldId} thesaurusId={field.tree_thesaurus_id} index={0} parents={[]} />
        </TreeView>
    </div>
}

function SelectorThesaurus({fieldId, thesaurusId, index, parents}) {
    const thesaurus = useSelector(state => selectTreeThesaurusById(state, thesaurusId));
    const subThesauruses = useSelector(state => selectSubThesaurus(state, thesaurusId));
    const inputThesaurus = useSelector(selectInputThesaurus);
    const dispatch = useDispatch();
    const handleSetValue = () => {
        dispatch(setInputThesaurusValue(thesaurus))
    }
    const classes = useStyles();
    // dont display line if root
    if (!thesaurus.parent_id) {
        return subThesauruses.map((subThesaurus, index) => {
            return <SelectorThesaurus thesaurusId={subThesaurus.id} index={index} parents={[...parents, thesaurusId]} fieldId={fieldId} />
        })
    }
    return <TreeItem
        nodeId={'thes'+thesaurus.id}
        label={
            <div className={classes.labelRoot}>
                <BookmarkBorder className={classes.labelIcon} />
                <ThesaurusDnD name={thesaurus.id} title={tdb(thesaurus.title)} thesaurus={thesaurus} fieldId={fieldId}>
                    <Typography className={classes.labelText}>
                        {tdb(thesaurus.title, thesaurus.name)}
                    </Typography>
                </ThesaurusDnD>
                {
                    !!inputThesaurus && parents.includes(inputThesaurus) && <Check onClick={handleSetValue} className={classes.labelIcon} />
                }
            </div>

        }
    >
        {subThesauruses.map((subThesaurus, index) => {
            return <SelectorThesaurus thesaurusId={subThesaurus.id} index={index} parents={[...parents, thesaurusId]} fieldId={fieldId} />
        })}
    </TreeItem>
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    panelContainer: {
        display: 'flex',
        flexDirection: "column-reverse"
    },
    panel: {
        borderBottom: '1px solid #ccc',
        paddingTop:3,
        paddingBottom:3,
        fontSize: 12
    },
    panelBar: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom:1,
    },
    panelInput: {
        //border:,
        border: '1px solid #666',
        borderRadius: 4,
        padding: 0,
        '& input': {
            fontSize: 12,
        }
    },
    search: {
        backgroundColor: 'black'
    },
    tree: {
        fontSize: 1
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'row',
    },
    labelIcon: {
        //marginRight: theme.spacing(1),
        height: "1rem",
        width: "1rem",
        color: "#999"
    },
    labelText: {
        flexGrow: 1,
        fontSize: 12
    },
    field: {
        //color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    fieldSelected: {
        backgroundColor: '#ccc',
    },
    categories: {
        height: '100%',
        overflow: 'auto'
    },
    paper: {
        height: 100,
    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));