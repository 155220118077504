import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../../services/defaultLightPalette";

import {
    Avatar, Box,
    Card,
    CardContent,
    CardHeader, Dialog, DialogContent, DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@material-ui/core";
import {ToggleButton, ToggleButtonGroup, TreeItem, TreeView} from "@material-ui/lab";
import {Add, ChevronRight, Code, Edit, ExpandMore, ImportExport, LabelImportant} from "@material-ui/icons";

import {selectTreeCategoryById} from "../../../store/entities/treeCategoriesSlice";
import {selectSubThesaurus, selectTreeThesaurusById} from "../../../store/entities/treeThesaurusSlice";
import {selectTreeFieldById} from "../../../store/entities/treeFieldsSlice";
import {selectFieldTypeById} from "../../../store/entities/fieldTypesSlice";
import {selectFieldSelected, selectThesaurusSelected, setFieldEdited, setThesaurusSelected} from "./TreeEditorSlice";

import tdb from "../../../services/TranslateDB";
import NiceTooltip from "../../Misc/CustomToolTip";
import {useTranslation} from "react-i18next";
import {ThesaurusRootTree} from "./TreeEditorCatThesaurus";

export default function Field({fieldId}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const field = useSelector(state=>selectTreeFieldById(state, fieldId))
    const type = useSelector(state => selectFieldTypeById(state, field.tree_field_type_id));
    const selectedField = useSelector(selectFieldSelected)
    
    const handleEdit = () => {
        dispatch(setFieldEdited(fieldId))
    }

    return <Grid item xs={12}>
        <Card>
            <CardHeader
                avatar={<Avatar><Code  color={"primary"} /></Avatar>}
                title={<Typography variant={"h5"}>{tdb(field.title)}</Typography>}
                subheader={type.name}
                action={
                    <ToggleButtonGroup>
                        <NiceTooltip title={t("TreeEditCategory")} placement={"bottom-end"}>
                            <ToggleButton size={"small"} onClick={handleEdit}><Edit /></ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>
                }
            />
            <CardContent>
                <List className={classes.list}>
                    <FieldParameter label={"name"} value={field.name} />
                    <FieldParameter label={"ord"} value={field.ord} />
                    <FieldParameter label={"group"} value={field.group} />
                    <FieldParameter label={"multiple"} value={field.multiple} />
                </List>
            </CardContent>
            {!!field.tree_thesaurus_id && <FieldThesaurus fieldId={fieldId} />}
            {type.is_thesaurus === true && <CardHeader
                title={t('TreeThesaurusAttributes')}
                action={<ToggleButtonGroup>
                    <NiceTooltip title={""} placement={"top-left"}>
                        <ToggleButton onClick={() => {
                        }} size={"small"}><Add/></ToggleButton>
                    </NiceTooltip>
                </ToggleButtonGroup>}
            />}
        </Card>
    </Grid>
}

function FieldThesaurus({fieldId}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const field = useSelector(state=>selectTreeFieldById(state, fieldId))
    const type = useSelector(state => selectFieldTypeById(state, field.tree_field_type_id));
    const selectedField = useSelector(selectFieldSelected)
    
    const thesaurus = useSelector(state => selectTreeThesaurusById(state, field.tree_thesaurus_id));
    
    useEffect(()=>{
        dispatch(setThesaurusSelected(field.tree_thesaurus_id))
    }, [])
    
    const handleSelectThesaurus = (event, nodesIds) => {
        dispatch(setThesaurusSelected(nodesIds))
    }
    
    return <CardContent>
        <ThesaurusRootTree thesaurusId={field.tree_thesaurus_id} onSelect={handleSelectThesaurus} />
    </CardContent>
}

function FieldParameter({label, value}) {
    const classes = useStyles();
    const {t} = useTranslation();
    return value ? <ListItem className={classes.listItem}>
        <ListItemText className={classes.listItemText}>
            <Box flexDirection={"row"} display={"flex"}>
                <Box flexGrow={1}>{label}</Box>
                <Box>{value === true ? t("yes") : value}</Box>
            </Box>
        </ListItemText>
    </ListItem> : null
}

const useStyles = makeStyles((theme) => ({
    autoScroll: {
        maxHeight: 600,
        overflow: 'auto'
    },
    list: {
        padding: 0,
    },
    listItem: {
        padding: 0,
    },
    listItemSelected: {
        backgroundColor: '#909090'
    },
    listItemIcon: {
        minWidth: 30,
    },
    listItemText: {
        // display: "flex",
        // flexDirection: "row",
        marginTop:0,
        marginBottom:0,
    },
    listItemSec: {
        right: 0
    },
    root: {
        flexGrow: 1
    },
    tree: {
        fontSize: 1
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'row',
    },
    labelIcon: {
        marginRight: theme.spacing(1),
        color: "#999"
    },
    labelText: {
        flexGrow: 1
    },
    field: {
        //color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    fieldSelected: {
        backgroundColor: '#ccc',
    },
    categories: {
        height: '100%',
        overflow: 'auto'
    },
    paper: {
        height: 100,
    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));