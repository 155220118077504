import React, {useEffect, useState} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {SimpleGrid} from "../Misc/Table";
import {
    selectAllSurveys,
    fetchSurveys,
    fetchDeleteSurvey,
    selectSurveysByCriminalCase,
    selectSurveysByInvestigation
} from "../../store/entities/surveysSlice";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {NavLink} from "react-router-dom";
import {DataGrid} from "@material-ui/data-grid";
import Chip from "@material-ui/core/Chip";

import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import WarningIcon from '@material-ui/icons/Warning';
import NiceTooltip from "../Misc/CustomToolTip";
import {
    Avatar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    useTheme
} from "@material-ui/core";
import {Assignment, Delete, RemoveRedEye} from "@material-ui/icons";
import {Alert} from "@material-ui/lab";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {isMobile} from "react-device-detect";
import {stackCardHeight} from "../Dimensions";
import Typography from "@material-ui/core/Typography";

const Surveys = ({investigationId, criminalCaseId, simpleTable, height, filter, title, inheritStacked}) => {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.surveys.status);

    const surveysCC = useSelector(state => selectSurveysByCriminalCase(state, criminalCaseId ? criminalCaseId : 0));
    const surveysIV = useSelector(state => selectSurveysByInvestigation(state, investigationId ? investigationId : 0));
    const surveysAll = useSelector(selectAllSurveys);

    const surveys = criminalCaseId ? surveysCC : (investigationId ? surveysIV : surveysAll);

    const filtered = filter
        ? surveys.filter((survey) => survey.notif >= 0)
        : surveys;

    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchSurveys(null));
            setInterval(() => {
              dispatch(fetchSurveys(null));
            }, 30000)
        }
    }, [status, dispatch]);

    const [surveyToDelete, setSurveyToDelete] = useState(null);
    const deleteSurvey = () => {
        if (surveyToDelete) {
            //dispatch(fetchDeleteSurvey(id));
            setSurveyToDelete(false);
        }
    }
    
    // stack display
    const theme = useTheme();
    const [stacked, setStacked] = useState(!isMobile);
    useEffect(()=>{setStacked(!isMobile)}, [isMobile])
    const { height: screenHeight, width: screenWidth } = useWindowDimensions();
    const [contentHeight, setContentHeight] = useState(100);
    useEffect(()=>{
        setContentHeight(screenHeight
            - stackCardHeight.header
            // - stackCardHeight.search
            - stackCardHeight.navBar
            - stackCardHeight.actions
            - stackCardHeight.bread
            - 2 * theme.spacing(2)
        )
    }, [screenHeight, screenWidth, stacked])

    const getViewCell = params => (<>
        <NiceTooltip title={t("See")} placement={"top-center"}>
            <IconButton variant="contained" color="default" size="small" to={'/surveys/'+params.row.id} component={NavLink}>
                <RemoveRedEye />
            </IconButton>
        </NiceTooltip>
        <NiceTooltip title={t('Delete')} placement={"top-center"}>
            <IconButton variant="contained" color="default" size="small" onClick={() => setSurveyToDelete(params.row.id)}>
                <Delete />
            </IconButton>
        </NiceTooltip>
    </>);

    const getNotifCell = params => (<>
        {params.row.notif > 0 ?
            <Chip size="small" icon={<NotificationsActiveIcon />} label={params.row.notif} color={"primary"} /> : null}
        {params.row.alert > 0 ?
            <Chip size="small" icon={<WarningIcon />} label={params.row.alert} color={"secondary"} /> : null}
    </>);
    
    const getImageCell = params => (<>
        { 'image' in params.row.search && <img src={params.row.search.image.url} style={{ height: '40px'}} />}
    </>);
    
    const getTargetCell = params => (<>
        { params.row.target ?
            params.row.target.map((value) => {return <span key={value.title} style={{ marginRight: '10px'}}>{value['title']} </span>})  : null}
    </>)


    const getTermsCell = params => (<>
        { params.row && 'terms' in params.row.search ?
            params.row.search.terms.map((value) => (value ? <Chip key={value} style={{ marginRight: '4px', height: 28}} label={value} /> : null) )
            : null}
    </>);
    
    const getNameCell = params => (<Link to={'/surveys/'+params.row.id} component={NavLink}>
        {params.value}
    </Link>)



    const columns = [
        {field:"name",          headerName:t("Name"),        attr: {xs: 1}, flex:1,     renderCell: getNameCell},
        {field:"image",         headerName:t("Image"),       attr: {xs: 1}, width: 120, renderCell: getImageCell, disableColumnMenu: true},
        {field:"terms",         headerName:t("Keywords"),    attr: {xs: 1}, flex:2,     renderCell: getTermsCell},
        {field:"target",        headerName:t("Targets"),     attr: {xs: 2}, flex:1,     renderCell: getTargetCell},
        {field:"alerte",        headerName:t("Alerts"),      attr: {xs: 1}, width:100,  renderCell: getNotifCell, disableColumnMenu: true},
        {field:"id",            headerName:t(" "),     attr: {xs: 1}, width:100,        renderCell: getViewCell, disableColumnMenu: true, align: 'right'}
    ];
    return <Card>
        <CardHeader
            avatar={<Avatar ><Assignment color={"primary"} /></Avatar>}
            title={<Typography variant={"h5"}>{title ? title : t('Surveys')}</Typography>}
            className={classes.mainCardHeader}
        />
        <CardContent className={classes.mainCardContent}>
            {/**
             <div style={{height:isMobile ? '' : contentHeight, overflowY: 'auto'}}>
             <DataGrid columns={columns} rows={filtered} pageSize={100} autoHeight={isMobile} density={"compact"} hideFooter={filtered.length<100} />
             </div>
             */}
            {
                simpleTable
                ?   <SimpleGrid columns={columns} rows={surveysAll} pageSize={8} spacing={0} />
                :   <div style={{
                        height:stacked ? contentHeight : 'auto',
                        overflowY: stacked ? 'auto' : 'visible'
                    }}>
                        <DataGrid columns={columns} rows={surveysAll} pageSize={100} autoHeight={isMobile || !stacked} density={"compact"} hideFooter={surveysAll.length<100} />
                    </div>
            }
        </CardContent>
        <CardActions className={classes.mainCardActions}>
            <Grid container justifyContent="flex-end">
                <Button title={t('CreateNewSurvey')} variant="contained" color="secondary" to={"/surveys/new"} component={NavLink}>
                    {t('CreateNewSurvey')}
                </Button>
            </Grid>
            {!!surveyToDelete && <Dialog onClose={()=>setSurveyToDelete(null)} open={true} maxWidth={"sm"} fullWidth>
                <DialogContent>
                    <Alert severity={"warning"} variant={"outlined"}>
                        {t('AreYouSure')}
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} color={"primary"}   onClick={()=>setSurveyToDelete(false)}>{t('Cancel')}</Button>
                    <Button variant={"contained"} color={"secondary"} onClick={()=>deleteSurvey()}>{t('Delete')}</Button>
                </DialogActions>
            </Dialog>}
        </CardActions>
    </Card>
};

export default Surveys;

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    mainCardHeader: {height: stackCardHeight.header},
    mainCardSearch: {height: stackCardHeight.search},
    mainCardActions: {height: stackCardHeight.actions},
    mainCardContent: {
        paddingTop: 0,
        paddingBottom: 0
    },
}));
