import React, {useEffect, useState} from 'react';
import clsx from 'clsx';

import {useDispatch, useSelector} from "react-redux";
import {useTranslation}     from 'react-i18next';
import { makeStyles, alpha, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Switch, Route, Link, NavLink, Redirect } from "react-router-dom";

import {Box, Hidden, Toolbar, List, Typography, ListItem, ListItemIcon, ListItemText, Divider, Avatar, InputBase, Badge, Menu, MenuItem, Fade, IconButton }                  from "@material-ui/core";

import MailIcon             from '@material-ui/icons/Mail';
import NotificationsIcon    from '@material-ui/icons/Notifications';
import SearchIcon           from '@material-ui/icons/Search';
import HomeIcon             from '@material-ui/icons/Home';
import VisibilityIcon       from '@material-ui/icons/Visibility';
import BusinessCenterIcon   from '@material-ui/icons/BusinessCenter';
import CollectionsIcon      from '@material-ui/icons/Collections';
import TrackChangesIcon     from '@material-ui/icons/TrackChanges';
import MapIcon              from '@material-ui/icons/Map';
import BookmarksIcon        from '@material-ui/icons/Bookmarks';
import PolicyIcon           from '@material-ui/icons/Policy';
import GroupIcon            from '@material-ui/icons/Group';
import StorageIcon          from '@material-ui/icons/Storage';
import TodayIcon            from '@material-ui/icons/Today';
import EqualizerIcon        from '@material-ui/icons/Equalizer';

import LanguageIcon         from '@material-ui/icons/Language';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import SecurityIcon         from '@material-ui/icons/Security';
import GroupWorkIcon        from '@material-ui/icons/GroupWork';
import MuseumIcon           from '@material-ui/icons/Museum';
import FingerprintIcon      from '@material-ui/icons/Fingerprint';
import AccountBalanceIcon   from '@material-ui/icons/AccountBalance';
import AssignmentLateIcon   from '@material-ui/icons/AssignmentLate';
import AccountBoxIcon       from '@material-ui/icons/AccountBox';

import CriminalCasesRouter  from "./CriminalCase/CriminalCasesRouter.jsx";
import InvestigationsRouter from './Investigation/InvestigationsRouter.jsx'
import CollectionsRouter    from "./Collection/CollectionsRouter";
import Identification       from "./Identification/Identification.jsx";
import Identifications       from "./Identification/Identifications.jsx";
import QueriesRouter        from "./Query/QueriesRouter.jsx";
import TreeViewer           from "./Selectors/TreeViewer/TreeViewer";
import TreeEditor           from "./Selectors/TreeEditor/TreeEditor";
import Home                 from './Home/Home.jsx';import ContentPage          from "./ContentPage";
import Datasources          from "./Datasource/Datasources.jsx";
import SurveysRouter        from "./Survey/SurveysRouter";
import UsersRouter          from "./User/UsersRouter";


import {getCurrentUser, logoutUser, isGranted, isAllowed} from "../store/entities/currentUserSlice";

import {isMobile}           from "react-device-detect";

import Selector from "./Selectors/Drawer/Selector";

import {Apartment} from "@material-ui/icons";
import OrganizationsRouter from "./Organization/OrganizationsRouter";
import {fetchCollections, selectAllCollections} from "../store/entities/collectionsSlice";

import {
    fetchSurveys,
    selectSurveysWithAlerts
} from "../store/entities/surveysSlice";
import NiceTooltip from "./Misc/CustomToolTip";
import HomeInventory from "./Home/HomeInventory";

// TOOLBAR
export function NavigationToolbar({menuOpener}) {
    const currentUser = useSelector(getCurrentUser);
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const handleChooseLanguage = (language) => {
        i18n.changeLanguage(language);
    }
    const handleUserMenu = (command) => {
        if (command === "logout") {
            window.location = process.env.REACT_APP_LOGOUT
        }
    }


    const alerts = useSelector(selectSurveysWithAlerts);

    const getTitle = () => {
        if (currentUser && currentUser.ui_title) {
            return currentUser.ui_title
        }
        else if (isGranted(currentUser, false)) {
            return "Parcs ADMINISTRATION"
        }
        else if (isGranted(currentUser, 'prevision')) {
            return "Parcs-PREVISION"
        }
        else if (isGranted(currentUser, 'lea')) {
            return "Parcs-LEA"
        }
        else if (isGranted(currentUser, 'weapons-user')) {
            return "ARMETEFACT"
        }
        return "ARTE-FACT"
    }

    return <Toolbar>
        {menuOpener}

        <Typography variant="h6" noWrap>
            {getTitle()}
        </Typography>
        {/*SMALL VIEW*/}
        <Hidden mdUp={true}>
            <Box className={classes.grow} />
            <Box>
                <ButtonMenu
                    badge={0}
                    items={[
                        {text:'fr'},
                        {text:'en'},
                        {text:'it'},
                    ]}
                    onclick={handleChooseLanguage}
                    text={i18n.language}
                />
            </Box>
            <ButtonMenu
                badge={0}
                icon={
                    <Avatar className={classes.smallAvatar} alt={currentUser.username} title={currentUser.username}>
                        {currentUser.firstName ? currentUser.firstName.charAt(0).toUpperCase() : "!"}
                        {currentUser.lastName ? currentUser.lastName.charAt(0).toUpperCase() : "?"}
                    </Avatar>
                }
                items={[
                    {text: `${currentUser.firstName} ${currentUser.lastName}`},
                    {text: t('logout'), short:'logout'}
                ]}
                onclick={handleUserMenu}
            />
        </Hidden>
        {/*BIG VIEW*/}
        <Hidden smDown={true}>
            <Box className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Box>
            <Box className={classes.grow} />
            <Box>
                <ButtonMenu
                    badge={0}
                    icon={<LanguageIcon />}
                    items={[
                        {text:'fr'},
                        {text:'en'},
                        {text:'it'},
                    ]}
                    onclick={handleChooseLanguage}
                    text={i18n.language}
                />
            </Box>
            {
                isGranted(currentUser, 'lea') && <>
                    <ButtonMenu
                        badge={0}
                        icon={<TodayIcon />}
                        items={[
                            {text: t('Ouvrir le calendrier Personnel')},
                            {text: t('Ouvrir le calendrier du Service')}
                        ]}
                    />
                    <ButtonMenu
                        badge={0}
                        icon={<MailIcon />}
                        items={[
                            {text: t('Pas de nouveaux messages')},
                        ]}
                    />
                </>
            }
            <ButtonMenu
                badge={alerts.length}
                icon={<NotificationsIcon />}
            />
            <ButtonMenu
                badge={0}
                icon={
                    <Avatar className={classes.avatar} alt={currentUser.username} title={currentUser.username}>
                        {currentUser.firstName ? currentUser.firstName.charAt(0).toUpperCase() : "!"}
                        {currentUser.lastName ? currentUser.lastName.charAt(0).toUpperCase() : "?"}
                    </Avatar>
                }
                items={[
                    {text: `${currentUser.firstName} ${currentUser.lastName}`, short:''},
                    {text: currentUser.type, short:''},
                    {text: t('logout'), short:'logout'}
                ]}
                onclick={handleUserMenu}
            />
        </Hidden>

    </Toolbar>;
}

function ButtonMenu({icon, text, items, badge, onclick, small}) {
    const [anchorEl, setAnchorEl] = React.useState(false);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = (item) => setAnchorEl(null);
    const handleChoose = (item) => {
        if (onclick) {
            onclick(item);
        }
        setAnchorEl(null);
    }
    return <div>
        {
            text ?
            <div onClick={handleClick}>
                <IconButton aria-label="" color="inherit">
                    <Badge badgeContent={badge} color="secondary">
                        {icon}
                    </Badge>
                </IconButton>
                {text}
            </div>
            :
            <IconButton aria-label="" color="inherit" onClick={handleClick}>
                <Badge badgeContent={badge} color="secondary">
                    {icon}
                </Badge>
            </IconButton>
        }

        {items
            ? <Menu
                open={anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                TransitionComponent={Fade}
            >
                {items.map((item, i)=>{
                    return <MenuItem key={i} onClick={() => handleChoose(item.short ? item.short : item.text)}>
                        {item.text}
                    </MenuItem>
                })}
            </Menu> : null
        }
    </div>;
}

// NAVIGATION
export function NavigationItems ({onClick}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const currentUser = useSelector(getCurrentUser);
    const userType = currentUser.type;
    
    // TODO: replace with nice grant handlers
    const lea = isGranted(currentUser, 'lea');
    const prevision = isGranted(currentUser, 'prevision');
    const admin = isGranted(currentUser);
    const survey_alerts = useSelector(selectSurveysWithAlerts);

    return <List>
        <LinkItem link="/home"              access={true}       allow={isAllowed(userType, "home")}             label="HomePageLink"        icon={<HomeIcon />} onClick={onClick}/>
        <LinkItem link="/cases"             access={lea}        allow={isAllowed(userType, "cases")}            label="CriminalCasesLink"   icon={<BusinessCenterIcon />}  onClick={onClick}/>
        <LinkItem link="/investigations"    access={lea}        allow={isAllowed(userType, "investigations")}   label="InvestigationsLink"  icon={<PolicyIcon />}  onClick={onClick}/>
        <Divider />
        <LinkItem link="/identifications"   access={true}       allow={isAllowed(userType, "identifications")}  label="IdentificationLink"  icon={<VisibilityIcon />}  onClick={onClick}/>
        <LinkItem link="/queries"           access={true}       allow={isAllowed(userType, "queries")}          label="QueriesLink"         icon={<SearchIcon />}  onClick={onClick}/>
        <LinkItem link="/surveys"           access={true}       allow={isAllowed(userType, "surveys")}          label="SurveyLink"          icon={<TrackChangesIcon />} alerts={survey_alerts.length}  onClick={onClick}/>
        <LinkItem link="/collections"       access={lea}        allow={isAllowed(userType, "collections")}      label="CollectionsLink"     icon={<CollectionsIcon />}  onClick={onClick}/>
        {prevision && !admin && <PersonnalCollectionLink />}
        <Divider />
        <ExtLinkItem link="http://data.parcs-pro.com" access={true} label="Statistics"      icon={<EqualizerIcon />}  onClick={onClick}/>
        <LinkItem link="/datasources"       access={true}       allow={isAllowed(userType, "datasources")}      label="Datasources"         icon={<StorageIcon />}  onClick={onClick}/>
        <LinkItem link="/places"            access={lea}        allow={isAllowed(userType, "places")}           label="LocationLink"        icon={<MapIcon />}  onClick={onClick}/>
        <LinkItem link="/thesaurus"         access={true}       allow={isAllowed(userType, "thesaurus")}        label="ThesaurusLink"       icon={<BookmarksIcon />}  onClick={onClick}/>
        <LinkItem link="/users"             access={admin}      allow={isAllowed(userType, "users")}            label="UsersLink"           icon={<GroupIcon />}  onClick={onClick}/>
        <LinkItem link="/organizations"     access={admin}      allow={isAllowed(userType, "organizations")}    label="OrganizationsLink"   icon={<Apartment />}  onClick={onClick}/>
    </List>;
}

export function PersonnalCollectionLink() {
    const dispatch = useDispatch();
    const status = useSelector(state => state.collections.status);
    const collections = useSelector(selectAllCollections);
    useEffect(()=>{
        if (status === 'idle')dispatch(fetchCollections(null));
    }, [status])

    const [content, setContent] = useState(null);
    useEffect(()=>{
        if (collections.length > 1 ) {
            setContent(<LinkItem link="/collections"     access={true}      label="Objects"   icon={<CollectionsIcon />} />)
        }
        else if (collections.length === 1) {
            setContent(<LinkItem link={`/collections/${collections[0].id}`}     access={true}      label="Objects"   icon={<CollectionsIcon />} />)
        }
    }, [collections])

    return content;
}

// MAIN
export function NavigationMain() {
    
    const currentUser = useSelector(getCurrentUser);
    const userType = currentUser.type;
    
    
    // TODO: replace with nice grant handlers
    const lea = isGranted(currentUser, 'lea');
    const prevision = isGranted(currentUser, 'prevision');
    const admin = isGranted(currentUser);

    const {t} = useTranslation();
    return <Switch>
        <Route path="/cases">
            <CriminalCasesRouter />
        </Route>
        <Route path="/investigations">
            <InvestigationsRouter />
        </Route>
        <Route path="/identification">
            <Identification />
        </Route>
        <Route path="/identifications">
            <Identifications />
        </Route>
        <Route path="/queries">
            <QueriesRouter />
        </Route>
        <Route path="/surveys">
            <SurveysRouter title={t("Surveys")} />
        </Route>
        <Route path="/collections">
            <CollectionsRouter />
        </Route>
        <Route path="/places">
            <ContentPage title={t("Places")} content="" />
        </Route>
        <Route path="/thesaurus">
            {/*<TreeViewer />*/}
            <TreeEditor />
        </Route>
        <Route path="/datasources">
            <Datasources />
        </Route>
        <Route path="/organizations">
            <OrganizationsRouter />
        </Route>
        <Route path="/users">
            <UsersRouter />
        </Route>
        <Route path="/home" exact>
            {userType === "inventory" ? <HomeInventory /> : <Home />}
        </Route>
        <Redirect to='/home' />
    </Switch>;
}

function LinkItem({icon, link, label, allow, access, alerts, onClick, open}) {
    const classes = useStyles();
    const {t} = useTranslation();
    if (!allow)return null;
    return <ListItem component={NavLink} to={link} className={classes.link} activeClassName={classes.activeLink} onClick={onClick}>
        <ListItemIcon className={classes.linkIcon}>
          <Badge badgeContent={alerts} color={"secondary"}>
              {icon}
          </Badge>
        </ListItemIcon>
        <ListItemText>{t(label)}</ListItemText>
    </ListItem>
}

function ExtLinkItem({icon, link, label, access}) {
    const classes = useStyles();
    const {t} = useTranslation();
    if (!access)return null;
    return <a href={link} target="_blank">
        <LinkItem className={classes.link} activeClassName={classes.activeLink} >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>{t(label)}</ListItemText>
    </LinkItem></a>;
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
        //background: 'linear-gradient(0deg, #4E4E4E 0%, #B4B4B4 100%)',
        //backgroundAttachment: 'fixed'
    },
    grow: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.primary.contrastText,
        textDecoration: 'none'
    },
    linkIcon: {
        color: theme.palette.primary.contrastText
    },
    activeLink: {
        backgroundColor: theme.palette.secondary.main,
        '& .MuiBadge-badge': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    menuButton: {
        marginRight: 36,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    avatar: {
        // marginLeft: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(5),
        height: theme.spacing(5),
        color: 'black'
    },
    smallAvatar: {
        backgroundColor: theme.palette.secondary.main,
        color: 'black'
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));
