import React from 'react';
import {Card, CardHeader, CardContent} from "@material-ui/core";

const Query = ({title, content}) => {
    return <Card>
        <CardHeader title={title} />
        <CardContent>
            {content}
        </CardContent>
    </Card>
};

export default Query;