import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {FormControl, FormLabel, Typography} from "@material-ui/core";

export default function FieldSet({title, children}) {
    const classes = useStyles();
    return <FormControl fullWidth component={"fieldset"} className={classes.fieldset}>
        <FormLabel component={"legend"} className={classes.legend}>
            {title}
        </FormLabel>
        {children}
    </FormControl>
}

const useStyles = makeStyles((theme) => ({
    fieldset: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.grey[500],
        //boxShadow: theme.shadows[3],
        // padding: theme.spacing(1),

        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    legend: {
        fontSize: theme.typography.h5.fontSize,
        color: 'black',
        backgroundColor: "white",
        paddingLeft: 5,
        paddingRight: 5,
    }
}))