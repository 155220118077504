import React, {useEffect, useRef} from "react";
import {useDrag} from "react-dnd";
import {getEmptyImage} from "react-dnd-html5-backend";

export default function ThesaurusDnD({name, children, title, thesaurus, fieldId}) {
    const ref = useRef(null);
    const [{ isDragging }, connectDrag, preview] = useDrag({
        item: {
            name,
            title,
            type: "thesaurus",
            thesaurus,
            fieldId
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    connectDrag(ref);

    useEffect(()=>{
        preview(getEmptyImage(), {captureDraggingState: true})
    }, [])

    return <>
        <div ref={ref} style={isDragging ? {backgroundColor: 'red', color: 'black'} : null}>
            {children}
        </div>
    </>
}