import React, {useEffect, useState} from "react";

import { useSelector, useDispatch } from "react-redux";

import {makeStyles, createTheme, ThemeProvider, withStyles} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";

import { NavLink, Link, useHistory} from "react-router-dom";

import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import SpeedDial from "@material-ui/lab/SpeedDial";

import CollectionsIcon from "@material-ui/icons/Collections";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import Surveys from "../Survey/Surveys";
import {useTranslation} from "react-i18next";
import {fetchInvestigations, selectAllInvestigations} from "../../store/entities/investigationsSlice";
import {fetchCriminalCases, selectAllCriminalCases} from "../../store/entities/criminalCasesSlice";
import {fetchTasks, selectAllTasks} from "../../store/entities/tasksSlice";

import ExampleGraph from "./ExampleGraph";
import {getCurrentUser, isGranted} from "../../store/entities/currentUserSlice";
import {CircularProgress, LinearProgress, List, ListItem} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import {TodayOutlined} from "@material-ui/icons";
import PolicyIcon from "@material-ui/icons/Policy";
import IconButton from "@material-ui/core/IconButton";
import NiceTooltip from "../Misc/CustomToolTip";
import {OpenIcon} from "../Misc/IconSet";
import {HomeInventoryImages, HomeInventoryRecent} from "./HomeInventory";
import {fetchCollections} from "../../store/entities/collectionsSlice";
import {fetchCollectionTypes} from "../../store/entities/collectionTypesSlice";
import {fetchFieldTypes} from "../../store/entities/fieldTypesSlice";
import Add from "@material-ui/icons/Add";
import CollectionCreate from "../Collection/CollectionCreate";

const Home = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentUser = useSelector(getCurrentUser);

    // TODO: replace with nice grant handlers
    const lea = isGranted(currentUser, 'lea');
    const prevision = isGranted(currentUser, 'prevision');
    const inventory = isGranted(currentUser, 'inventory');
    const admin = isGranted(currentUser);

    const {t} = useTranslation();
    
    const status = useSelector(state => state.collections.status);
    const statusType = useSelector(state => state.collectionTypes.status);
    const statusFieldType = useSelector(state => state.fieldTypes.status);
    
    useEffect(()=>{
        if (inventory) {
            console.log("status", status);
            if (status === 'idle') {
                dispatch(fetchCollections());
            }
            console.log("statusType", statusType);
            if (statusType === 'idle') {
                dispatch(fetchCollectionTypes());
            }
            console.log("statusFieldType", statusFieldType)
            if (statusFieldType === 'idle') {
                dispatch(fetchFieldTypes());
            }
        }
    }, [status, statusType, dispatch, inventory]);
    
    const [createCollection, setCreateCollection] = useState(false);

    return <Container disableGutters={true} maxWidth={"xl"}>
        <Typography variant="h1">
            {t("Dashboard")}
        </Typography>
        <ThemeProvider theme={lightTheme(currentUser && currentUser.type === "prevision")}>
            <Grid spacing={2} container>
                {inventory && <>
                    <Block sm={6} xs={12} md={6}
                           title={t('HomeInventory_RecentInventories')}
                           content={<HomeInventoryRecent />}
                           actions={
                               <Button
                                   size={"small"}
                                   startIcon={<Add />}
                                   color={"secondary"}
                                   variant={"contained"}
                                   onClick={()=>setCreateCollection(true)}
                               >{t('CreateCollection')}</Button>
                           }
                    />
                    {createCollection && <CollectionCreate open={true} onClose={()=>setCreateCollection(false)} />}
                    <Block sm={6} xs={12} md={6} op={1}
                           title={t('HomeInventory_ImagesToProcess')}
                           content={<HomeInventoryImages />}
                           actions={
                               <Button
                                   size={"small"}
                                   startIcon={<CollectionsIcon />}
                                   color={"default"}
                                   variant={"contained"}
                                   component={NavLink}
                                   to={'/collections'}
                               >{t('Collections')}</Button>
                           }
                    />
                </>}
                {lea &&
                <>
                    <BlockInvestigations h={120} sm={6} xs={12} md={4}/>
                    <BlockCriminalCases  h={120} sm={6} xs={12} md={4}/>
                    <BlockTask           h={120} sm={12} xs={12} md={4}/>
                </>
                }

                {lea&&<Block xs={12} sm={6} md={6} title={t("Activity")}
                        content={<HomeList>
                            <HomeListItem>{t('DashboardLastConnection')} : hier</HomeListItem>
                            <HomeListItem>{t('DashboardConnected')} : 1</HomeListItem>
                            <HomeListItem>{t('DashboardMyPreferences')}</HomeListItem>
                        </HomeList>}
                        actions={<Button variant="contained" color="default">{t('See')}</Button>}
                />}
                {lea&&<Block xs={12} sm={6} md={6} title={t("Validations")}
                        content={<HomeList>
                            <HomeListItem>2 {t('CriminalCasesLink')}</HomeListItem>
                            <HomeListItem>0 {t('Investigations')}</HomeListItem>
                            <HomeListItem>0 {t('Collections')}</HomeListItem>
                        </HomeList>}
                        actions={<Button variant="contained" color="default">{t('See')}</Button>}
                />}
                <Grid item xs={12}>
                    <Surveys title={t("DashboardSurveillanceNotification")} filter />
                </Grid>
                <Block xs={12} title={t("DashboardConnections")}
                       content={<ExampleGraph />}
                       actions={<Button variant="contained" color="default">{t('See')}</Button>}
                />
                {lea&&<>
                    <Block xs={12} sm={6} md={3} title={t("DashboardCalendar")} h={120} op={0.8}
                           content={<HomeList>
                               <HomeListItem>{t('DashboardCalendarPrivate')}</HomeListItem>
                               <HomeListItem>{t('DashboardCalendarService')}</HomeListItem>
                           </HomeList>}
                           actions={<Button variant="contained" color="default">{t('Agenda')}</Button>}
                    />
                    <Block xs={12} sm={6} md={3} title={t("DashboardDirectory")} h={120} op={0.9}
                           content={<HomeList>
                               <HomeListItem>{t('DashboardDirectoryInternal')}</HomeListItem>
                               <HomeListItem>{t('DashboardDirectoryNational')}</HomeListItem>
                               <HomeListItem>{t('DashboardDirectoryForeign')}</HomeListItem>
                               <HomeListItem>{t('DashboardDirectoryExperts')}</HomeListItem>
                           </HomeList>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                    <Block xs={12} sm={6} md={3} title={t("DashboardContact")} h={120} op={0.9}
                           content={<HomeList>
                               <HomeListItem>{t('DashboardContactVideo')}</HomeListItem>
                               <HomeListItem>{t('DashboardContactVoice')}</HomeListItem>
                               <HomeListItem>{t('DashboardContactChat')}</HomeListItem>
                           </HomeList>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                    <Block xs={12} sm={6} md={3} title={t("DashboardDocumentation")} h={120} op={0.9}
                           content={<HomeList>
                               <HomeListItem>{t('DashboardDocumentationNews')}</HomeListItem>
                               <HomeListItem>{t('DashboardDocumentationInternal')}</HomeListItem>
                               <HomeListItem>{t('DashboardDocumentationLegal')}</HomeListItem>
                               <HomeListItem>{t('DashboardDocumentationGoodPractice')}</HomeListItem>
                           </HomeList>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                    <Block h={100} xs={12} sm={6} md={4} title={t("DashboardFormReport")} h={120} op={0.8}
                           content={<HomeList>
                               <HomeListItem>{t('DashboardFormInternalForm')}</HomeListItem>
                               <HomeListItem>{t('DashboardFormTools')}</HomeListItem>
                           </HomeList>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                    <Block h={100} xs={12} sm={6} md={4} title={t("DashboardAdministration")} h={120} op={0.8}
                           content={<HomeList>
                               <HomeListItem>{t('DashboardAdministrationRules')}</HomeListItem>
                               <HomeListItem>{t('DashboardAdministrationUsers')}</HomeListItem>
                           </HomeList>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                    <Block h={100} xs={12} sm={6} md={4} title={t("DashboardMyProfile")} h={120} op={0.8}
                           content={<HomeList>
                               <HomeListItem>{t('DashboardMyProfilePassword')}</HomeListItem>
                               <HomeListItem>{t('DashboardMyProfileAbsence')}</HomeListItem>
                           </HomeList>}
                           actions={<Button variant="contained" color="default">{t('See')}</Button>}
                    />
                </>}

            </Grid>


        </ThemeProvider>
        <SpeedDialer classes={classes} />
    </Container>;
};

export function HomeList({children}) {
    return <Box>
        {children}
    </Box>
}

export function HomeListItem({icon, link, children}) {
    if (link) {
        return <Button
            align
            fullWidth
            variant={"text"}
            square
            component={NavLink}
            to={link}
            style={{justifyContent: "flex-start"}}
            startIcon={icon ? icon : null}
        >
            {children}
        </Button>
    }
    else {
        return <Box>
            {icon}
            {children}
        </Box>
    }
}

function Block({title, content, actions, xs, lg, sm, md, h, op}) {
    const classes = useStyles();
    return <Grid item xs={xs} lg={lg} sm={sm} md={md}>
        <Card elevation={3} style={{backgroundColor: `rgba(255,255,255,${op ? op : 100})`}}>
            <CardHeader
                title={title}
                className={classes.cardHeader}
                action={actions}
            />
            {!!content && <CardContent style={{height: h}} className={classes.cardContent}>
                {content}
            </CardContent>}
        </Card>
    </Grid>;
}

function BlockInvestigations({xs, lg, sm, md, h}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const status = useSelector(state => state.investigations.status);
    const investigations = useSelector(selectAllInvestigations);
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchInvestigations(null));
        }
    }, [status, dispatch]);
    
    return <Grid item xs={xs} lg={lg} sm={sm} md={md}>
        <>
            <Card elevation={3}>
                <CardHeader
                    title={t('DashboardRecentInvestigation')} className={classes.cardHeader}
                    action={<NiceTooltip title={t('InvestigationsLink')} placement={"top-center"}>
                        <IconButton color="secondary" to={"/investigations"} component={Link}>
                            <OpenIcon />
                        </IconButton>
                    </NiceTooltip>}
                />
                <CardContent style={{height: h}} className={classes.cardContent}>
                    {status === "loading" && <LinearProgress color={"secondary"} />}
                    {status === "succeeded" && <HomeList>
                        {investigations.map((investigation, index)=>{
                            if (index <= 1) return <HomeListItem icon={<PolicyIcon />} link={`investigations/${investigation.id}`}>
                                {investigation.name}
                            </HomeListItem>
                        })}
                    </HomeList>}
                </CardContent>
            </Card>
        </>
    </Grid>;
}

function BlockCriminalCases({xs, lg, sm, md, h}) {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.criminalCases.status);
    const criminalCases = useSelector(selectAllCriminalCases);
    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchCriminalCases(null));
        }
    }, [status, dispatch]);
    return <Grid item xs={xs} lg={lg} sm={sm} md={md}>
        <>
            <Card elevation={3}>
                <CardHeader
                    title={t('DashboardRecentCases')} className={classes.cardHeader}
                    action={<NiceTooltip title={t('CriminalCasesLink')} placement={"top-center"}>
                        <IconButton color="secondary" to={"/cases"} component={Link}>
                            <OpenIcon />
                        </IconButton>
                    </NiceTooltip>}
                />
                <CardContent style={{height: h}} className={classes.cardContent}>
                    {status === "loading" && <LinearProgress color={"secondary"} />}
                    {status === "succeeded" && <HomeList>
                    {criminalCases.map((criminalCase, index) => {
                        if (index <= 1) return <HomeListItem icon={<BusinessCenterIcon />} link={`cases/${criminalCase.id}`}>
                            {criminalCase.summary}
                        </HomeListItem>
                    })}
                    </HomeList>}
                </CardContent>
            </Card>
        </>
    </Grid>;

}
function BlockTask({xs, lg, sm, md, h}) {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const status = useSelector(state => state.tasks.status);
    const tasks = useSelector(selectAllTasks);
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchTasks(null));
        }
    }, [status, dispatch]);
    return <Grid item xs={xs} lg={lg} sm={sm} md={md}>
        <>
            <Card elevation={3} style={{backgroundColor: `rgba(255,255,255,.6)`}}>
                <CardHeader
                    title={t('DashboardIncomingTasks')} className={classes.cardHeader}
                />
                <CardContent style={{height: h}} className={classes.cardContent}>
                    {status === "loading" && <LinearProgress color={"secondary"} />}
                    {status === "failed" && <Box>{t('NoTasksUpcoming')}</Box>}
                    {status === "succeeded" && <HomeList>
                        {tasks.length === 0 && t('NoTasksUpcoming')}
                        {tasks.map((tasks, index)=>{
                            if (index <= 1) return <HomeListItem icon={<TodayOutlined />} link={`investigations/${tasks.id}`}>
                                {tasks.name}
                            </HomeListItem>
                        })}
                    </HomeList>}
                </CardContent>
            </Card>
        </>
    </Grid>;
}


// dialer
function SpeedDialer({classes}) {

    const [dialerOpened, setDialerOpened] = useState(false);
    const history = useHistory();

    const handleOpen = () => {
        setDialerOpened(true);
    };
    const handleClick = (link) => {
        history.push(link)
        setDialerOpened(false);
    };
    const actions = [
        { icon: <ImageSearchIcon />, name: 'search', title: 'Search', to:'/identification' },
        { icon: <CollectionsIcon />, name: 'collec', title: 'Collections', to: '/collections' },
    ];
    return <SpeedDial
        ariaLabel="SpeedDial tooltip"
        open={dialerOpened}
        hidden={false}
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={()=>setDialerOpened(false)}
        onOpen={handleOpen}
    >
        {actions.map((action) => (
            <SpeedDialAction
                title={action.title}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={()=>handleClick(action.to)}
            />
        ))}
    </SpeedDial>
}

// Theme
const lightTheme = (prevision = false) => createTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette)
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        /*flexGrow: 1*/
    },
    cardHeader: {
        //padding: theme.spacing(1),
        height: 56,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    cardContent: {
        overflow: "auto",
        // paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    ul: {
        paddingLeft: '1rem'
    },
    create: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        height: 100
    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));
export default Home;
