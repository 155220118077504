import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentUser} from "../../store/entities/currentUserSlice";
import React, {useEffect, useState} from "react";
import {fetchCollectionTypes, selectAllCollectionTypes} from "../../store/entities/collectionTypesSlice";
import {fetchFieldTypes} from "../../store/entities/fieldTypesSlice";
import {createCollection, selectCreateStatus} from "../../store/entities/collectionsSlice";
import {
    Button, Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid, ListItem, MenuItem, Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import tdb from "../../services/TranslateDB";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import Translatable from "./Fields/Translatable";

export default function CollectionCreate({open, onClose, investigationId}) {
    // const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const currentUser = useSelector(getCurrentUser);
    
    useEffect(()=>{
        if (currentUser) {
            console.log(currentUser)
        }
    }, [currentUser])
    
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [redirect, setRedirect] = useState(true);
    const [upload, setUpload] = useState(true);
    
    const collectionTypes = useSelector(selectAllCollectionTypes)
    const statusType = useSelector(state => state.collectionTypes.status);
    
    // const createStatus = useSelector(state => state.collections.createStatus);
    const createStatus = useSelector(state => selectCreateStatus(state))
    
    useEffect(()=>{
        if (statusType === 'idle') {
            dispatch(fetchCollectionTypes(null));
            dispatch(fetchFieldTypes(null));
        }
        else if (statusType === 'succeeded' && collectionTypes) {
            const defaultType = collectionTypes.find(c=>c.ord===1).id
            setType(defaultType.toString())
        }
    }, [statusType, dispatch]);
    
    const [launched, setLaunched] = useState(false);
    const handleCreate = () => {
        if (type && name) {
            
            setLaunched(true)
            const body = {
                collection_type: parseInt(type),
                name: name,
                //description: description
                description: {
                    'default': description,
                    [i18n.language]: description
                }
            };
            if (investigationId)body.investigation_id = investigationId;
            dispatch(createCollection({body: body}));
            if (!redirect) {
                onClose();
            }
        }
    }
    
    useEffect(()=>{
        console.log("createStatus", createStatus)
        if (redirect && launched && createStatus && createStatus.id) {
            let url = `/collections/${createStatus.id}`;
            if (upload)url += '/upload'
            history.push(url);
        }
    }, [createStatus])
    
    return <Dialog open={open} fullWidth maxWidth={"sm"} onClose={onClose}>
        <DialogTitle
            title={t('CreateCollection')}
        >
            {t('CreateCollection')}
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField variant="outlined" size="medium" value={name} onChange={event=>setName(event.target.value)} placeholder="Name" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    {/*<Translatable initValue={description} label={"Description"} onChange={(value)=>setDescription(value)} fieldsetMode={t("Description")} limit={2} />*/}
                    <TextField variant="outlined" size="medium" value={description} onChange={event=>setDescription(event.target.value)} placeholder="Description" multiline fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label={t("CollectionType")} variant={"outlined"} value={type} onChange={event=>setType(event.target.value)} select>
                        {collectionTypes.map((collectionType, index) => {
                            if (currentUser.type === "admin" || (collectionType.parameters && collectionType.parameters.usage && collectionType.parameters.usage.includes(currentUser.type))) {
                                return <MenuItem value={`${collectionType.id}`}>
                                    {tdb(collectionType.title)}
                                </MenuItem>
                            }
                        })}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={redirect} />} label={t('CreationCreateRedirect')} onChange={event=>setRedirect(event.target.checked)} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox checked={upload} />} label={t('CreationCreateUploadImages')} onChange={event=>setUpload(event.target.checked)} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="default" variant={"contained"}>
                {t('Cancel')}
            </Button>
            <Button onClick={handleCreate} color="secondary" variant="contained" disabled={!name || !type}>
                {t('Create')}
            </Button>
        </DialogActions>
    </Dialog>;
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    }
}));