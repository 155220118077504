import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/client';
import {fetchCriminalCaseById, selectAllCriminalCases} from "./criminalCasesSlice";
import {tasksFetched} from './tasksSlice';
import {fetchCollectionById} from "./collectionsSlice";

const investigationsAdapter = createEntityAdapter();
const initialState = investigationsAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch all investigations
export const fetchInvestigations = createAsyncThunk(
    'investigations/fetchInvestigations',
    async (parameters, thunkAPI) => {
        const response = await client.get('/investigation/getAll');
        const investigations = response.investigation;
        investigations.map(investigation => {
            if (investigation.tasks && investigation.tasks.length) {
                if (typeof investigation.tasks.length[0] === "object") {
                    thunkAPI.dispatch(tasksFetched(investigation.tasks))
                }
                else {
                    // TODO fetchTaskById
                }
            }
            if (investigation.collections && investigation.collections.length) { // && typeof investigation.collections.length[0] === "string"
                investigation.collections.map(collectionId => {
                    // thunkAPI.dispatch(fetchCollectionById(collectionId));
                });
            }
        });
        return investigations;
    }
);

export const fetchInvestigationById = createAsyncThunk(
    'investigations/fetchInvestigationById',
    async(investigationId, thunkAPI) => {
        const response = await client.get('/investigation/get/'+investigationId);
        const investigation = response.investigation;
        if (investigation.tasks) {
            thunkAPI.dispatch(tasksFetched(investigation.tasks))
        }
        if (investigation.collections && investigation.collections.length) { // && typeof investigation.collections.length[0] === "string"
            investigation.collections.map(collectionId => {
                thunkAPI.dispatch(fetchCollectionById(collectionId));
            });
        }
        if (investigation.criminalCases && investigation.criminalCases.length) { // && typeof investigation.collections.length[0] === "string"
            investigation.criminalCases.map(criminalCaseId => {
                if (!(criminalCaseId in thunkAPI.getState().criminalCases.entities)) {
                    thunkAPI.dispatch(fetchCriminalCaseById(criminalCaseId));
                }
            });
        }
        delete investigation.tasks;
        return investigation;
    }
);

export const createInvestigation = createAsyncThunk(
    'investigations/createInvestigation',
    async(parameters, thunkAPI) => {
        const response = await client.post('/investigation/create', parameters.body);
        return response.investigation;
    }
);

export const fetchInvestigationUpdate = createAsyncThunk(
    'investigations/fetchInvestigationUpdate',
    async(parameters, thunkAPI) => {
        const response = await client.post('/investigation/'+parameters.investigationId, parameters.body);
        return response.investigation;
    }
);

// THE REDUCERS
const investigationsSlice = createSlice({
    name: 'investigations',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchInvestigations.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchInvestigations.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched investigations to the array
            investigationsAdapter.upsertMany(state, action.payload);
        },
        [fetchInvestigations.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        [fetchInvestigationById.fulfilled]: (state, action) => {
            investigationsAdapter.upsertOne(state, action.payload);
        },
        [fetchInvestigationUpdate.fulfilled]: (state, action) => {
            investigationsAdapter.upsertOne(state, action.payload);
        },
        [createInvestigation.fulfilled]: (state, action) => {
            investigationsAdapter.upsertOne(state, action.payload);
        }
    },
});

// export const {  } = investigationsSlice.actions;

export default investigationsSlice.reducer

export const {
    selectAll: selectAllInvestigations,
    selectById: selectInvestigationById,
    selectIds: selectInvestigationIds,
} = investigationsAdapter.getSelectors((state) => state.investigations);

export const selectInvestigationByCriminalCase = createSelector(
    [selectAllInvestigations, (state, CriminalCaseId) => CriminalCaseId],
    (investigations, CriminalCaseId) => investigations.filter((investigation) => (
        investigation.criminalCases.indexOf(CriminalCaseId) > -1
    ))
);
