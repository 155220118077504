import {
    createSlice
} from '@reduxjs/toolkit';

const initialState = {
    editMode: false,
    currentNode: false,
    showCategories: true,
    showFields: true,
    showOnlyPath: false,
    showThesaurus: false,
    categoryExpanded: [],
    categorySelected: null,
    categoryEdited: null,
    fieldSelected: null,
    fieldEdited: null,
    thesaurusSelected: null,
    thesaurusExpanded: [],
    filterNonThesaurus: true,
    thesaurusOpened: [],
    fieldsOpened: [],
    fieldThesaurusExpanded: {},

    // used for communication between selector and object editor
    inputThesaurus: null,
    inputThesaurusValue: null
};

const selectorSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
        showDrawer(state) {                             state.showDrawer =          true},
        hideDrawer(state) {                             state.showDrawer =          false},
        setDrawer(state, action) {                      state.showDrawer =          action.payload},
        setEditMode(state, action) {                    state.editMode =            action.payload},

        setShowOnlyPath(state, action) {                state.showOnlyPath =        action.payload},
        setShowCategories(state, action) {              state.showCategories =      action.payload},
        setShowFields(state, action) {                  state.showFields =          action.payload},
        setShowThesaurus(state, action) {               state.showThesaurus =       action.payload},

        setFilterNonThesaurus(state, action) {          state.filterNonThesaurus =  action.payload},

        setCategoryExpanded(state, action) {            state.categoryExpanded =    action.payload},
        setCategorySelected(state, action) {            state.categorySelected =    action.payload},
        setCategoryEdited(state, action) {              state.categoryEdited =      action.payload},

        setThesaurusSelected(state, action) {           state.thesaurusSelected =   action.payload},
        setThesaurusExpanded(state, action) {           state.thesaurusExpanded =   action.payload},

        setFieldSelected(state, action) {               state.fieldSelected =       action.payload},
        setFieldEdited(state, action) {                 state.fieldEdited =         action.payload},

        setFieldsOpened(state, action) {                state.fieldsOpened =        action.payload},
        addFieldsOpened(state, action) {                state.fieldsOpened =    [...state.fieldsOpened, action.payload]},
        removeFieldsOpened(state, action) {             state.fieldsOpened =        state.fieldsOpened.filter(t=>t!==action.payload)},

        setThesaurusOpened(state, action) {             state.thesaurusOpened =     action.payload},
        addThesaurusOpened(state, action) {             state.thesaurusOpened = [...state.thesaurusOpened, action.payload]},
        removeThesaurusOpened(state, action) {          state.thesaurusOpened =     state.thesaurusOpened.filter(t=>t!==action.payload)},

        setFieldThesaurusExpanded(state, action) {      state.thesaurusExpanded =   {...state.thesaurusExpanded, [action.payload.field]: action.payload.values}},
        addFieldThesaurusExpanded(state, action) {      state.thesaurusExpanded[action.payload.field] = [...state.thesaurusExpanded[action.payload.field], action.value]},
        removeFieldThesaurusExpanded(state, action) {   state.thesaurusExpanded[action.payload.field] =     state.thesaurusExpanded[action.payload.field].filter(t=>t!==action.payload.value)},

        setInputThesaurus(state, action) {              state.inputThesaurus =      action.payload},
        setInputThesaurusValue(state, action) {         state.inputThesaurusValue = action.payload},
    }
});

export default selectorSlice.reducer

export const {
    showDrawer, hideDrawer, setDrawer, setShowOnlyPath,
    setShowFields, setShowCategories, setShowThesaurus,
    setCategoryExpanded, setCategoryEdited, setThesaurusExpanded, setFieldEdited,
    setCategorySelected, setThesaurusSelected, setFieldSelected,
    setFilterNonThesaurus,
    setThesaurusOpened, addThesaurusOpened, removeThesaurusOpened,
    setFieldsOpened, addFieldsOpened, removeFieldsOpened,
    setFieldThesaurusExpanded, removeFieldThesaurusExpanded, addFieldThesaurusExpanded,
    setEditMode, setInputThesaurus, setInputThesaurusValue
} = selectorSlice.actions;

export const selectDrawerVisible =          (state) => state.treeEditor.showDrawer;
export const selectEditMode =               (state) => state.treeEditor.editMode;
export const selectShowOnlyPath =           (state) => state.treeEditor.showOnlyPath;
export const selectShowCategories =         (state) => state.treeEditor.showCategories;
export const selectShowFields =             (state) => state.treeEditor.showFields;
export const selectShowThesaurus =          (state) => state.treeEditor.showThesaurus;
export const selectCategoryExpanded =       (state) => state.treeEditor.categoryExpanded;
export const selectCategorySelected =       (state) => state.treeEditor.categorySelected;
export const selectCategoryEdited =         (state) => state.treeEditor.categoryEdited;
export const selectFieldSelected =          (state) => state.treeEditor.fieldSelected;
export const selectFieldEdited =            (state) => state.treeEditor.fieldEdited;
export const selectThesaurusSelected =      (state) => state.treeEditor.thesaurusSelected;
export const selectThesaurusExpanded =      (state) => state.treeEditor.thesaurusExpanded;
export const selectFilterNonThesaurus =     (state) => state.treeEditor.filterNonThesaurus;
export const selectThesaurusOpened =        (state) => state.treeEditor.thesaurusOpened;
export const selectFieldsOpened =           (state) => state.treeEditor.fieldsOpened;
export const selectFieldThesaurusExpanded = (state) => state.treeEditor.fieldThesaurusExpanded;
export const selectInputThesaurus =         (state) => state.treeEditor.inputThesaurus;
export const selectInputThesaurusValue =    (state) => state.treeEditor.inputThesaurusValue;
