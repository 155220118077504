import React from "react";
import {useSelector} from "react-redux";
import {selectImagesVersions, selectImageById} from "../../../store/entities/imagesSlice";
import {makeStyles} from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {Card, CardActionArea, CardContent} from "@material-ui/core";
// import DialogActions from "@material-ui/core/DialogActions";
// import Button from "@material-ui/core/Button";

export default function CollectionImage ({imageId, imageSize, openModal, selected, onSelect}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const image = useSelector(state => selectImageById(state, imageId));
    const versionImages = useSelector(state => selectImagesVersions(state, imageId));
    const getMappedSize = () => {
        return imageSize * 25 + 50;
    };
    const content = <img
        alt=""
        src={image.binary ? image.binary : image.urls.medium}
        draggable={false}
        className={classes.media}
        style={{width: getMappedSize(), height: getMappedSize()}}
        onDoubleClick={()=>openModal(imageId)}
    />;
    return (
        <>
            <Badge color="secondary" badgeContent={versionImages.length} >
                <Card className={clsx({[classes.selected]:selected})} elevation={selected ? 0 : 3}>
                    <CardActionArea
                        onClick={()=>onSelect(imageId)}
                        onDoubleClick={()=>openModal(imageId)}
                    >
                        <CardContent style={{padding: 8}}>
                            {content}
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Badge>
        </>
    );
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    badge: {

    },
    image: {
        borderStyle: 'hash',
        borderColor: theme.palette.primary.contrastText,
        borderWidth: 1,
        padding: theme.spacing(1),
    },
    selected: {
        backgroundColor: "rgba(0,0,0,.2)",
        outline: "1px solid #666"
    },
    media: {
        height: '100%',
        width: '100%',
        backgroundColor: 'black'
    }
}));