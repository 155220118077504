import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';


const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,

    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        borderTop: theme.palette.common.white,
        borderLeft: theme.palette.common.white,
        fontSize: 13
    },
}));

function NiceTooltip(props) {
    const classes = useStylesBootstrap();
    let _props = props;
    if (!('placement' in _props)) {
        _props = {..._props, placement: 'top-center'}
    }
    return <Tooltip arrow value={"tooltip"} classes={classes} {..._props} />;
}

export default NiceTooltip