import React, { useState, createRef, useRef } from "react";
import { NavLink } from "react-router-dom";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./style.css";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import RotateRight from '@material-ui/icons/RotateRight';
import RotateLeft from '@material-ui/icons/RotateLeft';
import Compare from '@material-ui/icons/Compare';
import Spellcheck from '@material-ui/icons/Spellcheck';
import History from '@material-ui/icons/History';
import TrackChanges from '@material-ui/icons/TrackChanges';
import Close from '@material-ui/icons/Close';


import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
  TextField
} from "@material-ui/core";

import client_ai from '../../api/client_ai';

import { createIdentification, setCurrentImage, setCurrentSearchImage, setCurrentDetectedText, launchDetectText, setDetectTextLanguage } from "../../store/entities/identificationSlice";
import { REACT_APP_DEFAULT_DETECT_TEXT_LANGUAGE } from "../../App";

export const ImageEditor = ({ onImageChange }) => {
  const currentImage = useSelector((state) => state.identifications.currentImage);
  const [openModal, setOpenModal] = useState(false);
  let defaultImage = currentImage ? currentImage : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
  const [image, setImage] = useState(defaultImage);
  const [cropper, setCropper] = useState();
  const [imgLoaded, setImgLoaded] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const status = useSelector(state => state.search.status);
  const fileInput = createRef();
  const stateRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  stateRef.current = cropper;

  const getBase64FromSrc = (src) => {
    return new Promise((resolve, reject) => {
      var canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = src;
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);
        resolve(canvas.toDataURL("image/jpeg", 1));
      }
    })
  }

  const handleOpenFile = () => {
    setImgLoaded(true)
    fileInput.current.click();
  }

  const getImageDimensions = (file) => {
    return new Promise(function (resolve, reject) {
      var i = new Image()
      i.onload = function () {
        resolve({ width: i.width, height: i.height })
      };
      i.src = file
    })
  };

  const resizeBase64Img = (base64) => {
    var max_width = 800;
    var max_height = 600;
    var resizedImage = getImageDimensions(base64).then((dimensions) => {
      var width_ratio = dimensions.width / max_width;
      var height_ratio = dimensions.height / max_height;
      var ratio = 1;
      if (width_ratio > 1 || height_ratio > 1) {
        ratio = Math.max(width_ratio, height_ratio);
      }
      else {
        ratio = 1;
      }
      var newWidth = dimensions.width / ratio;
      var newHeight = dimensions.height / ratio;
      return new Promise((resolve, reject) => {
        var canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        let context = canvas.getContext("2d");
        let img = new Image();
        img.src = base64;
        img.onload = function () {
          context.drawImage(img, 0, 0, newWidth, newHeight);
          resolve(canvas.toDataURL("image/jpeg", 1));
        }
      });
    })
    return resizedImage
  };

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    let reader = new FileReader()
    reader.file = files[0];
    reader.onload = (e) => {
      resizeBase64Img(reader.result).then(function (newImg) {
        setImage(newImg);
        dispatch(setCurrentImage(newImg));
        // No callback found in the cropper. This can be enhanced with a callback for new image loaded.
        // Without interval checking , stateRef.current.getCroppedCanvas() does not exist right after image change
        let interval = window.setInterval(() => {
          if (stateRef.current.getCroppedCanvas()) {
            onImageChange(stateRef.current.getCroppedCanvas().toDataURL("image/jpeg", 1))
            dispatch(setCurrentSearchImage(stateRef.current.getCroppedCanvas().toDataURL("image/jpeg", 1)))
            clearInterval(interval)
          }
        }, 10)
      });
      let parameters = {
        data: reader.result,
        name: reader.file.name,
        size: reader.file.size,
      };
      dispatch(createIdentification(parameters));
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof stateRef.current !== "undefined") {
      onImageChange(stateRef.current.getCroppedCanvas().toDataURL("image/jpeg", 1))
      dispatch(setCurrentSearchImage(stateRef.current.getCroppedCanvas().toDataURL("image/jpeg", 1)))
    }
  };

  const handleRotateRight = () => {
    cropper.rotate(-90);
  };

  const handleRotateLeft = () => {
    cropper.rotate(90);
  };

  const detectText = () => {
    setIsSpinning(true)
    setDetectTextLanguage(process.env.REACT_APP_DEFAULT_DETECT_TEXT_LANGUAGE)
    dispatch(launchDetectText()).then(() => {
      setOpenModal(true)
      setIsSpinning(false)
    }
    );
  };

  const removeBackground = () => {
    setIsSpinning(true)
    var data = {
      "fullImage": image,
      "resultFormat": "base64"
    }
    client_ai.post("/remove_background", data).then(
      (response) => {
        setImage(response["base64"]);
        dispatch(setCurrentImage(response["base64"]));
        setIsSpinning(false)
      }
    )
  }

  const initializeCropper = (instance) => {
    setCropper(instance);
  }

  if (currentImage && !imgLoaded) {
    getBase64FromSrc(currentImage).then(
      (img) => {
        resizeBase64Img(img).then(function (newImg) {
          setImage(newImg);
        });
      }
    )
  }


  return (
    <div>
      <div style={{ width: "100%" }}>
        <input type="file" accept="image/*" onChange={onChange} ref={fileInput} style={{ display: "None" }} />
        <div>
          <div style={{ position: "relative" }}>
            <Cropper
              style={{ height: 400, width: "100%" }}
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={initializeCropper}
              cropend={getCropData}
              guides={true}
              dragMode={'none'}
            />
            {isSpinning || status === "loading" ?
              <div style={{ width: '80px', height: '80px', position: 'absolute', top: '50%', left: '50%', margin: '-40px 0 0 -40px' }}>
                <img src="/img/spinner.gif" style={{ width: '100%', height: 'auto' }} />
              </div>
              : null}
            {!imgLoaded && !currentImage ?
              <div class="overlay" style={{ width: "100%", height: "100%", background: "rgba(0, 0, 0, 0.4)", position: "absolute", zIndex: "10", top: "0px", textAlign: "center" }}>
                {!imgLoaded && !currentImage ?
                  <div onClick={handleOpenFile} style={{ cursor: "pointer", display: "inline-block", color: "#fff", fontSize: "24px", margin: "0 auto", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", width: "180px", height: "50px" }}>
                    {t('ClickToChooseImage')}
                  </div>
                  : null}
              </div>
              : null
            }

          </div>
          <div class="cropper-menu">
            <Tooltip title={t('ChangeImage')}>
              <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleOpenFile}>
                <AddAPhotoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('RemoveBackground')}>
              <IconButton color="primary" aria-label="upload picture" component="span" onClick={removeBackground}>
                <Compare />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('RotateRight')}>
              <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleRotateRight}>
                <RotateLeft />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('RotateLeft')}>
              <IconButton color="primary" aria-label="upload picture" component="span" onClick={handleRotateLeft}>
                <RotateRight />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('DetectText')}>
              <IconButton color="primary" aria-label="detect text" component="span" onClick={detectText}>
                <Spellcheck />
              </IconButton>
            </Tooltip>
            <span class="vertical-spacer"></span>
            <Tooltip title={t('Create survey from this identification')}>
              <IconButton color="primary" aria-label="upload picture" component={NavLink} to="/surveys/new">
                <TrackChanges />
              </IconButton>
            </Tooltip>
            <span class="vertical-spacer"></span>
            <Tooltip title={t('History')}>
              <IconButton color="primary" aria-label="upload picture" component={NavLink} to="/identifications">
                <History />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      {/*<br style={{ clear: "both" }} />*/}
      <ImageModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
};

export function ImageModal({ openModal, setOpenModal }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentDetectedText = useSelector((state) => state.identifications.currentDetectedText);


  const languages = [
    { 'label': 'afr', 'name': 'Afrikaans' },
    { 'label': 'amh', 'name': 'Amharic' },
    { 'label': 'ara', 'name': 'Arabic' },
    { 'label': 'asm', 'name': 'Assamese' },
    { 'label': 'aze', 'name': 'Azerbaijani' },
    { 'label': 'aze_cyrl', 'name': 'Azerbaijani - Cyrillic' },
    { 'label': 'bel', 'name': 'Belarusian' },
    { 'label': 'ben', 'name': 'Bengali' },
    { 'label': 'bod', 'name': 'Tibetan' },
    { 'label': 'bos', 'name': 'Bosnian' },
    { 'label': 'bul', 'name': 'Bulgarian' },
    { 'label': 'cat', 'name': 'Catalan; Valencian' },
    { 'label': 'ceb', 'name': 'Cebuano' },
    { 'label': 'ces', 'name': 'Czech' },
    { 'label': 'chi_sim', 'name': 'Chinese - Simplified' },
    { 'label': 'chi_tra', 'name': 'Chinese - Traditional' },
    { 'label': 'chr', 'name': 'Cherokee' },
    { 'label': 'cym', 'name': 'Welsh' },
    { 'label': 'dan', 'name': 'Danish' },
    { 'label': 'deu', 'name': 'German' },
    { 'label': 'dzo', 'name': 'Dzongkha' },
    { 'label': 'ell', 'name': 'Greek, Modern (1453-)' },
    { 'label': 'eng', 'name': 'English' },
    { 'label': 'enm', 'name': 'English, Middle (1100-1500)' },
    { 'label': 'epo', 'name': 'Esperanto' },
    { 'label': 'est', 'name': 'Estonian' },
    { 'label': 'eus', 'name': 'Basque' },
    { 'label': 'fas', 'name': 'Persian' },
    { 'label': 'fin', 'name': 'Finnish' },
    { 'label': 'fra', 'name': 'French' },
    { 'label': 'frk', 'name': 'German Fraktur' },
    { 'label': 'frm', 'name': 'French, Middle (ca. 1400-1600)' },
    { 'label': 'gle', 'name': 'Irish' },
    { 'label': 'glg', 'name': 'Galician' },
    { 'label': 'grc', 'name': 'Greek, Ancient (-1453)' },
    { 'label': 'guj', 'name': 'Gujarati' },
    { 'label': 'hat', 'name': 'Haitian; Haitian Creole' },
    { 'label': 'heb', 'name': 'Hebrew' },
    { 'label': 'hin', 'name': 'Hindi' },
    { 'label': 'hrv', 'name': 'Croatian' },
    { 'label': 'hun', 'name': 'Hungarian' },
    { 'label': 'iku', 'name': 'Inuktitut' },
    { 'label': 'ind', 'name': 'Indonesian' },
    { 'label': 'isl', 'name': 'Icelandic' },
    { 'label': 'ita', 'name': 'Italian' },
    { 'label': 'ita_old', 'name': 'Italian - Old' },
    { 'label': 'jav', 'name': 'Javanese' },
    { 'label': 'jpn', 'name': 'Japanese' },
    { 'label': 'kan', 'name': 'Kannada' },
    { 'label': 'kat', 'name': 'Georgian' },
    { 'label': 'kat_old', 'name': 'Georgian - Old' },
    { 'label': 'kaz', 'name': 'Kazakh' },
    { 'label': 'khm', 'name': 'Central Khmer' },
    { 'label': 'kir', 'name': 'Kirghiz; Kyrgyz' },
    { 'label': 'kor', 'name': 'Korean' },
    { 'label': 'kur', 'name': 'Kurdish' },
    { 'label': 'lao', 'name': 'Lao' },
    { 'label': 'lat', 'name': 'Latin' },
    { 'label': 'lav', 'name': 'Latvian' },
    { 'label': 'lit', 'name': 'Lithuanian' },
    { 'label': 'mal', 'name': 'Malayalam' },
    { 'label': 'mar', 'name': 'Marathi' },
    { 'label': 'mkd', 'name': 'Macedonian' },
    { 'label': 'mlt', 'name': 'Maltese' },
    { 'label': 'msa', 'name': 'Malay' },
    { 'label': 'mya', 'name': 'Burmese' },
    { 'label': 'nep', 'name': 'Nepali' },
    { 'label': 'nld', 'name': 'Dutch; Flemish' },
    { 'label': 'nor', 'name': 'Norwegian' },
    { 'label': 'ori', 'name': 'Oriya' },
    { 'label': 'pan', 'name': 'Panjabi; Punjabi' },
    { 'label': 'pol', 'name': 'Polish' },
    { 'label': 'por', 'name': 'Portuguese' },
    { 'label': 'pus', 'name': 'Pushto; Pashto' },
    { 'label': 'ron', 'name': 'Romanian; Moldavian; Moldovan' },
    { 'label': 'rus', 'name': 'Russian' },
    { 'label': 'san', 'name': 'Sanskrit' },
    { 'label': 'sin', 'name': 'Sinhala; Sinhalese' },
    { 'label': 'slk', 'name': 'Slovak' },
    { 'label': 'slv', 'name': 'Slovenian' },
    { 'label': 'spa', 'name': 'Spanish; Castilian' },
    { 'label': 'spa_old', 'name': 'Spanish; Castilian - Old' },
    { 'label': 'sqi', 'name': 'Albanian' },
    { 'label': 'srp', 'name': 'Serbian' },
    { 'label': 'srp_latn', 'name': 'Serbian - Latin' },
    { 'label': 'swa', 'name': 'Swahili' },
    { 'label': 'swe', 'name': 'Swedish' },
    { 'label': 'syr', 'name': 'Syriac' },
    { 'label': 'tam', 'name': 'Tamil' },
    { 'label': 'tel', 'name': 'Telugu' },
    { 'label': 'tgk', 'name': 'Tajik' },
    { 'label': 'tgl', 'name': 'Tagalog' },
    { 'label': 'tha', 'name': 'Thai' },
    { 'label': 'tir', 'name': 'Tigrinya' },
    { 'label': 'tur', 'name': 'Turkish' },
    { 'label': 'uig', 'name': 'Uighur; Uyghur' },
    { 'label': 'ukr', 'name': 'Ukrainian' },
    { 'label': 'urd', 'name': 'Urdu' },
    { 'label': 'uzb', 'name': 'Uzbek' },
    { 'label': 'uzb_cyrl', 'name': 'Uzbek - Cyrillic' },
    { 'label': 'vie', 'name': 'Vietnamese' },
    { 'label': 'yid', 'name': 'Yiddish' },
  ]

  const [defaultOption, setDefaultOption] = useState(languages.filter(function (el) {
    return el.label === process.env.REACT_APP_DEFAULT_DETECT_TEXT_LANGUAGE
  })[0]);

  return (
    <Dialog
      open={openModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <div>
          <span  style={{ display: 'inline-block',}}>
            <Autocomplete
              id="combo-box-lang"
              options={languages}
              value={defaultOption}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label={t('Document\'s language')} variant="outlined" />}
              onChange={(event, newValue) => {
                if(newValue){
                  setDefaultOption(newValue)
                  dispatch(setDetectTextLanguage(newValue.label))
                  dispatch(launchDetectText())
                }
              }
              }
            />
          </span>
          <span style={{ float: 'right',}}>
            <IconButton color="primary" onClick={() => setOpenModal(false)}>
              <Close />
            </IconButton>
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <TextField
            multiline
            fullWidth
            value={currentDetectedText}
            onChange={(event) => dispatch(setCurrentDetectedText(event.target.value))}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ImageEditor;
