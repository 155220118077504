import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useTranslation}     from 'react-i18next';

import {makeStyles, createTheme} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";
import { NavLink} from "react-router-dom";

import Typography from "@material-ui/core/Typography";

import {
    Grid, Box, IconButton, Card, CardHeader, CardContent, Container, Tabs, Tab
} from "@material-ui/core";

import ViewComfy from "@material-ui/icons/ViewComfy";
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";

import {getCriminalCasesFields} from '../../api/criminalCasesFields';

import ToggleButton from "@material-ui/lab/ToggleButton";
import {selectCriminalCaseById, fetchCriminalCaseById, fetchCriminalCaseUpdate} from "../../store/entities/criminalCasesSlice";
import {
    fetchInvestigations,
    fetchInvestigationUpdate,
    selectInvestigationByCriminalCase
} from "../../store/entities/investigationsSlice";

import {selectCollectionsByCriminalCase} from "../../store/entities/collectionsSlice";
import Collection from "../Collection/Collection.jsx";

import Surveys from "../Survey/Surveys";
import EditableText from "../Collection/Fields/EditableText";
import {Add, BusinessCenter, Cancel, Close, DeleteForever, Edit} from "@material-ui/icons";
import NiceTooltip from "../Misc/CustomToolTip";
import {
    Avatar, CardActions, Dialog, DialogTitle, List, ListItem, ListItemIcon, ListItemText
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {InvestigationsTable} from "../Investigation/Investigations";
import PolicyIcon from "@material-ui/icons/Policy";

const CriminalCase = ({ criminalCaseId }) => {

    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const criminalCase = useSelector(state => selectCriminalCaseById(state, criminalCaseId));

    const [displayMode, setDisplayMode] = useState('tabs');
    const handleDisplayMode = (event, mode) => {setDisplayMode(mode)};

    const [currentTab, setCurrentTab] = React.useState(0);
    const handleCurrentTab = (event, newValue) => setCurrentTab(newValue);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);

    const [editing, setEditing] = useState(false);
    const handleEditing = (event, newValue) => setEditing(newValue);

    const handleClose = () => setAnchorEl(null);
    const collections = useSelector(state => selectCollectionsByCriminalCase(state, criminalCaseId));

    useEffect(()=>{
        dispatch(fetchCriminalCaseById(criminalCaseId));
    }, [criminalCaseId, dispatch]);

    const handleChange = (value, field) =>  {
        let body = {};
        body[field] = value;
        dispatch(fetchCriminalCaseUpdate({
            criminalCaseId: criminalCaseId,
            body: body
        }))
    }

    if (!criminalCase)return <Container disableGutters={true}>Loading</Container>;

    return <Card className={classes.criminalCase} elevation={3}>
        <CardHeader
            avatar={<Avatar>
                <BusinessCenter color={"primary"} />
            </Avatar>}
            title={
                editing
                    ? <EditableText
                        initValue={criminalCase.origin}
                        onChange={handleChange}
                        field='origin'
                        variant='h5'
                        inputClasses={classes.h5input}
                    />
                    : <Typography variant="h5">{criminalCase.origin} -</Typography>
            }
            subheader={
                editing
                    ? <EditableText
                        initValue={criminalCase.summary}
                        onChange={handleChange}
                        field='origin'
                        variant='subtitle1'
                        //inputClasses={classes.h5input}
                    />
                    : <Typography variant="subtitle1">{criminalCase.summary} -</Typography>
            }
            action={
                <ToggleButtonGroup>
                    <NiceTooltip title={t("toggleEditMode")} placement={"bottom-end"}>
                        <ToggleButton onClick={()=>setEditing(e=>!e)} selected={editing} size={"small"}>
                            <Edit />
                            <Typography variant={"subtitle2"} style={{marginLeft:5}}>{t('EditMode')}</Typography>
                        </ToggleButton>
                    </NiceTooltip>
                </ToggleButtonGroup>
            }
        />
        <CardContent>
            <Card>
                <CardHeader title={t('CriminalCaseData')} action={
                    <Grid direction={"row"} container>
                    {displayMode === "tabs" &&
                        <Grid item>
                            <Tabs value={currentTab} onChange={handleCurrentTab} aria-label="simple tabs example">
                                <Tab label={t("CriminalCase-Info")} />
                                <Tab label={t("CriminalCase-Action")} />
                                <Tab label={t("CriminalCase-Legal")} />
                                <Tab label={t("CriminalCase-Admin")} />
                            </Tabs>
                        </Grid>
                    }
                        <Grid item>
                            <ToggleButtonGroup size="small" exclusive value={displayMode} onChange={handleDisplayMode}>
                                <ToggleButton value="tabs" style={{height: 40}}>
                                    <ViewCompactIcon fontSize="small"/>
                                </ToggleButton>
                                <ToggleButton value="grid" style={{height: 40}}>
                                    <ViewComfy fontSize="small"/>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                    </Grid>
                } />
                <CardContent>
                    {displayMode === "tabs"
                        ? <CriminalCaseTabs data={criminalCase} currentTab={currentTab} />
                        : <CriminalCaseGrid data={criminalCase} />
                    }
                </CardContent>
            </Card>
        </CardContent>
        <CriminalCaseInvestigations criminalCaseId={criminalCaseId}/>
        <CardContent>
            <Surveys criminalCaseId={criminalCaseId} simpleTable={false} createButton={true} />
        </CardContent>
        <CardContent>
            {collections.map(collection => {
                return  <Collection collectionId={collection.id} fullPage={false} givenPath={`/cases/${criminalCaseId}/`} />
            })}
        </CardContent>
   </Card>;
};

function CriminalCaseInvestigations({criminalCaseId}) {
    const investigations = useSelector(state => selectInvestigationByCriminalCase(state, criminalCaseId));
    const status = useSelector(state => state.investigations.status);
    const dispatch = useDispatch();
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchInvestigations(null));
        }
    }, [status, dispatch]);

    const {t} = useTranslation();

    const [showDetach, setShowDetach] = useState(false);

    const handleDetach = () => {
        const _investigation = investigations.find(i=>i.id === showDetach)
        if (_investigation) {
            console.log("_investigation", _investigation);
            const _criminalCases = _investigation.criminalCases.filter(c=>c!=criminalCaseId);
            const _params = {
                investigationId: showDetach,
                body: {
                    criminalCases: _criminalCases
                }
            }
            console.log("params", _params);
            dispatch(fetchInvestigationUpdate(_params));
        }
    }

    const handleDelete = () => {

    }

    return <CardContent>
        <Card>
            <CardHeader
                title={<Typography variant={"h5"}>
                    {t('CriminalCaseInvestigation')}
                </Typography>}
                avatar={<Avatar><PolicyIcon color={"primary"} /></Avatar>}
                // action={<ToggleButton size={"small"}>
                //     <Add />
                //     <Typography variant={"caption"}>
                //         {t('CreateNewInvestigation')}
                //     </Typography>
                // </ToggleButton>}
            />
            <CardContent>
                {
                    investigations.length
                        ? <InvestigationsTable
                            investigations={investigations}
                            gridProps={{density:"compact", hideFooter:true}}
                            extraCols={[{field: "op2",           headerName: " ", width: 50, disableColumnMenu: true,
                                renderCell: (params) => (
                                    <IconButton onClick={()=>setShowDetach(params.row.id)} size={"small"} color={"secondary"}>
                                        <Close />
                                    </IconButton>
                                )
                            }]}
                        />
                        : t('NoInvestigationForThisCase')
                }
            </CardContent>
            <CardActions>
                <Button size={"small"} startIcon={<Add />} variant={"contained"} color={"secondary"} disabled={true}>
                    {t('CreateNewInvestigation')}
                </Button>
            </CardActions>
        </Card>
        {showDetach && <Dialog open={true} onClose={()=>setShowDetach(false)} maxWidth={"sm"} fullWidth>
            <DialogTitle style={{backgroundColor: "black", color: "white"}}>
                {investigations.find(i=>i.id===showDetach).name}
            </DialogTitle>
            <List disablePadding>
                <ListItem button onClick={handleDelete}>
                    <ListItemIcon><DeleteForever color={"secondary"} /></ListItemIcon>
                    <ListItemText>{t('InvestigationDelete')}</ListItemText>
                </ListItem>
            </List>
            <List>
                <ListItem button onClick={handleDetach}>
                    <ListItemIcon><Close /></ListItemIcon>
                    <ListItemText>{t('DetachInvestigationFromCase')}</ListItemText>
                </ListItem>
            </List>
            <List>
                <ListItem button onClick={()=>setShowDetach(false)}>
                    <ListItemIcon><Cancel /></ListItemIcon>
                    <ListItemText>{t('Cancel')}</ListItemText>
                </ListItem>
            </List>
        </Dialog>}
    </CardContent>;
}


function InvestigationLink({investigation}) {
    return <NavLink to={`/investigations/${investigation.id}`} >
        {investigation.name}
    </NavLink>;
}

function CriminalCaseGrid({data}) {
    return <Grid container spacing={4}>
            <Grid md={6} xs={12} item>
                <DataDisplayGroup object={data} group="info" />
            </Grid>
            <Grid md={6} xs={12} item>
                <DataDisplayGroup object={data} group="action" />
            </Grid>
            <Grid md={6} xs={12} item>
                <DataDisplayGroup object={data} group="legal" />
                <DataDisplayGroup object={data} group="victim" />
            </Grid>
            <Grid md={6} xs={12} item>
                <DataDisplayGroup object={data} group="admin" />
            </Grid>
        </Grid>;
}

function CriminalCaseTabs({data, currentTab}) {
    return <CardContent>
            <TabPanel currentTab={currentTab} index={0}>
                <DataDisplayGroup object={data} group="info" />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={1}>
                <DataDisplayGroup object={data} group="action" />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={2}>
                <DataDisplayGroup object={data} group="legal" />
                <DataDisplayGroup object={data} group="victim" />
            </TabPanel>
            <TabPanel currentTab={currentTab} index={3}>
                <DataDisplayGroup object={data} group="admin" />
            </TabPanel>
        </CardContent>;
}

function TabPanel({children, currentTab, index, ...other}) {
    return <div
        role="tabpanel"
        hidden={currentTab !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {currentTab === index && (
            <Box>
                {children}
            </Box>
        )}
    </div>;
}

function DataDisplayGroup({object, data, group}) {
    const {t} = useTranslation();
    const fields = getCriminalCasesFields();
    const groupedField = fields.filter(field => field.group === group);
    return groupedField.map(field => {
        return <DataDisplay label={t(`CriminalCase--${field.field}`)} value={object[field.field]} />
    });
}

function DataDisplay({label, value}) {
    const classes = useStyles();
    return <Grid item container classes={classes.dataRow}>
        <Grid xs={4} className={classes.label}>{label}</Grid>
        <Grid xs={8} className={classes.value}>{value}</Grid>
    </Grid>;
}

function CrimininalCaseDaisplay({criminalCase}) {
    return <Grid>

    </Grid>;
}

// Theme
const lightTheme = (prevision = false) => createTheme({
    ...(!prevision ? defaultLightPalette : previsionPalette),
    shape: {
        borderRadius: 10
    }
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    criminalCase: {
        backgroundColor: 'rgba(255,255,255,0.8)'
    },
    dataRow: {
        backgroundColor: "#ccc",
        borderTop: '1px solid #e0e0e0'
    },
    label: {
        color: '#666'
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));

export default CriminalCase;