// ---------------------------------------------------------------------------------------------------------------------
// OBJECT DIALOG
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {addToCollection, deleteObject, selectObjectById, updateObject} from "../../../store/entities/objectsSlice";
import {selectTreeCategoryById} from "../../../store/entities/treeCategoriesSlice";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Tooltip, Typography} from "@material-ui/core";
import WithRightButtons from "../../Misc/WithRightButtons";
import {
    Cancel,
    Collections as CollectionsIcon,
    Delete,
    DeleteForever,
    DeleteOutlineRounded,
    Edit,
    Error,
    MoreVert as MoreVertIcon,
    NavigateBefore,
    NavigateNext,
    RemoveRedEye
} from "@material-ui/icons";
import tdb from "../../../services/TranslateDB";
import {deleteImage, fetchUpdateImage, selectImagesByObject} from "../../../store/entities/imagesSlice";
import {selectInheritedFieldsByCategory} from "../../../store/entities/treeFieldsSlice";
import {selectAllFieldTypes} from "../../../store/entities/fieldTypesSlice";
import Image from "material-ui-image";
import {ObjectData, ObjectField, ObjectThesaurus} from "../Fields/Fields";
import EditableCategory from "../Fields/EditableCategory";
import {
    fetchCollectionById,
    fetchCollections,
    selectAllCollections,
    selectCollectionById,
    updateCollection
} from "../../../store/entities/collectionsSlice";
import {Autocomplete, ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import {selectCategorySelected, setCategorySelected} from "../../Selectors/Drawer/SelectorSlice";
import NiceTooltip from "../../Misc/CustomToolTip";
import EditableText from "../Fields/EditableText";

export default function CollectionObject({collectionId, handleClose, next, prev}) {
    const {objectId} = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation();
    
    
    const [editing, setEditing] = useState(false);

    const object = useSelector(state => selectObjectById(state, objectId));
    const images = useSelector(state => selectImagesByObject(state, objectId));
    const category = useSelector(state => selectTreeCategoryById(state, object ? object.category : null));

    // DELETE
    const [deleteMenu, setDeleteMenu] = useState(false);
    const handleOpenDeleteMenu = () => setDeleteMenu(true);
    const handleCloseDeleteMenu = () => setDeleteMenu(false);
    const handleDelete = (full) => {
        //images.map(image=>{dispatch(fetchUpdateImage({imageId: image.id, body: {object: null}}))})
        dispatch(deleteObject({objectId: objectId}));
        handleClose()
    }
    const handleDeleteAll = (full) => {
        images.map(image=>{dispatch(deleteImage({imageId: image.id}))})
        dispatch(deleteObject({objectId: objectId}))
        handleClose()
    }
    

    const selectedCategory = useSelector(selectCategorySelected)
    useEffect(()=>{
        if (category) {
            dispatch(setCategorySelected(category.id));
        }
    }, [category])

    // JSON
    const [fieldJSON, setFieldJSON] = useState("{}");
    const [openJSON, setOpenJSON] = useState(false);
    const [errorJSON, setErrorJSON] = useState();
    const handleOpenJSON = () => setOpenJSON(true);
    const handleCloseJSON = () => setOpenJSON(false);
    const handleFieldJSON = (event) => {
        setFieldJSON(event.target.value)
    }
    useEffect(()=>{
        if (object && object.fields) {
            setFieldJSON(JSON.stringify(object.fields, null, 2));
        }
    }, [object])

    useEffect(()=>{
        if (fieldJSON) {
            try {
                const parse = JSON.parse(fieldJSON);
                setErrorJSON(false)
            }
            catch (e) {
                setErrorJSON(true)
            }
        }
    }, [fieldJSON])
    
    const handleChange = (value, field) =>  {
        let body = {};
        body[field] = value;
        dispatch(updateObject({
            objectId: objectId,
            body: body
        }))
    }
    
    const handleSaveJSON = () => {
        const _params = {
            objectId: objectId,
            body: {
                fields: JSON.parse(fieldJSON)
            }
        };
        console.log(_params);
        dispatch(updateObject(_params))
    }

    // COPY
    const [copyMenu, setCopyMenu] = useState(false);
    const handleOpenCopyMenu = () => setCopyMenu(true);
    const handleCloseCopyMenu = () => setCopyMenu(false);

    if (!object)return null;

    return <Dialog
        classes={{scrollPaper: classes.scrollPaper}}
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
    >
        <DialogTitle>
            <WithRightButtons
                actions={
                    <>
                        <ToggleButtonGroup>
                            <NiceTooltip title={t("previousObjectInCollection")}>
                                <ToggleButton onClick={() => prev(objectId)}>
                                    <NavigateBefore />
                                </ToggleButton>
                            </NiceTooltip>
                            <NiceTooltip title={t("nextObjectInCollection")}>
                                <ToggleButton onClick={() => next(objectId)}>
                                    <NavigateNext />
                                </ToggleButton>
                            </NiceTooltip>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup className={classes.formGroup}>
                            <NiceTooltip title={t("editObject")}>
                                <ToggleButton selected={editing} onClick={()=>setEditing(e=>!e)}>
                                    <Edit />
                                </ToggleButton>
                            </NiceTooltip>
                        </ToggleButtonGroup>
                    </>
                }
            >
                <Typography variant={"h5"}>
                    {editing
                        ? <EditableText
                            initValue={object.name}
                            onChange={handleChange}
                            field='name'
                            variant='h5'
                            inputClasses={classes.h5input}
                        />
                        : object.name}
                </Typography>
            </WithRightButtons>
        </DialogTitle>
        <DialogContent>
            {/* Images and Fields object */}
            <FullObject objectId={objectId} editing={editing} />
            {/* Collections object */}
            {object.collections.map(objectCollectionId => {
                return <ObjectCollection collectionId={objectCollectionId} objectId={objectId} currentCollection={collectionId} />;
            })}
        </DialogContent>
        <DialogActions style={{borderTop: '1px solid #ccc'}}>
            <Box flexGrow={1}>
                <Button variant={"contained"} color={"default"} onClick={handleOpenDeleteMenu}>
                    {t('DeleteObject')}
                </Button>
                <Dialog open={deleteMenu} onClose={handleCloseDeleteMenu}>
                    <DialogTitle>Delete</DialogTitle>
                    <List>
                        <ListItem button color="secondary" onClick={handleDelete}>
                            <ListItemIcon><Delete /></ListItemIcon>
                            <ListItemText>{t('DeleteObjectAndLeaveImages')}</ListItemText>
                        </ListItem>
                        <ListItem button onClick={handleDeleteAll}>
                            <ListItemIcon><DeleteForever /></ListItemIcon>
                            <ListItemText>{t('DeleteObjectAndImage')}</ListItemText>
                        </ListItem>
                        <ListItem button onClick={handleCloseDeleteMenu}>
                            <ListItemIcon><Cancel /></ListItemIcon>
                            <ListItemText>{t('Cancel')}</ListItemText>
                        </ListItem>
                    </List>
                </Dialog>
                {editing && <Button variant={"contained"} onClick={handleOpenJSON}>Edit JSON</Button>}
                <Dialog open={openJSON} onClose={handleCloseJSON} maxWidth={"sm"} fullWidth>
                    <DialogTitle>JSON</DialogTitle>
                    <DialogContent>
                        <TextField error={errorJSON} variant={"outlined"} value={fieldJSON} onChange={handleFieldJSON} multiline fullWidth />
                    </DialogContent>
                    <DialogActions>
                        {!errorJSON && <Button onClick={handleSaveJSON}>{t('Save')}</Button>}
                    </DialogActions>
                </Dialog>
            </Box>
            <Box>
                <Button variant={"contained"} color={"default"} onClick={handleOpenCopyMenu}>
                    {t('CopyToOtherCollection')}
                </Button>
                {copyMenu && <ObjectCopyToCollection objectId={objectId} onClose={handleCloseCopyMenu} />}
                <Button variant={"contained"} color={"default"}>
                    {t('identifyObject')}
                </Button>
            </Box>
        </DialogActions>
    </Dialog>
}

// ---------------------------------------------------------------------------------------------------------------------
// OBJECT DATA (without collections)
export function FullObject({objectId, editing}) {
    const classes = useStyles();
    const fieldClasses = useFieldStyle();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const object = useSelector(state => selectObjectById(state, objectId));
    const images = useSelector(state => selectImagesByObject(state, objectId));
    const category = useSelector(state => selectTreeCategoryById(state, object.category));
    const fields = useSelector(state => selectInheritedFieldsByCategory(state, object.category));
    const types = useSelector(selectAllFieldTypes);
    const [currentImage, setCurrentImage] = useState(0);
    const handleCurrentImage = (index) => setCurrentImage(index)

    // UPDATE OBJECT
    const handleChange = (body) =>  {
        console.log({onChange: body})
        dispatch(updateObject({
            objectId: objectId,
            body: body
        }))
    }
    // UPDATE OBJECT FIELD
    const handleChangeField = (value, field) =>  {
        let body = {fields:{}};
        body.fields[field] = value;
        console.log(body)
        dispatch(updateObject({
            objectId: objectId,
            body: body
        }))
    }

    return <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    {
                        images.length === 0
                            ? <Image animationDuration={500} src={"/error"} />
                            : <Image animationDuration={500} src={images[currentImage].urls.big} />
                    }
                    
                    
                </Grid>
                {images.length > 1 && images.map((image, i)=>{
                    return <Grid item xs={4} onClick={()=>handleCurrentImage(i)}>
                        <Image alt="" src={image.urls.small} draggable={false} className={classes.media} />
                    </Grid>
                })}
            </Grid>
        </Grid>
        <Grid item sm={8} xs={12}>
            <ObjectData name={t('ObjectCategory')} value={
                editing
                    ?   <EditableCategory
                            initValue={category ? category.id : null}
                            field={"categoryId"}
                            onChange={handleChange}
                        />
                    : <Typography classes={fieldClasses}>
                        {category ? category.name : 'Unknown'}
                    </Typography>
            } />
            <ObjectData name={t('DateCreated')} value={`${moment(object.created).format('DD/MM/YYYY hh:mm')} (${moment(object.created).fromNow()})`} />
            <hr/>
            {fields.map((field, i) => {
                const type = types.find(type => type.id === field.tree_field_type_id)
                return type && type.is_thesaurus
                    ? <ObjectThesaurus
                        key={`${field.name}-${i}`}
                        field={field}
                        values={object.fields}
                        editing={editing}
                        update={handleChangeField}
                        textClasses={fieldClasses}
                        cols={[4,7,1]}
                    />
                    : <ObjectField
                            key={`${field.name}-${i}`}
                            field={field}
                            values={object.fields}
                            editing={editing}
                            update={handleChangeField}
                            textClasses={fieldClasses}
                            type={type}
                            cols={[4,7,1]}
                    />
            })}
        </Grid>
    </Grid>
}

// ---------------------------------------------------------------------------------------------------------------------
// COLLECTION OF OBJECT
function ObjectCollection({collectionId, objectId, currentCollection}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const collection = useSelector(state => selectCollectionById(state, collectionId));
    const object = useSelector(state => selectObjectById(state, objectId));
    const history = useHistory();

    useEffect(()=>{
        if (!collection) {
            dispatch(fetchCollectionById(collectionId));
        }
    }, [collectionId, collection]);

    const handleViewInCollection = () => {
        history.push("/collections/"+collectionId+"/object/"+objectId);
    }

    const [removeMode, setRemoveMode] = useState(false);
    const handleRemoveFromCollection = () => {
        // TODO Finish handleRemoveFromCollection
    }

    if (collection) {
        return <Box className={classes.objectCollection}>
            <WithRightButtons
                actions={
                    <ToggleButtonGroup size={"small"}>
                        <NiceTooltip title={t("seeObjectInCollection")}>
                            <ToggleButton onClick={handleViewInCollection} size={"small"} selected={collectionId === currentCollection}>
                                <RemoveRedEye size={"small"} />
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t('DetachObjectFromCollection')}>
                            <ToggleButton onClick={()=>setRemoveMode(true)} disabled={object.collections.length === 1 || collectionId === currentCollection}>
                                <DeleteOutlineRounded />
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>
                }
                content={<Typography variant={"h5"}>{collection.name}</Typography>}
            />
            {collection.fields && Object.entries(collection.fields).map(([fieldName, fieldValue])=>{
                return <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <strong>{fieldName}</strong>
                    </Grid>
                    <Grid item xs={8}>
                        {fieldValue}
                    </Grid>
                </Grid>;
            })}
            <Dialog open={removeMode} onClose={()=>setRemoveMode(false)}>
                <DialogTitle>{t('ConfirmDetachObjectFromCollection')}</DialogTitle>
                <DialogActions>
                    <Button variant={"contained"} color={"default"} onClick={()=>setRemoveMode(false)} >
                        {t('Cancel')}
                    </Button>
                    <Button variant={"contained"} color={"secondary" } >
                        {t('Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>;
    }
    else return null;
}

// CONTEXTUAL MENU OF OBJECT COLLECTION
function ObjectCollectionActions({objectId, collectionId, currentCollection}) {
    const object = useSelector(state => selectObjectById(state, objectId));
    const history = useHistory();
    const collection = useSelector(state => selectCollectionById(state, collectionId));
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const actions = [];

    // move to other collection only if different
    if (collectionId !== currentCollection) {
        const handleViewInCollection = () => {
            handleClose();
            history.push("/collections/"+collectionId+"/object/"+objectId);
        }
        actions.push(<MenuItem onClick={handleViewInCollection}>
            <ListItemIcon>
                <CollectionsIcon />
            </ListItemIcon>
            <ListItemText>
                {t('seeObjectInCollection')}
            </ListItemText>
        </MenuItem>)
    }

    // remove from collection only if there's anotherone
    if (object.collections.length > 1) {
        actions.push(<MenuItem onClick={handleClose}>
            <ListItemIcon>
                <DeleteOutlineRounded />
            </ListItemIcon>
            <ListItemText>
                {t('removeFromCollection')}
            </ListItemText>
        </MenuItem>);
    }

    if (!actions.length)return null;
    return <div>
        <IconButton aria-label="settings" onClick={handleClick}>
            <MoreVertIcon />
        </IconButton>
        <Menu id="investigation-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {actions.map(action=>action)}
        </Menu>
    </div>
}

// COPY OBJECT TO OTHER COLLECTION MODAL
function ObjectCopyToCollection({objectId, onClose}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    // const collection = useSelector(state => selectCollectionById(state, collectionId));
    const object = useSelector(state => selectObjectById(state, objectId));

    const status = useSelector(state => state.collections.status);
    const collections = useSelector(selectAllCollections);
    // const collectionTypes = useSelector(selectAllCollectionTypes);
    // const collectionTypeIds = useSelector(selectCollectionTypeIds);

    useEffect(()=>{
        if (status === 'idle')dispatch(fetchCollections(null));
    }, [dispatch, status])

    // TODO : parameters on which collection can be filled
    const filteredCollections = collections.filter(collection => {
        return object.collections.indexOf(collection.id) === -1
    });

    const [destinationId, setDestinationId] = useState();
    const handleDestinationId = (event, value) => setDestinationId(value.id);
    const handleCopy = () => {
        const params = {
            body: {
                collection_hash: destinationId,
                object_hash: objectId
            }
        };
        dispatch(addToCollection(params));
    }

    return <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
            {t('Copy Object')}
        </DialogTitle>
        <DialogContent>
            <Autocomplete
                id={"destinationCollection"}
                label={t("ChooseDestinationCollection")}
                renderInput={(params) => <TextField {...params} title={t("ChooseDestinationCollection")} variant={"outlined"} label={t("ChooseDestinationCollection")} />}
                getOptionLabel={(option) => option.name}
                value={destinationId}
                onChange={handleDestinationId}
                options={filteredCollections} />
        </DialogContent>
        <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleCopy}>Copy</Button>
        </DialogActions>
    </Dialog>
}

const useFieldStyle = makeStyles(theme=>({
    root: {
        fontSize: ".9rem",
    },
    input: {
        fontSize: ".9rem",
    },
}));

// style of the layout
const useStyles = makeStyles((theme) => ({
    badge: {

    },
    field: {
        fontSize: ".9rem",
        color: '#FF0000'
    },
    fieldClass: {
        root: {
            fontSize: ".9rem",
            color: '#00FF00'
        }
    },
    fieldNotEdited: {
        root: {
            fontSize: ".9rem"
        }
    },
    fieldEdited: {
        input: {
            fontSize: ".9rem"
        }
    },
    scrollPaper: {
        alignItems: 'baseline'
    },
    objectCollection: {
        marginTop: theme.spacing(3),
        borderTop: "1px dashed #666",
        paddingTop: theme.spacing(1)

    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    media: {
        height: '100%',
        width: '100%',
        backgroundColor: 'black'
    },
    formGroup: {
        marginLeft: "1rem"
    }
}));
