import React from "react";
import {Card, CardContent, CardHeader, Grid, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {selectThesaurusSelected, setThesaurusSelected} from "./TreeEditorSlice";
import tdb from "../../../services/TranslateDB";
import {selectSubThesaurus, selectTreeThesaurusById} from "../../../store/entities/treeThesaurusSlice";
import {ToggleButton, TreeItem, TreeView} from "@material-ui/lab";
import {ChevronRight, Close, ExpandMore, LabelImportant} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";


export function Thesaurus() {
    const thesaurusId = useSelector(selectThesaurusSelected);
    const thesaurus = useSelector(state => selectTreeThesaurusById(state, thesaurusId))
    
    return <Grid item xs={12}>
        <Card>
            <CardHeader
                title={tdb(thesaurus.title, thesaurus.name)}
                action={<ToggleButton>
                    <Close />
                </ToggleButton>}
            />
            <CardContent>
                <ThesaurusRootTree thesaurusId={thesaurusId} />
            </CardContent>
        </Card>
    </Grid>
}


export function ThesaurusRootTree({thesaurusId, onSelect = ()=>{}}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    return <TreeView
        className={classes.tree}
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        //onNodeToggle={handleToggle}
        onNodeSelect={onSelect}
        //defaultExpanded={['thes'+selectedThesaurus, 0]}
    >
        <ThesaurusLeaf thesaurusId={thesaurusId} index={0} />
    </TreeView>;
}

export function ThesaurusLeaf({thesaurusId, index}) {
    
    const thesaurus = useSelector(state => selectTreeThesaurusById(state, thesaurusId));
    const subThesauruses = useSelector(state => selectSubThesaurus(state, thesaurusId));
    
    const dispatch = useDispatch();
    
    const classes = useStyles();
    return <TreeItem
        nodeId={thesaurus.id}
        label={
            <div className={classes.labelRoot}>
                <LabelImportant className={classes.labelIcon} />
                <Typography className={classes.labelText}>
                    {tdb(thesaurus.title, thesaurus.name)}
                </Typography>
            </div>
            
        }
    >
        {subThesauruses.map((subThesaurus, index) => {
            return <ThesaurusLeaf thesaurusId={subThesaurus.id} index={index} />
        })}
    </TreeItem>
}

const useStyles = makeStyles((theme) => ({
    autoScroll: {
        maxHeight: 600,
        overflow: 'auto'
    },
    list: {
        padding: 0,
    },
    listItem: {
        padding: 0,
    },
    listItemSelected: {
        backgroundColor: '#909090'
    },
    listItemIcon: {
        minWidth: 30,
    },
    listItemText: {
        // display: "flex",
        // flexDirection: "row",
        marginTop:0,
        marginBottom:0,
    },
    listItemSec: {
        right: 0
    },
    root: {
        flexGrow: 1
    },
    tree: {
        fontSize: 1
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'row',
    },
    labelIcon: {
        marginRight: theme.spacing(1),
        color: "#999"
    },
    labelText: {
        flexGrow: 1
    },
    field: {
        //color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    fieldSelected: {
        backgroundColor: '#ccc',
    },
    categories: {
        height: '100%',
        overflow: 'auto'
    },
    paper: {
        height: 100,
    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));