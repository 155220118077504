import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch } from "react-redux";
import {toggleSelectedResult} from "../../store/entities/searchSlice";

export default function SelectedResult({ content }) {
    const classes = useStyles();
    const dispatch = useDispatch()

    function handleClick(){
      let data = {
        'id': content.id
      }
      dispatch(toggleSelectedResult(data))
    }


    let action = <ClearIcon onClick={handleClick} />;
    return content ? <Card elevation={3} className={classes.selectedCard} >
       <CardHeader className={classes.selectedCardHeader} title={<Typography className={classes.typographyHeader}>{content.title}</Typography>} action={action} />
       <CardContent>
           <Grid container spacing={4}>
               <Grid item xs={12}><img src={content.img} className={classes.image}/></Grid>
           </Grid>
       </CardContent>
   </Card> : null ;
};


// style of the layout
const useStyles = makeStyles((theme) => ({
  image: {
      width: '100%',
      height: 'auto'
  },
  selectedCard: {
    marginBottom: '10px'
  },
  typographyHeader: {
    maxHeight: '25px',
    overflow: 'hidden'
  },
  selectedCardHeader: {
    paddingBottom: "0px !important"
  }
}));
