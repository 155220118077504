import React, {useEffect, useState} from 'react';
import {
    LinearProgress,
    Card,
    CardHeader,
    CardActions,
    CardContent,
    Button,
    useTheme,
    Grid,
    IconButton,
    Typography, Avatar
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {selectAllQueries, fetchQueries} from "../../store/entities/queriesSlice";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useHistory} from "react-router-dom";
import {DataGrid} from "@material-ui/data-grid";
import tdb from "../../services/TranslateDB";
import {stackCardHeight} from "../Dimensions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {isMobile}           from "react-device-detect";
import NiceTooltip from "../Misc/CustomToolTip";
import {OpenIcon} from "../Misc/IconSet";
import QueryCreate from "./QueryCreate";
import VisibilityIcon from "@material-ui/icons/Visibility";


const Queries = () => {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    
    const status = useSelector(state => state.queries.status);
    const queries = useSelector(selectAllQueries);
    
    useEffect(()=>{
        if (status === 'idle') {
            dispatch(fetchQueries(null));
        }
    }, [status, dispatch]);

    const getViewCell = (params) => {
        /* to={'/queries/'+params.value} component={NavLink}*/
        return <NiceTooltip title={t('See')}>
            <IconButton disabled variant="contained" color="secondary" size="small">
                <OpenIcon />
            </IconButton>
        </NiceTooltip>
    };

    const columns = [
        {field:"Date",      headerName:t("Date"),          flex:1, resizable: false },
        {field:"Nom",       headerName:t("Name"),           flex:2, resizable: false },
        {field:"Theme",     headerName:t("Theme"),         flex:2, resizable: false },
        {field:"Typologie", headerName:t("Typologies"),     flex:2, resizable: false },
        {field:"Objets",    headerName:t("Object_other"),        flex:1, resizable: false },
        {field:"Cibles",    headerName:t("Targets"),        flex:1, resizable: false },
        {field: "id",       headerName: " ",            width:80, renderCell: getViewCell, resizable: false, align: 'right' }
    ];
    
    const handleRowClick = (params) => {
        //history.push('/collections/'+params.row.id);
    }

    const filtered = queries.map(query => {
        return {
            ...query,
            Nom: tdb(query.Nom),
            Theme: tdb(query.Theme),
            Typologie: tdb(query.Theme),
            Objets: tdb(query.Objets),
            Cibles: tdb(query.Cibles),
        }
    })
    
    // stack display
    const theme = useTheme();
    const [stacked, setStacked] = useState(!isMobile);
    useEffect(()=>{setStacked(!isMobile)}, [isMobile])
    const { height: screenHeight, width: screenWidth } = useWindowDimensions();
    const [contentHeight, setContentHeight] = useState(100);
    useEffect(()=>{
        setContentHeight(screenHeight
            - stackCardHeight.header
            // - stackCardHeight.search
            - stackCardHeight.navBar
            - stackCardHeight.actions
            - stackCardHeight.bread
            - 2 * theme.spacing(2)
        )
    }, [screenHeight, screenWidth, stacked]);
    
    const [createMode, setCreateMode] = useState();

    return <Card elevation={3}>
        <CardHeader
            title={<Typography variant={"h5"}>{t('Queries')} </Typography>}
            avatar={<Avatar><VisibilityIcon  color={"primary"} /></Avatar>}
            className={classes.mainCardHeader}
        />
        {/*<CardContent className={classes.mainCardSearch}></CardContent>*/}
        <CardContent className={classes.mainCardContent}>
            <div style={{
                height:stacked ? contentHeight : 'auto',
                overflowY: stacked ? 'auto' : 'visible'
            }}>
            {
                status === "loading"
                    ? <LinearProgress variant={"indeterminate"} color={"secondary"} />
                    : <DataGrid
                        columns={columns}
                        rows={filtered}
                        pageSize={100}
                        hideFooter={filtered.length<100}
                        density={"compact"}
                        onRowDoubleClick={handleRowClick}
                        autoHeight={isMobile}
                    />
            }
            </div>
        </CardContent>
        <CardActions className={classes.mainCardActions}>
            <Grid container justifyContent="flex-end">
                <Button title={t('CreateNewQuery')} variant="contained" color="secondary" onClick={()=>setCreateMode(true)}>
                    {t('CreateNewQuery')}
                </Button>
                {createMode && <QueryCreate onClose={()=>setCreateMode(false)} />}
            </Grid>
        </CardActions>
    </Card>
};

export default Queries;

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    mainCardHeader: {height: stackCardHeight.header},
    mainCardSearch: {height: stackCardHeight.search},
    mainCardActions: {height: stackCardHeight.actions},
    mainCardContent: {
        paddingTop: 0,
        paddingBottom: 0
    },
}));