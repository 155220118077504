import React, {useEffect, useRef} from "react";
import { useDrop } from 'react-dnd';
import {useDispatch, useSelector} from "react-redux";
import {createObject, updateObject} from "../../../store/entities/objectsSlice";
import {selectRootCategories} from "../../../store/entities/treeCategoriesSlice";
import * as colors from "./Borders";

export default  ({collectionId, children, categoryId, className, handleSelect, onUpdate, overObjects, selection}) => {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const rootCategories = useSelector(selectRootCategories);

    // ---------------------------------------------------------------------------------------
    // drop Image > create object
    const [{canDrop: canDropImage, isOver: isOverImage}, connectDropImage] = useDrop({
        accept: 'image',
        drop: (item, monitor) => {
            if  (!monitor.didDrop()) {
                if (item.images && item.images.length > 1) {
                    item.images.forEach(image=>{
                        dispatch(createObject({
                            collectionId: collectionId,
                            imageId: image.id,
                            title: image.originalFilename
                                .replace(/\.([a-z]|[A-Z])+$/, '')
                                .replace(/_/g, ' '),
                            categoryId: categoryId ? categoryId : rootCategories[0].id
                        }));
                    })
                }
                else {
                    dispatch(createObject({
                        collectionId: collectionId,
                        imageId: item.name,
                        title: item.filename,
                        categoryId: categoryId ? categoryId : rootCategories[0].id
                    }));
                }
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({shallow: true}),
            canDrop: monitor.canDrop(),
        }),
    });

    useEffect(()=>{
        connectDropImage(ref);
    }, [connectDropImage])

    //
    // drop object > change category
    const [{canDrop: canDropObject, isOver: isOverObject, item}, connectDropObject] = useDrop({
        accept: 'object',
        drop: (item, monitor) => {
            if  (!monitor.didDrop()) {
                if (selection.length && selection.includes(item.name)) {
                    selection.forEach(objectId=>{
                        dispatch(updateObject({
                            objectId: objectId,
                            body: {
                                categoryId: categoryId
                            }
                        }))
                        handleSelect(objectId)
                    })
                }
                else {
                    dispatch(updateObject({
                        objectId: item.name,
                        body: {
                            categoryId: categoryId
                        }
                    }))
                }
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({shallow: true}),
            canDrop: monitor.canDrop(),
            item: monitor.getItem()
        }),
    });

    useEffect(()=>{
        connectDropObject(ref);
    }, [connectDropObject])


    // // drop Category
    // const [{canDrop: canDropCategory, isOver: isOverCategory}, connectDropCategory] = useDrop({
    //     accept: 'category',
    //     drop: (item, monitor) => {
    //         if  (!monitor.didDrop()) {
    //             alert('drop 1')
    //             if (onUpdate) {
    //                 alert('drop 2')
    //                 onUpdate(item.name)
    //             }
    //         }
    //     },
    //     collect: (monitor) => ({
    //         isOver: monitor.isOver({shallow: true}),
    //         canDrop: monitor.canDrop(),
    //     }),
    // });
    // connectDropCategory(ref);



    let isActive = ((isOverImage && canDropImage) || (isOverObject && canDropObject && item && item.categoryId !== categoryId)); //  || (isOverCategory && canDropCategory)
    let border= '2px solid transparent';
    if (isActive) {
        border= colors.activeColor;
    }
    else if (canDropImage) { // || canDropCategory
        border= colors.canDropColor;
    }
    return (
        <div
            ref={ref}
            key={collectionId}
            style={{...style, border}}
            className={className}
        >
            {children}
        </div>
    );
}

const style = {

};