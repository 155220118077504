import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';

import client from '../../api/client_ai';

export const tabNames = {
  // police_test: {
  //     label: "Police Test",
  //     groupBy:null,
  //     position:1
  // },
  // looted: {
  //     label: "Objets spoliés",
  //     groupBy:null,
  //     position:1
  // },
  police: {
    label: "Police",
    groupBy: null,
    position: 2
  },
  ecommerce: {
    label: "Sites Marchands",
    groupBy: null,
    position: 2
  },
  positive_database: {
    label: "Bases Positives",
    groupBy: "loca",
    groupName: "Base",
    position: 2,
    exclude: ['id', 'image', 'geolocalisation_ville','additionalImages', 'constituents', 'measurements', 'tags']
  },
  // redLists :    {
  //     label: "Red List Old",
  //     groupBy:"red_list",
  //     groupName: "Red List",
  //     exclude: ['img', 'image', 'id', 'dist']
  // },
  redListInt: {
    label: "Red List",
    groupBy: "provenance",
    groupName: "provenance",
    position: 2
  },
  smartBrowser: {
    label: "Smart Browser",
    groupBy: "joinedCategories",
    groupName: "Categorie",
    position: 2
  },
};



export const launchSearch = createAsyncThunk(
  'search/launchSearch',
  async (parameters) => {
    let request_result = {};
    if (typeof parameters["result"] != "undefined") {
      request_result = parameters["result"]
    } else {
      const body = {
        fullImage: parameters.fullImage,
        url: parameters.url,
        precision: { value: 1 },
        searchText: parameters.searchText,
        searchData: parameters.searchData,
        indexes: parameters.indexes ? parameters.indexes : null
      };
      const response = await client.post('/identification/json', body);
      request_result = response.result
    }
    // const response = await client.post('/identification', body);
    var response = {};
    var result = {};
    var tabsOrder = [
      // 'police_test',
      //'looted',
      'police',
      'ecommerce',
      'positive_database',
      'redListInt',
      'smartBrowser'];
    tabsOrder.forEach(function (item, index) {
      if (request_result[item]) {
        result[item] = request_result[item]
      }
    });
    var bestMatch = null;
    var dist = 1;
    for (var key in request_result) {
      if (request_result[key].length > 0 && request_result[key][0]["dist"] < dist) {
        dist = request_result[key][0]["dist"]
        bestMatch = key
      }
    };
    response.search = result
    response.bestMatch = bestMatch
    return response;
  });


export const imageSave = createAsyncThunk(
  'search/imageSave',
  async (parameters) => {
    const body = {
      fullImage: parameters.fullImage,
    };
    const response = await client.post('/addImage/json', body);
    return response.data;
  });

// Creating the slice
const searchSlice = createSlice({
  name: 'search',
  initialState: {
    status: 'idle',
    search: { search: {} },
    selectedResults: []
  },
  reducers: {
    searchInit(state) {
      state.status = 'idle';
      state.search = null;
      state.selectedResults = []
    },
    clearSearch(state) {
      state.status = 'idle';
      state.search = null;
      state.selectedResults = []
    },
    toggleSelectedResult(state, data) {
      var data_full;
      for (var key in state.search.search) {
        for (var index in state.search.search[key]) {
          if (state.search.search[key][index].id == data.payload.id) {
            data_full = state.search.search[key][index]
          }
        }
      }
      if (state.selectedResults.findIndex(x => x.id === data.payload.id) === -1) {
        state.selectedResults.push(data_full)
      } else {
        state.selectedResults.splice(state.selectedResults.findIndex(x => x.id === data.payload.id), 1)
      }
    }
  },
  extraReducers: {
    [launchSearch.pending]: (state, action) => {
      state.status = 'loading';
    },
    [launchSearch.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [launchSearch.fulfilled]: (state, action) => {
      state.status = 'success';
      state.search = action.payload
    },
  },
});


export const { searchInit, clearSearch, toggleSelectedResult, isInSelectedResults } = searchSlice.actions;
export default searchSlice.reducer

export const selectSearch = (state) => {
  return state.search
};
