import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    Avatar, Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@material-ui/core";
import {Edit, Label, Add, ImportExport, More} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";

import store from "../../../store/store";
import tdb from "../../../services/TranslateDB";
import NiceTooltip from "../../Misc/CustomToolTip";

import {selectPathOfCategory, selectSubCategories, selectTreeCategoryById} from "../../../store/entities/treeCategoriesSlice";
import {selectCategorySelected, setCategoryEdited, setCategorySelected} from "./TreeEditorSlice";
import {InheritedFields} from "./TreeEditorCatFields";
import CreateCategory from "./TreeEditorCategoryCreate";

const cat = (id) => (store.getState().treeCategories.entities[id])

export function TreeCategory({categoryId, cols}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    
    const category = useSelector(state => selectTreeCategoryById(state, categoryId));
    const subCategories = useSelector(state => selectSubCategories(state, categoryId));
    const path = useSelector(state => selectPathOfCategory(state, categoryId));
    
    const selectedCategory = useSelector(selectCategorySelected);
    const handleSelectCategory = (id) => dispatch(setCategorySelected(id))
    const handleEditCategory = () => dispatch(setCategoryEdited(categoryId))

    const [sortMode, setSortMode] = useState(false);
    const [createMode, setCreateMode] = useState();
    
    if (!category)return null;


    return <Grid item xs={12}>
        <Card elevation={2}>
            <CardHeader
                style={{alignItems: 'center'}}
                avatar={<Avatar>
                    <Label color={"primary"} fontSize={"large"} />
                </Avatar>}
                subheader={<Typography variant={"h5"}>{tdb(category.title, category.name)}</Typography>}
                title={<Typography variant={"subtitle1"}>{
                    path.length > 0 && path
                        .reverse()
                        .map((p,i) => <><Link href='#' underline={"none"} onClick={() => handleSelectCategory(p)}>
                            {i ? tdb(cat(p).title) : 'Obj'}
                        </Link> / </>)
                }
                </Typography>}
                action={
                    <ToggleButtonGroup>
                        <NiceTooltip title={t("TreeEditCategory")} placement={"bottom-end"}>
                            <ToggleButton size={"small"} onClick={handleEditCategory}><Edit /></ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>
                }
            />
            <CategoryData categoryId={categoryId} />
            <CardHeader
                title={<Typography variant={"h5"}>{t('SubCategories')}</Typography>}
                action={
                    <ToggleButtonGroup>
                        <NiceTooltip title={t("ReorderMode")} placement={"bottom-end"}>
                            <ToggleButton size={"small"} selected={sortMode} onClick={()=>setSortMode(s=>!s)}>
                                <ImportExport />
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("TreeAddSubCategory")} placement={"bottom-end"}>
                            <ToggleButton size={"small"} onClick={()=>setCreateMode(true)}><Add /></ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>
                }
                style={subCategories.length > 0 ? {paddingBottom : 0} : {}}
            />
            {createMode && <CreateCategory parentId={categoryId} onClose={()=>setCreateMode(false)} />}
            {subCategories.length > 0 && <CardContent style={{paddingTop: 3}}>
                <List
                    disablePadding
                    className={classes.list}
                >
                    {subCategories.map((subCategory, i)=>(
                        <ListItem
                            button
                            onClick={()=>handleSelectCategory(subCategory.id)}
                            className={classes.listItem}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <Label className={classes.labelIcon} />
                            </ListItemIcon>
                            <ListItemText className={classes.listItemText}>
                                {tdb(subCategory.title)}
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>

            </CardContent>}
            {/*<CardContent>*/}
            {/*    {!!selectedCategory && <InheritedFields categoryId={selectedCategory} />}*/}
            {/*</CardContent>*/}
        </Card>
    </Grid>
}

function CategoryData({categoryId}) {
    const classes = useStyles();
    const category = useSelector(state => selectTreeCategoryById(state, categoryId));
    const content1 = [
        <CategoryDataItem label={"Source"} value={category.source_name} />,
        <CategoryDataItem label={"Tree"} value={category.thesaurus} />,
        <CategoryDataItem label={"Branch"} value={category.branch} />,
    ]
    const content2 = [
        <CategoryDataItem label={"WikiData"} value={category.wiki_data_ref} url={category.wiki_data_url} />,
        <CategoryDataItem label={"AAT"} value={category.aat_ref} url={category.aat_url} />,
        <CategoryDataItem label={"translations"} value={Object.keys(category.title).length} />,
        // <CategoryDataItem label={"ID"} value={categoryId} />,
    ]
    
    return <CardContent>
        <Box>
            <List className={classes.list}>
                {content1}
                {content2}
            </List>
        </Box>
        <Grid container spacing={2}>
            
            {/*<Grid item xs={12}>
                <List className={classes.list}>
                    {content1}
                </List>
            </Grid>
            <Grid item xs={12} style={{borderLeft: '1px solid #999'}}>
                <List className={classes.list}>
                    {content2}
                </List>
            </Grid>*/}
        </Grid>

    </CardContent>
}

function CategoryDataItem({label, value, url}) {
    const classes = useStyles();
    const {t} = useTranslation();
    if (!value && url===null)return null;
    if (!value && url)value = url;
    if (value && url) value = <Link underline={'always'} href={url} target={'_blank'}>{value}</Link>
    return value ? <ListItem className={classes.listItem}>
        <ListItemText className={classes.listItemText}>
            <Box flexDirection={"row"} display={"flex"}>
                <Box flexGrow={1} overflow={"hidden"}>{label}</Box>
                <Box>{value === true
                    ? t("yes")
                    : value
                }</Box>
            </Box>
        </ListItemText>
    </ListItem> : null
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    list: {
        padding: 0,
    },
    listItem: {
        padding: 0,
        fontSize: '.5rem !important'
    },
    listItemIcon: {
        minWidth: 30,
    },
    listItemText: {
        marginTop:0,
        marginBottom:0,
        fontSize: '.5rem !important'
    },
}));