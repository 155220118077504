import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter
} from '@reduxjs/toolkit';
import client from '../../api/client';
import {fetchCriminalCaseById, selectAllCriminalCases} from "./criminalCasesSlice";
import {tasksFetched} from './tasksSlice';
import {fetchCollectionById} from "./collectionsSlice";
import {selectAllTreeCategories, selectSubCategories, selectTreeCategoryById} from "./treeCategoriesSlice";

const treeThesaurusesAdapter = createEntityAdapter();
const initialState = treeThesaurusesAdapter.getInitialState({
    status: 'idle',
    error: null,
});

// fetch all treeThesauruses
export const fetchTreeThesauruses = createAsyncThunk(
    'treeThesauruses/fetchTreeThesauruses',
    async (parameters, thunkAPI) => {
        const response = await client.get('/tree/thesaurus/all');
        return response.thesauruses;
    }
);

// THE REDUCERS
const treeThesaurusesSlice = createSlice({
    name: 'treeThesauruses',
    initialState,
    reducers: {

    },
    extraReducers: {
        [fetchTreeThesauruses.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchTreeThesauruses.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            // Add any fetched treeThesauruses to the array
            treeThesaurusesAdapter.upsertMany(state, action.payload);
        },
        [fetchTreeThesauruses.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        }
    },
});

// export const {  } = treeThesaurusesSlice.actions;

export default treeThesaurusesSlice.reducer

export const {
    selectAll: selectAllTreeThesauruses,
    selectById: selectTreeThesaurusById,
    selectIds: selectTreeThesaurusIds,
    selectEntities: selectTreeThesaurusEntities
} = treeThesaurusesAdapter.getSelectors((state) => state.treeThesauruses);

export const selectRootThesauruses = createSelector(
    [selectAllTreeThesauruses],
    (treeThesauruses) => treeThesauruses.filter((treeThesaurus) => (
        treeThesaurus.parent_id === null
    ))
);

export const selectPathOfThesaurus = (state, thesaurusId) => {
    if (!thesaurusId)return [];
    let thesaurus = selectTreeThesaurusById(state, thesaurusId);
    let ids = [];
    let parentId = thesaurus.parent_id;
    while (parentId) {
        thesaurus = selectTreeThesaurusById(state, parentId);
        ids.push(thesaurus.id);
        parentId = thesaurus.parent_id;
    }
    return ids;
};

export const selectSubThesaurus = createSelector(
    [selectAllTreeThesauruses, (state, parentId) => parentId],
    (treeThesauruses, parentId) => treeThesauruses.filter((treeThesaurus) => (
        treeThesaurus.parent_id === parentId
    ))
);

export const selectThesaurusFlatTree = (state, thesaurusId) => {
    const tree = [];
    addTreeBranch(state, tree, 0, thesaurusId, "");
    return tree;
};

const addTreeBranch = (state, tree, level, thesaurusId, path) => {
    const children = selectSubThesaurus(state, thesaurusId);
    children.map(child => {
        tree.push({
            level: level,
            id: child.id,
            title: child.title,
            path: [...path, child.title]
        });
        addTreeBranch(state, tree, level+1, child.id, [...path, child.title]);
    })
}