import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
    current
} from '@reduxjs/toolkit';

import {objectAdded} from "./objectsSlice";
import client from '../../api/client';
import {imagesFetched} from "./imagesSlice";

// Create EntityAdapter
const collectionsAdapter = createEntityAdapter();
const initialState = collectionsAdapter.getInitialState({
    status: 'idle',
    error: null,
    createStatus: null,
    createError: null,
    deleteStatus: null,
    deleteError: null
});

// fetch Collections
export const fetchCollections = createAsyncThunk(
    'collections/fetchCollections',
    async (params, thunkAPI) => {
        const response = await client.get('/getCollections');
        const collections = [];
        const images = [];
        response.collections.map(collection => {
            if (collection.sample_images && collection.sample_images.length > 0) {
                collection.sample_images.map(image=>images.push(image));
                delete collection.sample_images;
            }
            collections.push(collection)
        });
        thunkAPI.dispatch(imagesFetched(images));
        return collections;
    });

// fetch Collection detail
export const fetchCollectionById = createAsyncThunk(
    'collections/fetchCollectionById',
    async (collectionId, thunkAPI) => {
        const response = await client.get('/getCollection/'+collectionId, {});
        return response.collection;
    });

// add Collection
export const createCollection = createAsyncThunk(
    'collections/createCollection',
    async (parameters) => {
        const response = await client.post('/collection/create', parameters.body);
        return response.collection;
    });

export const updateCollection = createAsyncThunk(
    'collections/updateCollection',
    async (parameters) => {
        const response = await client.post('/collection/'+parameters.collectionId, parameters.body);
        return response.collection;
    });

export const removeObject = createAsyncThunk(
    'collections/updateCollection',
    async (parameters) => {
        //const response = await client.post('/collection/removeObject'+parameters.collectionId, parameters.body);
        //return response.collection;
    });

// fetch Collection detail
export const deleteCollection = createAsyncThunk(
    'collections/deleteCollection',
    async (collectionId, thunkAPI) => {
        const response = await client.get('/deleteCollection/'+collectionId, {});
        if (response)return {id: collectionId};
    });

// Creating the slice
const collectionsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        collectionUpdated(state, action) {
            const { id, title, content } = action.payload;
            const existingCollection = state.entities[id];
            if (existingCollection) {
                existingCollection.title = title;
                existingCollection.content = content;
            }
        },
        collectionUpdateUnclassified(state, action) {
            const {id, unclassified} = action.payload;
            const existingCollection = state.entities[id];
            if (existingCollection) {
                existingCollection.unclassified = unclassified;
            }
        },
        resetCreateStatus(state, action) {
            state.createStatus = null;
        }
    },
    extraReducers: {
        // fetchCollections
        [fetchCollections.pending]: (state, action) => {
            state.status = 'loading';
        },
        [fetchCollections.fulfilled]: (state, action) => {
            state.status = 'succeeded';
            collectionsAdapter.upsertMany(state, action.payload);
        },
        [fetchCollections.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.payload;
        },
        // fetchCollectionById
        [fetchCollectionById.pending]: (state, action) => {},
        [fetchCollectionById.fulfilled]: (state, action) => {
            collectionsAdapter.upsertOne(state, action.payload);
        },
        [fetchCollectionById.rejected]: (state, action) => {},
        // createCollection
        [createCollection.pending]: (state, action) => {
            state.createStatus = 'loading'
        },
        [createCollection.rejected]: (state, action) => {
            state.createStatus = 'error'
        },
        [createCollection.fulfilled]: (state, action) => {
            state.createStatus = action.payload
            collectionsAdapter.addOne(state, action.payload);
        },
        // deleteCollection
        [deleteCollection.pending]: (state, action) => {
            state.deleteStatus = 'loading'
        },
        [deleteCollection.rejected]: (state, action) => {
            state.deleteStatus = 'error'
        },
        [deleteCollection.fulfilled]: (state, action) => {
            state.deleteStatus = action.payload
            collectionsAdapter.removeOne(state, action.payload.id);
        },
        // updateCollection
        [updateCollection.fulfilled]: (state, action) => {
            collectionsAdapter.upsertOne(state, action.payload);
        },
    },
});

export const { collectionAdded, collectionUpdated, collectionUpdateUnclassified, resetCreateStatus } = collectionsSlice.actions;

export default collectionsSlice.reducer

export const {
    selectAll: selectAllCollections,
    selectById: selectCollectionById,
    selectIds: selectCollectionIds,
} = collectionsAdapter.getSelectors((state) => state.collections);

export const selectCreateStatus = state => state.collections.createStatus;

export const selectCollectionsByUser = createSelector(
    [selectAllCollections, (state, userId) => userId],
    (collections, userId) => collections.filter((collection) => collection.user === userId)
);

export const selectCollectionsByInvestigation = createSelector(
    [selectAllCollections, (state, investigationId) => investigationId],
    (collections, investigationId) => collections.filter((collection) => collection.investigation === investigationId)
);

export const selectCollectionsByCriminalCase = createSelector(
    [selectAllCollections, (state, criminalCaseId) => criminalCaseId],
    (collections, criminalCaseId) => collections.filter((collection) => collection.criminal_case === criminalCaseId)
);

/*
export const selectCollectionRootImages = (state, collectionId) => {
    const collection = state.collections.entities[collectionId];
    return collection.images === undefined ? [] : collection.images.filter(image => image.group === null);
};

export const selectCollectionObjects = (state, collectionId) => {
    const collection = state.collections.entities[collectionId];
    return collection.objects === undefined ? [] : collection.objects;
};
export const selectCollectionGroupImages = createSelector(
    [
        (state, collectionId) => selectCollectionById(collectionId),
        (state, imageId) => imageId
    ],
    (collection, imageId) => collection.images.filter(image => image.group === imageId)
);*/
