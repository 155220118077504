import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useTranslation}     from 'react-i18next';
import {NavLink, Route, Switch as SwitchRoute, useHistory, useLocation} from "react-router-dom";
import {makeStyles, createTheme} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../services/defaultLightPalette";

import {
    Box, Grid, LinearProgress, Card, CardHeader, CardContent, CardActions, Button, Typography, Badge, Link, Avatar, Hidden, Dialog, DialogTitle, DialogActions
} from "@material-ui/core";
import {
    Add, Edit, Collections as CollectionsIcon, AddAPhoto, Image, EditAttributes, ImportExport, GetApp, Delete
} from "@material-ui/icons";

import {SpeedDial, SpeedDialAction, SpeedDialIcon, ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import CollectionImages from './Images/CollectionImages.jsx';
import CollectionObjects from './Objects/CollectionObjects.jsx';
import CollectionObjectsDnD from "./DnD/CollectionObjectsDnD.jsx";

import {
    deleteCollection,
    fetchCollectionById,
    selectCollectionById,
    updateCollection
} from "../../store/entities/collectionsSlice";
import {fetchTreeCategories, selectAllTreeCategories} from "../../store/entities/treeCategoriesSlice";
import {fetchTreeFields} from "../../store/entities/treeFieldsSlice";
import {fetchFieldTypes, selectAllFieldTypes} from "../../store/entities/fieldTypesSlice";
import {fetchTreeThesauruses} from "../../store/entities/treeThesaurusSlice";
import {fetchCollectionTypes, selectCollectionTypeById} from "../../store/entities/collectionTypesSlice";
import {fetchCollectionTypeFields, selectCollectionFieldsOfType} from "../../store/entities/collectionTypesFieldsSlice";
import {getCurrentUser, isGranted} from "../../store/entities/currentUserSlice";
import {selectEditMode} from "../Selectors/Drawer/SelectorSlice";

import EditableText from "./Fields/EditableText";
import {ObjectField, ObjectThesaurus} from "./Fields/Fields";
import CollectionImport from "./CollectionImport";

import NiceTooltip from "../Misc/CustomToolTip";
import {selectCollectionRootImages} from "../../store/entities/imagesSlice";
import tdb from "../../services/TranslateDB";
import {fetchCriminalCaseById, selectCriminalCaseById} from "../../store/entities/criminalCasesSlice";
import {fetchInvestigationById, selectInvestigationById} from "../../store/entities/investigationsSlice";


const Collection = ({collectionId, fullPage=true, givenPath=false}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const location = useLocation();
    
    const collection = useSelector(state => selectCollectionById(state, collectionId));
    // const categories = useSelector(selectAllTreeCategories);

    const statusCategories = useSelector(state => state.treeCategories.status);
    const statusThesauruses = useSelector(state => state.treeThesauruses.status);
    const statusFields = useSelector(state => state.treeFields.status);
    const statusTypes = useSelector(state => state.fieldTypes.status);
    const statusCollectionTypes = useSelector(state => state.collectionTypes.status);
    const statusCollectionTypeFields = useSelector(state => state.collectionTypeFields.status);

    // const rootImages = useSelector((state) => selectCollectionRootImages(state, collectionId));

    const currentUser = useSelector(getCurrentUser);
    const lea = isGranted(currentUser, 'lea');
    const prevision = isGranted(currentUser, 'prevision');
    const admin = isGranted(currentUser);

    // const editMode = useSelector(selectEditMode);
    // const setEditMode = (value) => {dispatch(setEditMode(value))}

    const [editing, setEditing] = useState(false);
    const [images, setImages] = useState(false);
    
    // action depending on location
    useEffect(()=>{
        if (location.pathname.endsWith('/upload')) {
            setEditing(true);
            setImages(true)
        }
        else if (location.pathname.endsWith('/edit')) {
            setEditing(true);
        }
        else if (location.pathname.endsWith('/images')) {
            setImages(true)
        }
    }, [location])
    
    // rights
    const [access, setAccess] = useState({
        upload: true,
        create: true,
        delete: true,
        edit: true
    });
    
    const handleCloseImport = () => history.push(`/collections/${collectionId}/`);

    // first time, we need to load all collection in case we get directly on this page
    // TODO : think about do this from slice
    useEffect(() => {if (!collection) {                           dispatch(fetchCollectionById(collectionId));}},   [dispatch, collection]);
    useEffect(() => {if (statusCategories === 'idle')             dispatch(fetchTreeCategories(null));},            [dispatch, statusCategories])
    useEffect(() => {if (statusThesauruses === 'idle')            dispatch(fetchTreeThesauruses(null));},           [dispatch, statusThesauruses])
    useEffect(() => {if (statusFields === 'idle')                 dispatch(fetchTreeFields(null));},                [dispatch, statusFields])
    useEffect(() => {if (statusTypes === 'idle')                  dispatch(fetchFieldTypes(null));},                [dispatch, statusTypes])
    useEffect(() => {if (statusCollectionTypes === 'idle')        dispatch(fetchCollectionTypes(null));},           [dispatch, statusCollectionTypes])
    useEffect(() => {if (statusCollectionTypeFields === 'idle')   dispatch(fetchCollectionTypeFields(null));},      [dispatch, statusCollectionTypeFields])

    const [createMode, setCreateMode] = useState();

    // wait for data
    const [ready, setReady] = useState(false);
    
    // remove the upload if images unset
    useEffect(()=>{
        if (ready && !images && location.pathname.endsWith('/upload')) {
            history.push(`/collections/${collectionId}`);
        }
    }, [images, location, ready])
    
    useEffect(()=>{
        if (collection && ![
            statusCategories, statusThesauruses, statusFields, statusTypes, statusCollectionTypes, statusCollectionTypeFields
        ].find(status => status !== 'succeeded')) {
            setReady(true)
        }
    }, [collection, statusCategories, statusThesauruses, statusFields, statusTypes, statusCollectionTypes, statusCollectionTypeFields,])

    
    const [openDialer, setOpenDialer] = useState(false);
    
    const handleCreateObject = () => {
        history.push(`/collections/${collectionId}/add`);
    }
    
    const handleUploadImages = () => {
        history.push(`/collections/${collectionId}/upload`);
    }

    const handleChange = (value, field) =>  {
        let body = {};
        body[field] = value;
        dispatch(updateCollection({
            collectionId: collectionId,
            body: body
        }))
    }

    const handleChangeField = (value, field) =>  {
        let body = {
            fields:{}
        };
        body.fields[field] = value;
        dispatch(updateCollection({
            collectionId: collectionId,
            body: body
        }))
    }
    
    const [deletable, setDeletable] = useState(false);
    useEffect(()=>{
        console.log("collection", collection);
        if (collection && collection.images_count === 0 && collection.objects_count === 0) {
            setDeletable(true);
        }
    }, [collection])
    
    const [deleteMode, setDeleteMode] = useState(false);
    const handleDelete = () => {
        dispatch(deleteCollection(collectionId));
        history.push(`/collections`)
    }

    const handleUpdateObject = (params) => {
        console.log("handleUpdate", params);
    }

    const loadingControl = (title, status, exclude) => {
        return status === exclude ? null : <li>{`${title} : ${status}`}</li>
    }

    if (!ready) {
        return <Card elevation={3}>
            <CardContent>
                <CardHeader title={"Collection"} />
                <CardContent>
                    <LinearProgress color={"secondary"} variant={"indeterminate"} />
                    <ul>
                        {loadingControl('Collection', !!collection, true)}
                        {loadingControl('Categories', statusCategories, 'succeeded')}
                        {loadingControl('Thesauruses', statusThesauruses, 'succeeded')}
                        {loadingControl('Fields', statusFields, 'succeeded')}
                        {loadingControl('Types', statusTypes, 'succeeded')}
                        {loadingControl('CollectionTypes', statusCollectionTypes, 'succeeded')}
                        {loadingControl('CollectionTypeFields', statusCollectionTypeFields, 'succeeded')}
                    </ul>
                </CardContent>
            </CardContent>
        </Card>
    }
    
    const actions = <Box style={{flexDirection: 'row'}}>
        <ToggleButtonGroup className={classes.buttonGroup}>
            <NiceTooltip title={t("toggleManageImages")} placement={"bottom-end"}>
                <ToggleButton selected={images} onClick={()=>setImages(e=>!e)}>
                    <Badge badgeContent={collection.unclassified} color="secondary">
                        <Image />
                        <Hidden xsDown>
                            <Typography style={{marginLeft:5}}>
                                {t('Images')}
                            </Typography>
                        </Hidden>
                    </Badge>
                </ToggleButton>
            </NiceTooltip>
        </ToggleButtonGroup>
        <ToggleButtonGroup>
            <NiceTooltip title={t("toggleEditMode")} placement={"bottom-end"}>
                <ToggleButton selected={editing} onClick={()=>setEditing(e=>!e)}>
                    <Edit />
                    <Hidden xsDown>
                        <Typography style={{marginLeft:5}}>{t('EditMode')}</Typography>
                    </Hidden>
                </ToggleButton>
            </NiceTooltip>
        </ToggleButtonGroup>
    </Box>

    return <Card elevation={3}>
        <CardHeader
            avatar={<Avatar><CollectionsIcon  color={"primary"} /></Avatar>}
            title={ editing
                ? <EditableText
                    initValue={collection.name}
                    onChange={handleChange}
                    field='name'
                    variant='h5'
                    inputClasses={classes.h5input}
                />
                : <Typography variant="h5">{collection.name} -</Typography>
            }
            action={actions}
        >
        </CardHeader>
        <CardContent>
            <CollectionFields collectionId={collectionId} editing={editing} handleFieldUpdate={handleChangeField} />
            <SwitchRoute>
                <Route path={`/collections/${collectionId}/import/`}>
                    <CollectionImport open={true} onClose={handleCloseImport} collectionId={collectionId} />
                </Route>
            </SwitchRoute>
        </CardContent>
        {/* IMAGES */}
        {images &&  <CollectionImages  collectionId={collectionId} editing={editing} fullPage={fullPage} />}
        {/* OBJECTS */}
        {editing
            ?   <CollectionObjectsDnD collectionId={collectionId} onUpdate={handleUpdateObject}>
                    <CollectionObjects collectionId={collectionId} editing={editing} fullPage={fullPage} givenPath={givenPath} showImages={images} />
                </CollectionObjectsDnD>
            :   <CollectionObjects collectionId={collectionId} editing={editing} fullPage={fullPage} givenPath={givenPath} showImages={images} />}
        {/* ACTIONS */}
        <CardActions style={{justifyContent: "flex-end"}}>
            <Box flexDirection={"row"} display={"flex"} justifyContent={"space-between"} width={"100%"}>
                <Box>
                    {false && admin && <Button
                        variant={"contained"}
                    >
                        {t("CreateQueryFromCollection")}
                    </Button>}
                    {admin && <Button
                        ariant={"contained"}
                        component={NavLink}
                        to={`/collections/${collectionId}/import/`}
                    >
                        {t("ImportList")}
                    </Button>}
                    {access && access.delete && deletable && <Button
                        startIcon={<Delete/>}
                        title={t('DeleteCollection')}
                        variant={"contained"}
                        color={"secondary"}
                        onClick={()=>setDeleteMode(true)}
                        style={{marginRight: 10, marginTop: 10}}
                    >
                        {t('DeleteCollection')}
                    </Button>}
                    {deleteMode && <Dialog open={true} onClose={()=>setDeleteMode(false)} fullWidth maxWidth={"sm"}>
                        <DialogTitle>{t('DeleteCollectionDialogTitle')}</DialogTitle>
                        <DialogActions>
                            <Button
                                onClick={()=>setDeleteMode(false)}
                                variant={"contained"}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                onClick={handleDelete}
                                variant="contained"
                                color="secondary"
                            >
                                {t('Delete')}
                            </Button>
                        </DialogActions>
                    </Dialog>}
                </Box>
                <Box>
                    {access && access.upload && <Button
                        startIcon={<AddAPhoto/>}
                        title={t('UploadImages')}
                        variant={"contained"}
                        color={"secondary"}
                        to={`/collections/${collectionId}/upload`}
                        component={NavLink}
                        style={{marginLeft: 10, marginTop: 10}}
                    >
                        {t('UploadImages')}
                    </Button>}
                    {access && access.create && <Button
                        startIcon={<Add/>}
                        title={t('CreateObject')}
                        variant={"contained"}
                        color={"secondary"}
                        to={`/collections/${collectionId}/add`}
                        component={NavLink}
                        style={{marginLeft: 10, marginTop: 10}}
                    >
                        {t('CreateObject')}
                    </Button>}
                </Box>
            </Box>
        </CardActions>
        {
            fullPage && <SpeedDial
                ariaLabel={"Speed Dial"}
                open={openDialer}
                className={classes.speedDial}
                onOpen={()=>setOpenDialer(true)}
                onClose={()=>setOpenDialer(false)}
                icon={<SpeedDialIcon />}
                FabProps={{ className:classes.speedDialIcon}}
            >
                <SpeedDialAction
                    className={classes.speedDialAction}
                    icon={<Add />}
                    title={t('CreateObject')}
                    onClick={handleCreateObject}
                />
                {/*<SpeedDialAction
                    className={classes.speedDialAction}
                    icon={<GetApp />}
                    title={t('ImportList')}
                    onClick={handleCreateObject}
                />*/}
                <SpeedDialAction
                    className={classes.speedDialAction}
                    icon={<AddAPhoto />}
                    title={t('UploadImages')}
                    onClick={handleUploadImages}
                />
            </SpeedDial>
        }
    </Card>;
};
export default Collection;

function CollectionFields({collectionId, editing, handleFieldUpdate}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const collection = useSelector(state => selectCollectionById(state, collectionId));
    const collectionType = useSelector(state => selectCollectionTypeById(state, collection.collection_type));
    const collectionTypeFields = useSelector((state) => selectCollectionFieldsOfType(state, collectionType.id));
    const types = useSelector(selectAllFieldTypes);
    const criminalCase = useSelector(state => selectCriminalCaseById(state, collection.criminal_case));
    const investigation = useSelector(state => selectInvestigationById(state, collection.investigation));

    useEffect(()=>{
        if (collection.criminal_case && !criminalCase)
            dispatch(fetchCriminalCaseById(collection.criminal_case));
        if (collection.investigation && !investigation)
            dispatch(fetchInvestigationById(collection.investigation));
    }, [collectionId])

    if (!collectionType)return null;

    const content = collectionTypeFields.map((field, i) => {
        const type = types.find(type => type.id === field.tree_field_type_id);
        return <>
            {(type && type.is_thesaurus)
                ? <ObjectThesaurus
                    key={`${field.name}-${i}`}
                    field={field}
                    values={collection.fields}
                    editing={editing}
                    update={handleFieldUpdate}
                    type={type}
                    cols={120}
                />
                : <ObjectField
                    key={`${field.name}-${i}`}
                    field={field}
                    values={collection.fields}
                    editing={editing}
                    update={handleFieldUpdate}
                    type={type}
                    cols={120}
                />}
        </>
    })
    
    // if (false)return <Card variant={"outlined"}>
    //     <CardHeader
    //         title={<Typography>{tdb(collectionType.title)}</Typography>}
    //         subheader={<Typography variant={"subtitle2"}>
    //             {!!investigation && <Link component={NavLink} to={`/investigations/${investigation.id}`}>{t('CollectionBelongsToInvestigation', {name: investigation.name})}</Link>}
    //             {!!criminalCase && <Link component={NavLink} to={`/cases/${criminalCase.id}`}>{t('CollectionBelongsToCase', {name: criminalCase.summary})}</Link>}
    //         </Typography>}
    //     />
    //     {content.length > 0 && <CardContent>{content}!!</CardContent>}
    // </Card>

    return <Grid container spacing={0} className={classes.fields}>
        <Grid item style={{marginRight: '3rem'}}>
            <Typography variant={"h6"}>{tdb(collectionType.title)}</Typography>
            <Typography variant={"subtitle1"}>
                {!!investigation && <Link component={NavLink} to={`/investigations/${investigation.id}`}>{t('CollectionBelongsToInvestigation', {name: investigation.name})}</Link>}
                {!!criminalCase && <Link component={NavLink} to={`/cases/${criminalCase.id}`}>{t('CollectionBelongsToCase', {name: criminalCase.summary})}</Link>}
            </Typography>
        </Grid>
        <Grid item style={{flexGrow: 1, paddingTop: 6}}>
            {content}
        </Grid>
    </Grid>
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    images: {
        marginLeft: 0,
        marginRight: 0,
        backgroundColor: "#ffcccc"

    },
    speedDial: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),

    },
    speedDialIcon: {
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light
        },
    },
    fields: {
        border: `1px solid ${theme.palette.grey[300]}`,
        backgroundColor: theme.palette.grey[100],
        flexDirection: 'row',
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1)
    },
    speedDialAction: {

    },
    zoneBar: {
        marginBottom: theme.spacing(2)
    },
    bordered: {
        width: '50%'
    },
    buttonGroup: {
        marginRight: theme.spacing(1)
    },
    slider: {
        color: 'rgba(0,0,0,1)',
    },
    collection: {
        padding: theme.spacing(5)
    },
    navLink: {
        color: theme.palette.primary.contrastText,
        textDecoration: 'inherit'
    }
}));

// export default withStyles(useStyles)(Collection);
