import React, {useState} from "react";
import clsx from "clsx";
import {ClickAwayListener, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import {selectCategoryFlatTree} from "../../../store/entities/treeCategoriesSlice";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import tdb from "../../../services/TranslateDB";

const KEY_ENTER = 13;
const KEY_ESC = 27;

export default function EditableCategory({initValue, field, textClasses, inputClasses, onChange, variant, initEditing=false}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [editing, setEditing] = useState(initEditing);

    const categoryTree = useSelector(state => selectCategoryFlatTree(state, null));
    const initCategory = categoryTree.find(category => category.id === initValue)


    const [value, setValue] = useState(initCategory);
    const handleValue = (event, newValue) => {
        console.debug({newValue:newValue})
        //setValue(newValue)
        if (onChange && newValue && newValue.id){
            onChange({
                categoryId: newValue.id,
                categoryData: newValue
            });
        }
        setEditing(false)
    }

    const handleEdit = () => setEditing(true);
    const handleUnEdit = () => {
        setEditing(false);
        setValue(initValue)
    }
    const clickAway = () => {
        handleUnEdit();
    }

    const handleKey = (event) => {
        if (event.keyCode === KEY_ENTER) {
            handleUnEdit();
        }
        else if (event.keyCode === KEY_ESC) {
            setValue(initValue);
            setEditing(false);
        }
    };


    return <>
        {!editing
            ? <Typography
                onClick={handleEdit} classes={textClasses}
                variant={variant}
            >
                {initCategory ? tdb(initCategory.title) : t("Unknown")}
            </Typography>
            : <ClickAwayListener onClickAway={clickAway}>
                <Autocomplete
                    // size="small"
                    fullWidth
                    value={value}
                    onKeyUp={handleKey}
                    defaultValue={1}
                    onChange={handleValue}
                    getOptionLabel={option => tdb(option.title)}
                    renderInput={(params) =>
                        <TextField fullWidth {...params} classes={clsx(inputClasses, classes.acInput)}/>
                    }
                    renderOption={option => (
                        <div style={{paddingLeft: 6 + option.level * 16}}>
                            {tdb(option.title)}
                        </div>
                    )}
                    classes={{
                        paper: classes.acPaper,
                        option: classes.acOption
                    }}
                    options={categoryTree}/>
            </ClickAwayListener>}</>
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    base: {
        borderWidth: 0,
        padding:0
    },
    input: {
        padding: 0
    },
    acPaper: {
        backgroundColor: '#eee',
    },
    acOption: {
        fontSize: '0.8rem',
        padding:1
    },
}));