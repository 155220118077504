import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {
    selectAllTreeCategories, selectPathOfCategory,
    selectRootCategories,
    selectSubCategories
} from "../../../store/entities/treeCategoriesSlice";
import {
    selectCategoryExpanded,
    selectCategorySelected, selectEditMode, selectShowOnlyPath,
    setCategoryExpanded,
    setCategorySelected, setShowOnlyPath
} from "./SelectorSlice";
import React, {useEffect, useState} from "react";
import {InputAdornment, TextField, Typography} from "@material-ui/core";
import {ToggleButtonGroup} from "@material-ui/lab";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {Search, UnfoldLess, UnfoldMore, VerticalAlignCenter} from "@material-ui/icons";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {selectFieldsByCategory} from "../../../store/entities/treeFieldsSlice";
import TreeItem from "@material-ui/lab/TreeItem";
import tdb from "../../../services/TranslateDB";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import NiceTooltip from "../../Misc/CustomToolTip";
import CategoryDnD from "./SelectorCategoryDnD";
import store from '../../../store/store'

export default function SelectorCategories() {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const statusCategories = useSelector(state => state.treeCategories.status);
    const rootCategories = useSelector(selectRootCategories);
    const categories = useSelector(selectAllTreeCategories);
    const editMode = useSelector(selectEditMode)

    // EXPAND
    const expanded = useSelector(selectCategoryExpanded)
    const setExpanded = (value) => {
        dispatch(setCategoryExpanded(value))
    }
    const handleExpandAll = () => {
        setExpanded(categories.map(root=>(root.id)))
    }
    const handleShrinkAll = () => {
        //setExpanded(rootCategories.map(root=>(root.id)))
        setExpanded([])
    }
    const handleToggle = (event, nodeIds) => {
        if (!showOnlyPath) {
            setExpanded(nodeIds)
        }
    };

    const getCategoryPath = (categoryId) => {
        const entities = store.getState().treeCategories.entities;
        console.log("entities", entities)
        let _path = [categoryId];
        while(entities[categoryId] && entities[categoryId].parent_id) {
            _path.push(entities[categoryId].parent_id)
            categoryId = entities[categoryId].parent_id;
        }
        return _path;
    }

    // SELECT
    const selectedCategory = useSelector(selectCategorySelected)
    const path = useSelector(state => selectPathOfCategory(state, selectedCategory));

    const selectCategory = (value) => {
        dispatch(setCategorySelected(value));
    }
    const handleSelect = (event, nodeId) => {
        selectCategory(nodeId);
    }

    const showOnlyPath = useSelector(selectShowOnlyPath);
    const handleShowOnlyPath = () => {
        dispatch(setShowOnlyPath(!showOnlyPath))
    }

    useEffect(()=>{
        if (showOnlyPath) {
            setExpanded([...path, selectedCategory]);
        }
    }, [selectedCategory])

    // SEARCH
    const [showSearch, setShowSearch] = useState(false);
    const [searchPath, setSearchPath] = useState([]);
    const [search, setSearch] = useState();

    const [showOnly, setShowOnly] = useState();

    useEffect(()=>{
        if (showSearch && search.length > 2) {
            setShowOnlyPath(null);
            let _expanded = [];
            let _selected = null;

            const _found = categories.filter(category=>{
                const _title = tdb(category.title);
                if (_title.includes(search)) {
                    _expanded = [..._expanded, ...getCategoryPath(category.id)]
                    if (!_selected) {
                        _selected = category.id;
                    }
                }
            });
            setSearchPath(_expanded)
            setExpanded(_expanded)
            setCategorySelected(_selected)
        }
        else {
            setSearchPath([])
        }
    }, [search])

    useEffect(()=>{
        if (rootCategories) {
            if (!selectedCategory && rootCategories.length > 0) {
                setCategorySelected(rootCategories[0].id)
            }
            handleShrinkAll();
        }
    }, [rootCategories])

    if (statusCategories !== "succeeded") {
        return <div>Loading {statusCategories}</div>;
    }

    return <div className={classes.panel}>
        <div className={classes.panelBar}>
            <Typography>{t('Categories')}</Typography>
            <ToggleButtonGroup>
                <NiceTooltip title={t("ShowOnlySelected")} placement={"top-end"} arrow>
                    <ToggleButton value="Focus" selected={showOnlyPath} style={{width: 30, height:30}} size="small" onClick={handleShowOnlyPath}>
                        <VerticalAlignCenter fontSize={"small"} />
                    </ToggleButton>
                </NiceTooltip>
                <NiceTooltip title={t("ReduceTree")} placement={"top-end"} arrow>
                    <ToggleButton value="Shrink" disabled={showOnlyPath} selected={false} style={{width: 30, height:30}} size="small" onClick={handleShrinkAll}>
                        <UnfoldLess fontSize={"small"} />
                    </ToggleButton>
                </NiceTooltip>
                <NiceTooltip title={t("ExpandTree")} placement={"top-end"} arrow>
                    <ToggleButton value="Expand" disabled={showOnlyPath} selected={false} style={{width: 30, height:30}} size="small" onClick={handleExpandAll}>
                        <UnfoldMore fontSize={"small"} />
                    </ToggleButton>
                </NiceTooltip>
                <NiceTooltip title={t("Search")} placement={"top-end"} arrow>
                    <ToggleButton value="search" selected={showSearch} style={{width: 30, height:30}} size="small" onClick={()=>setShowSearch(s=>!s)}>
                        <Search fontSize={"small"} />
                    </ToggleButton>
                </NiceTooltip>
            </ToggleButtonGroup>
        </div>
        {
            showSearch && <div style={{margin:2}}>
                <TextField
                    fullWidth
                    className={classes.panelInput}
                    variant={"standard"}
                    onChange={(event)=>setSearch(event.target.value)}
                    InputProps={{disableUnderline: true,
                        startAdornment: <InputAdornment position={"start"}>
                            <Search fontSize={"small"} />
                        </InputAdornment>}}
                />
            </div>
        }
        <TreeView
            className={classes.tree}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selectedCategory}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
        >
            {rootCategories.map((rootCategory, index) => {
                return <Category index={index} category={rootCategory} selected={selectedCategory} showonly={showOnlyPath ? path : (searchPath.length > 0 ? searchPath : false)} />
            })}
        </TreeView>
    </div>;
}

function Category({category, index, selected, showonly}) {
    // TODO : use categoryId here
    const classes = useStyles();
    const subCategories = useSelector(state => selectSubCategories(state, category.id));
    const fields = useSelector(state => selectFieldsByCategory(state, category.id));
    if (showonly && selected !== category.id && !showonly.includes(category.id))return null;
    return <TreeItem
        nodeId={category.id}
        label={
            <div className={clsx({
                [classes.labelRoot]: true,
                [classes.categorySelected]: selected === category.id
            })}>
                {/*<LabelIcon className={classes.labelIcon} />*/}
                <CategoryDnD name={category.id} title={tdb(category.title, category.name)} category={category}>
                    <Typography className={classes.labelText}>{tdb(category.title, category.name)}</Typography>
                    {/*<Typography className={classes.labelText}>{category.id}</Typography>*/}
                </CategoryDnD>

                <div>
                    {fields.length ? <Typography className={classes.fieldIndicator}>{fields.length}</Typography> : null}
                </div>
            </div>
        }

    >
        {subCategories.map((subCategory, index) => {
            return <Category category={subCategory} index={index} selected={selected} showonly={selected === category.id ? false : showonly} />
        })}
    </TreeItem>
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    panel: {
        borderBottom: '1px solid #ccc',
        paddingTop:3,
        paddingBottom:3,
        fontSize: 12
    },
    panelBar: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom:1,
    },
    panelInput: {
        //border:,
        border: '1px solid #666',
        borderRadius: 4,
        padding: 0,
        '& input': {
            fontSize: 12,
        }
    },
    search: {
        backgroundColor: 'black'
    },
    categorySelected: {
        backgroundColor: '#000'
    },
    tree: {
        fontSize: 1
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'row',
    },
    labelIcon: {
        color: "#999"
    },
    labelText: {
        flexGrow: 1,
        fontSize: 12
    },
    fieldIndicator: {
        fontSize: 12
    },
    toolTip: {
        '& .MuiTooltip-popper': {
            backgroundColor: 'black'
        },
        '& .MuiTooltip-popperArrow': {
            backgroundColor: 'black'
        },
    }
}));