import React, {memo, useState} from "react";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
// MATERIAL
import {Grid, Box, Typography, Card, CardHeader, CardActionArea, CardContent, Link} from "@material-ui/core";
// SLICES
import Image from "material-ui-image";
import {selectObjectById} from "../../../store/entities/objectsSlice";
import {selectImagesByObject} from "../../../store/entities/imagesSlice";
import {selectTreeCategoryById} from "../../../store/entities/treeCategoriesSlice";
// CUSTOM
import tdb from "../../../services/TranslateDB";
import clsx from "clsx";
import {selectInheritedFieldsByCategory} from "../../../store/entities/treeFieldsSlice";
import {selectAllFieldTypes} from "../../../store/entities/fieldTypesSlice";
import {ObjectData, ObjectField, ObjectThesaurus} from "../Fields/Fields";
import moment from "moment";
import {useHistory} from "react-router-dom";
import CollectionObjectThumb from "./CollectionObjectThumb";
let objectTimeout;

// ---------------------------------------------------------------------------------------------------------------------
// OBJECT THUMBNAIL
export default function CollectionObjectSimple({objectId, imageSize, editing, actionPath, selected, onSelect}) {
    const classes = useStyles();
    const fieldClasses = useFieldStyle();
    const {t} = useTranslation();
    const object = useSelector(state => selectObjectById(state, objectId));
    const images = useSelector(state => selectImagesByObject(state, objectId));
    const category = useSelector(state => selectTreeCategoryById(state, object.category));
    const fields = useSelector(state => selectInheritedFieldsByCategory(state, object.category));
    const types = useSelector(selectAllFieldTypes);
    const history = useHistory();
    const [currentImage, setCurrentImage] = useState(0);

    const handleCurrentImage = (event, index) => {
        setCurrentImage(index);
    }

    const openObject = () => {
        history.push(actionPath + 'object/' + objectId)
    }

    const handleSelect = (event) => {
        if (event.target.tagName !== "IMG") {
            onSelect(objectId)
        }
    }

    const handleMouseDown = (event) => {
        clearTimeout(objectTimeout)
        objectTimeout = setTimeout(()=>openObject(), 1000)
    }

    const handleMouseUp = (event) => {
        clearTimeout(objectTimeout)
        onSelect(objectId);
    }

    const getMappedSize = () => {
        return imageSize * 25 + 50;
    };

    return <>
        <Card className={clsx({[classes.selected]:selected})} elevation={selected ? 0 : 3}>
            <CardActionArea
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                // onClick={()=>onSelect(objectId)}
                onDoubleClick={()=>openObject()}
            >
                <CardContent style={{padding: 4}}>
                    <Box style={{height:44, overflow: 'visible'}}>
                        <Typography style={{width: getMappedSize(), fontSize:  ["", ".82rem", ".85rem", ".88rem", ".92rem", ".95rem"][imageSize], height:22, overflow: 'hidden', whiteSpace: 'nowrap'}}>
                            {object.name}
                            {/*{tdb(object.title, t('Untitled'))}*/}
                        </Typography>
                        <Typography style={{width: getMappedSize(), fontSize: ["", ".65rem", ".7rem", ".76rem", ".8rem", ".9rem"][imageSize], height:22, overflow: 'hidden', whiteSpace: 'nowrap'}}>
                            {category ? tdb(category.title, category.name) : t('Unclassified')}
                        </Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={"row"}>
                        <Box>
                                <span>
                                {images.length
                                    ? <img
                                        alt=""
                                        src={images[currentImage].urls.medium ? images[currentImage].urls.medium : "error"}
                                        draggable={false}
                                        className={classes.media}
                                        style={{width: getMappedSize(), height: getMappedSize()}}
                                        onClick={handleCurrentImage}
                                    />
                                    : <div
                                        className={classes.media}
                                        style={{width: getMappedSize(), height: getMappedSize()}}
                                    >
                                    </div>
                                }
                            </span>
                        </Box>
                        <Box flexGrow={1} style={{paddingLeft:8}}>
                            <ObjectData name={t('DateCreated')} value={`${moment(object.created).format('DD/MM/YYYY')}`} />
                            {fields.map((field, i) => {
                                const type = types.find(type => type.id === field.tree_field_type_id)
                                return type && type.is_thesaurus
                                    ? <ObjectThesaurus
                                        key={`${field.name}-${i}`}
                                        field={field}
                                        values={object.fields}
                                        editing={false}
                                        update={()=>{}}
                                        textClasses={fieldClasses}
                                        cols={[4,8]}
                                        showBookMark={false}
                                    />
                                    : <ObjectField
                                        key={`${field.name}-${i}`}
                                        field={field}
                                        values={object.fields}
                                        editing={false}
                                        update={()=>{}}
                                        textClasses={fieldClasses}
                                        type={type}
                                        cols={[4,8]}
            
                                    />
                            })}
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    </>
}

function compareProps(prev, next) {
    return prev.objectId === next.objectId
        && prev.imageSize === next.imageSize
        && prev.selected === next.selected
        && prev.actionPath === next.actionPath
        && prev.version === next.version
}

export const CollectionObjectSimpleMemo = memo(CollectionObjectSimple, compareProps);

const useFieldStyle = makeStyles(theme=>({
    root: {
        fontSize: ".9rem",
    },
    input: {
        fontSize: ".9rem",
    },
}));


export function CollectionObjectSimpleField({field, object}) {
    //const field =
    return <Box flexDirection={"row"} justifyItems={"center"}>
        <Typography style={{flexGrow: 1}}>

        </Typography>
        <Typography style={{flexGrow: 1}}>

        </Typography>
    </Box>
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    image: {
        // borderStyle: 'hash',
        // borderColor: theme.palette.primary.contrastText,
        // borderWidth: 1,
        //padding: theme.spacing(2),
    },
    selected: {
        backgroundColor: "#cccccc"
    },
    media: {
        height: '100%',
        width: '100%',
        backgroundColor: 'black'
    }
}));

