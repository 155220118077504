import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,Grid, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

import {setCategoryEdited} from "./TreeEditorSlice";
import tdb from "../../../services/TranslateDB";
import Translatable from "../../Collection/Fields/Translatable2";
import FieldSet from "../../Misc/FieldSet";
import {createCategory, selectAllTreeCategories, selectTreeCategoryById, updateCategory} from "../../../store/entities/treeCategoriesSlice";

export default function CreateCategory({parentId, onClose}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const category = useSelector(state => selectTreeCategoryById(state, parentId));
    const categories = useSelector(selectAllTreeCategories)

    const [form, setForm] = useState({
        name: "",
        title: {[i18n.language]:""},
        synonym: {},
        false_cognate: {},
        thesaurus: "",
        branch: "",
        wiki_data_ref: "",
        wiki_data_url: "",
        aat_ref: "",
        aat_url: "",
        parent_id: parentId
    });
    
    const [error, setError] = useState(false);
    useEffect(()=>{
        let _error = false
        if (!form.title[i18n.language])_error = true;
        else if (!form.name)_error = true;
        else if (categories.find(c=>c.name === form.name))_error = true;
        setError(_error);
    }, [form])
    
    const handleSave = () => {
        dispatch(createCategory({
            body: form
        }))
    }

    return <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle className={classes.dialogTitle}>
            {t('TreeAddCategoryBelow', {parent: tdb(category.title)})}
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid xs={12} item>
                    <TextField
                        fullWidth
                        label={"Ref"}
                        variant={"outlined"}
                        value={form.name}
                        onChange={(event)=>setForm(state=>({...state, name: event.target.value}))}
                    />
                </Grid>
                <Grid xs={12} item>
                    <Translatable
                        inputProps={{variant: "outlined"}}
                        initValue={form.title}
                        onChange={(newValue)=>setForm(state=>({...state, title:newValue}))}
                        label={t("Title")}
                        fieldsetMode={t("Title")}
                    />
                </Grid>
                <Grid sm={6} xs={12} item>
                    <FieldSet title={"WikiData"}>
                        <TextField
                            label={"Ref"}
                            variant={"outlined"}
                            value={form.wiki_data_ref}
                            onChange={(event)=>setForm(state=>({...state, wiki_data_ref: event.target.value}))}
                        />
                        <TextField
                            label={"URL"}
                            variant={"outlined"}
                            value={form.wiki_data_url}
                            style={{marginTop: '1rem'}}
                            //InputProps={{disableUnderline:true}}
                            onChange={(event)=>setForm(state=>({...state, wiki_data_url: event.target.value}))}
                        />
                    </FieldSet>
                </Grid>
                <Grid sm={6} xs={12} item>
                    <FieldSet title={"AAT"}>
                        <TextField
                            label={"Ref"}
                            variant={"outlined"}
                            value={form.aat_ref}
                            onChange={(event)=>setForm(state=>({...state, aat_ref: event.target.value}))}
                        />
                        <TextField
                            label={"URL"}
                            variant={"outlined"}
                            value={form.aat_url}
                            style={{marginTop: '1rem'}}
                            onChange={(event)=>setForm(state=>({...state, aat_url: event.target.value}))}
                        />
                    </FieldSet>
                </Grid>
                <Grid xs={12} item>
                    <Translatable
                        inputProps={{variant: "outlined"}}
                        initValue={form.false_cognate}
                        onChange={(newValue)=>setForm(state=>({...state, false_cognate:newValue}))}
                        label={t("FalseCognate")}
                        fieldsetMode={t("FalseCognate")}
                    />
                </Grid>
                <Grid xs={12} item>
                    <Translatable
                        inputProps={{variant: "outlined"}}
                        initValue={form.synonym}
                        onChange={(newValue)=>setForm(state=>({...state, synonym:newValue}))}
                        label={t("Synonyms")}
                        fieldsetMode={t("Synonyms")}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color={"secondary"} variant={"contained"} onClick={handleSave} disabled={error}>
                {t('TreeEditorCategoryCreate.Save')}
            </Button>
        </DialogActions>
    </Dialog>
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
    },
    group: {
        marginTop: '1rem'
    },
    fieldset: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.grey[500],
        padding: theme.spacing(1)
    },
    legend: {
        backgroundColor: "white",
        paddingLeft: 5,
        paddingRight: 5,
    }
}))