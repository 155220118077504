import React from "react";
import {ExitToApp, OpenInNew, Save} from "@material-ui/icons";

export function OpenIcon(props) {
    return <ExitToApp {...props} />;
}

export function OpenURL(props) {
    return <OpenInNew {...props} />;
}

export function CreateIcon(props) {
    return <Save {...props} />;
}