import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {toggleSelectedResult} from "../../store/entities/searchSlice";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Rating from "@material-ui/lab/Rating";
import DialogContent from "@material-ui/core/DialogContent";
import Avatar from "@material-ui/core/Avatar";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from '@material-ui/core/Tooltip';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {tabNames} from "../../store/entities/searchSlice";
import {isMobile} from "react-device-detect";

import Chip from "@material-ui/core/Chip";
import WarningIcon from '@material-ui/icons/Warning';

const redListPriorities2 = {
    "webOrigin":        {group: "A", order:1},
    "mainType":         {group: "A", order:2},
    "redListTags":      {group: "A", order:3},
    "type":             {group: "A", order:4},
    "keyTerm":          {group: "A", order:5},
    "redListOrigin":    {group: "A", order:6},
    "attachement":      {group: "A", order:7},
    "tags":             {group: "A", order:8},
    "estimatedCulture": {group: "B", order:1},
    "estimatedOrigin":  {group: "B", order:2},
    "provenance":       {group: "B", order:3},
    "provenanceTags":   {group: "B", order:3},
    "function":         {group: "C", order:1},
    "material":         {group: "C", order:2},
    "shape":            {group: "C", order:3},
    "length":           {group: "D", order:1},
    "width":            {group: "D", order:2},
    "depth":            {group: "D", order:3},
    "museum":           {group: "E", order:1},
    "url":              {group: "E", order:2},
    "property":         {group: "E", order:3},
    "id":               {group: "F", order:1},
    "source":           {group: "F", order:2},
    "imageName":        {group: "F", order:3},
    "redListIcom":      {group: "F", order:4},
    "redListNumber":    {group: "F", order:5},
    "image":            {group: "F", order:6},
    "from":             {group: "F", order:7},
    "to":               {group: "F", order:8},
    "features":         {group: "F", order:8},
    "decor":            {group: "F", order:9},
};

const redListPriorities = ['webOrigin', 'redListTags', 'type', 'keyTerm', 'estimatedCulture', 'estimatedOrigin', 'provenance', 'from', 'to', 'material', 'function', 'shape', 'length', 'width', 'depth', 'note1', 'source', 'url', 'note2',];


const IdentificationResults = ({ value, index, type, content, display, col }) => {
    if (type === "smartBrowser") {
        content = content.map((found) => {
            return {...found, joinedCategories: found.data.categories.join('/')};
        });
        display = 'grid';
        col = 2;
    }

    let list, introduction;
    const specialists = [];
    if (tabNames[type].groupBy) {
        const field = tabNames[type].groupBy;
        const groups = {};
        content.map((found) => {
            let fieldValue = found[field] ? found[field] : found.data[field];
            if (typeof fieldValue == "undefined") fieldValue = "Undefined";
            if (Object.keys(groups).indexOf(fieldValue) === -1) groups[fieldValue] = [];
            groups[fieldValue].push(found);
            return;
        });
        if (type === 'smartBrowser') {
            introduction = `${Object.keys(groups).length} ${tabNames[type].groupName}${Object.keys(groups).length>1?'s':''}`;
        }
        else if (Object.keys(groups).length < content.length) {
            introduction = `${content.length} résultat${content.length>1?'s':''} de ${Object.keys(groups).length} ${tabNames[type].groupName}${Object.keys(groups).length>1?'s':''}`;
        }
        else {
            introduction = `${content.length} résultat${content.length>1?'s':''} de ${Object.keys(groups).length} ${tabNames[type].groupName}${Object.keys(groups).length>1?'s':''}`;
        }
        list = Object.entries(groups).map(([name, groupedList], id1)=> {
            return <Grid spacing={3} container key={`gl-${id1}`}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"}>{name ? name : null}</Typography>
                    </Grid>
                    {groupedList.map((found, id2) => {
                        if (found.data.source) {
                            specialists.push(found.data.source)
                        }
                        return <IdentificationResult key={`ir-${id1}-${id2}`} type={type} found={found} display={display} col={col} exclude={tabNames[type].exclude}/>
                    })}
                </Grid>
        });


    }
    else {
        introduction = `${content.length} Items`;
        list = <Grid spacing={3} container>
            {content.map((found, index) => {
                if (found.source) {
                    specialists.push(found.source)
                }
                return <IdentificationResult type={type} found={found} display={display} col={col}/>
            })}
        </Grid>;
    }

    return <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`}>
        <Grid container spacing={3} justify={"space-between"}>
            <Grid item  flexgrow={1} p={3}>
                <Typography variant={"h6"}>{introduction}</Typography>
            </Grid>
            <Grid item>
                {specialists && specialists.length > 0 ? <ResultSpecialist sources={specialists} /> : null}
            </Grid>
        </Grid>
        {value === index ? list : null}
    </div>;
}

function IdentificationResult({type, found, display, col, exclude, index}) {
    let content;
    switch (type) {
        case "police":     content = <Police     display={display} found={found} />;break;
        // case "police_test":     content = <Police     display={display} found={found} />;break;
        case "ecommerce": content = <Ecommerce          display={display} found={found} />;break;
        case "redLists": content = <RedList      display={display} found={found} />;break;
        case "redListInt": content = <RedListInt   display={display} found={found} />;break;
        case "positive_database":  content = <PositiveDatabase  display={display} found={found} exclude={exclude} />;break;
        case "smartBrowser": content = <SmartBrowser display={display} found={found} />;break;
        case "looted": content = <Looted display={display} found={found} />;break;
        default : content = <Typography>unknown type '{type}'</Typography>;break;
    }
    return <Grid item xs={display === 'grid' ? col : 12}>
        {content}
    </Grid>;
}


// LAYOUT
function ResultLayout({title, dist, image, content, display, summary, id, data}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [showContent, setShowContent] = useState(false);
    const isThere = useSelector(state => state.search.selectedResults.findIndex(x => x.id === id)!==-1);

    function handleChange (event) {
      let data = {
        'id': id
      }
      dispatch(toggleSelectedResult(data))
    }
    function toggleShow(){
      setShowContent(!showContent)
    }
    let action = <span><Tooltip title={t('Add this item to the list of object of interest')}><input type="checkbox" checked={isThere} onChange={handleChange} /></Tooltip> {dist}</span>
    if (display === 'list') {
        return <Card elevation={3} >
            <CardHeader title={<Typography>{title} {
                 data.survey_status && data.survey_status=="new" ?
                 <Chip size="small" icon={<WarningIcon />} label={t("New")} color={"secondary"} />
                 : null}
               </Typography>} action={action} />
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={3}>{image}</Grid>
                    { isMobile && !showContent ?
                    null : <Grid  item xs={12} sm={9}>{content}</Grid>}
                    <div style={{width: "100%", textAlign: "center"}}>
                    <span style={{textDecoration: "underline", cursor: "pointer"}} onClick={toggleShow}>{showContent ? "View less" : "View more"}</span>
                    </div>
                </Grid>
            </CardContent>
        </Card>
    }
    else {
        return <Card elevation={3}>
            <CardHeader title={<Typography>{title}</Typography>} action={action} />
            <CardContent>
                {image}
            </CardContent>
            <CardContent>
                {summary}
            </CardContent>
        </Card>
    }
}

function Police({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((120 - parseFloat(found.dist) * 120)/120*100);
    return <ResultLayout
        id={found.id}
        image={<img src={found.img} className={classes.image} />}
        title={""}
        dist={`${dist}%`}
        content={<DisplayDataGrid name={"Data"} data={found.data} exclude={['img', 'image', 'id', 'dist']}/>}
        display={display}
        data={found}
    />
}

function Looted({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((120 - parseFloat(found.dist) * 120)/120*100);
    return <ResultLayout
        id={found.id}
        image={<img src={found.img} className={classes.image} />}
        title={found.data.title}
        dist={`${dist}%`}
        content={<DisplayDataGrid name={"Data"} data={found.data} exclude={['img', 'image', 'id', 'dist']}/>}
        display={display}
        data={found}
    />
}

function Ecommerce({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((120 - parseFloat(found.dist) * 120)/120*100);
    return <ResultLayout
        id={found.id}
        title={found.data.subject}
        dist={`${dist}%`}
        image={<img src={found.img} className={classes.image} />}
        content={
            <>
                <Link href={found.data.url} target="_blank" style={{color: 'red'}}>{found.data.url}</Link>
                {typeof found.data.description!="undefined" ? <DisplayDataGrid name={"Description"} data={found.data.description} exclude={[]}/> : null}
                <DisplayDataGridLine name={"Categorie"} value={found.data.category_name} />
                <DisplayDataGridLine name={"Publication"} value={found.data.first_publication_date} />
                <DisplayDataGridLine name={"Prix"} value={found.data.price+' '+found.data.price_unit} />
                 { typeof found.data.location != 'undefined' ?
               <>
               <DisplayDataGridLine name={"City"} value={found.data.location.city} />
               <DisplayDataGridLine name={"Country"} value={found.data.location.country_id} />
               <DisplayDataGridLine name={"Region"} value={found.data.location.region_name} />
               </>
               : null }



                {
                    Array.isArray(found.data.attribute) ? (
                        found.attribute.map(attribute=>{
                            return <DisplayDataGridLine name={attribute.key} value={attribute.value} />
                        })
                    ) : null
                }
            </>
        }
        summary={<Link href={found.url} target="_blank"  style={{color: 'red'}}>{found.url}</Link>}
        display={display}
        data={found}
    />
}

function RedList({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((120 - parseFloat(found.dist) * 120)/120*100);
    return <ResultLayout
        id={found.id}
        title={found.data.title}
        dist={`${dist}%`}
        image={<img src={found.img} className={classes.image} />}
        // image={<Image src={image}  width="100%" height="auto" />}
        content={<DisplayDataGrid data={found.data} exclude={[
            'attachment',
            'image',
            'id',
            'dist',
            'imageName'
        ]}/>}
        /*'img', 'image', 'id', 'dist'*/
        display={display}
        summary={""}
        data={found}
    />;
}

/**
 * Source
 */
function RedListInt({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((120 - parseFloat(found.dist) * 120)/120*100);
    return <ResultLayout
        id={found.id}
        title={found.data.provenanceTags}
        dist={`${dist}%`}
        image={<img src={found.img} className={classes.image} />}
        content={<DisplayDataGrid name={"Data"}
                                  data={found.data}
                                  exclude={[]}
                                  excluder={['img', 'image', 'id', 'dist', 'redListIcom', 'redListIcom', 'redListOrigin', 'redListTags']}
                                  show={redListPriorities}
        />}
        display={display}
        summary={""}
        data={found}
    />;
}

function PositiveDatabase({found, display, exclude}) {
    const classes = useStyles();
    const dist = Math.floor((120 - parseFloat(found.dist) * 120)/120*100);
    return <ResultLayout
        id={found.id}
        title={found.data.title}
        dist={`${dist}%`}
        image={<img src={found.img} className={classes.image} />}
        content={
          <> { typeof found.data.geolocalisation_ville != 'undefined' ?
          <a href={`https://www.google.com/maps/@${found.data.geolocalisation_ville[1]},${found.data.geolocalisation_ville[0]},14z`} target="_blank" style={{color: 'red'}}>Emplacement</a>
          : null }
                <DisplayDataGrid  name={found.data.datasetid} data={found.data} exclude={exclude}/></>
        }
        display={display}
        summary={""}
        data={found}
    />;
}

// DONE
/*
Grouper par category
 */
function SmartBrowser({found, display}) {
    const classes = useStyles();
    const dist = Math.floor((120 - parseFloat(found.dist) * 120)/120*100);
    return <ResultLayout
        id={found.id}
        title={""}
        dist={`${dist}%`}
        image={<img src={found.img} className={classes.image} />}
        display={display}
        content={""}
        summary={""}
        data={found}
    />;
}



function DisplayDataGridLine({name, value}) {
    return <Grid container>
        <Grid item xs={12} md={3}><strong>{name}</strong></Grid>
        <Grid item xs={12} md={9}>{value}</Grid>
    </Grid>
}

function DisplayDataGrid({name, data, id, exclude, open, show}) {
    const {t} = useTranslation();
    const truncateURL = (url) => {
        if  (url.split("?").length > 0) {
            url = url.split("?")[0];
        }
        return url.replace(/(.{20})/g,"$1 ")
    }
    if (exclude.indexOf(name)>=0)return null;
    // STRING
    if (typeof(data) === "string") {
        if (data == ""){
          return null
        }
        return <DisplayDataGridLine
            name={name}
            value={
                data.startsWith("http")
                    ?   <div style={{width: '100%', overflow: 'hidden'}}>
                            <a href={data} target="_blank" style={{color: 'red'}}>{truncateURL(data)}</a>
                        </div>
                    :   data
            }
        />
    }
    // NUMBER
    if (typeof(data) === "number") {
        return <DisplayDataGridLine name={name} value={data}/>
    }
    // OBJECT
    else if (data && typeof data === "object" && !Array.isArray(data)) {
        let displayed = [];
        if (show) {
            return <>
                {show.map(field => {
                    if (data[field] && data[field]!="") {
                        return <DisplayDataGrid name={field} data={data[field]} exclude={exclude} show={show} />
                    }
                    else {
                        displayed.push(field);
                    }
                })}
            </>;
        }
        return <>
            {Object.entries(data).map(([name, val], index)=> {
                return <DisplayDataGrid name={t(name)} data={val} key={`ddg-${name}-${index}`} exclude={exclude} />
            })}
        </>;
    }
    // ARRAY
    else if (data && Array.isArray(data)) {
        return <>
            <label>{name}</label>
            <ul>
                {data.map((val, index)=> {
                    return <li>
                        <DisplayDataGrid name={t(name)} data={val} exclude={exclude} />
                    </li>
                })}
            </ul>
        </>
    }
    // ELSE
    else if (data) {
        return <DisplayDataGridLine name={t(name)} value={data}/>;
    }
    // BIZARRE
    else {
      // Displays nulls
      //  return <div>{typeof(data)}</div>
      return <span></span>
    }
}

function ResultSpecialist({sources}) {
    const specialists2 = sources.filter(source => source == "Julie Marchand");
    const specialist1 = specialists2.map(specialist=>{
        return "Conservateur expert antiquités égyptiennes. France";
    });
    const specialists = specialist1.filter((v, i, a) => a.indexOf(v) === i);
    const [specialistModal, setSpecialistModal] = useState(false);
    const {t} = useTranslation();
    const handleModalOpen = (specialist) => setSpecialistModal(specialist);
    const handleModalClose = () => setSpecialistModal(false);
    return <Box p={2}>
        {specialists.map((specialist, index) => {
            return <Button
                variant={"outlined"} onClick={() => handleModalOpen(specialist)}
            >
                {t('ContactExperts')} : {specialist}
                {/* <Rating name="size-small" defaultValue={4.5} size="small" /> */}
            </Button>;
        })}
        {specialists.map((specialist, index) => {
            return <Dialog
                onClose={handleModalClose}
                open={specialist === specialistModal}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle color={"default"}>Specialist</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            {/*<Image src={"https://storage.gra.cloud.ovh.net/v1/AUTH_08039aa9790e44acb556a9f6515b6899/Parcs-Main/b0a22f2e108a8a06/big/011f990ce07a.jpg"} />*/}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ExpertData name={t("name")} value={specialist} />
                            {/* <ExpertData name={"rating"} value={
                                <div>
                                    <Rating name="size-small" defaultValue={4.5} size="medium" />
                                </div>
                            } /> */}
                            <ExpertData name={t("current position")} value={" Conservateur Musée National France"} />
                            <ExpertData name={t("speciality")} value={
                                <ul>
                                    <li>{t('Ancient Egypt')}</li>
                                    <li>{t('Assyriology')}</li>
                                </ul>
                            } />
                            <ExpertData name={"email"} value={
                                <Link href={"mailto:Expert.732@arte-fact.com"}  style={{color: 'red'}}>Expert.732@arte-fact.com</Link>
                            } />
                            <ExpertData name={"tel"} value={"..."} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} color={"secondary"}>
                        {t("ContactThisExpert")}
                    </Button>
                </DialogActions>
            </Dialog>;
        })}
    </Box>;
}

function ExpertData({name, value}) {
    return <Grid container spacing={1} style={{marginBottom: 4}}>
        <Grid item xs={5}>
            <strong>{name}</strong>
        </Grid>
        <Grid item xs={7}>
            {value}
        </Grid>
    </Grid>
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        // marginBottom: theme.spacing(5),
        height: 100,

    },
    image: {
        width: '100%',
        height: 'auto',
        maxWidth: "200px"
    },
    navLink: {
        color: 'black',
        textDecoration: 'inherit'
    }
}));

export default IdentificationResults;
