import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid, IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@material-ui/core";
import {createTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette, {previsionPalette} from "../../../services/defaultLightPalette";
import {Add, Code, ExpandMore, ImportExport} from "@material-ui/icons";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";

import {selectPathOfCategory, selectTreeCategoryById} from "../../../store/entities/treeCategoriesSlice";

import {selectFieldTypeById} from "../../../store/entities/fieldTypesSlice";
import {selectCategorySelected, selectFieldSelected, setFieldSelected} from "./TreeEditorSlice";
import {selectFieldsByCategory, selectTreeFieldById} from "../../../store/entities/treeFieldsSlice";

import NiceTooltip from "../../Misc/CustomToolTip";
import tdb from "../../../services/TranslateDB";
import clsx from "clsx";
import FieldCreate from "./TreeEditorCatFieldCreate";

export function InheritedFields({categoryId}) {
    const category = useSelector(state => selectTreeCategoryById(state, categoryId));
    const path = useSelector(state => selectPathOfCategory(state, categoryId));
    if (!category)return null;
    return <>
        <CategoryFields categoryId={categoryId} key={`fieldOf-${categoryId}`}  />
        {path.map((parentId, index)=>{
            return <CategoryFields categoryId={parentId} key={`fieldOf-${parentId}`} />
        })}
    </>;
}

export function CategoryFields({categoryId}) {
    const {t} = useTranslation();
    const [sortMode, setSortMode] = useState(false);

    const fields = useSelector(state => selectFieldsByCategory(state, categoryId));
    const category = useSelector(state => selectTreeCategoryById(state, categoryId));
    const selectedCategory = useSelector(selectCategorySelected)

    const [createMode, setCreateMode] = useState(false);
    const [collapsed, setCollapsed] = useState(selectedCategory !== categoryId);
    
    if (!fields.length && selectedCategory !== categoryId)return null;
    

    return <Grid item xs={12}>
        <Card elevation={2} style={{backgroundColor: selectedCategory === categoryId ? 'white' : 'rgba(255,255,255,0.5)'}}>
            <CardHeader
                title={<Typography variant={"h6"}>{`${t('FieldsOf')} ${tdb(category.title, category.name)}`}</Typography>}
                action={
                    <ToggleButtonGroup>
                        <NiceTooltip title={t("ReorderMode")} placement={"bottom-end"}>
                            <ToggleButton size={"small"} selected={sortMode} onClick={()=>setSortMode(s=>!s)}>
                                <ImportExport />
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("TreeAddSubCategory")} placement={"bottom-end"}>
                            <ToggleButton size={"small"} onClick={()=>setCreateMode(true)}><Add /></ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>
                }
            />
            {collapsed
                ? <CardContent>
                    <IconButton onClick={()=>setCollapsed(false)}><ExpandMore /></IconButton>
                </CardContent>
                :<CardContent>
                    <List>
                        {fields.map((field, index) => (<FieldLine fieldId={field.id}/>))}
                    </List>
                </CardContent>
            }
            {createMode && <FieldCreate categoryId={categoryId} onClose={()=>setCreateMode(false)} />}
        </Card>
    </Grid>;
}

export function FieldLine({fieldId, select, thesaurus}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const field = useSelector(state => selectTreeFieldById(state, fieldId));
    const type = useSelector(state => selectFieldTypeById(state, field.tree_field_type_id));
    const selectedField = useSelector(selectFieldSelected);
    const handleSelect = () => dispatch(setFieldSelected(fieldId));
    return <ListItem
        button
        onClick={handleSelect}
        className={clsx(classes.listItem, {[classes.listItemSelected]:fieldId===selectedField})}
    >
        <ListItemIcon className={classes.listItemIcon}>
            <Code />
        </ListItemIcon>
        <ListItemText className={classes.listItemText}>
            {tdb(field.title, field.name)}
        </ListItemText>
        <ListItemSecondaryAction className={classes.listItemIcon}>
            {type && type.name}
        </ListItemSecondaryAction>
    </ListItem>
}

// style of the layout
const useStyles = makeStyles((theme) => ({
    list: {
        padding: 0,
    },
    listItem: {
        padding: 0,
    },
    listItemSelected: {
        backgroundColor: '#909090'
    },
    listItemIcon: {
        minWidth: 30,
    },
    listItemText: {
        marginTop:0,
        marginBottom:0,
    },
}));