import React, {useEffect, useState} from "react";
import {FormControl, IconButton, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {Close, Search} from "@material-ui/icons";

let timeout = false;
export default function SearchTextArea({className, callBack, placeholder, size="small", variant="outlined", delay=1000, initValue=""}) {
    const [value, setValue] = useState(initValue);

    const handleValue = (event) => {
        setValue(event.target.value)
    }

    const handleKey = (event) => {
        if (event.keyCode === 27) {
            clearTimeout(timeout)
            setValue("");
        }
    }

    useEffect(()=>{
        clearTimeout(timeout)
        timeout = window.setTimeout(()=>callBack(value.toLowerCase()), delay)
    }, [value])

    return <TextField
        variant={variant}
        size={size}
        value={value}
        placeholder={placeholder}
        onChange={handleValue}
        onKeyDown={handleKey}
        className={className}
        InputProps={{
            style: {paddingRight: 0},
            startAdornment: <Search />,
            endAdornment: <IconButton size={"small"} onClick={()=>setValue("")}><Close /></IconButton>
        }}
    />
}

export function SearchCountResults({className, callBack, size, placeholder, variant, initValue, values=[50, 100, 200, 500, 1000], controlProps={}, selectProps={}}) {
    const [value, setValue] = useState(initValue);
    const handleValue = (event) => {
        setValue(event.target.value);
    }

    useEffect(()=>{
        if (value !== initValue)callBack(value);
    }, [value])

    useEffect(()=>{
        if (initValue) {
            setValue(initValue)
        }
    }, [initValue])

    return <FormControl variant={variant} className={className} size={size} placeholder={placeholder} {...controlProps}>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={handleValue}
            {...selectProps}
        >
            {values.map(v=>(<MenuItem value={v} key={v}>{v}</MenuItem>))}
        </Select>
    </FormControl>
}