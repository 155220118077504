import React from "react";
import {ThemeProvider, createTheme, makeStyles} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";
import {Switch, Route, NavLink, useParams} from "react-router-dom";
import {useTranslation}     from 'react-i18next';
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
// import Query from "./Query.jsx";
import Organizations from "./Organizations.jsx";
import Typography from "@material-ui/core/Typography";
import Organization from "./Organization";

const OrganizationsRouter = () => {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    // TODO improve breadbrumb
    return <Container disableGutters={true} maxWidth={"xl"}>
        <ThemeProvider theme={lightTheme}>
            <Switch>
                <Route exact path="/organizations" >
                    <Breadcrumbs separator="/">
                        <Link to="/" component={NavLink} className={classes.Link}>
                            {t('HomePageLink')}
                        </Link>
                        <Typography>
                            {t('OrganizationsLink')}
                        </Typography>
                    </Breadcrumbs>
                    <Organizations/>
                </Route>
                <Route path="/organizations/:organizationId">
                    <CurrentOrganization />
                </Route>
            </Switch>
        </ThemeProvider>
    </Container>;
};

function CurrentOrganization({}) {
    const {t, i18n} = useTranslation();
    const {organizationId} = useParams();
    const classes = useStyles();
    return <>
        <Breadcrumbs separator="/">
            <Link to="/" component={NavLink} className={classes.Link}>
                {t('HomePageLink')}
            </Link>
            <Link to="/organizations" component={NavLink} className={classes.Link}>
                {t('OrganizationsLink')}
            </Link>
            <Typography>
                Current
            </Typography>
        </Breadcrumbs>
        <Organization organizationId={organizationId} />
    </>
}

// Theme
const lightTheme = createTheme({
    ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    Link: {
        color: 'white'
    }
}));

export default OrganizationsRouter;