import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    Container, Typography, createTheme, ThemeProvider, TextField, LinearProgress,
    Dialog, DialogContent, DialogActions, DialogTitle, FormGroup, MenuItem,
    FormControl, InputLabel, FormHelperText, Select, IconButton,
    Card, CardContent, CardHeader, CardActions, Button, useTheme, Avatar
} from "@material-ui/core";
import defaultLightPalette from "../../services/defaultLightPalette";

import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

import {DataGrid} from "@material-ui/data-grid";
import {fetchOrganizations, selectAllOrganizations, selectOrganizationById, updateOrganization} from "../../store/entities/organizationsSlice";
import Link from "@material-ui/core/Link";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import ViewComfy from "@material-ui/icons/ViewComfy";
import EditIcon from "@material-ui/icons/Edit";

import Grid from "@material-ui/core/Grid";
import {stackCardHeight} from "../Dimensions";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {isMobile} from "react-device-detect";
import SearchTextArea from "../Misc/SearchTextField";
import {Group} from "@material-ui/icons";
import {useHistory} from "react-router-dom";

export default function Organizations() {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const status = useSelector(state => state.organizations.status);
    const organizations = useSelector(state => selectAllOrganizations(state));
    const [filter, setFilter] = useState();
    const [editingOrganization, setEditingOrganization] = useState(null);

    const [displayMode, setDisplayMode] = useState('table');
    const handleDisplayMode = (mode) => setDisplayMode(mode);

    // alert(status);
    useEffect(()=>{
        if (status === 'idle') {
            console.log("FETCHING USERS");
            dispatch(fetchOrganizations());
        }
    }, [status, dispatch]);

    useEffect(()=>{
        console.log("USERS", organizations);
    }, [organizations]);

    const getViewCell = (params) => {
        return <Button variant="contained" color="default" size="small">{t('Search')}</Button>
    };

    const getUrlCell = (params) => {
        return <Button variant="text" color="default" size="small" href={params.value} target={"_blank"} component={Link}>{t('Open')}</Button>
    };

    const handleEdit = (id) => setEditingOrganization(id)
    const handleUnEdit = () => setEditingOrganization(null)

    const handleDelete = (id) => {

    }

    const _renderName = (params) => {
        return params.row.firstName+' '+params.row.lastName
    }

    const _renderCounter = (params) => {
        if (params.field in params.row) {
            return params.row[params.field];
        }
        else if (params.field+'_count' in params.row) {
            const value = params.row[params.field+'_count'];
            return Array.isArray(value) ? value[0] : value;
        }
        return null;
    }

    const _hasKC = (params) => {
        if (params.row.id_kc && String(params.row.id_kc).length) {
            return params.row.id_kc;
        }
        return null;
    }

    const _actions = (params) => {
        return <>
            <IconButton>
                <EditIcon onClick={() => handleEdit(params.row.id)} />
            </IconButton>
            {/*<DeleteIcon onClick={() => handleDelete(params.row.id)} />*/}
        </>
    }
    
    const handleRowClick = (params) => {
        history.push(`/organizations/${params.row.id}`)
    }

    const columns = [
        {field:"name",          headerName:t("Name"),               flex:2},
        {field:"type",          headerName:t("Type"),               flex:1},
        {field:"collections",   headerName:t("Collection_other"),   flex:1, resizable: false, renderCell: _renderCounter, disableColumnMenu: true},
        {field:"images",        headerName:t("Images"),             flex:1, resizable: false, renderCell: _renderCounter, disableColumnMenu: true},
        {field:"objects",       headerName:t("Objects"),            flex:1, resizable: false, renderCell: _renderCounter, disableColumnMenu: true},
    ];


    const handleSetFilter = (value) => setFilter(value);
    const filtered = filter
        ? organizations.filter(row=>(
            row.name.toLowerCase().includes(filter.toLowerCase())
            || row.email.toLowerCase().includes(filter.toLowerCase())
        ))
        : organizations
    ;

    const rows = filtered.map(row=>({
        ...row
    }));
    
    // stack display
    const theme = useTheme();
    const [stacked, setStacked] = useState(!isMobile);
    useEffect(()=>{setStacked(!isMobile)}, [isMobile])
    const { height: screenHeight, width: screenWidth } = useWindowDimensions();
    const [contentHeight, setContentHeight] = useState(100);
    useEffect(()=>{
        setContentHeight(screenHeight
            - stackCardHeight.header
            - stackCardHeight.search
            - stackCardHeight.navBar
            - stackCardHeight.actions
            - stackCardHeight.bread
            - 2 * theme.spacing(2)
        )
    }, [screenHeight, screenWidth, stacked])

    return <Card>
        <CardHeader
            title={<Typography variant={"h5"}>{t('Organizations')} </Typography>}
            avatar={<Avatar><Group  color={"primary"} /></Avatar>}
            className={classes.mainCardHeader}
            
        />
        <CardContent className={classes.mainCardSearch}>
            <Grid>
                <SearchTextArea callBack={handleSetFilter} initValue={filter} />
            </Grid>
        </CardContent>
        <CardContent className={classes.mainCardContent}>
            <div style={{height:isMobile ? '' : contentHeight, overflowY: 'auto'}}>
                {status === "failed" && <LinearProgress color="secondary" />}
                {status === "succeeded" && <DataGrid
                    columns={columns}
                    rows={rows}
                    pageSize={20}
                    hideFooter={rows.length<100}
                    onCellDoubleClick={handleRowClick}
                />}
            </div>
        </CardContent>
        <CardActions className={classes.mainCardActions}>
            <Grid container justifyContent="flex-end">
                <Button variant={"contained"} color={"secondary"}>{t("CreateOrganization")}</Button>
            </Grid>
        </CardActions>
    </Card>
};

// Theme
const lightTheme = createTheme({
    ...defaultLightPalette
});

// style of the layout
const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(3),
        '& label': {
            backgroundColor: 'white !important',
            color: '#666 !important'
        }
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    searchField: {
        marginRight: theme.spacing(2)
    },
    toggleButtons: {
        height: 50,
    },
    mainCardHeader: {height: stackCardHeight.header},
    mainCardHelp: {height: stackCardHeight.help},
    mainCardSearch: {height: stackCardHeight.search},
    mainCardActions: {height: stackCardHeight.actions},
    mainCardContent: {
        paddingTop: 0,
        paddingBottom: 0
    },

}));
