/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Switch, Route, useHistory, NavLink} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import {useTranslation} from "react-i18next";

import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import {makeStyles} from "@material-ui/core/styles";

import {Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Badge, TextField, FormControl} from '@material-ui/core';

import {fetchCollectionObjects, selectObjectsByCollection, selectAllObjects} from "../../../store/entities/objectsSlice";
import {selectAllTreeCategories, selectTreeCategoryById} from "../../../store/entities/treeCategoriesSlice";
import WithRightButtons from "../../Misc/WithRightButtons";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import {AddCircle, Ballot, Bookmarks, DoneAll, RemoveCircle, ZoomIn, ZoomOut, ArtTrack, ViewList, ViewComfy, Edit, RemoveRedEye, SelectAll, MoreVert, FileCopy, Delete, Add, FileCopyOutlined, Search, Close} from "@material-ui/icons";
import {selectDrawerVisible, setDrawer} from "../../Selectors/Drawer/SelectorSlice";
import tdb from "../../../services/TranslateDB";

import CollectionObjectDnD from "../DnD/CollectionObjectDnD";
import CollectionObjectsDnD from "../DnD/CollectionObjectsDnD";
import {DataGrid} from "@material-ui/data-grid";

import {LinearProgress} from "@material-ui/core";
import NiceTooltip from "../../Misc/CustomToolTip";
import CollectionObjectCreate from "./CollectionObjectCreate";
import CollectionObject from "./CollectionObject";
import CollectionObjectThumb, {CollectionObjectThumbMemo} from "./CollectionObjectThumb";
import {selectCollectionDisplay, setCollectionDisplay} from "../../../store/reducers/preferencesSlice";
import {selectAllImages} from "../../../store/entities/imagesSlice";
import EditableCategory from "../Fields/EditableCategory";
import Fade from "@material-ui/core/Fade";
import CollectionObjectSimple, {CollectionObjectSimpleMemo} from "./CollectionObjectSimple";
import i18n from "i18next";
import {
    CollectionObjectsAssignValueToObjects, CollectionObjectsCopyObjectsInExistingCollection,
    CollectionObjectsCopyObjectsInNewCollection,
    CollectionObjectsDeleteObjects
} from "./CollectionObjectsActions";
import {selectCollectionById, updateCollection} from "../../../store/entities/collectionsSlice";
import SearchTextArea, {SearchCountResults} from "../../Misc/SearchTextField";
import ReactPaginate from "react-paginate";
import {Pagination} from "@material-ui/lab";
import {useLocation} from "react-router-dom/cjs/react-router-dom";

export default function CollectionObjects({collectionId, editing, fullPage, givenPath = null, showImages}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const {t} = useTranslation();
    const collection = useSelector(state => selectCollectionById(state, collectionId));
    
    /* This should work but dont behave like
        memoize result when called twice
        (like in investigation page)
    */
    
    // const objects = useSelector(state => selectObjectsByCollection(state, collectionId));
    const allObjects = useSelector(selectAllObjects)
    
    const rootPath = givenPath ? givenPath : `/collections/${collectionId}/`;

    
    
    //
    const [objects, setObjects] = useState([]);
    useEffect(()=>{
        setObjects(allObjects.filter((object) => object.collections.includes(collectionId)))
    }, [allObjects]);
    
    
    const [objectIds, setObjectIds] = useState([]);
    useEffect(()=>{
        if (objects) {
            setObjectIds(objects.map(o=>o.id))
        }
    }, [objects])
    
    
    const setCollectionParameter = (name, value) => {
        if (collection && (!(collection.parameters && typeof(collection.parameters) === "object" && name in collection.parameters) || value !== collection.parameters[name])) {
            dispatch(updateCollection({
                collectionId: collectionId,
                body: {
                    parameters: {
                        [name]: value
                    }
                }
            }))
        }

    }
    
    /*
    had to remove these because of conflict when several collections are shown at once
     */
    
    // DISPLAY MANAGEMENT
    // const display = useSelector(selectCollectionDisplay)
    // const handleDisplay = (event, mode) => dispatch(setCollectionDisplay(mode))
    
    const [display, setDisplay] = useState("grid");
    const handleDisplay = (event, mode) => {
        setDisplay(mode);
        setCollectionParameter('objectDisplay', mode);
    }
    
    const [cellSize, setCellSize] = useState(2);
    const handleCellSize = (event, size) => {
        setCellSize(size)
        setCollectionParameter('objectCellSize', size)
    };

    // GROUPING MANAGEMENT
    const [grouping, setGrouping] = useState(false);
    const handleGrouping = () => {
        setCollectionParameter('objectGrouping', !grouping)
        setGrouping(!grouping);
    }

    // SELECTION MANAGEMENT
    const [selection, setSelection] = useState([]);
    // const handleSelect = (objectId) => {
    //     console.log(this);
    //     let newSelection;
    //     if (selection.indexOf(objectId) === -1) {
    //         newSelection = multipleSelect ? [objectId, ...selection] : [objectId]
    //     } else {
    //         newSelection = [...selection];
    //         newSelection.splice(selection.indexOf(objectId), 1)
    //     }
    //     console.log({objectId, selection, newSelection})
    //     setSelection(s => (newSelection));
    // }

    const [version, setVersion] = useState(0);
    const [multipleSelect, setMultipleSelect] = useState(true);
    const handleMultipleSelect = () => {
        setMultipleSelect(m => !m)
        setVersion(s=>s+1)
    };
    
    // memoized select when multiple
    const memoSelectMultiple = useCallback(objectId => setSelection(state=>(
        state.indexOf(objectId) === -1 ? [objectId, ...state] : [...state].filter(id=>id !== objectId)
    )), [])

    // memoized select when single
    const memoSelectSingle = useCallback(objectId => setSelection(state=>(
        state.indexOf(objectId) === -1 ? [objectId] : []
    )), [])

    // emptySelection when mode change
    useEffect(() => {
        console.log("multipleSelect", multipleSelect)
        if (!multipleSelect && selection.length > 0) {
            setSelection([]);
        }
    }, [multipleSelect])

    // SELECTION ACTIONS
    const [selectionMenuAnchor, setSelectionMenuAnchor] = useState();
    const [showSelectionMenu, setShowSelectionMenu] = useState(false);
    const [currentSelectionAction, setCurrentSelectionAction] = useState("");
    const handleWithSelection = (event) => {
        setSelectionMenuAnchor(event.currentTarget)
        setShowSelectionMenu(true)
    }
    
    
    useEffect(()=>{
        console.log("selectionMenuAnchor", selectionMenuAnchor);
        console.log("showSelectionMenu", showSelectionMenu);
        console.log("currentSelectionAction", currentSelectionAction);
    }, [selectionMenuAnchor, showSelectionMenu, currentSelectionAction])
    
    const handleInvertSelect = () => {
        setSelection(p => filtered.filter(o => !p.includes(o.id)).map(f => f.id))
    }

    // DROP MANAGAGEMENT
    // const [dropAffected, setDropAffected] = useState();
    // useEffect(() => {
    //     setDropAffected(selection);
    // }, [selection])

    // SELECTOR DRAWER TOGGLE
    const selectorView = useSelector(selectDrawerVisible)
    const handleSelector = () => {
        dispatch(setDrawer(!selectorView))
    }

    const location = useLocation();
    // Open modal by pushing history
    const handleModalOpen = (objectId) => {
        console.log("before", window.location)
        history.push(rootPath + 'object/' + objectId);
        console.log("after")
    }

    // go next
    const handleModalNext = (objectId) => {
        const current = objectIds.indexOf(objectId)
        if (current === -1) return;
        const nextId = current === objectIds.length - 1 ? objectIds[0] : objectIds[current + 1]
        history.push(rootPath + 'object/' + nextId);
    }

    // go prev
    const handleModalPrev = (objectId) => {
        const current = objectIds.indexOf(objectId)
        if (current === -1) return;
        const nextId = current === 0 ? objectIds[objectIds.length - 1] : objectIds[current - 1]
        history.push(rootPath + 'object/' + nextId);
    }

    // back to collection
    const handleModalClose = () => {
        history.push(rootPath);
    }

    useEffect(() => {
        dispatch(fetchCollectionObjects(collectionId));
    }, [collectionId, dispatch]);

    // SEARCH
    const [search, setSearch] = useState();
    const [filtered, setFiltered] = useState([]);
    const handleSearch = (value) => {
        setSearch(value)
    }
    useEffect(()=>{
        if (search) {
            setFiltered(objects.filter(o=>o.name.includes(search)))
        }
        else {
            setFiltered(objects)
        }
    }, [search, objects]);

    useEffect(()=>{
        // TODO optimize depending on sizes
        if (filtered.length && selection.length) {
            const filteredIds = filtered.map(o=>o.id);
            setSelection(_selection=>_selection.filter(id=>filteredIds.includes(id)));
        }
    }, [filtered])

    const [limit, setLimit] = useState(50);
    const handleLimit = (value) => {
        setLimit(value)
        setCollectionParameter('objectLimit', value)
    }

    const [page, setPage] = useState(1);
    const handlePage = (event, value) => {
        setPage(value);
    }

    useEffect(()=>{
        if (collection.parameters) {
            if (collection.parameters.objectDisplay)setDisplay(collection.parameters.objectDisplay);
            if (collection.parameters.objectCellSize)setCellSize(collection.parameters.objectCellSize);
            if (collection.parameters.objectGrouping)setGrouping(collection.parameters.objectGrouping);
            if (collection.parameters.objectLimit) {setLimit(collection.parameters.objectLimit);}
        }
    }, [collection])

    const handleUnselect = (event) => {
        if (event.keyCode === 27) {
            setSelection([])
        }
    }

    // optimization
    const [hovered, setHovered] = useState();

    return <CardContent onKeyUp={handleUnselect} key={`KEY--${collectionId}`}>
        <Grid spacing={2} direction="row" justifyContent="space-between" alignItems="flex-start" container className={classes.zoneBar}>
            <Grid item style={{flexGrow: 1}}>
                <Typography variant="h5">{`${filtered.length !== objects.length ? filtered.length+'/':''} ${objects.length} ${t('CollectionObjets')}`}</Typography>
            </Grid>
            <Grid item>
                <Box flexWrap={"nowrap"}>
                    <SearchTextArea
                        className={classes.buttonGroup}
                        variant={"outlined"} size={"small"} placeholder={t("Search")}
                        callBack={handleSearch}
                        delay={500}
                    />
                    <SearchCountResults
                        className={classes.buttonGroup}
                        variant={"outlined"} size={"small"} placeholder={t("Search")}
                        values={[20, 50, 100, 200, 500, 1000]}
                        callBack={handleLimit}
                        initValue={limit}
                        //controlProps={{disabled: grouping===true}}
                    />
                    {filtered.length > limit &&
                        <Box className={classes.buttonBox}>
                            <Box className={classes.inButtonBox}>
                                <Pagination count={Math.ceil(filtered.length / limit)} shape="rounded" page={page} onChange={handlePage} />
                            </Box>
                        </Box>
                    }
                </Box>
            </Grid>
            <Grid item>
                <Box flexWrap={"nowrap"}>
                    {/* SELECTION */}
                    <ToggleButtonGroup className={classes.buttonGroup} aria-label={"display"}>
                        <NiceTooltip title={t("ObjectsMultipleSelection")} placement={"top-end"}>
                            <ToggleButton value={t("multipleSelect")} selected={multipleSelect} size="small"
                                          style={{height: 40}} onClick={handleMultipleSelect}>
                                <DoneAll/>
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("ObjectsInvertSelection")} placement={"top-end"}>
                            <ToggleButton value={t("invertSelect")} selected={false} disabled={!multipleSelect}
                                          size="small" style={{height: 40}} onClick={handleInvertSelect}>
                                <SelectAll/>
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("OperationsOnSelection")} placement={"top-end"}>
                            <ToggleButton value={t("invertSelect")} selected={false} disabled={selection.length === 0}
                                          size="small" style={{height: 40}} onClick={handleWithSelection}>
                                <Badge badgeContent={selection.length} color={"secondary"}>
                                    <MoreVert/>
                                </Badge>
                            </ToggleButton>
                        </NiceTooltip>
                        {/* SELECTION MENU */}
                        <Menu
                            open={showSelectionMenu}
                            anchorEl={selectionMenuAnchor}
                            onClose={() => setShowSelectionMenu(false)}
                            id={"selection-menu"}
                            anchorOrigin={{vertical: "top", horizontal: "right"}}
                            TransitionComponent={Fade}
                        >
                            <MenuList>
                                <MenuItem disabled button onClick={()=>setCurrentSelectionAction("assignValueToObjects")}>
                                    <ListItemIcon><Edit/></ListItemIcon>
                                    <ListItemText>{t('AssignFieldValue')}</ListItemText>
                                </MenuItem>
                                <MenuItem button onClick={()=>setCurrentSelectionAction("copyObjectsInNewCollection")}>
                                    <ListItemIcon><FileCopyOutlined/></ListItemIcon>
                                    <ListItemText>{t('copyObjectsInNewCollection')}</ListItemText>
                                </MenuItem>
                                <MenuItem button onClick={()=>setCurrentSelectionAction("copyObjectsInExistingCollection")}>
                                    <ListItemIcon><FileCopy/></ListItemIcon>
                                    <ListItemText>{t('CopyToCollection')}</ListItemText>
                                </MenuItem>
                                <MenuItem button onClick={()=>setCurrentSelectionAction("deleteObjects")}>
                                    <ListItemIcon><Delete/></ListItemIcon>
                                    <ListItemText>{t('Delete')}</ListItemText>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                        {/* SELECTION ACTIONS */}
                        {currentSelectionAction === "deleteObjects" &&
                            <CollectionObjectsDeleteObjects selection={selection} collectionId={collectionId} onClose={()=>setCurrentSelectionAction("")} />}
                        {currentSelectionAction === "assignValueToObjects" &&
                            <CollectionObjectsAssignValueToObjects selection={selection} collectionId={collectionId} onClose={()=>setCurrentSelectionAction("")} />}
                        {currentSelectionAction === "copyObjectsInNewCollection" &&
                            <CollectionObjectsCopyObjectsInNewCollection selection={selection} collectionId={collectionId} onClose={()=>setCurrentSelectionAction("")} />}
                        {currentSelectionAction === "copyObjectsInExistingCollection" &&
                            <CollectionObjectsCopyObjectsInExistingCollection selection={selection} collectionId={collectionId} onClose={()=>setCurrentSelectionAction("")} />}
                    </ToggleButtonGroup>



                    {/* GROUPING */}
                    <ToggleButtonGroup className={classes.buttonGroup}>
                        <NiceTooltip title={t("groupedView")} placement={"top-end"}>
                            <ToggleButton value="check" selected={grouping} size="small" style={{height: 40}}
                                          onClick={handleGrouping}>
                                <Ballot/>
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>

                    {/* SELECTOR */}
                    <ToggleButtonGroup className={classes.buttonGroup}>
                        <NiceTooltip title={t("ShowThesaurusPanel")} placement={"top-end"}>
                            <ToggleButton value="check" selected={selectorView} size="small" style={{height: 40}}
                                          onClick={handleSelector}>
                                <Bookmarks/>
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>
                </Box>
            </Grid>
            <Grid item>
                <Box flexWrap={"nowrap"}>

                    {/* DISPLAY */}
                    <ToggleButtonGroup className={classes.buttonGroup} aria-label={"display"}>
                        <NiceTooltip title={t("ObjectsGridView")} placement={"top-end"}>
                            <ToggleButton value="grid" size="small" selected={display === "grid"} style={{height: 40}}
                                          onClick={handleDisplay} aria-label={"grid"}>
                                <ViewComfy/>
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("ObjectsSimpleView")} placement={"top-end"}>
                            <ToggleButton value="simple" size="small" selected={display === "simple"}
                                          style={{height: 40}} onClick={handleDisplay} aria-label={"simple"}>
                                <ArtTrack/>
                            </ToggleButton>
                        </NiceTooltip>
                        <NiceTooltip title={t("ObjectsSheetView")} placement={"top-end"}>
                            <ToggleButton value="list" size="small" selected={display === "list"} style={{height: 40}}
                                          onClick={handleDisplay} aria-label={"sheet"}>
                                <ViewList/>
                            </ToggleButton>
                        </NiceTooltip>
                    </ToggleButtonGroup>

                    {/* IMAGE SIZE */}
                    <ToggleButtonGroup>
                        <ToggleButton size="small" style={{height: 40}} value={false}>
                            <ZoomOut/>
                            <Slider
                                style={{width: 80}}
                                className={classes.slider}
                                defaultValue={cellSize}
                                value={cellSize}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                onChange={handleCellSize}
                                step={1} marks min={1} max={5}
                            />
                            <ZoomIn/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Grid>
        </Grid>
        <Box className={classes.helpers}>
            {objects.length === 0 && (
                collection.objects_count === 0
                    ? <>
                        {collection.unclassified > 0
                            ? (editing && <Typography variant={"body2"}>{t('ObjectZoneHelper_DragImageToCreateFirstObject')}</Typography>)
                            : (<Typography variant={"body2"}>{t('ObjectZoneHelper_SendImages')}</Typography>)
                        }
                    </>
                    : <LinearProgress color={"secondary"} variant={"indeterminate"} />
            )}
            {/*{collection.objects_count===0 && editing && collection.unclassified > 0 && <Typography variant={"body2"}>{t('NoObjectWithImages')}</Typography>}*/}
            {/*{collection.objects_count===0 && editing && collection.unclassified === 0 && <Typography variant={"body2"}>{t('NoObjectNoImages')}</Typography>}*/}
            {collection.unclassified > 0 && !showImages && <Typography variant={"body2"}>{t('ObjectZoneHelper_ShowImagesToCreateObjectsFromImage')}</Typography>}
            {objects.length > 0 && <Typography variant={"body2"}>{t('ObjectZoneHelper_clickToSelect')}. {t('ObjectZoneHelper_doubleClickToOpen')}</Typography>}
        </Box>
        {
            grouping
                ? <CollectionObjectGrouping
                    collectionId={collectionId}
                    handleSelect={multipleSelect ? memoSelectMultiple : memoSelectSingle}
                    editing={editing}
                    cellSize={cellSize}
                    display={display}
                    actionPath={rootPath}
                    grouping={grouping}
                    objects={objects}
                    filtered={filtered}
                    selection={selection}
                    search={search}
                    limit={limit}
                    page={page}
                    version={version}
                />
                : <CollectionObjectSwitch
                    collectionId={collectionId}
                    handleSelect={multipleSelect ? memoSelectMultiple : memoSelectSingle}
                    editing={editing}
                    categoryId={null}
                    cellSize={cellSize}
                    display={display}
                    actionPath={rootPath}
                    grouping={grouping}
                    objects={objects}
                    filtered={filtered}
                    selection={selection}
                    search={search}
                    limit={limit}
                    page={page}
                    selectionInGroup={selection}
                    hovered={hovered}
                    setHovered={setHovered}
                    version={version}
                />
        }
        <Switch>
            <Route path={`${rootPath}add`}>
                <CollectionObjectCreate handleClose={handleModalClose} collectionId={collectionId}/>
            </Route>
            <Route path={`${rootPath}object/:objectId`}>
                <CollectionObject
                    handleClose={handleModalClose}
                    collectionId={collectionId}
                    next={handleModalNext}
                    prev={handleModalPrev}
                />
            </Route>
        </Switch>
    </CardContent>
}

// COMPONENT THAT DECIDE WHETHER SHEET OR GRID
function CollectionObjectSwitch(props) {
    return props.display === "list"
        ? <CollectionObjectsSheet {...props} />
        : <CollectionObjectsGrid {...props} />
}

// COMPONENT THAT SPLITS GROUPS
function CollectionObjectGrouping(props) {
    const {objects, filtered} = props
    const groups = {};
    filtered
        .map((object, i) => {
        if (object.category) {
            if (!groups[object.category])groups[object.category] = [];
            groups[object.category].push(object)
        }
        else {
            if (!groups[0])groups[0] = [];
            groups[0].push(object)
        }
    });
    return <Box>
        {Object.entries(groups).map(([categoryId, groupObjects]) => {
            return <CollectionObjectsGroup {...props} key={categoryId} categoryId={categoryId} groupObjects={groupObjects} />
        })}
    </Box>;
}

// COMPONENT FOR THE GROUP
function CollectionObjectsGroup(props) {
    const classes = useStyles();
    const {editing, selection, collectionId, categoryId, groupObjects} = props
    const category = useSelector(state => selectTreeCategoryById(state, categoryId));
    const [show, setShow] = useState(true);
    const handleShow = () => setShow(!show);

    // TODO: will be used for mass change
    const handleUpdate = (params) => {
        console.log("update", groupObjects, params);
    }

    const [selectionInGroup, setSelectionInGroup] = useState([]);
    useEffect(()=>{
        if (editing) {
            setSelectionInGroup(groupObjects
                .filter(object=>(selection.includes(object.id)))
                .map(object=>(object.id))
            );
        }
        else {
            setSelectionInGroup([])
        }
    }, [groupObjects, selection, editing])

    const [hovered, setHovered] = useState();

    const content = <>
        <WithRightButtons actions={<SelectAll />} className={classes.groupTitle}>
            <Button onClick={handleShow}>
                <IconButton>{show ? <RemoveCircle /> : <AddCircle />}</IconButton>
                <Typography variant={"h5"}>{category ? tdb(category.title) : "Unclassified"} ({groupObjects.length})</Typography>
            </Button>
        </WithRightButtons>
        {show && <CollectionObjectSwitch
            {...props}
            filtered={groupObjects}
            categoryId={categoryId}
            className={classes.groupSection}
            hovered={hovered}
            setHovered={setHovered}
            selectionInGroup={selectionInGroup}
        />}
    </>;

    return editing
        ? <CollectionObjectsDnD
            collectionId={collectionId}
            categoryId={categoryId}
            className={classes.groupDnD}
            onUpdate={handleUpdate}
            selection={props.selection}
            handleSelect={props.handleSelect}
            hovered={hovered}
            setHovered={setHovered}
            selectionInGroup={selectionInGroup}
        >
            {content}
        </CollectionObjectsDnD>
        : <>{content}</>;
}

// COMPONENT THAT DIPLAYS GRID
function CollectionObjectsGrid({limit, filtered, page, display, editing, categoryId, cellSize, selection, collectionId, handleSelect, actionPath, className, hovered, setHovered, selectionInGroup, version}) {
    const classes = useStyles();
    return <Grid container spacing={2} wrap="wrap" justifyContent="flex-start" className={className}>
        {filtered
            .slice(((page-1)*limit), limit * page)
            .map((object, index) => {
                const thumb = (display === 'grid')
                    ? <CollectionObjectThumbMemo
                        key={object.id}
                        objectId={object.id}
                        imageSize={cellSize}
                        actionPath={actionPath}
                        selected={selection.indexOf(object.id) > -1}
                        onSelect={(id)=>handleSelect(id)}
                        version={version}
                    />
                    : <CollectionObjectSimpleMemo
                        key={object.id}
                        objectId={object.id}
                        imageSize={cellSize}
                        editing={editing}
                        displayMode={display}
                        actionPath={actionPath}
                        selected={selection.indexOf(object.id) > -1}
                        onSelect={handleSelect}
                        version={version}
                    />;

                const content = (editing
                    ? <CollectionObjectDnD
                        key={object.id}
                        collectionId={collectionId}
                        objectId={object.id}
                        selection={selection}
                        selectionInGroup={selectionInGroup}
                        hovered={hovered}
                        setHovered={setHovered}
                        categoryId={categoryId}
                    >{thumb}</CollectionObjectDnD>
                    : thumb)

                return (display === 'grid')
                    ? (<Grid item key={object.id}>{content}</Grid>)
                    : (<Grid item key={object.id} xl={3} lg={4} md={6} sm={12} xs={12}>{content}</Grid>)
                ;
            }
        )}
        {
            filtered.length > limit && <div className={classes.andMore}>+{filtered.length - limit}</div>
        }
    </Grid>
}

// COMPONENT THAT DIPLAYS SHEET
function CollectionObjectsSheet({objects, filtered, search, display, editing, cellSize, selection, collectionId, handleSelect, actionPath, className}) {
    // TODO : optimize this
    let categories = useSelector(selectAllTreeCategories);
    const images = useSelector(selectAllImages);
    const language = i18n.language;

    const {t} = useTranslation();

    const renderImage = (params) => {
        if (!params.value)return null;
        return <img src={params.value} />
    }

    const renderOpener = (params) => {
        return <IconButton to={`/collections/${collectionId}/object/${params.value}`} component={NavLink} >
            {editing ? <Edit /> : <RemoveRedEye /> }
        </IconButton>
    }

    const handleChange = (body) => {
        console.log(body)
    }

    const renderEditCategory = (params) => {
        return <EditableCategory
            initValue={params.row.category}
            field={"categoryId"}
            onChange={handleChange}
            textClasses={{
                root: {
                    width: '100%'
                }
            }}
            inputClasses={{
                root: {
                    width: '100%'
                },
                input: {
                    borderWidth: 0
                }
            }}
        />
    }

    const renderField = (params) => {

    }

    const renderCategory = (params) => {
        const category = params.value;
        return editing
            ?   <EditableCategory
                    initValue={category ? category.id : null}
                    field={"categoryId"}
                    onChange={handleChange}
                />
            : <Typography classes={{}}>
                {category ? tdb(category.title) : 'Unknown'}
            </Typography>
    }

    const renderThesaurus = (params) => {

    }

    const renderCustom = (params) => {

    }

    let initCols = [
        {field: "image",    headerName: "image",    width: 100, disableColumnMenu: true, renderCell: renderImage, sortable: false},
        {field: "id",       headerName: " ",        width: 50,  disableColumnMenu: true, renderCell: renderOpener, sortable: false, align:'center'},
        {field: "name",     headerName: "Name",     width: 150, disableColumnMenu: true, editable: true},
        {field: "category", headerName: "Category", width: 200, disableColumnMenu: true, renderCell: renderCategory},
    ]
    const [fields, setFields] = useState([]);
    const [cols, setCols] = useState(initCols);

    useEffect(()=>{
        if (fields) {
            //const _cols = [...initCols, ...fields];
            setCols(initCols.concat(fields))
        }
    }, [fields])

    const [rows, setRows] = useState([]);
    useEffect(()=>{
        if (filtered) {
            let _fieldNames = [];
            let _fields = [];
            let _rows = filtered.map(object=>{
                let _category = categories.find(cat=>cat.id === object.category);
                let image = images.find(i=>i.object_id===object.id);
                let _row = {
                    id: object.id,
                    name: object.name,
                    image: image ? image.urls.small : null,
                    category: _category
                }
                if (object.fields) {
                    Object.keys(object.fields).map(fieldName => {
                        if (object.fields[fieldName] !== null) {
                            _row["f-"+fieldName] = object.fields[fieldName];
                            if (!_fieldNames.includes(fieldName)) {
                                _fieldNames.push(fieldName);
                                _fields.push({
                                    field: "f-" + fieldName,
                                    headerName: fieldName,
                                    disableColumnMenu: true,
                                    width: 150,
                                    renderCell: renderAdditionalCol
                                })
                            }
                        }
                    })
                }
                return _row;
            })
            setRows(_rows);
            setFields(_fields)
        }
    }, [objects])

    const renderAdditionalCol = (params) => {
        if (!params.value) {
            return null;
        }
        const _type = typeof params.value;
        if (['string', 'number'].includes(_type)) {
            return params.value;
        }
        else if (_type === "object") {
            if (params.value.title)
                return tdb(params.value.title);
            else if (params.value.title)
                return params.value.value;
            else
                return "object"
        }
        return "n/c";
    }

    const getHeight = () => {
        return null;
    }

    return <div style={{height: getHeight()}}>
        <DataGrid
            resizable={true}
            density={"compact"}
            columns={cols}
            rows={rows}
            //editMode={'cell'}
            checkboxSelection={editing}
            //isCellEditable={editing}
            disableSelectionOnClick
            autoHeight
            hideFooter
        />
    </div>
}

// ---------------------------------------------------------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    zoneBar: {
        marginBottom: theme.spacing(2)
    },
    buttonGroup: {
        marginRight: theme.spacing(2),
        marginTop:2,
    },
    buttonBox: {
        marginTop:2,
        height: 40,
        display: 'inline-block',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.grey[500],
    },
    inButtonBox: {
        display: 'flex',
        height: 38,
        alignItems: 'center',
    },
    groupTitle: {
        //marginTop: ".5rem",
        //marginBottom: ".5rem",
    },
    groupSection: {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(4)
    },
    groupDnd: {
        paddingLeft: theme.spacing(1)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    slider: {
        color: 'rgba(0,0,0,1)',
        marginLeft: 3,
        marginRight: 3,
    },
    andMore: {
        display: 'inline-block',
        fontSize: 14,
        padding: 12
    },
    helpers: {
        marginBottom: theme.spacing(1)
    }
}));