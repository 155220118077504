import React, {useState, useEffect, createRef, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import {makeStyles, ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import defaultLightPalette from "../../services/defaultLightPalette";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Container from '@material-ui/core/Container';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useTranslation} from "react-i18next";
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { NavLink, useParams} from "react-router-dom";

import {
    addNewSurvey,
    fetchSurveyById,
    selectSurveyById,
    markAsRead
} from "../../store/entities/surveysSlice";
import {launchSearch} from "../../store/entities/searchSlice";

import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


import client_ai from '../../api/client_ai';
import client from '../../api/client';

import SearchResult from "../Identification/SearchResult";


const Survey = () => {
    const { surveyId } = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();
    const fileInput = createRef();
    const {t, i18n} = useTranslation();
    const form = useRef(null);
    const [isReady, setIsReady] = useState(false);


    const survey = useSelector(state => selectSurveyById(state, surveyId));

    const doMarkAsRead = () => {
      dispatch(markAsRead(surveyId));
    }


    useEffect(()=>{
        dispatch(fetchSurveyById(surveyId)).then((value) => {
          var data ={
              result: value.payload.last_result.result
          }
          dispatch(launchSearch(data)).then(() =>{
            setIsReady(true)
          });
        })
    }, [surveyId, dispatch]);




    return <>{ survey ?
    <Card>
      <Container maxWidth="md">
        <CardHeader title={survey ? t('Survey') : t('CreateNewSurvey')} />
        <CardContent>
            {/* TODO: form here */}
            <Grid container spacing={2} justify={"center"}>
                <Grid md={4} sm={6} xs={12} item>
                    {
                        <img src={survey.search.image.url} style={{ width: '100%', height: 'auto'}}   />
                    }
                </Grid>
                <Grid md={4} sm={6} xs={12} item>
                  <h3>{t('SurveySettings')}</h3>
                    <h4>{survey ? survey.name : null}</h4>
                    <Divider variant="middle" />
                    {survey.target.map((value, index) => {return <div key={index} style={{ margin: '10px'}}>{value.title}</div>})}
                    <h3>{t('SearchTerms')}</h3>
                    <div>
                    {survey.search.terms.map((value, index) => {return <div key={index} style={{ margin: '10px'}}>{value}</div>})}
                    </div>
                    <h3>{t('Informations')}</h3>
                    <div>
                      {t('Created')} : {new Date(survey.created).toLocaleDateString('en-EN')}
                    </div>
                    <div>
                      {t('LastChecked')} : {new Date(survey.last_cron).toLocaleDateString('en-EN')}
                    </div>
                    <Button variant="contained" color="default" startIcon={< CheckCircleIcon />} component='label' onClick={doMarkAsRead}>
                          {t('Mark as read')}
                      </Button>
                </Grid>
            </Grid>
        </CardContent>
      </Container>
      {
        isReady ? <SearchResult /> : null
      }
   </Card>
  : null }; </>
};

// style of the layout
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    input: {
        marginTop: ".5rem",
        width: '100%'
    }
}));

export default Survey;
