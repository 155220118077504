import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import LayoutIcon from './components/LayoutRemainingIcons.jsx'
import LayoutNoIcon from './components/LayoutPersistent'
import {getCurrentUser, fetchCurrentUser} from './store/entities/currentUserSlice';
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";
import {DndProvider} from "react-dnd";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {isMobile} from "react-device-detect";
import moment from "moment";
import {Button, Link} from "@material-ui/core";

/**
 * Make sure that current user is properly loaded before displaying layout
 * TODO : better display of loading and errors
 */

moment.locale('fr', {
  months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  monthsParseExact : true,
  weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact : true,
  longDateFormat : {
    LT : 'HH:mm',
    LTS : 'HH:mm:ss',
    L : 'DD/MM/YYYY',
    LL : 'D MMMM YYYY',
    LLL : 'D MMMM YYYY HH:mm',
    LLLL : 'dddd D MMMM YYYY HH:mm'
  },
  calendar : {
    sameDay : '[Aujourd’hui à] LT',
    nextDay : '[Demain à] LT',
    nextWeek : 'dddd [à] LT',
    lastDay : '[Hier à] LT',
    lastWeek : 'dddd [dernier à] LT',
    sameElse : 'L'
  },
  relativeTime : {
    future : 'dans %s',
    past : 'il y a %s',
    s : 'quelques secondes',
    m : 'une minute',
    mm : '%d minutes',
    h : 'une heure',
    hh : '%d heures',
    d : 'un jour',
    dd : '%d jours',
    M : 'un mois',
    MM : '%d mois',
    y : 'un an',
    yy : '%d ans'
  },
  dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
  ordinal : function (number) {
    return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse : /PD|MD/,
  isPM : function (input) {
    return input.charAt(0) === 'M';
  },
  // In case the meridiem units are not separated around 12, then implement
  // this function (look at locale/id.js for an example).
  // meridiemHour : function (hour, meridiem) {
  //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
  // },
  meridiem : function (hours, minutes, isLower) {
    return hours < 12 ? 'PD' : 'MD';
  },
  week : {
    dow : 1, // Monday is the first day of the week.
    doy : 4  // Used to determine first week of the year.
  }
});

export const LOGIN_URL = `http://kc.parcs.solutions/auth/realms/Parcs/protocol/openid-connect/auth?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}&response_type=code&scope=openid`;


const App = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const status = useSelector((state) => state.currentUser.status);
  const error = useSelector((state) => state.currentUser.error);
  
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchCurrentUser(null));
    }
  }, [status, dispatch]);
  
  const style = {
    width: "100%",
    height: '100vh',
    display: "flex",
    backgroundColor: '#ccc',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  };
  
  let content;
  switch (status) {
    case "loading": content =
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
    ; break;
    case "success":
      content = isMobile
          ? <DndProvider backend={TouchBackend} options={{ignoreContextMenu:true}}>
            <LayoutNoIcon />
          </DndProvider>
          : <DndProvider backend={HTML5Backend}>
            <LayoutIcon />
          </DndProvider>;
      break;
    case "failed":
      content = <div style={style}>
        <p>No session opened</p>
        <Button variant={"contained"} component={Link} href={LOGIN_URL}>
          Login
        </Button>
      </div>;
      window.location = LOGIN_URL
      break;
    default:
      content = <div style={style}>
        <p>Unknown status : {status}</p>
        <p>
          <Button variant={"contained"} component={Link} href={LOGIN_URL}>Login</Button>
        </p>
      </div>;
    
  }
  
  const theme = currentUser && themes[currentUser.type] ? themes[currentUser.type] : previsionTheme
  
  return <ThemeProvider theme={theme}>
    {content}
  </ThemeProvider>;
};

const UserError = () => {
  return <Container>
    <Card>
      <CardContent>
        no User
      </CardContent>
    </Card>
  </Container>;
};

const commonTheme = {
  mixins: {
    toolbar: {
      minHeight: 64
    }
  },
  typography: {
    h1: {
      fontSize: "2.8rem"
    },
    h2: {
      fontSize: "2.4rem"
    },
    h3: {
      fontSize: "2.1rem"
    },
    h4: {
      fontSize: "1.8rem"
    },
    h5: {
      fontSize: "1.5rem"
    },
    h6: {
      fontSize: "1.2rem"
    }
  }
}

// Building my theme here
const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#000000',
    },
    background: {
      default: '#7c7c7c',
    }
  },
  ...commonTheme
});

// Building my theme here
const previsionTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#5689c9',
      contrastText: "#fff"
    },
    secondary: {
      main: "#9c61b5",
      contrastText: "#000"
    },
    background: {
      default: '#fff',
    }
  },
  ...commonTheme
});

// Building my theme here
const weaponsTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#a30000',
      contrastText: "#fff"
    },
    secondary: {
      main: "#FFC300",
      contrastText: "#fff"
    },
    background: {
      default: '#fff',
    }
  },
  ...commonTheme
});

const themes = {
  "admin": darkTheme,
  "inventory": darkTheme,
  "lea": darkTheme,
  "prevision": previsionTheme,
  "weapons-user": weaponsTheme
}

export default App;
